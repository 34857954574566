import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Tab } from "semantic-ui-react"
import FileManager from "../util/FileManager/FileManager"
import LinkEvidence from "../util/linkEvidence/linkEvidence"
import TextEvidence from "../util/textEvidence/textEvidence"

import "./library.sass"

function Library(){
    const [indexTab, setIndexTab] = useState(0)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const panes = [
        {
            menuItem: t('FILES'),
            render: () => <FileManager/>
        },
        {
            menuItem: t('TEXTS'),
            render: () => <TextEvidence/>
        },
        {
            menuItem: t('LINKS'),
            render: () => <LinkEvidence/>
        }
    ]

    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: `${t('LIBRARY')}`
        })
    })

    return(
        <div className="library setFlex1 marginBot setFlex">
            <div className="tabContainer setFlex1 setFlexColumn">
                <Tab
                    panes={panes}
                    menu={{secondary: true, pointing: true}} 
                    activeIndex={indexTab}
                    onTabChange={(e, { activeIndex }) => setIndexTab(activeIndex)}
                />
            </div>
        </div>
    )
}

export default Library