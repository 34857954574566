import axios from 'axios'
import { SITE_URL } from '../../util/util'
const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
    (config) => {
        const acc = localStorage.getItem('AT')
        const idc = localStorage.getItem('c-id')
        if(config.url.match("/savexlsx") || config.url.match("/uploadTaskFile") || config.url.match("/uploadUserTaskFiles") || config.url.match("/file"))
            config.headers = {
                'Content-Type': 'multipart/form-data',
                'Accept': 'multipart/form-data'
            }
        else
            config.headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        config.headers["X-API-KEY"] = acc
        config.headers["connection-id"] = idc

        return config
    },
    (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.response){
            if(error.response.status === 403){
                if(localStorage.getItem('AT'))
                        localStorage.removeItem('AT')
                        localStorage.removeItem('c-id')
                        window.location.href = SITE_URL
            }
        }
        return Promise.reject(error)
    }
)

export default axiosInstance;