import axiosForDownloads from "./axiosForDownloads";
import { API_URL } from "../../../util/util";

const api = {
    get: (endpoint, params = {}) => {
        const url = new URL(`${API_URL}${endpoint}`)
        for (const key in params) {
            url.searchParams.append(key, params[key])
        }
        return axiosForDownloads.get(url.href)
    },
    post: (endpoint, body) => axiosForDownloads.post(`${API_URL}${endpoint}`, body),
    put: (endpoint, body) => axiosForDownloads.put(`${API_URL}${endpoint}`, body),
    delete: (endpoint, body) => axiosForDownloads.delete(`${API_URL}${endpoint}`, body)
}

export default api;