import { useState } from "react"
import { useTranslation } from "react-i18next"
import ActivateCompany from "../registration/activateCompany/activateCompany"
import Logs from "./logs/logs"
import { Tab } from "semantic-ui-react"

function AdminConfiguration(){
    const { t } = useTranslation()

    const [tabIndex, setTabIndex] = useState(0)

    const renderPanes = () => {
        return [
            {menuItem: t('COMPANIES'), render: () => <ActivateCompany/>},
            {menuItem: t('USAGE_LOGS'), render: () => <Logs/>}
        ]
    }
    return(
        <div className="configuration setFlex1 setFlex">
            <Tab 
                panes={renderPanes()} 
                activeIndex={tabIndex} 
                onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
                className="setFlex1 setFlexColumn"
                menu={{ secondary: true, pointing: true }}
                />
        </div>
    )
}

export default AdminConfiguration