import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Form, Button, Grid, Message } from "semantic-ui-react"
import { Store } from 'react-notifications-component';
import { getAreas, getSimpleUserList, postNewUser, getRoles, updateUser, getPositions, getFrameworksExp, checkEmail, postUserToCompany } from '../util/requests';

import { formatToTranslate, renderNotification, useDidUpdateEffect } from '../../util/util';
import { RegExEmail, RegExNumber } from '../util/regex'
import { useTranslation } from 'react-i18next';

function AddUserForm({isEdit = false, closeModal=null, data={}, getUserData=null}){
    const { t } = useTranslation()

    // react-router-dom navigator
    const navigate = useNavigate();

    // State hooks
    const [areas, setAreas] = useState([])
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [positions, setPositions] = useState([])
    const [frameworks, setFrameworks] = useState([])

    const defaultUserCheck = {
        userExists: null,
        userRelated: null
    }

    const [{
        userExists,
        userRelated
    }, setUserCheck] = useState(defaultUserCheck)
    
    // STATES
    const [idUser, setIdUser] = useState()
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [reportsTo, setReportsTo] = useState()
    const [selectedRol, setSelectedRol] = useState()
    const [position, setPosition] = useState([])
    const [fwExperience, setFWExperience] = useState([])
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')

    const [loading, setLoading] = useState(false)
    
    // ERROR STATES
    const [nameError, setNameError] = useState()
    const [lastNameError, setLastNameError] = useState()
    const [selectedRolError, setSelectedRolError] = useState()
    const [passwordError, setPasswordError] = useState()
    const [emailError, setEmailError] = useState()
    const [phoneError, setPhoneError] = useState()
    const [areaError, setAreaError] = useState()
    // State hooks


    const runCheckEmail = async  () => {
        const correct =  await checkInput.email()
        if(correct){
            setLoading(true)
            const body = {
                email
            }
            checkEmail(body).then(res => {
                if(res.data && res.data["related"]){
                    setUserCheck({
                        userExists: res.data["idUser"],
                        userRelated: true
                    })
                }else{
                    setUserCheck({
                        userExists: res.data["idUser"],
                        userRelated: false
                    })
                }
                setLoading(false)
            }).catch(err =>{
                if(err.response && err.response.status === 404){
                    setUserCheck({
                        userExists: false,
                        userRelated: false
                    })
                }else{
                    Store.addNotification(
                        renderNotification("error", "Email verification", "There was an error on email verification")
                    )
                    setUserCheck(defaultUserCheck)
                }
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        setUserCheck(defaultUserCheck)
    }, [email])

    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            runCheckEmail()
        }, 800)
        return () => {
            clearTimeout(delayFunction)
        }
    }, [email])

    useEffect(() => {
        if(isEdit){
            if(data.hasOwnProperty("idUser") && data["idUser"]){
                setIdUser(data["idUser"])
            }
            if(data.hasOwnProperty("name") && data["name"]){
                setName(data["name"])
            }
            if(data.hasOwnProperty("lastName") && data["lastName"]){
                setLastName(data["lastName"])
            }
            if(data.hasOwnProperty("companies") && data["companies"].length > 0 && data["companies"][0].hasOwnProperty("reportsTo") && data["companies"][0]["reportsTo"]){
                setReportsTo(data["companies"][0]["reportsTo"]["idUser"])
            }
            if(data.hasOwnProperty("companies") && data["companies"].length > 0 && data["companies"][0].hasOwnProperty("rol") && data["companies"][0]["rol"]){
                setSelectedRol(data["companies"][0]["rol"]["idRol"])
            }
            if(data.hasOwnProperty("email") && data["email"]){
                setEmail(data["email"])
            }
            if(data.hasOwnProperty("phone") && data["phone"]){
                setPhone(data["phone"])
            }
            if(data.hasOwnProperty("frameworksExperience") && data["frameworksExperience"]){
                setFWExperience(data["frameworksExperience"].map(itm => itm.idFrameworkExperience))
            }
            if(data.hasOwnProperty("positions") && data["positions"]){
                setPosition(data["positions"].map(itm => itm.idPosition))
            }
        }
    }, [data])

    useEffect(()=>{
        getSimpleUserList().then(response => {
            let options = response.data ? response.data : []
            options = options.map(itm => {
                return {
                    key: `usr-${itm.idUser}`,
                    text: `${itm.name} ${itm.lastName}`,
                    value: itm.idUser
                }
            })
            setUsers(options)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('CONNECTION_ERROR'), t('CONNECTION_ERROR_MSG'))
            )
        })
        getAreas().then(response => {
            // console.log(response.data)
            let options = response.data ? response.data : []
            options = options.map(itm => {
                return {
                    key: `area-${itm.idArea}`, 
                    text: itm.name, 
                    value: itm.idArea
                }
            })
            setAreas(options)
        }).catch((err)=>{
            Store.addNotification(
                renderNotification("error", t('CONNECTION_ERROR'), t('CONNECTION_ERROR_MSG_2'))
            )
        })
        getRoles().then(res => {
            setRoles(res.data.map(itm => {
                return {
                    key: `area-${itm.idRol}`, 
                    text: t(formatToTranslate(itm.name)), 
                    value: itm.idRol
                }
            }))
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('CONNECTION_ERROR'), t('CONNECTION_ERROR_MSG_3'))
            )
        })
        getPositions().then(res => {
            const pos = res.data ? res.data : []
            setPositions(
                pos.map(itm => ({
                    key: `user-position-${itm.idPosition}`,
                    text: itm.name,
                    value: itm.idPosition
                }))
            )
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('CONNECTION_ERROR'), t('CONNECTION_ERROR_MSG_3'))
            )
        })

        getFrameworksExp().then(res => {
            const fwExp = res.data ? res.data : []
            setFrameworks(fwExp.map(itm => ({
                key: `pramework-experience-${itm.idFrameworkExperience}`,
                text: itm.name,
                value: itm.idFrameworkExperience
            })))
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('CONNECTION_ERROR'), t('CONNECTION_ERROR_MSG_3'))
            )
        })
    }, [])

    const checkInput = {
        email: async () =>{
            if(userExists || isEdit){
                return true
            }
            if(!email){
                setEmailError({
                    content: t('ERR_EMAIL'),
                })
                return false
            }else if(email.length < 6 || email.length > 120){
                setEmailError({
                    content: t('ERR_EMAIL_LENGTH')
                })
                return false
            }else if(!RegExEmail.test(String(email))){
                setEmailError({
                    content: t('ERR_EMAIL_VALID')
                })
                return false
            }else{
                setEmailError(null)
                return true
            }
        },
        rol: async () => {
            if(!selectedRol){
                setSelectedRolError({
                    content: t('ERR_ROL')
                })
                return false
            }else{
                setSelectedRolError(null)
                return true
            }
        },
        name: async () => {
            if(userExists){
                return true
            }
            if(!name){
                setNameError({
                    content: t('ERR_USER_NAME'),
                })
                return false
            }else if(name.length < 2 || name.length > 45){
                setNameError({
                    content: t('ERR_USER_NAME_LENGTH')
                })
                return false
            }else{
                setNameError(null)
                return true
            }
        },
        lastName: async () => {
            if(userExists){
                return true
            }
            if(userExists){
                return true
            }
            if(!lastName){
                setLastNameError({
                    content: t('ERR_LAST_NAME')
                })
                return false
            }else if(lastName.length < 2 || lastName.length > 45){
                setNameError({
                    content: t('ERR_LAST_NAME_LENGTH')
                })
                return false
            }else{
                setLastNameError(null)
                return true
            }
        },
        phone: async () => {
            if(userExists){
                return true
            }
            if(!phone){
                setPhoneError({
                    content: t('ERR_PHONE')
                })
                return false
            }else if(phone.length < 7 || phone.length > 15){
                setPhoneError({
                    content: t('ERR_PHONE_LENGTH')
                })
                return false
            }else if(!RegExNumber.test(String(phone))){
                setPhoneError({
                    content: t('ERR_PHONE_VALID')
                })
                return false
            }else{
                setPhoneError(null)
                return true
            }
        },
        password: async () => {
            if(userExists){
                return true
            }
            if(!password){
                setPasswordError({
                    content: t('ERR_PASSWORD')
                })
                return false
            }else if(password.length < 8 || password.length > 128){
                setPasswordError({
                    content: t('ERR_PASSWORD_LENGTH')
                })
                return false
            }else{
                setPasswordError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    const addUserToCompany = async () =>{
        const check = await checkInput.checkAll()
        if(!check){
            return
        }
        let addUser = {
            idUser: userExists,
            idRol: Number(selectedRol),
            idPosition: position,
            idFrameworkExperience: fwExperience,
        }
        if(reportsTo){
            addUser["reportsTo"] = reportsTo
        }
        postUserToCompany(addUser).then(response => {
            Store.addNotification(
                renderNotification("success", t('NEW_USER'), t('NEW_USER_MSG'))
            )
            navigate("/dashboard/users")
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('NEW_USER'), t('ADD_NEW_USER_ERR'))
            )
        })
    }

    const createUser = async () => {
        const check = await checkInput.checkAll()
        if(!check){
            return
        }
        if(userExists){
            addUserToCompany()
            return
        }
        let newUser = {
            name,
            lastName,
            //idArea: area,
            idRol: Number(selectedRol),
            idPosition: position,
            idFrameworkExperience: fwExperience,
            phone,
            email,
            password
        }
        if(reportsTo){
            newUser["reportsTo"] = reportsTo
        }
        postNewUser(newUser).then(response => {
            Store.addNotification(
                renderNotification("success", t('NEW_USER'), t('NEW_USER_MSG'))
            )
            navigate("/dashboard/users")
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('NEW_USER'), t('ADD_NEW_USER_ERR'))
            )
        })
    }

    const editUser = async () => {
        if(isEdit && idUser){
            const check = await checkInput.checkAll(["password"])
            if(!check){
                return
            }
            let updateData = {}
            if(name !== data["name"]){
                updateData["name"] = name
            }
            if(lastName !== data["lastName"]){
                updateData["lastName"] = lastName
            }
            console.log("reportsTo", reportsTo, "data", data["reportsTo"])
            if(reportsTo !== data["reportsTo"]){
                updateData["reportsTo"] = reportsTo
            }
            updateData["idRol"] = Number(selectedRol)
            updateData["idPosition"] = position
            updateData["idFrameworkExperience"] = fwExperience
            // if(email !== data["email"]){
            //     updateData["email"] = email
            // }
            if(phone !== data["phone"]){
                updateData["phone"] = phone
            }
            if(Object.keys(updateData).length > 0){
                updateUser(idUser, updateData).then(res => {
                    Store.addNotification(
                        renderNotification("success", t('USER_UPDATE'), t('USER_UPDATE_MSG'))
                    )
                    if(getUserData){
                        getUserData()
                    }
                    if(closeModal){
                        closeModal()
                    }
                }).catch(err => {
                    Store.addNotification(
                        renderNotification("error", t('USER_UPDATE_ERR'), t('USER_UPDATE_ERR_MSG'))
                    )
                })
            }
        }
    }

    return (
        <Form className="formStyle">
            {
                !isEdit &&
                <Fragment>
                    <Form.Field>
                        <label>{t('EMAIL')}</label>
                        <Form.Input 
                            type="email" 
                            loading={loading}
                            placeholder={t('EMAIL')}
                            onChange={(e, data) => setEmail(data.value)} 
                            value={email}
                            onBlur={() => checkInput.email()}
                            error={emailError}
                        />
                    </Form.Field>
                    {
                        userExists !== null && userRelated !== null ?
                            <Message color='blue'>
                                {
                                    userExists ?
                                        userRelated ?
                                        `"${email}" is already registered for this company`
                                        :
                                        `"${email}" already exists at simplix, fill the data below and add it to your company!`
                                    :
                                        `"${email}" does not exists on simplix, create his account!`
                                }
                            </Message>
                        :
                            null
                    }
                </Fragment>
            }
            {
                (isEdit || (userRelated === false)) &&
                <Fragment>
                    <Form.Field>
                        <label>{t('REPORTS_TO')}</label>
                        <Form.Dropdown
                            placeholder="Select supervisor"
                            search
                            selection
                            onChange={(e, data)=> setReportsTo(data.value)}
                            value={reportsTo}
                            options={users}
                            clearable
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t('ROL')}</label>
                        <Form.Dropdown
                            placeholder="Select rol"
                            selection
                            onChange={(e, {value}) => setSelectedRol(value)}
                            value={selectedRol}
                            onBlur={() => checkInput.rol()}
                            options={roles}
                            error={selectedRolError}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t('POSITION')}</label>
                        <Form.Dropdown
                            placeholder='Select positions'
                            onChange={(e, { value }) => setPosition(value)}
                            value={position}
                            options={positions}
                            selection
                            multiple
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t('FRAMEWORK_EXPERIENCE')}</label>
                        <Form.Dropdown
                            placeholder='Select Framework Experiences'
                            onChange={(e, { value }) => setFWExperience(value)}
                            value={fwExperience}
                            options={frameworks}
                            selection
                            multiple
                        />
                    </Form.Field>
                </Fragment>
            }
            {
                (isEdit || (userExists === false && userRelated === false)) &&
                <Fragment>
                    <Form.Field>
                        <label>{t('NAME')}</label>
                        <Form.Input 
                            type="text" 
                            placeholder={t('NAME')}
                            onChange={(e, data) => setName(data.value)} 
                            value={name}
                            onBlur={() => checkInput.name()}
                            error={nameError}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t('LAST_NAME')}</label>
                        <Form.Input 
                            type="text" 
                            placeholder={t('LAST_NAME')}
                            onChange={(e, data) => setLastName(data.value)} 
                            value={lastName}
                            onBlur={() => checkInput.lastName()}
                            error={lastNameError}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t('PHONE')}</label>
                        <Form.Input 
                            type="tel" 
                            placeholder={t('PHONE')}
                            onChange={(e, data) => setPhone(data.value)} 
                            value={phone}
                            onBlur={() => checkInput.phone()}
                            error={phoneError}
                        />
                    </Form.Field>
                    {
                        !isEdit &&
                            <Form.Field>
                                <label>{t('PASSWORD')}</label>
                                <Form.Input 
                                    placeholder={t('PASSWORD')}
                                    type="password" 
                                    onChange={(e, data) => setPassword(data.value)} 
                                    onBlur={() => checkInput.password()}
                                    error={passwordError}
                                />
                            </Form.Field>
                    }
                </Fragment>
            }
            {
                (isEdit || (userExists !== null && userRelated === false)) &&
                    <Grid>
                        <Grid.Column width={16}>
                            <Button 
                                type="submit" 
                                className='buttonBlue'
                                onClick={isEdit ? editUser : createUser} 
                                floated='right'>
                                    {isEdit ? t('UPDATE') : t('SAVE')}
                            </Button>
                            {
                                isEdit &&
                                <Button 
                                    className='buttonDarkGray'
                                    onClick={()=> closeModal()} 
                                    floated={'right'}>{t('CLOSE')}
                                </Button>
                            }
                        </Grid.Column>
                    </Grid>
            }
        </Form>
    )
}

export default AddUserForm