
import { useDispatch } from "react-redux"
import DashHeader from "../dashHeader"
import "../dashboard.sass"
import DashPartnerMenu from "./dashPartnerMenu"
import { useEffect, useState } from "react"
import { partnerData } from "../../util/requests"
import { Outlet, useNavigate } from "react-router-dom"
import { Image } from "semantic-ui-react"

function Partner(){
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loaded, setLoaded] =  useState(false)

    useEffect(() => {
        partnerData().then(response => {
            if(!response.data["partner"]){
                navigate('/dashboard')
            }
            dispatch({
                type: 'SET_USER',
                userName: `${response.data["name"]} ${response.data["lastName"]}`
            })
            dispatch({
                type: 'SET_USER_ID',
                idUser: response.data["idUser"]
            })
            dispatch({
                type: 'SET_PARTNER',
                partner: response.data["partner"] ? response.data["partner"] : false
            })
            dispatch({
                type: 'SET_ULTRA',
                ultraUser: response.data["ultraUser"] ? response.data["ultraUser"] : false
            })
        })
        setLoaded(true)
    }, [])
    return(
        <div className="dashboardClass">
            <div className="classContainer">
                <div className="sideMenu">
                    <div className="logoContainer" onClick={() => navigate("/partner/")}>
                        <Image src="/logo_svg.svg"/>
                    </div>
                    <DashPartnerMenu/>
                </div>
                <div className="dashboardContent">
                    <DashHeader partnerDashboard={true}/>
                    {
                        loaded &&
                        <div className="dashboardMainContent">
                            <Outlet/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Partner