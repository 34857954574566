import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal } from "semantic-ui-react";
import { getCompanyCategories, partnerCreateCompany } from "../../../util/requests";
import { Store } from "react-notifications-component";
import { renderNotification } from "../../../../util/util";

function CreateCompany({openModal, closeModal, callback=null}){
    const { t } = useTranslation()

    const [companyName, setCompanyName] = useState("")
    const [companyType, setCompanyType] = useState(null)
    const [companySize, setCompanySize] = useState("")

    const [companyNameError, setCompanyNameError] = useState(null)
    const [companyTypeError, setCompanyTypeError] = useState(null)
    const [companySizeError, setCompanySizeError] = useState(null)

    const [companyCategories, setCompanyCategories] = useState([])

    useEffect(() => {
        initComponent()
    }, [])

    const initComponent = () => {
        getCompanyCategories().then(res => {
            const data = res.data ? res.data : []
            setCompanyCategories(
                data.map((itm, ix) => ({
                    key: `category-${itm["idCategory"]}-${ix}`,
                    value: itm["idCategory"],
                    text: itm["name"]
                }))
            )
        }).catch(err => {
            Store.addNotification(
                renderNotification('error', t('ERROR_CATEGORIES'), t('ERROR_CATEGORIES_MSG'))
            )
        })
    }

    const postCompany = async () => {
        const checked = await checkInput.checkAll()
        if(!checked){
            return
        }
        const data = {
            companyName,
            numberEmployees: companySize,
            companyType
        }
        partnerCreateCompany(data).then(response => {
            if(callback){
                callback()
            }
            closeModal(true)
        }).catch(err => {
            renderNotification("error", "Company creation", "There was an error with the request")
            console.log(err)
        })
    }

    const numberController = (e, { value }) => {
        const v = Number(value)
        if(isNaN(v) && v !== ""){
            return
        }
        
        setCompanySize(value.replace('.', ''))
    }

    const checkInput = {
        companyName: async () => {
            if(!companyName){
                setCompanyNameError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(companyName.length > 100){
                setCompanyNameError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else{
                setCompanyNameError(null)
                return true
            }
        },
        companyType: async() => {
            if(!companyType){
                setCompanyTypeError({
                    content: t('ERR_EMPTY_OPTION')
                })
                return false
            }else{
                setCompanyTypeError(null)
                return true
            }
        },
        companySize: async() => {
            if(!companySize){
                setCompanySizeError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else{
                setCompanySizeError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    return(
        <Modal
            dimmer='blurring'
            open={openModal}
            onClose={closeModal}
        >
            <Modal.Header>
                {
                    t('NEW_COMPANY')
                }
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>
                            {t('COMPANY_NAME')}
                        </label>
                        <Form.Input
                            type="text"
                            placeholder={t('COMPANY')}
                            value={companyName}
                            error={companyNameError}
                            onChange={(e, { value })=> setCompanyName(value)}
                            onBlur={checkInput.companyName}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>
                            {t('NUMBER_EMPLOYEES')}
                        </label>
                        <Form.Input
                            type="text"
                            placeholder="0"
                            value={companySize}
                            error={companySizeError}
                            onChange={numberController}
                            onBlur={() => checkInput.companySize}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>
                            {t('COMPANY_CATEGORY')}
                        </label>
                        <Form.Dropdown
                            options={companyCategories}
                            value={companyType}
                            error={companyTypeError}
                            onChange={(e, { value }) => setCompanyType(value)}
                            onBlur={checkInput.companyType}
                            search
                            selection
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => postCompany()}>
                    {t('SAVE')}
                </Button>
                <Button onClick={() => closeModal(false)}>
                    {t('CANCEL')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default CreateCompany