import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Grid, Header, Icon, Image, Modal } from "semantic-ui-react";

import "./recoverPassword.sass";
import { checkPWRecoveryToken, recoverPassword } from "../util/requests";
import { Store } from "react-notifications-component";
import { renderNotification } from "../../util/util";
import { PASS_REGEX } from "../util/regex";

export default function RecoverPassword() {
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate()

    const { token } = useParams();

    const { t } = useTranslation();

    useEffect(() => {

    }, [])

    const restorePassword = async () => {
        const check = await checkInput.checkAll()
        if(!check){
            return
        }
        const data = {
            password,
        }
        try{
            const response = await recoverPassword(token, data)
            setModal(true)
        }catch(err){
            Store.addNotification(
                renderNotification("error", t("ERROR"), t("ERROR_RESTORE_PASSWORD"))
            )
        }
    }

    const checkInput = {
        "password": async () => {
            if(!password){
                setPasswordError({
                    content: t('ERR_NEW_PASSWORD')
                })
                return false
            }else if(!PASS_REGEX.test(password)){
                setPasswordError({
                    content: t('ERR_PASSWORD_REGEX')
                })
                return false
            }else{
                setPasswordError(null)
                return true
            }
        },
        "confirmPassword": async () => {
            if(!confirmPassword){
                setConfirmPasswordError({
                    content: t('ERR_NEW_PASSWORD_COMPROBATION')
                })
                return false
            }else if(confirmPassword !== password){
                setConfirmPasswordError({
                    content: t('ERR_NEW_PASSWORD_COMPROBATION_DIFF')
                })
                return false
            }else{
                setConfirmPasswordError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    const okModal = () => {
        setModal(false)
        navigate("/")
    }

    const testToken = async (t) => {
        try{
            const response = await checkPWRecoveryToken(t)
            if(response.status !== 200){
                navigate("/")
            }
        }catch(err){
            navigate("/")
        }
    }
    
    useEffect(() => {
        if(!token){
            navigate("/")
        }
        testToken(token)
    }, [])

    return (
        <Grid className="recoverPassword noMargin" centered style={{backgroundImage: "url(/bg-login.png)"}}>
            <Grid.Column computer={8} tablet={10} mobile={10}>
                <Grid centered>
                    <Grid.Column computer={6} tablet={9} mobile={10}>
                        <Image src="/white-mainicon.png"  className="chooseFWImg"/>
                        <p className='welcome'>
                            {t("WELCOME!")}
                        </p>
                        <Form>
                            <Form.Field>
                                <label className="whiteColor centerText">{t('NEW_PASSWORD')}:</label>
                                <Form.Input
                                    type='password'
                                    placeholder='********'
                                    onChange={(e, {value}) => setPassword(value)}
                                    value={password}
                                    error={passwordError}
                                    onBlur={() => checkInput.password()}
                                    fluid
                                    required
                                />
                            </Form.Field>
                            <Form.Field>
                                <label className="whiteColor centerText">{t('CONFIRM_NEW_PASSWORD')}:</label>
                                <Form.Input
                                    type='password'
                                    placeholder='********'
                                    onChange={(e, {value}) => setConfirmPassword(value)}
                                    value={confirmPassword}
                                    error={confirmPasswordError}
                                    onBlur={() => checkInput.confirmPassword()}
                                    fluid
                                    required
                                />
                            </Form.Field>
                            <Grid centered>
                                <Grid.Column className="marginTop" width={14}>
                                    <Button fluid className='buttonBlue' onClick={() => restorePassword()}>{t('CONTINUE')}</Button>
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Modal
                basic
                onClose={() => okModal()}
                onOpen={() => setModal(true)}
                open={modal}
                size='small'
            >
                <Header icon>
                    <Icon name='info'/>
                    {t('PASSWORD_RESTORED')}
                </Header>
                <Modal.Content>
                    <p>{t('PASSWORD_RESTORED_MESSAGE')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color="blue" onClick={() => okModal()}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    )
}