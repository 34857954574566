import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import ReactQuill from "react-quill"
import { Button, Input, Modal } from "semantic-ui-react"
import { quillFormats, quillModules, renderNotification } from "../../../util/util"
import { postNewText } from "../requests"

import "./newText.sass"

function NewText({open, setOpen, callback}){
    const { t } = useTranslation()
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")

    useEffect(() => {
        
    }, [open])

    const postText = () => {
        const body = {
            title,
            text
        }
        postNewText(body).then(res => {
            Store.addNotification(
                renderNotification("success", t('POST_TEXT'), t('POST_TEXT_SUCCESS'))
            )
            if(callback){
                callback()
            }
            setTitle("")
            setText("")
            setOpen(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('POST_TEXT'), t('POST_TEXT_SUCCESS'))
            )
        })
    }

    return(
        <Modal
            onClose={() => setOpen(false)}
            opnOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>{t('NEW_TEXT')}</Modal.Header>
            <Modal.Content>
                <div className="newText setFlexColumn">
                    <div className="marginBot editableTitleContainer">
                        <Input 
                            value={title}
                            onChange={({target}) => setTitle(target.value)}
                            placeholder={t('SET_A_TITLE')}
                            fluid/>
                    </div>
                    <div className="flexOverflow quillContainer">
                        <ReactQuill
                            theme="snow"
                            value={text}
                            onChange={setText}
                            modules={quillModules}
                            formats={quillFormats}
                            placeholder={t('SET_TEXT')}
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className="buttonBlue"
                    onClick={() => postText()}>
                    {t('POST_TEXT')}
                </Button>
                <Button 
                    className="buttonDarkGray"
                    onClick={() => setOpen(false)}>
                    {t('CANCEL')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default NewText