import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from 'semantic-ui-react';
import './simpleButtonMenu.sass'

function SimpleButtonMenu({ initial, setSection, items}){
    const [active, setActive] = useState(initial)
    const isMounted = useRef(false);
    const navigate = useNavigate()

    const setFramework = (value) => {
        setActive(value)
        navigate("/dashboard/frameworks")
    }

    useEffect(()=>{
        if(isMounted.current){
            setSection(active)
            // console.log("EFFECT")
        }else{
            isMounted.current = true
        }
    //eslint-disable-next-line
    }, [active, setSection])
    // console.log("INITIAL", initial)
    return(
        <Grid className='SimpleButtonMenu'>
            {items.map((itm, ix) => <div key={itm.name + ix}>
                <Button 
                    className={active === itm.value ? 'buttonWhiteBlue BWBActive' : 'buttonWhiteBlue'}
                    onClick={() => setFramework(itm.value)}>{itm.name}</Button>
            </div>)}
        </Grid>
    )
}

export default SimpleButtonMenu;