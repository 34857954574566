import { Fragment, useEffect, useRef, useState } from "react";
import { useDidUpdateEffect } from "../../../../util/util";
import { Handle, NodeResizer, Position } from "reactflow";
import DiamondShape from "./diamondShape";

export default function DiamondNode ({ id, data, selected }){
	const [text, setText] = useState(data.label)
	const textareaRef = useRef(null);
    
	useDidUpdateEffect(() => {
		const delayFunction = setTimeout(() => {
            data.update(id, {label: text})
        }, 500)
		return () => {
			clearTimeout(delayFunction)
		}
	}, [text])

	useEffect(() => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = 'auto'; // Reset height to recalculate
			textarea.style.height = textarea.scrollHeight + 'px'; // Set to scroll height to expand as needed
		}
    }, [text]);

    return (
		<Fragment>
			<NodeResizer 
				minWidth={100} 
				minHeight={100} 
				handleClassName='handleResize'
				lineClassName='handleLineResize'
				isVisible={selected}
				keepAspectRatio
			/>
			<Handle type='target' id='a' position={Position.Top} />
			<DiamondShape>
				<textarea
					type="textarea" 
					ref={textareaRef}
					value={text} 
					onChange={({target}) => setText(target.value)}
					style={{
						background: 'transparent',
						border: 'none',
						textAlign: 'center',
						outline: 'none',
						width: '100%',
						overflow: 'visible',
						resize: 'none',
					}}
				/>
			</DiamondShape>
			<Handle
				type="source"
				position={Position.Left}
				id="b"
			/>
			<Handle
				type="source"
				position={Position.Right}
				id="c"
			/>
		</Fragment>
    );
};