import { Fragment, useEffect, useRef, useState } from "react";
import { useDidUpdateEffect } from "../../../../util/util";
import { Handle, NodeResizer, Position } from "reactflow";
import RectangleShape from "./rectangleShape";

export default function RectangleNode({id, data, selected}){
	const [text, setText] = useState(data.label)

	const textareaRef = useRef(null);

	useDidUpdateEffect(() => {
		const delayFunction = setTimeout(() => {
            data.update(id, {label: text})
        }, 500)
		return () => {
			clearTimeout(delayFunction)
		}
	}, [text])

	useEffect(() => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = 'auto'; 
			textarea.style.height = textarea.scrollHeight + 'px'; 
		}
	}, [text]);

	let { node_connections } = data
	node_connections = node_connections ? node_connections : []
	const targetHandle = node_connections.filter(itm => itm.type.toLowerCase() === 'target')
	const sourceHandle = node_connections.filter(itm => itm.type.toLowerCase() === 'source')
	return (
		<Fragment>
			<NodeResizer 
				handleClassName='handleResize'
				lineClassName='handleLineResize'
				isVisible={selected}
			/>
			<RectangleShape>
				<textarea
					type="textarea" 
					ref={textareaRef}
					value={text} 
					onChange={({target}) => setText(target.value)}
					style={{
						background: 'transparent',
						border: 'none',
						textAlign: 'center',
						outline: 'none',
						minWidth: '100%',
						width: 'auto',
						overflow: 'visible',
						resize: 'none',
						height: 100
					}}
				/>
			</RectangleShape>
			{
				node_connections.length === 0 ? (
					<Fragment>
						<Handle
							id={"a"}
							type={"target"}
							position={Position.Top}
						/>
						<Handle
							id={"b"}
							type={"source"}
							position={Position.Bottom}
						/>
					</Fragment>
				)
				:
				<Fragment>
					{
						targetHandle.map((itm, ix) => (
							<Handle
								key={ix}
								id={itm.id}
								type={itm.type}
								position={Position.Top}
								style={{left : targetHandle.length === 1 ? '50%' : `${(ix+1) * 100 / targetHandle.length}%`}}
							/>
						))
					}
					{
						sourceHandle.map((itm, ix) => (
							<Handle
								key={ix}
								id={itm.id}
								type={itm.type}
								position={Position.Bottom}
								style={{left : sourceHandle.length === 1 ? '50%' : `${(ix+1) * 100 / sourceHandle.length}%`}}
							/>
						))
					}
				</Fragment>
			}
		</Fragment>
	)
}