import { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import { Button, Checkbox, Divider, Form, Grid, Input, Item, Label, List, Modal, Pagination, Popup } from "semantic-ui-react"
import { renderNotification, useDidUpdateEffect } from "../../../util/util"
import ConfirmModal from "../confirmModal/ConfirmModal"
import { deleteLink, getLinks, postNewLink, putLink } from "../requests"

import "./linkEvidence.sass"

const LinkEvidence = forwardRef(({
    idKey="idLink",
    simple=false,
    selectedLinks=[],
    setSelectedLinks,
    searchParams={},
    deleteRelation
}, ref) => {

    useImperativeHandle(ref, () => ({
        reload(){
            getElements()
        }
    }))

    const simpleList = !!setSelectedLinks || simple
    const [search, setSearch] = useState("")
    const [elements, setElements] = useState([])
    const [linkEditable, setLinkEditable] = useState("")
    const [linkEditableError, setLinkEditableError] = useState(null)
    
    const modalDefault = {
        modalOpen: false,
        modalId: null
    }
    const modalDelete = {
        deleteOpen: false,
        idDelete: null
    }

    
    const [{ modalOpen, modalId }, setModal] = useState(modalDefault)

    const [{idDelete, deleteOpen}, setDelete] = useState(modalDelete)

    // PAGINATION
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    
    const { t } = useTranslation()

    const getElements = () => {
        let params = {
            ...searchParams,
            page
        }
        if(search){
            params["search"] = search
        }
        getLinks(params).then(res => {
            setElements(res.data && res.data.elements ? res.data.elements : [])
            setTotalPages(res.data && res.data.totalPages ? res.data.totalPages : 1)
        }).catch(err =>  {
            Store.addNotification(
                renderNotification("error", t('GET_ELEMENT_LINK'), t('GET_ELEMENT_LINK_ERROR'))
            )
        })
    }

    const saveLink = async () => {
        
        if(!linkEditable){
            return
        }
        if(!(await checkInput.checkAll())){
            return
        }
        const data = {
            link: linkEditable
        }
        if(!modalId){
            postNewLink(data).then(res => {
                Store.addNotification(
                    renderNotification("success", t('NEW_LINK'), t('NEW_LINK_MSG'))
                )
                getElements()
                setModal(modalDefault)
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('NEW_LINK'), t('NEW_LINK_ERROR'))
                )
            })
        }else{
            putLink(modalId, data).then(res => {
                Store.addNotification(
                    renderNotification("success", t('UPDATE_LINK'), t('UPDATE_LINK_MSG'))
                )
                getElements()
                setModal(modalDefault)
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('UPDATE_LINK'), t('UPDATE_LINK_ERROR'))
                )
            })
        }
    }

    const removeLink = () => {
        if(!idDelete){
            return
        }
        deleteLink(idDelete).then(res => {
            Store.addNotification(
                renderNotification("success", t('DELETE_LINK'), t('DELETE_LINK_MSG'))
            )
            getElements()
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('DELETE_LINK'), t('DELETE_LINK_MSG_ERR'))
            )
        })
    }

    useEffect(() => {
        getElements()
    }, [page])

    useEffect(() => {
        if(modalId){
            const ix = elements.findIndex(itm => itm[idKey] === modalId)
            if(ix !== -1)
                setLinkEditable(elements[ix].link)
        }
    }, [modalId])

    useEffect(() => {
        if(!modalOpen){
            setLinkEditable("")
        }
    }, [modalOpen])

    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                getElements()
            }
        }, 800)
        return () => {
            clearTimeout(delayFunction)
        }
    }, [search])

    const renderElements = (items = []) => {
        const openLink = (e, link) => {
            e.stopPropagation()
            window.open(link, "_blank")
        }
        const toggleCheck = (id, related) => {
            let ignore = false
            let newLinks = [...selectedLinks]
            const ix = newLinks.findIndex(link => link === id)        
            if(ix !== -1){
                newLinks.splice(ix, 1)
            }else{
                if(!related){
                    newLinks.push(id)
                }
            }
            if(!ignore){
                setSelectedLinks(newLinks)
            }
            return () => { ignore = true }
        }
        return(
            <Item.Group divided>
                {
                    items.map((itm, ix) => {
                        return(
                            <Item 
                                key={`item-details-${itm[idKey]}-${ix}`} 
                                className={simpleList && setSelectedLinks ? "clickableItem" : ""} 
                                onClick={simpleList && setSelectedLinks ? () => toggleCheck(itm[idKey], itm.related) : null}>
                                {
                                    simpleList && setSelectedLinks &&
                                    <div className="checkBoxContainer">
                                        <Checkbox
                                            checked={selectedLinks.findIndex(link => link === itm[idKey]) !== -1}
                                            // onChange={() => toggleCheck(itm[idKey])}
                                        />
                                    </div>
                                }
                                <Item.Image 
                                    size={simpleList ? "tiny" : "small"}
                                    src={itm.image && itm.image.image ? itm.image.image : "/image.png"}
                                    className={"imageContainer"}
                                    />
                                <Item.Content>
                                    <Item.Header>{itm.title ? itm.title : "-"}</Item.Header>
                                    <Item.Meta>
                                        {
                                            simpleList ? 
                                                <span>{itm.link}</span>
                                            :
                                                <a href={itm.link} target="_blank">{itm.link}</a>
                                        }
                                    </Item.Meta>
                                    {
                                        !simpleList && 
                                        <Item.Description>
                                            <p>
                                                {itm.description}
                                            </p>
                                        </Item.Description>
                                    }
                                    {
                                        itm.related === 1 &&
                                            <List.Description>
                                                <Popup
                                                    content={t('ATTACHED_DESC')}
                                                    trigger={
                                                        <Label color="green">
                                                            {t('ATTACHED')}
                                                        </Label>
                                                    }
                                                    position="right center"/>
                                            </List.Description>
                                    }
                                    <Item.Extra>
                                        <p>{t('DATE')}: {itm.date} | {t('LAST_UPDATE')}: {itm.lastUpdate}</p>
                                        <p>
                                        {t('POSTED_BY')}: {`${itm.postedBy && itm.postedBy.name ? itm.postedBy.name : '-'} 
                                            ${itm.postedBy && itm.postedBy.lastName ? itm.postedBy.lastName : '-'}`}
                                        </p>
                                        {
                                            simpleList ?
                                            <Fragment>
                                                <Button
                                                    floated='right'
                                                    icon="external alternate"
                                                    className="buttonBlue"
                                                    onClick={(e) => openLink(e, itm.link)}
                                                />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <Button 
                                                    floated='right' 
                                                    color="red"
                                                    onClick={() => setDelete({deleteOpen: true, idDelete: itm[idKey]})}
                                                >
                                                    {t('DELETE')}
                                                </Button>
                                                <Button 
                                                    floated='right' 
                                                    className="buttonBlue"
                                                    onClick={() => setModal({modalOpen: true, modalId: itm[idKey]})}>
                                                    {t('EDIT')}
                                                </Button>
                                            </Fragment>
                                        }
                                        {
                                            deleteRelation && 
                                            <Button
                                                color="red"
                                                icon="delete"
                                                onClick={() => deleteRelation(itm[idKey])}
                                            />
                                        }
                                    </Item.Extra>
                                </Item.Content>
                            </Item>
                        )
                    })
                }
            </Item.Group>
        )
    }

    const validUrl = async (url) => {
        try{
            const formated_url = new URL(url)
            if(formated_url.protocol === "https:" || formated_url.protocol === "http:"){
                return true
            }
            return false
        }catch(err){
            return false
        }
    }

    const checkInput = {
        link: async () => {
            if(linkEditable.length === 0){
                setLinkEditableError({
                    content: t('LINK_EMPTY')
                })
                return false
            }else{
                const isValid = await validUrl(linkEditable)
                if(!isValid){
                    setLinkEditableError({
                        content: t('NOT_A_LINK')
                    })
                    return false
                }else{
                    setLinkEditableError(null)
                    return true
                }
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    return(
        <div className="linkEvidence">
            <Grid>
                <Grid.Column width={16} className="setFlexColumn">
                    <div className="marginBottom">
                        <Input
                            icon='search'
                            placeholder={`${t('SEARCH')}...`}
                            value={search}
                            onChange={({ target }) => setSearch(target.value)}
                            fluid
                        />
                    </div>
                    <div className="linkListContainer flexOverflow">
                            {
                                renderElements(elements)
                            }
                    </div>
                    <div className="centerText">
                        <Pagination
                            activePage={page}
                            onPageChange={(e, { activePage }) => setPage(activePage)}
                            totalPages={totalPages}
                        />
                    </div>
                    <Divider/>
                    <div>
                        <Button
                            className="buttonBlue"
                            floated="right"
                            onClick={() => setModal({...modalDefault, modalOpen: true})}>
                            {t('NEW_LINK')}
                        </Button>
                    </div>
                </Grid.Column>
                <Modal
                    size="large"
                    open={modalOpen}
                    onClose={() => setModal(modalDefault)}
                >
                    <Modal.Header>
                        {
                            modalId ? 
                                t('EDIT_LINK')
                                :
                                t('POST_NEW_LINK')
                        }
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                <label>{t('LINK')}</label>
                                <Form.Input
                                    value={linkEditable}
                                    onChange={({ target }) => setLinkEditable(target.value)}
                                    onBlur={() => checkInput.link()}
                                    error={linkEditableError}
                                />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={() => setModal(modalDefault)}
                            className='buttonDarkGray'
                        >
                            {
                                t('CLOSE')
                            }
                        </Button>
                        <Button
                            onClick={() => saveLink()}
                            className='buttonBlue'
                        >
                            {
                                t('SAVE')
                            }
                        </Button>
                    </Modal.Actions>
                </Modal>
                <ConfirmModal
                    open={deleteOpen}
                    type="delete"
                    setOpen={() => setDelete(modalDelete)}
                    title={t('DELETE_LINK')}
                    description={t('DELETE_LINK_QUESTION')}
                    callback={() => removeLink()}
                />
            </Grid>
        </div>
    )
})

export default LinkEvidence