import { useCallback } from "react"
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import { Icon, List, Progress } from "semantic-ui-react"
import "./dropFiles.sass"

function DropFiles({myFiles, setMyFiles, loading=0, title=null, description=null, error=false, errorMsg=null, multiple=true}){
    const { t } = useTranslation()
    const onDrop = useCallback(acceptedFiles => {
        if(multiple){
            setMyFiles([...myFiles, ...acceptedFiles])
        }else{
            setMyFiles([...acceptedFiles])
        }
    })

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        multiple
    });

    const removeFile = file => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const files = myFiles.map((file, ix) => (
        <List.Item key={`file-selected-${ix}`}>
            <Icon name="close" onClick={()=> removeFile(file)}/>
            <List.Content>
                <List.Header>{file.path}</List.Header>
                <List.Description>{file.size} bytes</List.Description>
            </List.Content>
        </List.Item>
    ));

    return(
        <div className="dropFiles">
            <h3 className="centerText">{title ? title : t('UPLOAD_NEW_FILES')}</h3>
            <h4>{description ? description : ""}</h4>
            <section className="container">
                <aside>
                    <h3>{t('FILES')}</h3>
                    <List>
                        {files}
                    </List>
                </aside>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>{t('DRAG_N_DROP')}</p>
                </div>
                    {
                        loading ? 
                            <Progress percent={loading > 100 ? 100 : loading} success={!error} error={error}>
                                {!error ? 
                                    (loading < 100 ? t('LOADING_FILES') : t('UPLOAD_SUCCESS')) : 
                                    (errorMsg ? errorMsg : t('UPLOAD_ERROR'))}
                            </Progress>
                        : null
                    }
            </section>
        </div>
    )
}

export default DropFiles;