
export default function OvalShape({ children, style={} }){
    return(
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: "100%",
            height: "100%",
            backgroundColor: '#FFF',
            color: '#333',
            border: '1px solid #222',
            borderRadius: '30px',
            padding: '10px',
            ...style
        }}>
                {children}
        </div>
    )
}