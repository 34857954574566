import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal } from "semantic-ui-react";


function CompanySelection({open, closeModal, companies=[]}){
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [companyOptions, setCompanyOptions] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null)



    useEffect(() => {
        setCompanyOptions(
            companies.map((itm, ix) => (
                {
                    key: `itm-${itm.idCompany}-${ix}`,
                    value: itm.idCompany,
                    text: itm.companyName
                }
            ))

        )
    }, [companies])

    const selectCompany = () => {
        localStorage.setItem('c-id', selectedCompany)
        closeModal()
        navigate("/dashboard")
    }

    
    const close = () => {
        localStorage.removeItem('AT')
        closeModal()
        navigate("/")
    }

    return(
        <Modal
            dimmer='blurring'
            open={open}
            onClose={close}
        >
            <Modal.Header>
                {t('SELECT_COMPANY')}
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    placeholder={t('SELECT_COMPANY')}
                    search
                    fluid
                    selection
                    options={companyOptions}
                    value={selectedCompany}
                    onChange={(e, { value }) => setSelectedCompany(value)}
                >

                </Dropdown>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={selectCompany} primary>
                    {t('SELECT_COMPANY')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default CompanySelection;