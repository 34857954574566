import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

export default function EmptyChart(){
    const { t } = useTranslation();
    // Start Managing Implementation Tasks!
    return(
        <Message>
            <Message.Header>{t('START_MANAGING')}</Message.Header>
            <p>{t('START_MANAGING_DESC')}</p>
        </Message>
    )
}