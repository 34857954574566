import { useTranslation } from "react-i18next"
import CompanyConfiguration from "./companyConfiguration"
import { Tab } from "semantic-ui-react"
import DiagramList from "./diagramList"
import { useState } from "react"
import "./diagramTabs.sass"

export default function DiagramTabs(){
    const [indexTab, setIndexTab] = useState(0) 
    const { t } = useTranslation()

    const panes = [
        {
            menuItem: t('DIAGRAMS'),
            render: () => <Tab.Pane attached={false}>
                <DiagramList/>
            </Tab.Pane>
        },
        {
            menuItem: t('COMPANY_INFO'),
            render: () => <Tab.Pane attached={false}>
                <CompanyConfiguration/>
            </Tab.Pane>
        }
    ]

    return(
        <div className="diagramTabs">
            <Tab
                className="tabContainer setFlex1 setFlexColumn"
                panes={panes}
                menu={{secondary: true, pointing: true}} 
            />
        </div>
    )
}