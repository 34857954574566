import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Button, Table, Icon, Confirm, Popup, Dropdown } from "semantic-ui-react"
import { assignedCount, formatToTranslate, readParents, renderNotification } from '../../util/util';
import MyTask from '../task/myTask';
import NewTask from '../task/newTask';
import Overview from '../util/overview/overview';
import { Store } from 'react-notifications-component';
import { deleteTask, getProjectById, getTaskStatus } from '../util/requests';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HitoIcon } from '../util/svg/square45.svg'

function Project(){
    const { t } = useTranslation()
    const [project, setProject] = useState({})
    const [taskStatus, setTaskStatus] = useState({})
    const [totalProgress, setTotalProgress] = useState()
    const [tasks, setTasks] = useState([])
    const [taskAvaliableStatus, setTaksAvaliableStatus] = useState([])
    const [filter, setFilter] = useState(-1)

    const { id } = useParams()
    const dispatch = useDispatch()

    const idUser = useSelector(state => state.idUser)

    const [modalTitle, setModalTitle] = useState()
    const [modalDescription, setModalDescription] = useState()
    const [modalProgress, setModalProgress] = useState()
    const [modalIdTask, setModalIdTask] = useState()

    const [modalOpen, setModalOpen] = useState(false)

    const [toDelete, setToDelete] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)


    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t("TASK_LIST")
        })
    }, [dispatch, t])

    useEffect(() => {
        requestProject()
        getTaskStatus().then(response => {
            let data = response.data ? response.data : []
            data = data.map(itm => {
                return {
                    key: `status-${itm.idTaskStatus}`,
                    value: itm.idTaskStatus,
                    text: itm.name
                }
            })
            setTaksAvaliableStatus(data)
        }).catch(err => {
            if(err.response){
                console.log("Error code", err.response.status)
            }else{
                console.log("Exception", err)
            }
        })
    }, [id])

    useEffect(() => {
        if(toDelete){
            setOpenConfirm(true)
        }
    }, [toDelete])

    useEffect(() => {
        if(!openConfirm){
            setToDelete(null)
        }
    }, [openConfirm])

    const requestProject = () => {
        getProjectById(id).then(response => {
            setProject(response.data ? response.data : null)
        }).catch(err => {
            if(err.response){
                console.log("Error code", err.response.status)
            }else{
                console.log("Exception", err)
            }
        })
    }

    const onDelete = () => {
        setOpenConfirm(false)
        if(!toDelete){
            return
        }
        deleteTask(toDelete).then(response =>{
            Store.addNotification(
                renderNotification("success", t('TASK_DELETED'), t('TASK_DELETED_MSG'))
            )
            setToDelete(null)
            requestProject()
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('TASK_DELETED_ERR'), t('TASK_DELETED_ERR_MSG'))
            )
            setToDelete(null)
        })
    }



    useEffect(() => {
        const asyncEffect = async () => {
            if(project && project.tasks && idUser){
                let tasksTab = []
                let overview = {
                    "completed": 0,
                    "pending": 0,
                    "process": 0,
                    "critical": 0,
                    "progress": 0,
                }
                let tasksNumber = 0
                const now = Date.now()
                for (const task of project.tasks) {
                    let row = {
                        "idTask": task.idTask,
                        "title": task.title,
                        "description": task.description,
                        "start": task.start,
                        "deadLine": task.deadLine,
                        "idProject": task.idProject,
                        "isHito": task.isHito,
                        "status": "-",
                        "tasksNumber": 0,
                        "assignedTo": [],
                        "assignedToThis": null,
                        "userProgress": null,
                        "progress": 0,
                    }
                    let datePartsDL = task.deadLine ? task.deadLine.split("-") : null;
                    const dl = datePartsDL ? new Date(datePartsDL[0], datePartsDL[1] - 1, datePartsDL[2].substr(0,2)) : null;
                    let datePartsSt = task.start ? task.start.split("-") : null;
                    const st = datePartsSt ? new Date(datePartsSt[0], datePartsSt[1] - 1, datePartsSt[2].substr(0,2)) : null;
                    //let countUsr = 0
                    let task_overview = {
                        "completed": 0,
                        "pending": 0,
                        "process": 0,
                        "critical": 0,
                    }
                    for (const userTask of task.users) {
                        if(idUser  === userTask.idUser){
                            row.assignedToThis = userTask.idUserTask
                            row.userProgress = userTask.progress ? userTask.progress : 0
                        }
                        row.tasksNumber ++
                        // switch (userTask.status.name) {
                        //     case "In process":
                        //         overview["process"] += 1
                        //         break;
                        //     case "Complete":
                        //         overview["completed"] += 1
                        //         row.progress +=1
                        //         break;
                        //     case "Critical":
                        //         overview["critical"] += 1
                        //         break;
                        //     case "Pending":
                        //         overview["pending"] += 1
                        //         break;
                        //     default:
                        //         break;
                        // }
                        if(userTask.progress === 100){
                            overview["completed"] += 1
                            task_overview["completed"] += 1
                        }else if((!dl || !st) || (st && st.getTime() > now)){
                            overview["pending"] += 1
                            task_overview["pending"] += 1
                        }else if(dl && dl.getTime() < now){
                            overview["critical"] += 1
                            task_overview["critical"] += 1
                        }else{
                            overview["process"] += 1
                            task_overview["process"] += 1
                        }
                        overview["progress"] += userTask["progress"] ? userTask["progress"] : 0
                        row.progress += userTask["progress"] ? userTask["progress"] : 0
                        row.assignedTo.push(`${userTask["user"]["name"]} ${userTask["user"]["lastName"]}`)
                        //row.status = row.status === null ? userTask.status : (row.status.level > userTask.status.level ? row.status : userTask.status)
                    }
                    row.status = task_overview["critical"] ? "Critical" : 
                        task_overview["process"] ? "In Process" : 
                        task_overview["pending"] ? "Pending" : "Completed"
    
                    tasksNumber += row.tasksNumber
                    //row.progress = row.tasksNumber > 0 ? ((row.progress * 100)/row.tasksNumber) : 100
                    row.progress = row.tasksNumber > 0 ? (row.progress/row.tasksNumber) : 0
                    tasksTab.push(row)
                }
                const allProgress = overview["progress"] ? overview.progress/tasksNumber : 0
                setTotalProgress(allProgress) 
                setTaskStatus(overview)
                setTasks(tasksTab)
                dispatch({
                    type: 'SET_DASH_SECTION',
                    dashSection: `${t('PROJECT')}: ${project.name}`
                })
                const rs = await readParents(project)
                dispatch({
                    type: "SET_BREADCRUMBS",
                    breadcrumbs: rs
                })
            }
        }
        asyncEffect()
    }, [project, idUser])

    const setModalData = (title, description, progress, idTask) =>{
        setModalTitle(title)
        setModalDescription(description)
        setModalProgress(progress)
        setModalIdTask(idTask)
        setModalOpen(true)
    }

    const filterActivities = [
        {
            key: "filter-1",
            value: -1,
            text: t('ALL_ACTIVITIES')
        },
        {
            key: "filter-2",
            value: 0,
            text: t('TASKS')
        },
        {
            key: "filter-3",
            value: 1,
            text: t('MILESTONES')
        }
    ]

    return(
        <div>
            <Grid>
                <Grid.Column widescreen={4} computer={4} tablet={16} mobile={16}>
                    <Overview 
                        completed={taskStatus["completed"] || taskStatus["completed"] === 0 ? taskStatus["completed"] : null}
                        pending={taskStatus["pending"] || taskStatus["pending"] === 0 ? taskStatus["pending"] : null}
                        process={taskStatus["process"] || taskStatus["process"] === 0 ? taskStatus["process"] : null}
                        critical={taskStatus["critical"] || taskStatus["critical"] === 0 ? taskStatus["critical"] : null}
                        progress={totalProgress}
                    />
                </Grid.Column>
                <Grid.Column widescreen={12} computer={12} tablet={16} mobile={16}>
                    <Grid>
                        <Grid.Column width={16}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                                    <span>
                                        {t('SHOW_ME')}:
                                        <Dropdown
                                            options={filterActivities}
                                            value={filter}
                                            onChange={(e, { value }) => setFilter(value)}
                                            selection
                                            className='marginLeft'
                                        />
                                    </span>
                                    </Grid.Column>    
                                    <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                                        <NewTask project={{
                                                idProject: project && project.idProject ? project.idProject : null, 
                                                name: project && project.name ? project.name : null
                                            }}
                                            tasks={tasks}
                                            requestProject={requestProject}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('NAME')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('STATUS')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('PROGRESS_TASK')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('ASSIGNED_TO')}</Table.HeaderCell>
                                    {
                                        filter !== 1 &&
                                        <Table.HeaderCell>{t('START_DATE')}</Table.HeaderCell>
                                    }
                                    <Table.HeaderCell>{t('DUE_DATE')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('MY_PROGRESS')}</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    (filter === -1 ? tasks : tasks.filter(itm => itm.isHito == filter)).map(itm => 
                                        <Table.Row key={`task-${itm.idTask}`}>
                                            <Table.Cell>
                                                <div className='setFlex'>
                                                    <div className='hitoIcon'>
                                                        {
                                                            itm.isHito &&
                                                                <Popup
                                                                    content={t('IS_A_MILESTONE')}
                                                                    trigger={<HitoIcon/>}
                                                                    position="bottom left"
                                                                />
                                                        }
                                                    </div>
                                                    <span className='marginLeft'>
                                                        {itm.title}
                                                    </span>
                                                </div>
                                                {/* {
                                                    itm.isHito &&
                                                    <div>
                                                        <HitoIcon/>
                                                    </div>
                                                } */}
                                            </Table.Cell>
                                            <Table.Cell>{t(formatToTranslate(itm.status))}</Table.Cell>
                                            <Table.Cell>{`${itm.progress}%`}</Table.Cell>
                                            <Table.Cell>{assignedCount(itm.assignedTo)}</Table.Cell>
                                            {
                                                filter !== 1 &&
                                                <Table.Cell>
                                                    {
                                                        !itm.isHito ? itm.start : "-"
                                                    }
                                                </Table.Cell>
                                            }
                                            <Table.Cell>{itm.deadLine}</Table.Cell>
                                            <Table.Cell>
                                                {
                                                    idUser && itm.assignedToThis &&
                                                        //<Dropdown options={taskAvaliableStatus} selection value={itm.assignedToThis.idTaskStatus}/>
                                                        <Button 
                                                            onClick={() => 
                                                            setModalData(itm.title, itm.description, itm.userProgress, itm.assignedToThis)}
                                                            className="buttonBlue">
                                                                {t('PROGRESS')}
                                                        </Button>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className='setFlex'>
                                                    <div className='setFlex1'>
                                                        <NewTask project={{
                                                                idProject: project && project.idProject ? project.idProject : null, 
                                                                name: project && project.name ? project.name : null,
                                                            }}
                                                            isEdit={true}
                                                            tasks={tasks}
                                                            //}task={itm.idTask}
                                                            task={project.tasks[project.tasks.findIndex(i => i.idTask === itm.idTask)]}
                                                            requestProject={requestProject}
                                                            floated={false}
                                                        />
                                                    </div>
                                                    <div className='setFlex'>
                                                        <div className='marginAuto'>
                                                            <Icon 
                                                                name="trash alternate outline" 
                                                                className="deleteIcon" 
                                                                onClick={() => setToDelete(itm.idTask)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            {/* <Grid.Column width={16}>
                                <NewTask project={{
                                        idProject: project && project.idProject ? project.idProject : null, 
                                        name: project && project.name ? project.name : null
                                    }}
                                    tasks={tasks}
                                    requestProject={requestProject}/>
                            </Grid.Column> */}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            <MyTask 
                open={modalOpen} 
                setOpen={setModalOpen} 
                title={modalTitle} 
                description={modalDescription}
                progress={modalProgress}
                idTask={modalIdTask}
                requestProject={requestProject}
            />
            {/* {renderModal()} */}
            <Confirm
                open={openConfirm}
                onCancel={() => setOpenConfirm(false)}
                onConfirm={() => onDelete()}
            />
        </div>
    )
}

export default Project