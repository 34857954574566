import { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import { Grid, List, Button, Segment, Divider, Modal, Comment, Header, Form, Label, Checkbox, Popup, Input, Pagination } from "semantic-ui-react"
import { renderNotification, useDidUpdateEffect } from "../../../util/util"
import DropFiles from "../dropFiles/dropFiles"
import { downloadFile, downloadVersionFile, getSingleFileNotes, postNewVersionNote, searchFiles, sendFile, sendVersion } from "../requests"
import "./FileManager.sass"


const  FileManager = forwardRef(({ 
        fileSelector = 0, 
        selectedRelationFiles = null,
        setSelectedRelationFiles = null,
        searchParams={}
    }, ref) => {
    
    useImperativeHandle(ref, () => ({
        reload(){
            getListFiles()
        }
    }))

    const notesInitial = {
        currentNotes: [],
        currentNotesFile: 0,
        currentNotesFileName: "",
        notesOpen: false
    }

    const [files, setFiles] = useState([])
    const [selectedFile, setSelectedFile] = useState(0)
    const [versions, setVersions] = useState([])

    const [openModalVersion, setOpenModalVersion] = useState(false)
    const [inputVersionFiles, setInputVersionFiles] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [myFiles, setMyFiles] = useState([])
    const [isFile, setIsFile] = useState(true)
    const [loading, setLoading] = useState(0)
    const [{
        currentNotes,
        currentNotesFile,
        currentNotesFileName,
        notesOpen
    }, setCurrentNotes] = useState(notesInitial)

    const [openNewNote, setOpenNewNote] = useState(false)
    const [newNote, setNewNote] = useState("")
    const [errorNewNote, setErrorNewNote] = useState(null)
    const [loadingNote, setLoadingNote] = useState(false)

    // EXCLUSIVE SELECTOR VARIABLES
    // const [selectedRelationFiles, setSelectedRelationFiles] = useState([])

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const [myFilesFilter, setMyFilesFilter] = useState(true)
    

    useEffect(() => {
        setNewNote("")
    }, [notesOpen])


    const { t } = useTranslation()
    
    useEffect(() => {
        getListFiles()
    }, [page, myFilesFilter])

    const openUploadFile = (f) => {
        setIsFile(f)
        setOpenModal(true)
    }

    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                getListFiles()
            }
        }, 800)
        return () => {
            clearTimeout(delayFunction)
        }
    }, [search])

    const getListFiles = () => {
        let params = {
            ...searchParams,
            page
        }
        if(myFilesFilter){
            params["myFiles"] = 1
        }
        if(search){
            params["search"] = search
        }
        searchFiles(params).then(res => {
            if(res.data){
                setFiles(res.data && res.data.elements ? res.data.elements : [])
                setTotalPages(res.data && res.data.totalPages ? res.data.totalPages : 1)
            }
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", "File list", "There was an error retrieving the file list.")
            )
        })
    }

    useEffect(()=> {
        if(!openModal){
            setLoading(0)
            setMyFiles([])
        }
    }, [openModal])

    useEffect(() => {
        if(!openModalVersion){
            setInputVersionFiles([])
        }
    }, [openModalVersion])

    useEffect(() => {
        if(!selectedFile){
            setVersions([])
        }else{
            const ix = files.findIndex(itm => itm.idFile === selectedFile)
            if(ix !== -1){
                setVersions(files[ix]["versions"])
            }else{
                setVersions([])
            }
        }
    }, [files, selectedFile])

    const setDownload = (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = url
        link.target='_blank'
        link.rel='noopener noreferrer'
        link.download=response.headers["content-disposition"].split('=')[1]
        document.body.appendChild(link)
        link.click()
    }

    const triggerDownloadFile = (id, isVersion=false) => {
        const dwFile = isVersion ? downloadVersionFile : downloadFile
        dwFile(id).then(res => {
            setDownload(res)
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", "File download", "There was an error trying to download the file")
            )
        })
    }

    const uploadFiles = (files = [], isFile=true, progress=0) => {
        if(files.length > 0){
            const singleProgress = Math.ceil(100/files.length)
            const formData = new FormData()
            const file = files.pop()
            formData.append("upload", file)
            if(!isFile){
                sendVersion(selectedFile, formData).then(res => {
                    setLoading(100)
                    Store.addNotification(
                        renderNotification("success", t('FILE_UPLOAD'), t('FILE_UPLOAD_MSG'))
                    )
                    getListFiles()
                    setOpenModal(false)
                }).catch(err => {
                    Store.addNotification(
                        renderNotification("error", t('FILE_UPLOAD'), t('FILE_UPLOAD_ERR_MSG'))
                    )
                })
            }else{
                sendFile(formData).then(res => {
                    const newProgress = progress + singleProgress
                    setLoading(newProgress)
                    if(files.length > 0){
                        uploadFiles(files, isFile, newProgress)
                    }
                    else{
                        Store.addNotification(
                            renderNotification("success", t('FILE_UPLOAD'), t('FILE_UPLOAD_MSG'))
                        )
                        getListFiles()
                        setOpenModal(false)
                    }
                }).catch(err => {
                    Store.addNotification(
                        renderNotification("error", t('FILE_UPLOAD'), t('FILE_UPLOAD_ERR_MSG'))
                    )
                })
            }
        }
    }

    const newVersionNote = (id, note) => {
        if(note.length > 250){
            return
        }
        const data = {
            idVersion: id,
            note
        }
        postNewVersionNote(data).then(res => {
            const newNotes = res.data ? res.data : []
            setCurrentNotes({
                currentNotes: newNotes,
                currentNotesFile,
                currentNotesFileName,
                notesOpen
            })
            Store.addNotification(
                renderNotification("success", t('NOTE_UPLOAD'), t('NOTE_UPLOAD_MSG'))
            )
            setNewNote("")
            setOpenNewNote(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('NOTE_UPLOAD'), t('NOTE_UPLOAD_ERR'))
            )
        })
    }

    const modalUpload = () => {
        return(
            <Modal
                onClose={() => setOpenModal(false)}
                onOpen={() => setOpenModal(true)}
                open={openModal}
            >
                <Modal.Header>{isFile ? t('UPLOAD_FILES') : t('UPLOAD_VERSION')}</Modal.Header>
                <Modal.Content>
                    <DropFiles
                        title={isFile ? t('UPLOAD_FILES') : t('UPLOAD_VERSION')}
                        myFiles={myFiles}
                        setMyFiles={setMyFiles}
                        loading={loading}
                        multiple={isFile}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        onClick={() => uploadFiles(myFiles, isFile)}
                        className="buttonBlue">
                        {isFile ? t('UPLOAD_FILES') : t('UPLOAD_VERSION')}
                    </Button>
                    <Button 
                        onClick={() => setOpenModal(false)}
                        className="buttonDarkGray">
                        {t('CANCEL')}
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    const checkInputNote = () => {
        if(newNote.length > 250){
            setErrorNewNote({
                content: t('ERR_NOTE_TOO_LONG')
            })
        }else{
            setErrorNewNote(null)
        }
    }

    const modalNotes = () => {
        return(
            <Modal
                onClose={() => setCurrentNotes(notesInitial)}
                open={notesOpen}
            >
                <Modal.Header>{`${t('FILE')}: ${currentNotesFileName}`}</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                                {/* {
                                    currentNotes.map(itm => 
                                        <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                                            <Card
                                                header={`${itm.user.name} ${itm.user.lastName}`}
                                                meta={`Date: ${itm.date}`}
                                                description={itm.note}
                                                fluid
                                            />
                                        </Grid.Column>
                                    )
                                } */}
                                <Grid.Column width={16} className="commentsContainer">
                                    <Comment.Group>
                                        <Header as='h2' dividing>
                                            {t('FILE_NOTES')}
                                        </Header>
                                        {
                                            currentNotes.map((itm, ix) => 
                                                <Comment key={`comment-item-${ix}`}>
                                                    <Comment.Avatar as="a" src='/image.png' />
                                                    <Comment.Content>
                                                        <Comment.Author as="span">{`${itm["user"]["name"]} ${itm["user"]["lastName"]}`}</Comment.Author>
                                                        <Comment.Metadata><div>{itm.date}</div></Comment.Metadata>
                                                        <Comment.Text><p>{itm.note}</p></Comment.Text>
                                                    </Comment.Content>
                                                </Comment>
                                            )
                                        }
                                        <Form reply className="lengthIndicator">
                                            <Form.TextArea
                                                onChange={({target}) => setNewNote(target.value)}
                                                onBlur={checkInputNote}
                                                error={errorNewNote}
                                                value={newNote}
                                            />
                                            <p className={newNote.length > 250 ? "fieldError": null}>
                                                {`${newNote.length}/250`}
                                            </p>
                                            <Button 
                                                onClick={() => newVersionNote(currentNotesFile, newNote)}
                                                className="buttonBlue"
                                                floated="right"
                                                disabled={newNote.length === 0}
                                            >
                                                {t('ADD_NEW_NOTE')}
                                            </Button>
                                        </Form>
                                    </Comment.Group>
                                </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    {/* <Button
                        className="buttonBlue"
                        onClick={() => setOpenNewNote(true)}
                    >
                        {t('ADD_A_NEW_NOTE')}
                    </Button> */}
                    <Button
                        onClick={() => setCurrentNotes(notesInitial)}
                        className="buttonDarkGray"
                        >
                        {t('CLOSE')}
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }


    const handleDownload = (e, id) => {
        e.stopPropagation()
        triggerDownloadFile(id, false)
    }

    const handleNotes = (e, id, title) => {
        setLoadingNote(true)
        e.stopPropagation()

        getSingleFileNotes(id).then(res => {
            const notes = res.data ? res.data : []
            setCurrentNotes({
                currentNotes: notes,
                currentNotesFile: id,
                currentNotesFileName: title,
                notesOpen: true
            })
            setLoadingNote(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t("GET_NOTES"), t("GET_NOTES_ERR"))
            )
            setLoadingNote(false)
        })
    }

    // EXCLUSIVE SELECTOR FUNCTIONS
    const toggleSelection = (id) => {
        const ix = selectedRelationFiles.indexOf(id)
        if (ix === -1){
            setSelectedRelationFiles([
                ...selectedRelationFiles,
                id
            ])
        }else{
            let removedRelation = [...selectedRelationFiles]
            removedRelation.splice(ix, 1)
            setSelectedRelationFiles(removedRelation)
        }
    }

    const renderFiles = () => {
        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16} className="searchInputs">
                            <Input 
                                icon='search'
                                placeholder={`${t('SEARCH')}...`}
                                value={search}
                                onChange={({ target }) => setSearch(target.value)}
                                fluid
                            />

                            <Checkbox
                                label={t('SHOW_ME_MY_FILES')}
                                checked={myFilesFilter}
                                className="marginTop"
                                onChange={(e, { checked }) => setMyFilesFilter(checked)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid className="setFlex1">
                    <Grid.Row>
                        <Grid.Column width={fileSelector ? 16 : 10} className={"filesContainer"}>
                            <div className="setFlexColumn">
                                <div className="listContainer">
                                {
                                    files.length > 0 ?
                                        <List selection className="fileList">
                                            {
                                                files.map((itm, ix) => {
                                                    const related = itm.related ? itm.related : false
                                                    return (
                                                            <List.Item 
                                                                onClick={
                                                                    fileSelector === 1 && !related ? 
                                                                        () => toggleSelection(itm.idFile) 
                                                                        : fileSelector === 0 ? 
                                                                            () => setSelectedFile(itm.idFile) 
                                                                            :
                                                                            null
                                                                } 
                                                                className={selectedFile === itm.idFile ? "selectedItem" : ""} 
                                                                key={`file-item-${ix}`}>
                                                                {
                                                                    fileSelector === 1 &&
                                                                    <List.Content floated="left" className="checkContainer">
                                                                        <Checkbox
                                                                            checked={selectedRelationFiles.includes(itm.idFile)}
                                                                            onChange={() => toggleSelection(itm.idFile)}
                                                                            disabled={related}
                                                                        />
                                                                    </List.Content>
                                                                }
                                                                <List.Icon name='file' />
                                                                <List.Content>
                                                                    <List.Content floated="right">
                                                                        {/* <Button onClick={(e) => handleDownload(e, itm.idFile)}>
                                                                            {t("DOWNLOAD")}
                                                                        </Button> */}
                                                                        <Button 
                                                                            onClick={(e) => handleDownload(e, itm.idFile)}
                                                                            icon="download"
                                                                        />
                                                                    </List.Content>
                                                                    <List.Header>{t('FILENAME')}: {itm.name ? itm.name : itm.versions[0].name}</List.Header>
                                                                    <List.Description>{t('DATE')}: {itm.date} | {t('LAST_UPDATE')} : {itm.versions[0].date}</List.Description>
                                                                    <List.Description>{t('UPLOADED_BY')}: {itm.versions[0]["user"] ? `${itm.versions[0]["user"]["name"]} ${itm.versions[0]["user"]["lastName"]}` : `-`}</List.Description>
                                                                    {
                                                                        related &&
                                                                            <List.Description>
                                                                                <Popup
                                                                                    content={t('ATTACHED_DESC')}
                                                                                    trigger={
                                                                                        <Label color="green">
                                                                                            {t('ATTACHED')}
                                                                                        </Label>
                                                                                    }
                                                                                    position="right center"/>
                                                                            </List.Description>
                                                                    }
                                                                </List.Content>
                                                            </List.Item>
                                                    )}
                                                )
                                            }
                                        </List>
                                    :
                                        // <h1>There is no files in the system</h1>
                                        <h1>{t('NO_FILES_LIBRARY')}</h1>
                                }
                                </div>
                                    <Grid>
                                        <Grid.Column width={16} className="centerText">
                                            <Pagination
                                                activePage={page}
                                                onPageChange={(e, { activePage }) => setPage(activePage)}
                                                totalPages={totalPages}
                                            />
                                            <Divider/>
                                            <Button 
                                                floated="right" 
                                                className="buttonBlue"
                                                onClick={() => openUploadFile(true)}>
                                                    {t('UPLOAD_FILE')}
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                        </div>
                        </Grid.Column>
                        {
                            fileSelector === 0 &&
                                <Grid.Column width={6} className="filesContainer">
                                    <Segment className="setFlexColumn versionsContainer">
                                        <h2>Versions</h2>
                                        <Divider/>
                                        {
                                            versions && versions.length > 0 ?
                                                <Fragment>
                                                    <div className="setFlex1">
                                                        <List>
                                                            {
                                                                versions.map((ver, ix) => 
                                                                    <List.Item className="marginBot" key={`version-item-${ix}`}>
                                                                        <List.Content floated="right">
                                                                            {/* <Button onClick={() => triggerDownloadFile(ver.idVersionFile, true)}>
                                                                                {t("DOWNLOAD")}
                                                                            </Button> */}
                                                                            <Button 
                                                                                icon="download"
                                                                                onClick={() => triggerDownloadFile(ver.idVersionFile, true)}
                                                                            />
                                                                            <Button
                                                                                icon="sticky note"
                                                                                onClick={(e) => handleNotes(e, ver.idVersionFile, ver.name)}
                                                                                loading={loadingNote}
                                                                                />
                                                                        </List.Content>
                                                                        <List.Icon name='file' />
                                                                        <List.Content>
                                                                            <List.Header>{t('VERSIONNAME')}: {ver.name}</List.Header>
                                                                            <List.Description>{t('DATE')}: {ver.date}</List.Description>
                                                                            <List.Description>{t('UPLOADED_BY')}: {`${ver["user"]["name"]} ${ver["user"]["lastName"]}`}</List.Description>
                                                                            {
                                                                                ix === 0 ?
                                                                                <List.Description>
                                                                                    <div>
                                                                                        <Label className="labelActive" color='green' horizontal>
                                                                                            Active version
                                                                                        </Label>
                                                                                    </div>
                                                                                </List.Description>
                                                                                :
                                                                                <List.Description>
                                                                                    <div>
                                                                                        <Label className="labelActive" color='red' horizontal>
                                                                                            Deprecated
                                                                                        </Label>
                                                                                    </div>
                                                                                </List.Description>
                                                                            }
                                                                        </List.Content>
                                                                    </List.Item>
                                                                )
                                                            }
                                                        </List>
                                                    </div>
                                                    <Divider/>
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Button
                                                                    className="buttonBlue"
                                                                    floated="right"
                                                                    onClick={() => openUploadFile(false)}>
                                                                    {t('NEW_VERSION')}
                                                                </Button>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Fragment>
                                            :
                                                null
                                        }
                                    </Segment>
                                </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
    
    return(
        <div className="fileManager setFlex1 marginBot setFlex">
            <div className="tabContainer setFlex1 setFlexColumn">
                {
                    renderFiles()
                }
            </div>
            {
                modalUpload()
            }
            {
                modalNotes()
            }
        </div>
    )
})

export default FileManager