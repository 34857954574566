import { useCallback, useEffect, useState } from "react"
import { Button, Grid, Icon, List, Modal } from "semantic-ui-react"
import { useDropzone } from 'react-dropzone';

import "./myTask.sass"
import { updateTaskProgress } from "../util/requests";
import { useTranslation } from "react-i18next";

function MyTask({idTask, title, description, progress, uploadedFiles = [], open, setOpen, requestProject}){
    const { t } = useTranslation()
    const [localProgress, setLocalProgress] = useState(progress ? progress : 0)

    const [myFiles, setMyFiles] = useState([])

    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
    })

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
    });

    const removeFile = file => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const removeAllFiles = () => {
        setMyFiles([])
    }

    const files = myFiles.map(file => (
        // <li key={file.path}>
        //     {file.path} - {file.size} bytes
        // </li>
        <List.Item>
            <Icon name="close" onClick={()=> removeFile(file)}/>
            <List.Content>
                <List.Header>{file.path}</List.Header>
                <List.Description>{file.size} bytes</List.Description>
            </List.Content>
        </List.Item>
    ));

    useEffect(() => {
        if(open){
            setLocalProgress(progress)
            removeAllFiles()
        }
    }, [open])

    const updateUserTask = () => {
        updateTaskProgress(idTask, localProgress).then(response => {
            if(requestProject)
                requestProject()
                setOpen(false)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <Modal open={open} className={"myTask"}>
            <Modal.Header>
                {t('TASK_NAME')} : {title}
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={16}>
                        <h3 className="centerText">{t('DESCRIPTION')}</h3>
                        <p>{description}</p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Grid centered>
                            <Grid.Column largeScreen={8} computer={8} tablet={10} mobile={14}>
                                <h3 className="centerText">{t('PROGRESS')}</h3>
                                <p className="centerText">{`${localProgress}%`}</p>
                                <input 
                                    className="fullWidth"
                                    type={"range"} 
                                    min={0} 
                                    max={100}
                                    value={localProgress}
                                    onChange={({target}) => setLocalProgress(target.value)}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    {/* {
                        uploadedFiles.length > 0 &&
                        <Grid.Column width={16}>
                            <h3>Uploaded files</h3>
                            <List divided>
                                <List.Item>
                                    <List.Icon name='file'/>
                                    <List.Content>Hola Mundo!</List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    } */}
                    {/* <Grid.Column width={16}>
                        <div>
                            <h3 className="centerText">Upload New Files</h3>
                            <section className="container">
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                <aside>
                                    <h3>Files</h3>
                                    <List>
                                        {files}
                                    </List>
                                </aside>
                            </section>
                        </div>
                    </Grid.Column> */}
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => updateUserTask()} primary>{t('SAVE')}</Button>
                <Button onClick={() => setOpen(false)}>{t('CANCEL')}</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default MyTask