import { useTranslation } from 'react-i18next';
import { Grid, Image } from 'semantic-ui-react';
import AddUserForm from './addUserForm';

function AddUser(){
    const { t } = useTranslation()
    // const description = "Add a new user, you can assign them a specific " +
    // "area and decide who should report their progress, you can provide their " +
    // "username and password so they can report their work to the person tho whom it " +
    // "has been assigned.";
    const description = t('ADD_NEW_USER_DESC')
    

    return(
        <div className="setFullFlex">
            <Grid columns={2} className="alignMiddle" stackable>
                <Grid.Column>
                    <div className="areaStepImg">
                        <Image src="/union7.png" size="small"/>
                    </div>
                    <div className="areaStepDescription">
                        <p>{t('ADD_NEW_USER')}</p>
                        <div className="areaSub">
                            <p>{description}</p>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column>
                    <Grid centered>
                        <Grid.Column widescreen={10} computer={10} tablet={13} mobile={16}>
                            <AddUserForm/>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default AddUser;