import { ResponsiveSankey } from "@nivo/sankey"
import EmptyChart from "./emptyChart"

export default function SimplixResponsiveSankey({ data }) {
    const customColors = [
        "#0047AB", // Dark blue
        "#0066CC", // Medium blue
        "#1E90FF", // Dodger blue
        "#4169E1", // Royal blue
        "#87CEFA", // Light sky blue
        "#FFA500", // Orange
        "#FF8C00", // Dark orange
        "#A9A9A9", // Dark grey
        "#D3D3D3"  // Light grey
    ];
    return (
        data.links.length === 0 ? <EmptyChart/> :
        <ResponsiveSankey
            data={data}
            margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
            align="justify"
            colors={customColors}
            nodeOpacity={1}
            nodeHoverOthersOpacity={0.35}
            nodeThickness={18}
            nodeSpacing={10}
            nodeBorderWidth={0}
            nodeBorderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.8
                    ]
                ]
            }}
            nodeBorderRadius={3}
            linkOpacity={0.5}
            linkHoverOthersOpacity={0.1}
            linkContract={3}
            enableLinkGradient={true}
            labelPosition="inside"
            labelOrientation="horizontal"
            labelPadding={16}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1
                    ]
                ]
            }}
            label={(e) => e.name}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    translateX: 130,
                    itemWidth: 100,
                    itemHeight: 14,
                    itemDirection: 'right-to-left',
                    itemsSpacing: 2,
                    itemTextColor: '#999',
                    symbolSize: 14,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    )
}