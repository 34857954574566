import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'react-notifications-component'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid, Button, Modal } from 'semantic-ui-react'
import { renderNotification } from '../../util/util'
import ConfirmModal from '../util/confirmModal/ConfirmModal'
import { users as userRequest, deleteUser } from '../util/requests'
import AddUserForm from './addUserForm'
import UserComponent from './userComponent'
import './users.sass'

function Users(){
    const { t } = useTranslation()
    const [userList, setUserList] = useState([])
    const [open, setOpen] = useState(false)

    const [userToDelete, setUserToDelete] = useState(null)

    const dispatch = useDispatch()

    const [modal, setModal] = useState(false)
    const [userToEdit, setUserToEdit] = useState(null)
    
    const [modalData, setModalData] = useState({})


    const requestUsers = () => {
        userRequest().then( response => {
            setUserList(response.data && Array.isArray(response.data) ? response.data : [])
        }).catch((err)=>{
            if(err.response){
                Store.addNotification(
                    renderNotification("error", t('USER_LIST'), t('USER_LIST_MSG'))
                )
            }
        })
    }

    const getUserData = () => {
        requestUsers()
    }

    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t('USERS')
        })
    }, [dispatch, t])

    useEffect(() => {
        getUserData()
    }, [])

    useEffect(() => {
        if(userToEdit !== null){
            const ix = userList.findIndex(itm => itm.idUser === userToEdit)
            if(ix === -1){
                return
            }
            setModalData(userList[ix])
            setModal(true)
        }
    }, [userToEdit])

    useEffect(() => {
        if(modal === false){
            setUserToEdit(null)
        }
    }, [modal])

    useEffect(() => {
        if(userToDelete){
            setOpen(true)
        }
    }, [userToDelete])

    useEffect(() => {
        if(open === false){
            setUserToDelete(null)
        }
    }, [open])

    // const userPrivilegies = (user) =>{
    //     let permissions = []
    //     for (const company of user.companies) {
    //         for (const privilegie of rol.privilegies) {
    //             permissions.push(privilegie.name)
    //         }
    //     }
    //     return permissions
    // }

    const del = () => {
        deleteUser(userToDelete).then(res => {
            Store.addNotification(
                renderNotification("success", t('USER_DELETE'), t('USER_DELETE_MSG'))
            )
            requestUsers()
            setOpen(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('USER_DELETE'), t('USER_DELETE_ERR_MSG'))
            )
            setOpen(false)
        })
    }

    return(
        <Grid className='users'>
            <Grid.Column width={16} className='topBar'>
                <h2 className='dashTitle'>{t('USERS_COMPANY')}</h2>
                <div className='buttonContainer buttonNextToTitle'>
                    <Link to="/dashboard/adduser">
                        <Button className='buttonBlue'>{t('ADD_USER')}</Button>
                    </Link>
                </div>
            </Grid.Column>
            <Grid.Column width={16} only="mobile">
                <div className='buttonContainer'>
                    <Link to="/dashboard/adduser">
                        <Button className='buttonBlue' fluid>{t('ADD_USER')}</Button>
                    </Link>
                </div>
            </Grid.Column>
            <Grid.Column width="8" className='topFilters' textAlign='right'>
            </Grid.Column>
            <Grid.Column width={16}>
                {
                    userList.map((itm, ix) => <UserComponent 
                        idUser={itm.idUser}
                        key={`usr-${ix}`}
                        //fullName={`${itm.name} ${itm.lastName}`}
                        name={itm.name}
                        lastName={itm.lastName}
                        reportsTo={itm.reportsTo}
                        email={itm.email}
                        phone={itm.phone}
                        //roles = {itm.roles.map(itm => itm.name).join(", ")}
                        roles = {itm.companies.map(itm => itm.rol)}
                        //privilegies = {userPrivilegies(itm).join(", ")}
                        deleteUser={setUserToDelete}
                        editUser={setUserToEdit}
                    />)
                }
            </Grid.Column>
            {/* <Confirm
                open={open}
                onCancel={() => setOpen(false)}
                onConfirm={() => del()}
            /> */}
            <ConfirmModal 
                open={open} 
                setOpen={setOpen} 
                type={"delete"} 
                title={t('DELETE_USER_TITLE')} 
                description={t('DELETE_USER_DESC')}
                callback={() => del()}
            />
            <Modal
                open={modal}
                onClose={() => setModal(false)}
            >
                <Modal.Header>
                    <h2>{t('EDIT_USER')}</h2>
                </Modal.Header>
                <Modal.Content>
                    <Grid centered>
                        <Grid.Column width={12}>
                            <AddUserForm 
                                isEdit={true} 
                                closeModal={() => setModal(false)} 
                                getUserData={getUserData}
                                data={modalData}/>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        </Grid>
    )
}

export default Users;