import { getAllFrameworkProgress, getFrameworkPercentagesData, getFrameworkProgress, getFrameworks, getUserFrameworkProgress } from '../util/requests'
import { useEffect, useState } from 'react'
import SimplixResponsiveSankey from './sankey'
import SimplixSunburst from './sunburst'
import SimplixResponsivePie from './pie'
import SimplixResposiveBar from './bar'
import { Dropdown, Grid, Segment, SegmentGroup } from 'semantic-ui-react'


export default function GraphView() {
    const [sankeyData, setSankeyData] = useState(null)
    const [sunBurstData, setSunBurstData] = useState(null)
    const [pieData, setPieData] = useState(null)
    const [barData, setBarData] = useState(null)
    const [frameworkList, setFrameworkList] = useState([])

    const [selectedFrameworkSankey, setSelectedFrameworkSankey] = useState(null)
    const [selectedFrameworkSunburst, setSelectedFrameworkSunburst] = useState(null)
    const [selectedFrameworkPie, setSelectedFrameworkPie] = useState(null)


    const requestFrameworkPercentages = async () => {
        try{
            const result = await getFrameworkPercentagesData()
            setBarData(
                result.data.map((itm, ix) => (
                    {
                        ...itm,
                        ix
                    }
                ))
            )
        }catch(e){
            console.log(e)
        }
    }

    const requestFrameworkProgressData = async () => {
        try{
            let params = {}
            if(selectedFrameworkPie){
                params.id = selectedFrameworkPie
            }
            const response = await getAllFrameworkProgress(params)
            const formated_data = response.data.map(itm => (
                {
                    ...itm,
                    id: itm.name.toLowerCase()
                }
            ))
            setPieData(formated_data)
        }catch(error){
            console.log(error)
        }
    }

    const requestFrameworksList = async () => {
        try{
            const resposnse = await getFrameworks()
            const fwList = resposnse.data.map(itm => (
                {
                    key: itm.idFramework,
                    text: itm.title,
                    value: itm.idFramework
                }
            ))
            setFrameworkList([
                {
                    key: 'all',
                    text: 'All',
                    value: null
                },
                ...fwList
            ])
        }catch(error){
            console.log(error)
        }
    }

    const requestSankeyData = async () => {
        try{
            let params = {}
            if(selectedFrameworkSankey){
                params.id = selectedFrameworkSankey
            }
            const response = await getFrameworkProgress(params)
            let formatedSankeyData = {
                "nodes": [
                    {
                        "id": "complete",
                        "name": "Complete"
                    },
                    {
                        "id": "pending",
                        "name": "Pending"
                    },
                    {
                        "id": "critical",
                        "name": "Critical"
                    },
                    {
                        "id": "inProcess",
                        "name": "In Process"
                    }
                ],
                "links": []
            }

            for(let i = 0; i < response.data.users.length; i++){
                const idUser = `user-${response.data.users[i].idUser}`
                formatedSankeyData.nodes.push({
                    "id": idUser,
                    "name": `${response.data.users[i].name} ${response.data.users[i].lastName}`
                })
                formatedSankeyData.links.push(
                    {
                        "source": idUser,
                        "target": 'complete',
                        "value": response.data.users[i].complete
                    },
                    {
                        "source": idUser,
                        "target": 'pending',
                        "value": response.data.users[i].pending
                    },
                    {
                        "source": idUser,
                        "target": 'critical',
                        "value": response.data.users[i].critical
                    },
                    {
                        "source": idUser,
                        "target": 'inProcess',
                        "value": response.data.users[i].inProcess
                    }
                )

            }
            for(let i = 0; i < response.data.frameworks.length; i++){
                // FORMAT FOR SANKEY DATA.
                const idFramework = `framework-${response.data.frameworks[i].idFramework}`
                formatedSankeyData.nodes.push({
                    "id": idFramework,
                    "name": response.data.frameworks[i].title
                })
                formatedSankeyData.links.push(
                    {
                        "source": 'complete',
                        "target": idFramework,
                        "value": response.data.frameworks[i].complete
                    },
                    {
                        "source": 'pending',
                        "target": idFramework,
                        "value": response.data.frameworks[i].pending
                    },
                    {
                        "source": 'critical',
                        "target": idFramework,
                        "value": response.data.frameworks[i].critical
                    },
                    {
                        "source": 'inProcess',
                        "target": idFramework,
                        "value": response.data.frameworks[i].inProcess
                    }
                )
            }
            setSankeyData(formatedSankeyData)
        }catch(error){
            console.log(error)
        }
    }

    const requestUserFrameworkProgress = async () => {
        try{
            const params = {}
            if(selectedFrameworkSunburst){
                params.id = selectedFrameworkSunburst
            }
            const response = await getUserFrameworkProgress(params)
            
            let formatedSunBurstData = {
                "name": "User_Tasks",
                "children": []
            }
            for(let i = 0; i < response.data.length; i++){
                formatedSunBurstData.children.push({
                    "id": `${i+1}. ${response.data[i].name} ${response.data[i].lastName}`,
                    "name": `${response.data[i].name} ${response.data[i].lastName}`,
                    "children": [
                        {
                            "name": `${response.data[i].name} ${response.data[i].lastName} complete`,
                            "value": response.data[i].complete
                        },
                        {
                            "name": `${response.data[i].name} ${response.data[i].lastName} pending`,
                            "value": response.data[i].pending
                        },
                        {
                            "name": `${response.data[i].name} ${response.data[i].lastName} critical`,
                            "value": response.data[i].critical
                        },
                        {
                            "name": `${response.data[i].name} ${response.data[i].lastName} inProcess`,
                            "value": response.data[i].inProcess
                        }
                    ]
                })
            }
            setSunBurstData(formatedSunBurstData)
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        requestFrameworksList()
        requestFrameworkPercentages()
    }, [])

    useEffect(() => {
        requestSankeyData()
    }, [selectedFrameworkSankey])

    useEffect(() => {
        requestUserFrameworkProgress()
    }, [selectedFrameworkSunburst])

    useEffect(() => {
        requestFrameworkProgressData()
    }, [selectedFrameworkPie])
    
    return (
        <div className='setFlex1'>
            <div className='fullWidth' style={{height: "500px"}}>
                <SegmentGroup>
                    {
                        sankeyData && 
                        <Segment>
                            <Dropdown
                                placeholder='Select Framework'
                                fluid
                                selection
                                search
                                options={frameworkList}
                                onChange={(e, {value}) => setSelectedFrameworkSankey(value)}
                                value={selectedFrameworkSankey}
                            />
                            <div className='fullWidth' style={{height: "50vh"}}>
                                <SimplixResponsiveSankey data={sankeyData}/>
                            </div>
                        </Segment>
                    }
                    <Grid>
                    {
                        sunBurstData !== null &&
                        <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8} className='noPaddingRight'>
                            <Segment>
                                <Dropdown
                                    placeholder='Select Framework'
                                    fluid
                                    selection
                                    search
                                    options={frameworkList}
                                    onChange={(e, {value}) => setSelectedFrameworkSunburst(value)}
                                    value={selectedFrameworkSunburst}
                                />
                                <div className='fullWidth' style={{height: "50vh"}}>
                                    <SimplixSunburst data={sunBurstData}/>
                                </div>
                            </Segment>
                        </Grid.Column>
                    }
                    {
                        pieData !== null &&
                            <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8} className='noPaddingLeft'>
                                <Segment>
                                    <Dropdown
                                        placeholder='Select Framework'
                                        fluid
                                        selection
                                        search
                                        options={frameworkList}
                                        onChange={(e, {value}) => setSelectedFrameworkPie(value)}
                                        value={selectedFrameworkPie}
                                    />
                                    <div className='fullWith' style={{height: "50vh"}}>
                                        <SimplixResponsivePie data={pieData}/>
                                    </div>
                                </Segment>
                            </Grid.Column>
                    }
                    </Grid>
                    {
                        barData !== null &&
                        <Segment>
                            <div className='fullWith' style={{height: "50vh"}}>
                                <SimplixResposiveBar 
                                    data={barData} 
                                    keys={["complete", "pending", "critical", "inProcess"]} 
                                    indexBy='title'
                                    bottomTitle='Framework'
                                    leftTitle='Progress'
                                />
                            </div>
                        </Segment>
                    }
                </SegmentGroup>
            </div>
        </div>
    )
}