import { Button, Grid, Table } from "semantic-ui-react"
import { activateCompany, getCompanies } from "../../util/requests"
import { useEffect, useState } from "react"
import { Store } from "react-notifications-component"
import { renderNotification } from "../../../util/util"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

function ActivateCompany(){
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [companies, setCompanies] = useState([])

    const runGetCompanies = () => {
        getCompanies().then(res => {
            setCompanies(res.data ? res.data : [])
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        runGetCompanies()
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t('CONFIG')
        })
    }, [])

    const sendActivateCompany = (idCompany, active) => {
        const data = {
            idCompany,
            active
        }
        activateCompany(data).then(res => {
            runGetCompanies()
            Store.addNotification(
                renderNotification("success", "Company update", "The company has been updated")
            )
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", "Company update", "There was an error at company activation")
            )
        })
    }

    return(
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Company
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Super User Email(s)
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Activate/Deactivate
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                companies.map((itm, ix) => (
                                    <Table.Row key={`company-${itm["idCompany"]}-${ix}`}>
                                        <Table.Cell>
                                            {itm["companyName"]}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {/* {itm["users"].reduce((res, itm, ix) => `${res} ${ix !== 0 ? ", " : ""}${itm["email"]}`, "")} */}
                                            {itm["users"].reduce((res, itm) => itm["superUser"] ? `${res} ${itm["user"]["email"]}` : res, "")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button 
                                                color={itm["active"] ? "red" : "green"}
                                                onClick={() => sendActivateCompany(itm["idCompany"], !itm["active"])}>
                                                {
                                                    itm["active"] ? "Deactivate" : "Activate"
                                                }
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )

}

export default ActivateCompany