const initState = {
    userName: null,
    idUser: null,
    companyName: null,
    dashTitle: "",
    dashSection: "",
    headerTitle: "",
    menuSection: null,
    superUser: false,
    ultraUser: false,
    partner: false,
    rol: [],
    breadcrumbs: [],
    companyIcon: null,
    iconContain: null,
    flowDiagramNodes: [],
    flowDiagramEdges: [],
}

const Reducer = (state=initState, action) =>{
    switch (action.type){
        case "SET_BREADCRUMBS": {
            return {
                ...state,
                breadcrumbs: action.breadcrumbs
            }
        }
        case "SET_USER":
            return {
                ...state,
                userName: action.userName
            }
        case "SET_HEADER_TITLE":
            return {
                ...state,
                headerTitle: action.headerTitle
            }
        case "SET_USER_ID":
            return {
                ...state,
                idUser: action.idUser
            }
        case "SET_COMPANY_NAME":
            return {
                ...state,
                companyName: action.companyName
            }
        case "SET_DASH_TITLE":
            return {
                ...state,
                dashTitle: action.dashTitle
            }
        case "SET_DASH_SECTION":
            return {
                ...state,
                dashSection: action.dashSection
            }
        case "SET_MENU_SECTION":
            return {
                ...state,
                menuSection: action.menuSection
            }
        case "SET_ROL":
            return {
                ...state,
                rol: action.rol
            }
        case "SET_SUPER_USER":
            return {
                ...state,
                superUser: action.superUser
            }
        case "SET_ULTRA":
            return {
                ...state,
                ultraUser: action.ultraUser
            }
        case "SET_PARTNER":
            return {
                ...state,
                partner: action.partner
            }
        case "SET_COMPANY_ICON":
            return {
                ...state,
                companyIcon: action.companyIcon
            }
        case "SET_ICON_CONTAIN":
            return {
                ...state,
                iconContain: action.iconContain
            }
        case "SET_FLOW_DIAGRAM_NODES":
            return {
                ...state,
                flowDiagramNodes: action.flowDiagramNodes
            }
        case "SET_FLOW_DIAGRAM_EDGES":
            return {
                ...state,
                flowDiagramEdges: action.flowDiagramEdges
            }
        case "RESET":
            return initState;
        default:
            return state;
    }
}

export default Reducer;