import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import { postNewProject } from "../util/requests";


function NewProject({practice, isEdit=false, requestPractice, floated="right"}){
    const { t } = useTranslation()
    const [modalProject, setModalProject] = useState()
    const [edit, setEdit] = useState(isEdit)

    const [projectName, setProjectName] = useState("")
    const [projectNameError, setProjectNameError] = useState()
    
    useState(()=>{
        setEdit(isEdit)
    }, [isEdit])

    const submitData = async () => {
        const check = await checkInput.checkAll()
        if(!check){
            return
        }
        const data  = {
            "name": projectName,
            idFramework: practice.id
        }
        postNewProject(data).then(res => {
            requestPractice(practice.id)
            setModalProject(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const updateData = () =>{

    }

    const checkInput = {
        name: async () => {
            if(!projectName){
                setProjectNameError({
                    content: t('ERR_PROJECT_NAME')
                })
                return false
            }else if (projectName.length < 3 || projectName.length > 100){
                setProjectNameError({
                    content: t('ERR_PROJECT_NAME_LENGTH')
                })
                return false
            }else{
                setProjectNameError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    return(
        <div>
            <Modal
                open={modalProject}
            >
                <Modal.Header>{edit ? "Project details" : t('CREATE_NEW_PROJECT')}</Modal.Header>
                <Modal.Description>
                    <div>
                        <Grid centered>
                            <Grid.Column width={14}>
                                <Form>
                                    {/* <h1 className="centerText">{edit ? "Edit Project" : "Add new Project"}</h1> */}
                                    <Form.Field width={16} style={{marginTop: "20px"}}>
                                        <label>{t('PRACTICE')}</label>
                                        <Form.Input
                                            name={"idPractice"}
                                            value={practice.title}
                                            disabled
                                        />
                                    </Form.Field>
                                    <Form.Field width={16} className="marginBottom">
                                        <label>{t('PROJECT_NAME')}</label>
                                        <Form.Input
                                            name={"name"}
                                            value={projectName}
                                            error={projectNameError}
                                            onChange={(e, { value }) => setProjectName(value)}
                                            type="text"
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </div>
                </Modal.Description>
                <Modal.Actions>
                    <Button 
                        onClick={() => setModalProject(false)}
                        className="buttonDarkGray">
                            {t('CANCEL')}
                    </Button>
                    <Button 
                        className="buttonBlue"
                        onClick={() => (edit ? updateData() : submitData())} 
                        primary>
                            {t('CREATE')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Button 
                floated={floated} 
                onClick={() => setModalProject(true)}
                className={"buttonBlue"}>
                    {edit ? t('EDIT_PROJECT') : t('ADD_PROJECT')}
            </Button>
        </div>
    )
}

export default NewProject;