import "./simplixCollapsible.sass"

function SimplixCollapsible({triggerName, children=null, propsOpen=null, setpropsOpen=null}){

    const toggle = () => {
        setpropsOpen(!propsOpen)
    }

    const renderContent = <div className={`sc_container ${propsOpen ? "sc_container_open" : "sc_container_closed"}`}>
                {children}
            </div>

    return (
        <div className="simplixCollapsible">
            <div className="sc_header" onClick={toggle}>
                <h3 className={`sc_triggerName ${propsOpen ? "sc_triggerName_open": ""}`}>{triggerName}</h3>
            </div>
            {renderContent}
        </div>
        // <Grid className="simplixCollapsible">
        //     <Grid.Row>
        //         <Grid.Column width={16} className="sc_header">
        //             <h3 className="sc_triggerName">{triggerName}</h3>
        //         </Grid.Column>
        //     </Grid.Row>
        //     <Grid.Row>
        //         <Grid.Column width={16}>
        //         {children}
        //         </Grid.Column>
        //     </Grid.Row>
        // </Grid>
    )
}

export default SimplixCollapsible