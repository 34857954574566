import axiosInstance from "./axiosInstance";
import { API_URL } from "../../util/util";

const api = {
    get: (endpoint, params = {}) => {
        const url = new URL(`${API_URL}${endpoint}`)
        for (const key in params) {
            url.searchParams.append(key, params[key])
        }
        return axiosInstance.get(url.href)
    },
    post: (endpoint, body) => axiosInstance.post(`${API_URL}${endpoint}`, body),
    put: (endpoint, body) => axiosInstance.put(`${API_URL}${endpoint}`, body),
    delete: (endpoint, body) => axiosInstance.delete(`${API_URL}${endpoint}`, body)
}

export default api;