import { Fragment } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Breadcrumb } from "semantic-ui-react"

function FrameworkBreadcrumb() {

    const breadcrumbs = useSelector(state => state.breadcrumbs)
    const navigate = useNavigate()

    return (
        <Breadcrumb>
            {
                breadcrumbs.map((itm, ix) => {
                    return breadcrumbs.length !== (ix + 1) ?
                    <Fragment key={`breadcrumb-element-${ix}`}>
                        <Breadcrumb.Section link onClick={() => navigate(itm.link)}>
                            {itm.title}
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                    </Fragment>
                    :
                    <Breadcrumb.Section key={`breadcrumb-element-${ix}`} active>
                        {itm.title}
                    </Breadcrumb.Section>
                    
                })
            }
        </Breadcrumb>
    )
}

export default FrameworkBreadcrumb