import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import { Grid, Button, List, Pagination, Input } from "semantic-ui-react"
import { renderNotification, setDownload, useDidUpdateEffect } from "../../../util/util"
import { deleteRelations, downloadFile, downloadVersionFile, searchFiles } from "../requests"


function SXFileList({ 
        requestParams={},
        deleteParams={},
        deleteFunction=false 
    }){
    // const [files, setFiles] = useState([])
    const { t } = useTranslation()
    const [files, setFiles] = useState([])
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)


    useEffect(() => {
        getFiles()
    }, [page])


    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                getFiles()
            }
        }, 800)
        return () => {
            clearTimeout(delayFunction)
        }
    }, [search])

    const triggerDownloadFile = (id, isVersion=false) => {
        const dwFile = isVersion ? downloadVersionFile : downloadFile
        dwFile(id).then(res => {
            setDownload(res)
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", "File download", "There was an error trying to download the file")
            )
        })
    }

    const getFiles = () => {
        let params = {
            ...requestParams,
            page
        }
        if(search.length > 0){
            params["search"] = search
        }
        searchFiles(params).then(res => {
            setFiles(res.data && res.data.elements ? res.data.elements : [])
            setTotalPages(res.data && res.data.totalPages ? res.data.totalPages : 1)
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('FILE_LIST'), t('FILE_LIST_ERR'))
            )
        })
    }

    const deleteWorkingFileRelation = (idFile) => {
        const data = {
            ...deleteParams,
            idFile: [idFile]
        }
        deleteRelations(data).then(res => {
            Store.addNotification(
                renderNotification("success", t("FILE_RELATION_DELETE"), t("FILE_RELATION_DELETE_MSG"))
            )
            getFiles()
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t("FILE_RELATION_DELETE"), t("FILE_RELATION_DELETE_ERR"))
            )
        })
    }

    const handleDownload = (e, id) => {
        e.stopPropagation()
        triggerDownloadFile(id, false)
    }

    return(
        <Grid>
            <Grid.Row>
                <Grid.Column width={16} className="filesContainer setFlexColumn">
                    <Input
                        icon='search' 
                        placeholder={`${t('SEARCH')}...`} 
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                        fluid
                    />
                    <div className="flexOverflow">
                        {
                            files.length > 0 ?
                            <List selection className="fileList">
                                {
                                    files.map((itm, ix) => 
                                        <List.Item 
                                            key={`file-item-list-${ix}`}>
                                            <List.Icon name='file' />
                                            <List.Content>
                                                <List.Content floated="right">
                                                    <Button 
                                                        onClick={(e) => handleDownload(e, itm.idFile)}
                                                        icon="download"
                                                    />
                                                </List.Content>
                                                {
                                                    deleteFunction && 
                                                        <List.Content floated="right">
                                                            <Button
                                                                onClick={() => deleteWorkingFileRelation(itm.idFile)}
                                                                color="red"
                                                                icon="remove"
                                                            />
                                                        </List.Content>
                                                }
                                                <List.Header>{t('FILENAME')}: {itm.name ? itm.name : itm.versions[0].name}</List.Header>
                                                <List.Description>{t('DATE')}: {itm.date} | {t('LAST_UPDATE')} : {itm.versions[0].date}</List.Description>
                                                <List.Description>{t('UPLOADED_BY')}: {itm.versions[0]["user"] ? `${itm.versions[0]["user"]["name"]} ${itm.versions[0]["user"]["lastName"]}` : `-`}</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    )
                                }
                            </List>
                        :
                            <h2>{t('NO_FILES')}</h2>
                        }
                    </div>
                    <div className="centerText">
                        <Pagination
                            activePage={page}
                            onPageChange={(e, { activePage }) => setPage(activePage)}
                            totalPages={totalPages}
                        />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default SXFileList