import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Grid, Table, Button, Icon, Confirm, Popup } from 'semantic-ui-react'
import Overview from '../util/overview/overview';
import { AICreateProjects, deletePractice, deleteProject, getTasksByPractice } from '../util/requests';
import NewProject from '../project/newProject';
import { useDispatch, useSelector } from 'react-redux';
import "./practice.sass"
import { Store } from 'react-notifications-component';
import { formatToTranslate, readParents, renderNotification } from '../../util/util';
import { useTranslation } from 'react-i18next';
import EditFramework from '../framework/EditFramework';
import DetailModal from '../util/detailModal/DetailModal';
import ConfirmModal from '../util/confirmModal/ConfirmModal';

function Practice(){
    const { t } = useTranslation()
    const [practice, setPractice] = useState({})
    const [projects, setProjects] = useState([])
    const [progress, setProgress] = useState(0)
    const [taskStatus, setTaskStatus] = useState({})
    const [framework, setFramework] = useState({})

    const [deletePracticeModal, setDeletePracticeModal] = useState(false)

    const [createProjectModal, setCreateProjectModal] = useState(false)
    const [loadingProjects, setLoadingProject] = useState(false)

    const [openModal, setOpenModal] = useState(false)

    const [selectedFramework, setSelectedFramework] = useOutletContext()

    const [openConfirm, setOpenConfirm] = useState(false)
    const [toDelete, setToDelete] = useState()

    const { id } = useParams()
    const section = useSelector(state => state.dashSection)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: `${t('PRACTICE')}: ${practice.title ? practice.title : ""}`
        })
    }, [dispatch, t, practice])

    const requestPractice = () =>{
        if(id){
            getTasksByPractice(id).then(response => {
                setFramework(response.data.parent.parent)
                setSelectedFramework(response.data.parent.parent.idFramework)
                setPractice(response.data ? response.data : {})
            }).catch(err => {
                if(err.response){
                    console.log("Error code", err.response.status)
                }else{
                    console.log("Exception", err)
                }
            });
        }
    }

    const runDeletePractice = () => {
        if(id && practice.parent && practice.parent.idFramework){
            const idProcessArea = practice.parent.idFramework
            deletePractice(id).then(res => {
                Store.addNotification(
                    renderNotification("success", t('DELETE_PRACTICE'), t('DELETE_PRACTICE_SUCCESS'))
                )
                navigate(`/dashboard/frameworks/processArea/${idProcessArea}`)
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('DELETE_PRACTICE'), t('DELETE_PRACTICE_ERR'))
                )
            })
        }
    }

    useEffect(() => {
        if(toDelete){
            setOpenConfirm(true)
        }
    }, [toDelete])

    useEffect(() => {
        if(!openConfirm){
            setToDelete(null)
        }
    }, [openConfirm])


    const onDelete = () => {
        setOpenConfirm(false)
        if(!toDelete){
            return
        }
        deleteProject(toDelete).then(response =>{
            Store.addNotification(
                renderNotification("success", t('PROJECT_DELETED'), t('PROJECT_DELETED_MSG'))
            )
            setToDelete(null)
            requestPractice(id)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('PROJECT_DELETED_ERR'), t('PROJECT_DELETED_ERR_MSG'))
            )
            setToDelete(null)
        })
    }

    useEffect(() => {
       requestPractice()
    }, [id])

    useEffect(() => {
        const asyncEffect = async () => {
            if(practice.projects){


                let projectsTab = []
                let overview = {
                    "completed": 0,
                    "pending": 0,
                    "process": 0,
                    "critical": 0,
                    "progress": 0,
                }
                let tasksNumber = 0
                const now = Date.now()
                for (const project of practice.projects) {
                    let row = {
                        idProject: project.idProject,
                        name: project.name,
                        status: null,
                        progress: 0,
                        tasksNumber: 0
                    }
                    for (const task of project.tasks){
                        for(const userTask of task.users){
                            row.tasksNumber ++
                            let datePartsDL = task.deadLine ? task.deadLine.split("-") : null;
                            const dl = datePartsDL ? new Date(datePartsDL[0], datePartsDL[1] - 1, datePartsDL[2].substr(0,2)) : null;
                            let datePartsSt = task.start ? task.start.split("-") : null;
                            const st = datePartsSt ? new Date(datePartsSt[0], datePartsSt[1] - 1, datePartsSt[2].substr(0,2)) : null;
                            if(userTask.progress === 100){
                                overview["completed"] += 1
                            }else if(dl && now > dl.getTime()){
                                overview["critical"] += 1
                            }else if(st && now > st.getTime()){
                                overview["process"] += 1
                            }else{
                                overview["pending"] += 1
                            }
                            overview["progress"] += userTask["progress"] ? userTask["progress"] : 0
                            row.progress += userTask["progress"] ? userTask["progress"] : 0
                            //row.status = row.status === null ? userTask.status : (row.status.level > userTask.status.level ? row.status : userTask.status)
                            row.status = overview["critical"] ? "Critical" : 
                                overview["process"] ? "In Process" : 
                                overview["pending"] ? "Pending" : "Completed"
                        }
                    }
                    tasksNumber += row.tasksNumber
                    //row.progress = row.tasksNumber > 0 ? ((row.progress * 100)/row.tasksNumber) : 100
                    row.progress = row.tasksNumber > 0 ? (row.progress/row.tasksNumber) : 100
                    projectsTab.push(row)
                }
                const allProgress = overview["progress"] ? overview.progress/tasksNumber : 0
                setTaskStatus(overview)
                setProgress(allProgress) 
                setProjects(projectsTab)
    
    
                //////////////////////////////////////  TESTING  //////////////////////////////////////
    
            }
            if(practice && practice.title){
                dispatch({
                    type: 'SET_DASH_SECTION', 
                    dashSection: `${t('PRACTICE')}: ${practice.title}`
                })
                const rs = await readParents(practice)
                dispatch({
                    type: "SET_BREADCRUMBS",
                    breadcrumbs: rs
                })
                //console.log("practice", practice)
            }
        }
        asyncEffect()
    }, [practice])

    const generateProjects = () => {
        setLoadingProject(true)
        const language = localStorage.getItem('language')
        AICreateProjects(id, language).then(() => {
            Store.addNotification(
                renderNotification("success", t('GENERATE_PROJECTS'), t('GENERATE_PROJECTS_MSG'))
            )
            requestPractice()
            setLoadingProject(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('GENERATE_PROJECTS'), t('GENERATE_PROJECTS_MSG_ERR'))
            )
        }).finally(() => {
            setLoadingProject(false)
        })
    }
    
    return(
        <div className='practiceSection'>
            <Grid>
                <Grid.Column width={16}>
                    <h1 className={"dashSubtitle"}>
                        {section}
                        {
                            practice && practice.title && practice.description &&
                            <Fragment>
                                <Popup
                                    trigger={
                                        <Button
                                            onClick={() => setOpenModal(true)}
                                            className='marginLeft erasePadding verticalAlignMiddle buttonBlue' 
                                            size='mini' 
                                            primary>
                                                {t('DETAILS')}
                                        </Button>
                                    }
                                    position="bottom left"
                                    content={t('DETAILS_DESC')}
                                />
                                <Popup
                                    trigger={
                                        <Button
                                            className='marginLeft erasePadding verticalAlignMiddle buttonBlue'
                                            size='mini'
                                            onClick={() => setCreateProjectModal(true)}
                                            loading={loadingProjects}
                                            disabled={loadingProjects}
                                        >
                                            {t('GENERATE_PROJECTS')}
                                        </Button>
                                    }
                                    position='bottom left'
                                    content={t('GENERATE_PROJECTS_DESCRIPTION')}
                                />
                                <DetailModal open={openModal} setOpen={setOpenModal} popupContent={t('DETAILS_DESC')}>
                                    <EditFramework
                                        id={id}
                                        // pTitle={practice.title}
                                        // pDescription={practice.description}
                                        data={practice}
                                        type="practice"
                                        okButton={() => setOpenModal(false)}
                                        callback={requestPractice}
                                    />
                                </DetailModal>
                                <Icon name="trash alternate outline" className="deleteIcon" onClick={() => setDeletePracticeModal(true)}/>
                            </Fragment>
                            
                        }
                        <span><br></br>{t('FRAMEWORK')}: {framework.title} </span>
                    </h1>
                </Grid.Column>
                <Grid.Column widescreen={4} computer={4} tablet={16} mobile={16}>
                <Overview 
                    completed={taskStatus["completed"] || taskStatus["completed"] === 0 ? taskStatus["completed"] : null}
                    pending={taskStatus["pending"] || taskStatus["pending"] === 0 ? taskStatus["pending"] : null}
                    process={taskStatus["process"] || taskStatus["process"] === 0 ? taskStatus["process"] : null}
                    critical={taskStatus["critical"] || taskStatus["critical"] === 0 ? taskStatus["critical"] : null}
                    progress={progress}
                />
                </Grid.Column>
                <Grid.Column widescreen={12} computer={12} tablet={16} mobile={16} className='setFlex'>
                    <Grid className='setFullFlex'>
                        <Grid.Column width={16} className='setFlex1'>
                            {
                                projects.length > 0 ? 
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>{t('NAME')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('TASKS')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('STATUS')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('PROGRESS')}</Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            projects.map(itm => 
                                                <Table.Row key={`project-${itm.idProject}`}>
                                                    <Table.Cell
                                                        onClick={() => navigate(`/dashboard/frameworks/project/${itm.idProject}`)}
                                                        className={"setPointer"}
                                                        >
                                                            {itm.name}
                                                    </Table.Cell>
                                                    <Table.Cell>{itm.tasksNumber}</Table.Cell>
                                                    <Table.Cell>{itm.status ? t(formatToTranslate(itm.status)) : "-"}</Table.Cell>
                                                    <Table.Cell>{itm.tasksNumber > 0 ? `${Math.floor(itm.progress)}%` : "-"}</Table.Cell>
                                                    <Table.Cell className='centerText'>
                                                        <div className='setFlex'>
                                                            <div className='setFlex1'>
                                                                <Popup
                                                                    trigger={
                                                                        <Button 
                                                                            onClick={() => navigate(`/dashboard/frameworks/project/${itm.idProject}`)}
                                                                            className="buttonBlue">
                                                                            {t('MANAGE_ACTIVITIES')}
                                                                        </Button>
                                                                    }
                                                                    content={t('MANAGE_ACTIVITIES_DESC')}
                                                                    position="bottom left"
                                                                />
                                                            </div>
                                                            <div className='setFlex'>
                                                                <div className='marginAuto'>
                                                                    <Icon name="trash alternate outline" className="deleteIcon" onClick={() => setToDelete(itm.idProject)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                                :
                                <div className={"noProjects"}>
                                    <h2>
                                        {t('NO_PROJECTS')}
                                    </h2>
                                    <NewProject 
                                        practice={{
                                            id: practice.idFramework, 
                                            title: practice.title}}
                                        requestPractice={requestPractice}
                                        floated={null}/>
                                </div>
                            }
                        </Grid.Column>
                        {
                            projects.length > 0 &&
                            <Grid.Column width={16} >
                                <NewProject 
                                    practice={{
                                        id: practice.idFramework, 
                                        title: practice.title}}
                                    requestPractice={requestPractice}/>
                            </Grid.Column>
                        }
                    </Grid>
                </Grid.Column>
            </Grid>
            <ConfirmModal
                open={createProjectModal}
                setOpen={setCreateProjectModal}
                type="create"
                title={t('GENERATE_PROJECTS')}
                description={t('GENERATE_PROJECTS_CONF')}
                callback={generateProjects}
            />
            <ConfirmModal
                open={deletePracticeModal}
                setOpen={setDeletePracticeModal}
                type="delete"
                title={t("DELETE_PRACTICE_TITLE")}
                description={t("DELETE_PRACTICE_DESC")}
                callback={runDeletePractice}
            />
            <Confirm
                open={openConfirm}
                onCancel={() => setOpenConfirm(false)}
                onConfirm={() => onDelete()}
            />
        </div>
    )
}

export default Practice;