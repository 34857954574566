import { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import ReactQuill from "react-quill"
import { Grid, Input, List, Button, Pagination, Checkbox, Label, Popup, Divider} from "semantic-ui-react"
import { quillFormats, quillModules, renderNotification, useDidUpdateEffect } from "../../../util/util"
import ConfirmModal from "../confirmModal/ConfirmModal"
import { deleteText, getPracticesByProcessArea, getProcessAreas, getText, putText } from "../requests"
import NewText from "./newText"

import "./textEvidence.sass"

const TextEvidence = forwardRef(({
    idKey = "idText",
    selectedTexts = [],
    searchParams={},
    setSelectedTexts
}, ref) => {

    const { t } = useTranslation()
    const [elements, setElements] = useState([])
    const [selectedElement, setSelectedElement] = useState(null)
    
    const [editTitle, setEditTitle] = useState("")
    const [editText, setEditText] = useState("")
    const [edit, setEdit] = useState(false)

    const [modalNewText, setModalNewText] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)

    // PAGINATION
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [search, setSearch] = useState("")
    const [processAreaOptions, setProcessAreaOptions] = useState([])
    const [selectedProcessArea, setSelectedProcessArea] = useState(null)
    const [practicesOptions, setPracticesOptions] = useState([])
    const [selectedPractice, setSelectedPractice] = useState(null)
    
    useImperativeHandle(ref, () => ({
        reload(){
            getElements()
        }
    }))

    const getProcessArea = () => {
        getProcessAreas().then(res => {
            const data = res.data ? res.data : []
            const options = data.map((itm, ix) => {
                return {
                    key: `process-area-${itm.idFramework}-${ix}`,
                    text: itm.title,
                    value: itm.idFramework
                }
            })
            setProcessAreaOptions(options)
        }).catch(() => {
            Store.addNotification(
                renderNotification('error', t('PROCESS_AREA_LIST'), t('PROCESS_AREA_LIST_ERR'))
            )
        })
    }

    const getPractices = (id) => {
        getPracticesByProcessArea(id).then(res => {
            const data = res.data ? res.data : []
            const options = data.map((itm, ix) => {
                return {
                    key: `practices-${itm.idFramework}-${ix}`,
                    text: itm.title,
                    value: itm.idFramework
                }
            })
            setPracticesOptions(options)
        }).catch(() => {
            Store.addNotification(
                renderNotification('error', t('PRACTICE_LIST'), t('PRACTICE_LIST_ERR'))
            )
        })
    }

    useEffect(() => {
        getProcessArea()
    }, [])

    useEffect(() => {
        getElements()
    }, [page])

    const getElements = () => {
        if(setSelectedTexts){
            setSelectedTexts([])
        }
        let params = {
            ...searchParams,
            page
        }
        if(search){
            params["search"] = search
        }
        getText(params).then(res => {
            setElements(res.data && res.data.elements ? res.data.elements : [])
            setTotalPages(res.data.totalPages ? res.data.totalPages : 1)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('GET_TEXT_ELEMENTS'), t('GET_TEXT_ELEMETS_ERR_MSG'))
            )
        })
    }
    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                getElements()
            }
        }, 800)
        return () => {
            clearTimeout(delayFunction)
        }
    }, [search])

    const setInitialValues = () => {
        if(!selectedElement){
            return
        }
        const ix = elements.findIndex(itm => itm[idKey] === selectedElement)
        if(ix === -1){
            return
        }
        setEditTitle(elements[ix]["title"])
        setEditText(elements[ix]["text"])
    }

    useEffect(() => {
        setInitialValues()
    }, [elements, selectedElement])

    useEffect(() => {
        if(!edit){
            setInitialValues()
        }
    }, [edit])

    useEffect(() => {
        if(selectedElement){
            setEdit(false)
        }
    }, [selectedElement])

    const renderElements = (items = []) => {
        const toggleSelection = (id, related) => {
            const ix = selectedTexts.indexOf(id)
            if(ix === -1 && !related){
                setSelectedTexts([
                    ...selectedTexts,
                    id
                ])
            }else{
                if(ix !== -1){
                    let removeRelation = [...selectedTexts]
                    removeRelation.splice(ix, 1)
                    setSelectedTexts(removeRelation)
                }
            }
        }
        return items.map((itm, ix) => {
                return (
                    <List.Item
                    onClick={
                        setSelectedTexts ?
                        () => toggleSelection(itm[idKey], itm.related)
                        :
                        () => setSelectedElement(itm[idKey])
                    }
                    className={!setSelectedTexts && selectedElement === itm[idKey] ? "selectedItem" : ""}
                    key={`element-${ix}-${itm[idKey]}`}>
                        {
                            setSelectedTexts &&
                            <List.Content floated="left">
                                <Checkbox
                                    checked={selectedTexts.includes(itm[idKey])}
                                    onChange={() => toggleSelection(itm[idKey], itm.related)}
                                    disabled={itm.related}
                                />
                            </List.Content>
                        }
                        <List.Icon name="file text"/>
                        <List.Content>
                            <List.Header>{t('NAME')}: {itm.title ? itm.title : '-'}</List.Header>
                            <List.Description>{t('DATE')}: {itm.date} | {t('LAST_UPDATE')}: {itm.lastUpdate}</List.Description>
                            <List.Description>
                                {t('POSTED_BY')}: {`${itm.postedBy && itm.postedBy.name ? itm.postedBy.name : '-'} 
                                ${itm.postedBy && itm.postedBy.lastName ? itm.postedBy.lastName : '-'}`}
                            </List.Description>
                            {
                                itm.related === 1 &&
                                    <List.Description>
                                        <Popup
                                            content={t('ATTACHED_DESC')}
                                            trigger={
                                                <Label color="green">
                                                    {t('ATTACHED')}
                                                </Label>
                                            }
                                            position="right center"/>
                                    </List.Description>
                            }
                        </List.Content>
                    </List.Item>
                )
            })
    }

    const updateText = () => {
        if(!selectedElement){
            return
        }
        const idText = selectedElement
        let body = {}
        const ix = elements.findIndex(itm => itm[idKey] === idText)
        if(ix === -1){
            return
        }
        if(elements[ix]["title"] !== editTitle){
            body["title"] = editTitle
        }
        if(elements[ix]["text"] !== editText){
            body["text"] = editText
        }
        if((Object.keys(body)).length === 0){
            return
        }
        putText(idText, body).then(res => {
            let newData = JSON.parse(JSON.stringify(elements))
            const ix = newData.findIndex(itm => itm[idKey] === idText)
            newData[ix] = res.data
            setElements(newData)
            setEdit(false)
            Store.addNotification(
                renderNotification("success", t('TEXT_UPDATE'), t('TEXT_UPDATE_SUCCESS'))
            )
        }).catch(err => {
            Store.addNotification("error", t('TEXT_UPDATE'), t('TEXT_UPDATE_ERR'))
        })
    }
    
    const deleteElement = () => {
        if(!selectedElement){
            return
        }
        deleteText(selectedElement).then(() => {
            Store.addNotification(
                renderNotification("success", t('TEXT_DELETE'), t('TEXT_DELETE_MSG'))
            )
            getElements()
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('TEXT_DELETE'), t('TEXT_DELETE_ERR_MSG'))
            )
        })
    }

    const renderElementView = (selected) => {
        const ix = elements.findIndex(itm => itm[idKey] === selected)
        if(ix === -1){
            return null
        }
        return(
            <Fragment>
                {
                    edit ? 
                        <div className="setFlexColumn fullHeight">
                            <div className="editableTitleContainer">
                                <Input
                                    fluid
                                    value={editTitle}
                                    onChange={({ target }) => setEditTitle(target.value)}
                                    className="marginBottom"
                                />
                            </div>
                            <div className="setFlex1 quillContainer">
                                <ReactQuill
                                    theme='snow'
                                    modules={quillModules}
                                    formats={quillFormats}
                                    value={editText}
                                    onChange={setEditText}
                                />
                            </div>
                            <Grid>
                                <Grid.Column width={16} className="marginTop">
                                    <Button
                                        floated="right"
                                        onClick={() => setEdit(false)}
                                        className="buttonDarkGray">
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        floated="right"
                                        onClick={() => updateText()}
                                        className="buttonBlue">
                                        {t('SAVE')}
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </div>
                        :
                        <div className="setFlexColumn fullHeight">
                            <div className="marginBot">
                                <h2 className="centerText">{elements[ix]["title"] ? elements[ix]["title"] : "-"}</h2>
                            </div>
                            <div
                                className="flexOverflow justifiedText paddingRight"
                                dangerouslySetInnerHTML={{__html: elements[ix]["text"] ? elements[ix]["text"] : "-"}}
                            />
                            <div className="marginTop">
                                <Button 
                                    floated="right"
                                    onClick={() => setEdit(true)}
                                    className="buttonBlue">
                                        {t('EDIT')}
                                </Button>
                                <Button
                                    floated="right"
                                    color="red"
                                    onClick={() => setDeleteOpen(true)}>
                                        {t('DELETE')}
                                </Button>
                            </div>
                        </div>
                }
            </Fragment>
        )
    }

    

    return(
        <div className="textEvidence">
            <Grid>
                <Grid.Row>
                    <Grid.Column widescreen={setSelectedTexts ? 16 : 6} computer={setSelectedTexts ? 16 : 6} tablet={setSelectedTexts ? 16 : 6} mobile={16} className="setFlexColumn">
                        <div className="marginBottom">
                            <Input 
                                icon='search' 
                                placeholder={`${t('SEARCH')}...`} 
                                value={search}
                                onChange={({ target }) => setSearch(target.value)}
                                fluid
                            />
                        </div>
                        <div className="flexOverflow">
                            <List selection>
                                {
                                    renderElements(elements)
                                }
                            </List>
                        </div>
                        <div className="centerText">
                            <Pagination
                                activePage={page}
                                onPageChange={(e, { activePage }) => setPage(activePage)}
                                totalPages={totalPages}
                            />
                        </div>
                        <Divider/>
                        <div>
                            <Button
                                className="buttonBlue"
                                floated="right"
                                onClick={() => setModalNewText(true)}>
                                {t('NEW_TEXT')}
                            </Button>
                        </div>
                    </Grid.Column>
                    {
                        !setSelectedTexts &&
                            <Grid.Column widescreen={10} computer={10} tablet={10} mobile={16}>
                                {
                                    renderElementView(selectedElement)
                                }
                            </Grid.Column>
                    }
                    <NewText
                        open={modalNewText}
                        setOpen={setModalNewText}
                        callback={() => getElements()}
                    />
                    <ConfirmModal
                        open={deleteOpen}
                        type="delete"
                        setOpen={setDeleteOpen}
                        title={t('DELETE_TEXT')}
                        description={t('DELETE_TEXT_QUESTION')}
                        callback={() => deleteElement()}
                    />
                </Grid.Row>
            </Grid>
        </div>
    )
})

export default TextEvidence