import { useState, useEffect } from "react";
import { Form, Grid, Dimmer, Loader, Radio, Button } from "semantic-ui-react";
import BoxSection from "../util/boxSection/boxSection";
import SimpleButtonMenu from "../util/simpleButtonMenu/simpleButtonMenu";
import AddArea from "./addArea"
import "./areaSection.sass"

import { getCompanyAreas } from "../util/requests";

function AreaSection(){
    const [selectedSection, setSection] = useState('operative')
    const [selectedFW, setSelectedFW] = useState('1')
    const [companyAreas, setCompanyAreas] = useState([])
    const [loading, setLoading] = useState(true)

    const FWDButtonMenu = [
        {
            name: "Operative",
            value: "operative"
        },
        // {
        //     name: "Administrative",
        //     value: "administrative"
        // },
        {
            name: "Add new area",
            value: "ANA"

        }
    ]

    useEffect(()=>{
        if(selectedSection === "Operative"){
            companyAreasReq()
        }
    }, [selectedSection])

    useEffect(()=>{
        companyAreasReq()
    }, [])

    const companyAreasReq = () =>{
        getCompanyAreas().then(response => {
            setCompanyAreas(response.data ? response.data : [])
            setLoading(false)
        }).catch(err => {
            if(err.response){
                console.log("ErrorCode", err.response.status)
            }else{
                console.log("Exception", err)
            }
        })
    }
    
    return (
        <Grid className="areaSection setFullFlex">
            <Grid.Column width={16}>
                <h1 className="dashTitle">FULL NAME COMPANY</h1>
            </Grid.Column>
            <Grid.Column width={16}>
                <SimpleButtonMenu items={FWDButtonMenu} initial='operative' setSection={setSection}/>
            </Grid.Column>
            <Grid.Column width={16}>
                <h1 className={"dashSubtitle"}>DASHBOARD <span>Areas of company</span></h1>
            </Grid.Column>
            <Grid.Column width={16} className={"flexed"}>
                {loading &&
                    <Dimmer active inverted>
                        <Loader size="medium">Loading</Loader>
                    </Dimmer>
                }
                {selectedSection === 'operative' ? 
                    <Grid>
                        {companyAreas.map((itm, ix) => {
                            return <Grid.Column widescreen={3} largeScreen={4} key={`areaOption-${ix}`}>
                                <BoxSection title={itm.name ? itm.name : itm.areaOption.name}/>
                            </Grid.Column>
                        })}
                    </Grid>
                    : selectedSection === 'administrative' ? 
                    <Grid className="frameworkConfiguration">
                        <Grid.Column width={6}>
                            <h1>Recommended framework</h1>
                            <div className="titleDecorationBar"></div>
                            <div className="textDescription">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras enim dui, 
                            luctus sed elit id, bibendum semper ante. Pellentesque eu odio purus. 
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; 
                            Nunc quis tempor est, id cursus risus. Praesent rhoncus dignissim mi. 
                            Integer tempor lorem quis auctor rutrum. Donec pulvinar scelerisque magna, eu lacinia 
                            lectus finibus sed. Sed laoreet, augue vestibulum porta imperdiet, lacus augue consequat 
                            metus, vel consequat est velit ut quam. Nunc viverra rhoncus urna eget volutpat. Vivamus 
                            tincidunt commodo lectus, ut lobortis ipsum bibendum eu. Maecenas a elit mauris. 
                            </div>
                            <Form className="FWSelection">
                                <Form.Field>
                                    <Radio
                                        label= 'Option #1'
                                        name='FWSelection'
                                        value='1'
                                        checked={selectedFW === '1'}
                                        onChange={()=> setSelectedFW('1')}
                                        style={{
                                            color: "#0061FF"
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label= 'Option #2'
                                        name='FWSelection'
                                        value='2'
                                        checked={selectedFW === '2'}
                                        onChange={()=> setSelectedFW('2')}
                                    />
                                </Form.Field>
                            </Form>
                            <Button className='grayButton'>Use template</Button>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                        <Grid.Column width={6}>
                            <h1>Build your own framework</h1>
                            <div className="titleDecorationBar"></div>
                            <div className="textDescription">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras enim dui, 
                            luctus sed elit id, bibendum semper ante. Pellentesque eu odio purus. 
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; 
                            Nunc quis tempor est, id cursus risus. Praesent rhoncus dignissim mi. 
                            Integer tempor lorem quis auctor rutrum. Donec pulvinar scelerisque magna, eu lacinia 
                            lectus finibus sed. Sed laoreet, augue vestibulum porta imperdiet, lacus augue consequat 
                            metus, vel consequat est velit ut quam. Nunc viverra rhoncus urna eget volutpat. Vivamus 
                            tincidunt commodo lectus, ut lobortis ipsum bibendum eu. Maecenas a elit mauris. 
                            </div>
                            <Button className='grayButton'>Build my project</Button>
                        </Grid.Column>
                    </Grid>
                    :
                    <AddArea setSection={setSection}/>
                }
            </Grid.Column>
        </Grid>
    )
}

export default AreaSection;