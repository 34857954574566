import { useEffect, useState } from 'react'
import { Dropdown, Grid } from 'semantic-ui-react'
import { getFrameworks } from '../util/requests';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FrameworkBreadcrumb from '../util/frameworkBreadcrumb/FrameworkBreadcrumb';

function DBFrameworks(){
    const [frameworkSelected, setFrameworkSelected] = useState(null)
    const [ixFrameworkSelected, setIxFrameworkSelected] = useState(null)
    const [frameworks, setFrameworks] = useState([])
    const [FWDButtonMenu, setFWDButtonMenu] = useState([])

    const [searchParams] = useSearchParams();

    const location = useLocation();
    const navigate = useNavigate();

    const company = useSelector(state => state.companyName)

    useEffect(() => {
        let idFrameworkFromParmams = searchParams.get("id")
        // console.log("PARAMS",searchParams.get("kek"))
        getFrameworks().then(response => {
            const data = response.data ? response.data : []
            // console.log("DATAFW", data)
            setFrameworks(data)
            let fwIx = -1
            if(idFrameworkFromParmams && !isNaN(idFrameworkFromParmams)){
                idFrameworkFromParmams = parseInt(idFrameworkFromParmams)
                fwIx = data.findIndex(itm => idFrameworkFromParmams === itm.idFramework)
            }
            if(fwIx && fwIx !== -1){
                setFrameworkSelected(idFrameworkFromParmams)
                // setIxFrameworkSelected(fwIx)
            }else{
                if(data.length >= 0){
                    setFrameworkSelected(data[0].idFramework)
                    // setIxFrameworkSelected(0)
                }
            }
            
            setFWDButtonMenu(data.map(itm => {
                return {
                    name: itm.title,
                    value: itm.idFramework
                }
            }))
        }).catch(err => {
            if(err.response){
                console.log(err.response.status)
            }else{
                console.log("Exception", err)
            }
        })
    }, [])

    useEffect(() => {
        if(!frameworkSelected){
            setIxFrameworkSelected(null)
        }else{
            let fwIx = frameworks.findIndex(itm => frameworkSelected === itm.idFramework)
            if(fwIx !== -1){
                setIxFrameworkSelected(fwIx)
            }
        }
        if(location.pathname !== "/dashboard/frameworks"){
            navigate("/dashboard/frameworks")
        }
    }, [frameworkSelected])


    const sectionIx = FWDButtonMenu.findIndex(itm => itm.value === frameworkSelected)
    const frameworkDropdown = FWDButtonMenu.map(itm => {
        return {
            key: `FrameworkDropdown-${itm.value}`,
            text: itm.name,
            value: itm.value,
        }
    })
    //console.log(frameworkSelected, sectionName, FWDButtonMenu)

    return(
        <Grid>
            <Grid.Column width={16}>
                <h1 className='dashTitle'>{company}</h1>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16} mobile={16} className="noVerticalPadding">
                {/* {frameworkSelected !== null &&
                    <SimpleButtonMenu items={FWDButtonMenu} initial={frameworkSelected} setSection={setFrameworkSelected}/>
                } */}

                <Dropdown 
                    selection 
                    options={frameworkDropdown}
                    value={frameworkSelected}
                    onChange={(e, {value}) => setFrameworkSelected(value)}
                    search
                    fluid
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <FrameworkBreadcrumb/>
            </Grid.Column>
            <Grid.Column width={16} className="marginBottom">
                <Outlet context={[frameworkSelected, setFrameworkSelected]}/>
            </Grid.Column>
        </Grid>
    )
}

export default DBFrameworks;