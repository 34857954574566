import './App.sass';
import 'semantic-ui-css/semantic.min.css'
import { Outlet } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'react-quill/dist/quill.snow.css';

import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next'

import ENTranslate from './locales/en/translation.json'
import ESTranslate from './locales/es/translation.json'

let language = localStorage.getItem("language")


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: ENTranslate,
      es: ESTranslate
    },
    lng: language ? language : "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  })

function App() {
  return (
    <div className="App">
      <ReactNotifications/>
      <Outlet/>
    </div>
  );
}

export default App;
