import { Fragment, useEffect, useState } from "react";
import { Button, Grid, Table, Icon, Popup, Form } from "semantic-ui-react";
import "./processArea.sass"
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getTasksByProcessArea, deletePractice } from "../../util/requests";
import { readParents, renderNotification } from "../../../util/util.js"
//import { taskExtractor } from "../../../util/util.js";
import Overview from "../../util/overview/overview";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DetailModal from "../../util/detailModal/DetailModal";
import EditFramework from "../../framework/EditFramework";
import ConfirmModal from "../../util/confirmModal/ConfirmModal";
import { Store } from "react-notifications-component";

function ProcessArea(){
    const { t } = useTranslation()
    const [processAreaData, setProcesAreaData] = useState({})
    const [practiceDetails, setPracticeDetails] = useState([])
    const [practiceToDelete, setPracticeToDelete] = useState()
    const [progress, setProgress] = useState(0)
    const [taskStatus, setTaskStatus] = useState({})
    const [framework, setFramework] = useState({})
    const [selectedFramework, setSelectedFramework] = useOutletContext()

    const [openModal, setOpenModal] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false)

    const [selectedLevelFilter, setSelectedLevelFilter] = useState(null)

    const dispatch = useDispatch()

    const section = useSelector(state => state.dashSection)

    const navigate = useNavigate()
    const { id } = useParams()

    const loadProcessArea = () => {
        let params = {}
        if(selectedLevelFilter){
            params["level"] = selectedLevelFilter
        }
        getTasksByProcessArea(id, params).then(response => {
            // console.log("FRAMEWORKFROMAREA", response.data.parent.idFramework)
            setFramework(response.data.parent)
            setSelectedFramework(response.data.parent.idFramework)
            setProcesAreaData(response.data ? response.data : {})
        }).catch(err => {
            if(err.response){
                console.log("Error code", err.response.status)
            }else{
                console.log("Exception", err)
            }
        })
    }

    useEffect(() => {
        if(practiceToDelete){
            setDeleteModal(true)
        }
    }, [practiceToDelete])

    useEffect(() => {
        if(!deleteModal){
            setPracticeToDelete(null)
        }
    }, [deleteModal])

    const deleteSelectedPractice = () => {
        if(practiceToDelete){
            deletePractice(practiceToDelete).then(res => {
                loadProcessArea()
                Store.addNotification(
                    renderNotification("success", t('DELETE_PRACTICE'), t('DELETE_PRACTICE_SUCCESS'))
                )
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('DELETE_PRACTICE'), t('DELETE_PRACTICE_ERR'))
                )
            })
        }
    }

    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: `${t('PROCESS_AREA')}: ${processAreaData.title ? processAreaData.title : ""}`
        })
        
    }, [dispatch, t, processAreaData])

    useEffect(()=>{
        loadProcessArea()
    }, [id, selectedLevelFilter])

    useEffect(() => {
        const asyncEffect = async () => {
            if(processAreaData && processAreaData.practices){
                let practices = JSON.parse(JSON.stringify(processAreaData.practices))
                let status = {
                    "completed": 0,
                    "pending": 0,
                    "process": 0,
                    "critical": 0,
                    "progress": 0
                }
    
                for (const itm of practices) {
                    if(itm.critical){
                        status["critical"] += itm.critical
                        itm["status"] = "critical"
                    }
                    if(itm.inProcess){
                        status["process"] += itm.inProcess
                        itm["status"] = !itm["status"] ? "process" : itm["status"]
                    }
                    if(itm.pending){
                        status["pending"] += itm.pending
                        itm["status"] = !itm["status"] ? "pending" : itm["status"]
                    }
                    if(itm.complete){
                        status["completed"] += itm.complete
                        itm["status"] = !itm["status"] ? "complete" : itm["status"]
                    }
                    if(itm.progress){
                        status["progress"] += itm.progress
                    }
                    if(!itm["status"]){
                        itm["status"] = "--"
                    }
                }
    
                setProgress(processAreaData.practices.length > 0 ? status["progress"]/processAreaData.practices.length : 0)
                setTaskStatus(status)
                setPracticeDetails(practices)
            }
            if(processAreaData && processAreaData.title){
                dispatch({
                    type: "SET_DASH_SECTION",
                    dashSection: `${t('PROCESS_AREA')}: ${processAreaData.title}`
                })
                const rs = await readParents(processAreaData)
                dispatch({
                    type: "SET_BREADCRUMBS",
                    breadcrumbs: rs
                })
            }
        }
        asyncEffect()
    }, [processAreaData])


    const fwMenu = [
        {
            name: "ISO 27001",
            value: "27001"
        },
        {
            name: "CMMI",
            value: "cmmi"
        }
    ]

    const filter_options = [
        {
            key: "1",
            text: "Level 1",
            value: "1"
        },
        {
            key: "2",
            text: "Level 2",
            value: "2"
        },
        {
            key: "3",
            text: "Level 3",
            value: "3"
        },
        {
            key: "4",
            text: "Level 4",
            value: "4"
        },
        {
            key: "5",
            text: "Level 5",
            value: "5"
        }
    ]

    // const sectionIx = fwMenu.findIndex(itm => itm.value === frameworkSelected)
    // const sectionName = sectionIx !== -1 ? fwMenu[sectionIx].name : null
    // const practices = processAreaData && processAreaData.hasOwnProperty("sfw") ? 
    //     processAreaData.sfw.filter(itm => itm.category.name === "Practices") : []
    return(
        <div className="processArea">
            {/* <Grid>
                <Grid.Column width={12}>
                    <h1 className="dashTitle">COMPANY initiative</h1>
                </Grid.Column>
                <Grid.Column width={12}>
                    <SimpleButtonMenu items={fwMenu} initial={"27001"} setSection={setFrameworkSelected}/>
                </Grid.Column>
                <Grid.Column width={8}>
                    <h1 className={"dashSubtitle"}>
                        DASHBOARD <span>{sectionName} | LEVEL - | 0% COMPLETED</span>
                    </h1>
                </Grid.Column>
            </Grid> */}
            <Grid>
                <Grid.Column width={16}>
                    <Grid>
                        <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16}>
                            <h1 className="dashSubtitle">
                                {section}
                            </h1>
                            {
                                processAreaData && processAreaData.title && processAreaData.description &&
                                <Fragment>
                                    <Popup
                                        trigger={
                                            <Button
                                                onClick={() => setOpenModal(true)}
                                                className='marginLeft erasePadding verticalAlignMiddle buttonBlue' 
                                                size='mini' 
                                                primary>
                                                    {t('DETAILS')}
                                            </Button>
                                        }
                                        position="bottom left"
                                        content={t('DETAILS_DESC')}
                                    />
                                    <DetailModal open={openModal} setOpen={setOpenModal}>
                                        <EditFramework
                                            id={id}
                                            // pTitle={processAreaData.title}
                                            // pDescription={processAreaData.description}
                                            data={processAreaData}
                                            type="process_area"
                                            okButton={() => setOpenModal(false)}
                                            callback={loadProcessArea}
                                        />
                                    </DetailModal>
                                </Fragment>
                            }
                        </Grid.Column>
                        <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16} className="rightText">
                            <Form.Dropdown
                                    options={filter_options}
                                    placeholder={t('SELECT_LEVEL')}
                                    label={`${t('FILTER_BY_FRAMEWORK_LEVEL')}: `}
                                    value={selectedLevelFilter}
                                    onChange={(e, {value}) => setSelectedLevelFilter(value)}
                                    selection
                                    clearable
                                />
                        </Grid.Column>
                    </Grid>
                        <span><br></br>{t('FRAMEWORK')}: {framework.title} </span>
                </Grid.Column>
                <Grid.Column width={16}>
                    
                </Grid.Column>
                <Grid.Column widescreen={4} computer={4} tablet={16} mobile={16}>
                    <Overview 
                        completed={taskStatus["completed"] || taskStatus["completed"] === 0 ? taskStatus["completed"] : null}
                        pending={taskStatus["pending"] || taskStatus["pending"] === 0 ? taskStatus["pending"] : null}
                        process={taskStatus["process"] || taskStatus["process"] === 0 ? taskStatus["process"] : null}
                        critical={taskStatus["critical"] || taskStatus["critical"] === 0 ? taskStatus["critical"] : null}
                        progress={progress}
                    />
                </Grid.Column>
                <Grid.Column widescreen={12} computer={12} tablet={16} mobile={16}>
                    <h2>Things to do</h2>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('PRACTICE_NAME')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('PROCESS_AREA')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('PRACTICE_PROGRESS')}</Table.HeaderCell>
                                {/* <Table.HeaderCell>{t('STATUS')}</Table.HeaderCell> */}
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                practiceDetails.map(itm => 
                                    <Table.Row key={`practice-${itm.idFramework}`}>
                                        <Table.Cell 
                                            onClick={()=> navigate(`/dashboard/frameworks/practice/${itm.idFramework}`)}
                                            className={"setPointer"}
                                            >
                                                {itm.title}
                                        </Table.Cell>
                                        <Table.Cell>{processAreaData.title}</Table.Cell>
                                        <Table.Cell>{isNaN(itm.progress) ? "--" : Math.floor(itm.progress)}%</Table.Cell>
                                        {/* <Table.Cell>{t(formatToTranslate(itm.status))}</Table.Cell> */}
                                        <Table.Cell>
                                            <div className="setFlex">
                                                <div className="setFlex1">
                                                    <Button 
                                                        onClick={()=> navigate(`/dashboard/frameworks/practice/${itm.idFramework}`)}
                                                        className="buttonBlue">
                                                            {t('SEE_ACTIVITIES')}
                                                    </Button>
                                                </div>
                                                <div className="setFlex">
                                                    <div className="marginAuto">
                                                        <Icon name="trash alternate outline" className="deleteIcon" onClick={() => setPracticeToDelete(itm.idFramework)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>)
                            }

                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
            <ConfirmModal
                open={deleteModal}
                setOpen={setDeleteModal}
                type="delete"
                title={t("DELETE_PRACTICE_TITLE")}
                description={t("DELETE_PRACTICE_DESC")}
                callback={deleteSelectedPractice}
            />
        </div>
    )
}

export default ProcessArea;