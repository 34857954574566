import React, {Fragment, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import ReactQuill from 'react-quill'
import { Button, Dropdown, Form, Grid, Input, Label, } from 'semantic-ui-react';
import { quillModules, quillFormats, renderNotification } from '../../util/util';
import { getSingleFramework, updateFramework, getAIAutocomplete, getSimpleUserList } from '../util/requests';
import SimplixCollapsible from '../util/SimplixCollapsible/simplixCollapsible';
import "./EditFramework.sass"

function EditFramework({id, data, okButton, callback=null, editable=true, type="framework"}){
    // Valid "framework" types: framework, process_area, practice
    const [title, setTitle] = useState()

    const [editionEnabled, setEditionEnabled] = useState(false)

    const [openDescription, setOpenDescription] = useState(false)
    const [openGuidance, setOpenGuidance] = useState(false)
    const [openRecommended, setOpenRecommended] = useState(false)
    const [openEvidence, setOpenEvidence] = useState(false)

    const [loadingAI, setLoadingAI] = useState(false)

    const [description, setDescription] = useState()
    const [guidance, setGuidance] = useState()
    const [recommended, setRecommended] = useState()
    const [evidence, setEvidence] = useState() 
    const [startDate, setStartDate] = useState("")
    const [dueDate, setDueDate] = useState("")
    
    const [emptyDescription, setEmptyDescription] = useState(false)
    const [emptyGuidance, setEmptyGuidance] = useState(false)
    const [emptyRecommended, setEmpyRecommended] = useState(false)
    const [emptyEvidence, setEmptyEvidence] = useState(false)

    const [managers, setManagers] = useState([])
    const [managersData, setManagersData] = useState([])
    const [userOptions, setUserOptions] = useState([])

    const descriptionRef = React.createRef()
    const guidanceRef = React.createRef()
    const recommendedRef = React.createRef()
    const evidenceRef = React.createRef()
    // let editor = null;
    //let unprivilegedEditor = null;

    const getAIFields = (id, fields=[]) => {
        setLoadingAI(true)
        const regex = /\n/g
        const l = localStorage.getItem('language')
        const requestPromises = fields.map(itm => getAIAutocomplete(id, itm, l))
        Promise.all(requestPromises).then(res =>{
            for (const response of res) {
                let data = response["data"]["result"]
                data = data.replace(regex, "<br>")
                switch (response.data.field) {
                    case 1:
                        setDescription(data)
                        break
                    case 2:
                        setGuidance(data)
                        break
                    case 3:
                        setRecommended(data)
                        break
                    case 4:
                        setEvidence(data)
                        break
                    default:
                        break
                }
                setLoadingAI(false)
            }
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", "AI description error", "There was one or more request errors. Try again later.")
            )
        }).finally(() => setLoadingAI(false))
    }

    const checkQuillImput = (quillRef, setEmptyEditor) => {
        if(quillRef.current){
            const editor = quillRef.current.getEditor()
            const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor);
            if((unprivilegedEditor.getText()).trim().length < 5){
                setEmptyEditor(true)
            }else{
                setEmptyEditor(false)
            }
            //console.log("DESC", description)
        }
    }

    const { t } = useTranslation()
    useEffect(() => {
        checkQuillImput(descriptionRef, setEmptyDescription)
    }, [description])

    useEffect(() => {
        if(editionEnabled){
            setOpenDescription(true)
            setOpenEvidence(true)
            setOpenGuidance(true)
            setOpenRecommended(true)
        }
    }, [editionEnabled])

    // useEffect(() => {
    //     checkQuillImput(guidanceRef, setEmptyGuidance)
    // }, [guidance])

    const setData = (data) =>{
        setTitle(data.title)
        setDescription(data.description)
        setManagers(data.managers ? data.managers.map(itm => itm.idUser) : [])
        setManagersData(data.managers)
        switch (type) {
            case "process_area":
                setStartDate(data.startDate ? data.startDate : "")
                setDueDate(data.dueDate ? data.dueDate : "")
                break;
            case "practice":
                setGuidance(data.guidance ? data.guidance : "")
                setRecommended(data.recommended ? data.recommended : "")
                setEvidence(data.evidenceExpected ? data.evidenceExpected : "")
                setStartDate(data.startDate ? data.startDate : "")
                setDueDate(data.dueDate ? data.dueDate : "")
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getSimpleUserList().then(res => {
            const options = res.data ? res.data.map((itm, ix) => {
                return {
                    key: `user-${itm.idUser}-${ix}`,
                    text: `${itm.name} ${itm.lastName}`,
                    value: itm.idUser
                }
            }) : []
            setUserOptions(options)
        }).catch(err => console.log(err))

        if(!data || !data.title || !data.description){
            getSingleFramework(id).then(res => {
                if(res.data){
                    setData(res.data)
                    // setTitle(res.data.title ? res.data.title : "")
                    // setDescription(res.data.description ? res.data.description : "")
                    //setGuidance(res.data.guidance ? res.data.description : "")
                }
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('ERR_GET_FRAMEWORK'), t('ERR_GET_FRAMEWORK_DESCRIPTION'))
                )
            })
        }else{
            setData(data)
        }
    }, [data])



    const saveData = () => {
        if(emptyDescription || title.trim().length < 3){
            return
        }
        let body = {
            title,
            description
        }
        if(type === "practice" || type === "process_area"){
            body["idManager"] = managers
            body["startDate"] = startDate ? startDate : null
            body["dueDate"] = dueDate ? dueDate : null
            if(type === "practice"){
                body["guidance"] = guidance ? guidance : null
                body["recommended"] = recommended ? recommended : null
                body["evidenceExpected"] = evidence ? evidence : null
            }
        }
        updateFramework(id, body).then(res => {
            Store.addNotification(
                renderNotification("success", t('EDIT_FRAMEWORK'), t('EDIT_FRAMEWORK_DESC'))
            )
            if(callback){
                callback()
            }
            setEditionEnabled(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('ERR_EDIT_FRAMEWORK'), t('ERR_EDIT_FRAMEWORK_DESC'))
            )
        })
    }

    const renderInputs = () => {
        return(
            <Grid.Column width={16} className="setFlex10 overflowY contentContainer">
                <Grid.Row>
                {
                    (type === "practice" || type === "process_area") && <Grid>
                        <Grid.Column width={16} className="marginBottom">
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16}>
                                            <Form.Field>
                                                <label>{t('MANAGERS')}</label>
                                                {
                                                    editionEnabled ?
                                                        <Dropdown
                                                            value={managers}
                                                            onChange={(e, { value }) => setManagers(value)}
                                                            options={userOptions}
                                                            search
                                                            multiple
                                                            selection
                                                            fluid
                                                        />
                                                    :
                                                    <p>{
                                                        managersData && managersData.length > 0 ?
                                                            managersData.map(itm => `${itm.name} ${itm.lastName}`).join(', ')
                                                        : 
                                                            '-'
                                                        }
                                                        </p>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column widescreen={4} computer={4} tablet={8} mobile={16}>
                                            <Form.Field>
                                                <label>{t("START_DATE")}</label>
                                                {
                                                    editionEnabled ? 
                                                        <Form.Input
                                                            className='dateInput'
                                                            type="date"
                                                            name="start"
                                                            value={startDate}
                                                            onChange={({target}) => setStartDate(target.value)}
                                                        />
                                                    :
                                                        <p>{startDate ? startDate : "-"}</p>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column widescreen={4} computer={4} tablet={8} mobile={16}>
                                            <Form.Field>
                                                <label>{t("DUE_DATE")}</label>
                                                {
                                                    editionEnabled ?
                                                        <Form.Input
                                                            className='dateInput'
                                                            type="date"
                                                            name="start"
                                                            value={dueDate}
                                                            onChange={({target}) => setDueDate(target.value)}
                                                        />
                                                    : 
                                                        <p>{dueDate ? dueDate : "-"}</p>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid>
                }
                <SimplixCollapsible
                    triggerName={t('DESCRIPTION')}
                    propsOpen={openDescription}
                    setpropsOpen={setOpenDescription}
                >
                    <Grid.Column width={16} className={`quillContainer`}>
                        {
                            editionEnabled ?
                            <div className='marginTop' onDrop={e => e.preventDefault()}>
                                <Form.Field>
                                    <ReactQuill
                                        theme='snow'
                                        className={emptyDescription ? 'inputError' : ''}
                                        value={description}
                                        modules={quillModules}
                                        formats={quillFormats}
                                        ref={descriptionRef}
                                        onChange={setDescription}
                                    />
                                    {
                                        emptyDescription &&
                                        <Label basic pointing className='errorLabel'>
                                            {t('ERROR_FW_DESCRIPTION')}
                                        </Label>
                                    }
                                </Form.Field>
                            </div>
                            :
                            <div className='initial descriptionDetails marginTop' dangerouslySetInnerHTML={{__html: (description ? description : "-")}}>
                            </div>
                        }
                    </Grid.Column>
                </SimplixCollapsible>
                {/* <Grid.Column width={16}>
                    <h2 className={`marginLeft`}>{t("DESCRIPTION")}:</h2>
                </Grid.Column> */}
                {
                    type === "practice" && 
                    <Fragment>
                        <SimplixCollapsible
                            triggerName={t("GUIDANCE")}
                            propsOpen={openGuidance}
                            setpropsOpen={setOpenGuidance}
                        >
                            <Grid.Column width={16} className="quillContainer">
                                {
                                    editionEnabled ?
                                    <div className='marginTop' onDrop={e => e.preventDefault()}>
                                        <Form.Field>
                                            <ReactQuill
                                                theme='snow'
                                                className={emptyGuidance ? 'inputError' : ''}
                                                value={guidance}
                                                modules={quillModules}
                                                formats={quillFormats}
                                                ref={guidanceRef}
                                                onChange={setGuidance}  
                                            />
                                            {
                                                emptyGuidance &&
                                                <Label basic pointing className='errorLabel'>
                                                    {t('ERROR_GUIDANCE')}
                                                </Label>
                                            }
                                        </Form.Field>
                                    </div>
                                    :
                                    <div className='initial descriptionDetails marginTop' dangerouslySetInnerHTML={{__html: (guidance ? guidance : "-")}}>
                                    </div>
                                }
                            </Grid.Column>
                        </SimplixCollapsible>
                        <SimplixCollapsible
                            triggerName={t("RECOMMENDED_PROJECT")}
                            propsOpen={openRecommended}
                            setpropsOpen={setOpenRecommended}
                        >
                            <Grid.Column width={16} className="quillContainer">
                                {
                                    editionEnabled ?
                                    <div className='marginTop' onDrop={e => e.preventDefault()}>
                                        <Form.Field>
                                            <ReactQuill
                                                theme='snow'
                                                className={emptyRecommended ? 'inputError' : ''}
                                                value={recommended}
                                                modules={quillModules}
                                                formats={quillFormats}
                                                ref={recommendedRef}
                                                onChange={setRecommended}  
                                            />
                                            {
                                                emptyGuidance &&
                                                <Label basic pointing className='errorLabel'>
                                                    {t('ERROR_RECOMMENDED_PROJECT')}
                                                </Label>
                                            }
                                        </Form.Field>
                                    </div>
                                    :
                                    <div className='initial descriptionDetails marginTop' dangerouslySetInnerHTML={{__html: (recommended ? recommended : "-")}}>
                                    </div>
                                }
                            </Grid.Column>
                        </SimplixCollapsible>
                        <SimplixCollapsible
                            triggerName={t("EVIDENCE_EXPECTED")}
                            propsOpen={openEvidence}
                            setpropsOpen={setOpenEvidence}
                        >
                            <Grid.Column width={16} className="quillContainer">
                                {
                                    editionEnabled ?
                                    <div className='marginTop' onDrop={e => e.preventDefault()}>
                                        <Form.Field>
                                            <ReactQuill
                                                theme='snow'
                                                className={emptyEvidence ? 'inputError' : ''}
                                                value={evidence}
                                                modules={quillModules}
                                                formats={quillFormats}
                                                ref={evidenceRef}
                                                onChange={setEvidence}
                                            />
                                            {
                                                emptyGuidance &&
                                                <Label basic pointing className='errorLabel'>
                                                    {t('EVIDENCE_EXPECTED')}
                                                </Label>
                                            }
                                        </Form.Field>
                                    </div>
                                    :
                                    <div className='initial descriptionDetails marginTop' dangerouslySetInnerHTML={{__html: (evidence ? evidence : "-")}}>
                                    </div>
                                }
                            </Grid.Column>
                        </SimplixCollapsible>
                    </Fragment>
                }
                </Grid.Row>
            </Grid.Column>
        )
    }

    //const { id } = useParams();

    return(
        <div className="editFramework">
            <Grid.Row className='setFlexColumn setFlex10'>
                <Grid.Column width={16} className='centerText marginBottom'>
                    {
                        editionEnabled ?
                        <Form.Field>
                            <Input 
                                placeholder="Enter a title..." 
                                value={title} 
                                onChange={({target}) => setTitle(target.value)}
                                className="editTitle"
                                error={title.trim().length < 3}
                                //error={false}
                            />
                            {
                                title.trim().length < 3 &&
                                <Label basic pointing className='errorLabel'>
                                    {t('ERROR_FW_TITLE')}
                                </Label>
                            }
                        </Form.Field>
                        :
                        <h2>{title ? title : "-"}</h2>
                    }
                </Grid.Column>
                {/* {
                    editable && editionEnabled && 
                        <Grid.Column width={16}>
                            <Button 
                                onClick={() => getAIFields(id, type==="practice" ? [1, 2, 3, 4] : [1])} 
                                className="buttonBlue wideButton marginBottom" 
                                loading={loadingAI} 
                                disabled={loadingAI}
                                floated="right"
                                >{t('GET_FROM_AI')}</Button>
                        </Grid.Column>
                } */}
                {
                    renderInputs()
                }
                <Grid.Column width={16} className={"buttonContainer marginTop"}>
                    {
                        editable && editionEnabled ?
                            <Fragment>
                                <Button onClick={() => setEditionEnabled(false)} className="buttonDarkGray wideButton">{t('CANCEL')}</Button>
                                <Button onClick={() => saveData()} primary className="buttonBlue wideButton" disabled={loadingAI}>{t('SAVE')}</Button>
                                <Button 
                                    onClick={() => getAIFields(id, type==="practice" ? [1, 2, 3, 4] : [1])} 
                                    className="buttonBlue wideButton marginBottom" 
                                    loading={loadingAI} 
                                    disabled={loadingAI}
                                    floated="right"
                                >{t('GET_FROM_AI')}</Button>
                            </Fragment>
                        :
                            <Fragment>
                                <Button onClick={okButton} className='buttonDarkGray wideButton'>Ok</Button>
                                {
                                    editable &&
                                        <Fragment>
                                            <Button  onClick={() => setEditionEnabled(true)} className="buttonBlue wideButton">{t('EDIT')}</Button>
                                        </Fragment>
                                }
                            </Fragment>
                    }
                </Grid.Column>
            </Grid.Row>
        </div>
    )
}

export default EditFramework