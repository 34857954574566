import { forwardRef, Fragment, useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import { Grid, Tab } from "semantic-ui-react"
import { renderNotification } from "../../util/util"
import FileManager from "../util/FileManager/FileManager"
import LinkEvidence from "../util/linkEvidence/linkEvidence"
import { postLinkRelation, postNewRelation, postTextRelation } from "../util/requests"
import TextEvidence from "../util/textEvidence/textEvidence"

const EvidenceSelection = forwardRef(({id, type, searchParams={}}, ref) => {

    useImperativeHandle(ref, () => ({
        attach(){
            attachEvidence()
        }
    }))

    const { t } = useTranslation()
    const [tabIndex, setTabIndex] = useState(0)
    const [fileSelection, setFileSelection] = useState([])
    const [textSelection, setTextSelection] = useState([])
    const [linkSelection, setLinkSelection] = useState([])

    const fileRef = useRef()
    const textRef = useRef()
    const linkRef = useRef()

    const attachEvidence = () => {
        if(tabIndex === 0){
            if(fileSelection.length === 0){
                return
            }
            const data = {
                idFile: fileSelection,
                id,
                // TYPES OF RELATIONS ALLOWED AT THE MOMENT
                // FrameworkEvidence: 1
                // TaskAttachments: 2
                // TaskEvidences: 3
                // UserTaskEvidences: 4
                // Since we are trying to make a relation on UserTaskEvidence, we have a type 4 relation.
                type
            }
            postNewRelation(data).then(res => {
                Store.addNotification(
                    renderNotification("success", t('FILE_EVIDENCE'), t('FILE_EVIDENCE_MSG'))
                )
                setFileSelection([])
                fileRef.current.reload()
                // getFiles(4, modalData["idUserTask"], setWorkingFileList)
            }).catch(err => {
                console.log(err)
                Store.addNotification(
                    renderNotification("error", t('FILE_EVIDENCE'), t('FILE_EVIDENCE_MSG_ERR'))
                )
            })
        }
        if(tabIndex === 1){
            if(textSelection.length === 0){
                return
            }
            let params = {type}
            params["idText"] = textSelection
            params["id"] = id
            postTextRelation(params).then(() => {
                Store.addNotification(
                    renderNotification("success", t('TEXT_ATTACHMENT'), t('TEXT_ATTACHMENT_MSG'))
                )
                setTextSelection([])
                textRef.current.reload()
            }).catch(err => {
                console.log(err)
                Store.addNotification(
                    renderNotification("error", t('TEXT_ATTACHMENT'), t('TEXT_ATTACHMENT_ERR'))
                )
            })
        }
        if(tabIndex === 2){
            if(linkSelection.length === 0){
                return
            }
            let params = {type}
            params["idLink"] = linkSelection
            params["id"] = id
            postLinkRelation(params).then(() => {
                Store.addNotification(
                    renderNotification("success", t('LINK_ATTACHMENT'), t('LINK_ATTACHMENT_MSG'))
                )
                setLinkSelection([])
                linkRef.current.reload()
            }).catch(err => {
                console.log(err)
                Store.addNotification(
                    renderNotification("error", t('LINK_ATTACHMENT'), t('LINK_ATTACHMENT_ERR'))
                )
            })
        }
    }

    const renderFileList = () => {
        return(
            <FileManager 
                fileSelector={1}
                selectedRelationFiles={fileSelection}
                setSelectedRelationFiles={setFileSelection}
                searchParams={searchParams}
                ref={fileRef}
            />
        )
    }

    const renderTextList = () => {
        return(
            <TextEvidence
                selectedTexts={textSelection}
                setSelectedTexts={setTextSelection}
                searchParams={searchParams}
                ref={textRef}
            />
        )
    }

    const renderLinkList = () => {
        return (
            <LinkEvidence
                selectedLinks={linkSelection}
                setSelectedLinks={setLinkSelection}
                searchParams={searchParams}
                ref={linkRef}
            />
        )
    }

    const renderTab = (child) => {
        return(
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} className="FMClass">
                        {child()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    const panes = [
        {
            menuItem: t('FILES'),
            render: () => renderTab(renderFileList)
        },
        {
            menuItem: t('TEXTS'),
            render: () => renderTab(renderTextList)
        },
        {
            menuItem: t('LINKS'),
            render: () => renderTab(renderLinkList)
        }
    ]
    return(
        <Fragment>
            <Tab
                menu={{secondary: true, pointing: true}}
                activeIndex={tabIndex}
                onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
                panes={panes}
                />
            {/* <Grid>
                <Grid.Column width={16}>
                    <Divider/>
                    <Button floated="right" className="buttonBlue" onClick={() => attachEvidence()}>
                        {t('ATTACH_EVIDENCE')}
                    </Button>
                </Grid.Column>
            </Grid> */}
        </Fragment>
    )
})

export default EvidenceSelection