import { useEffect, useState, Fragment } from "react";
import { Button, Form, Grid, Icon, Input, Pagination, Popup, Tab, Table } from "semantic-ui-react";
import BoxFramework from "../util/boxFramework/boxFramework";
import { deleteFrameworkAPI, getAvailableFrameworks, getFrameworks, postJsonFramework, sendXLSXFile, sendXLSXFileAPI } from "../util/requests";
import { renderNotification, useDidUpdateEffect } from "../../util/util.js"
import { API_URL } from "../../util/util";
import { Store } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../util/confirmModal/ConfirmModal";

function FrameworkDashboard(){
    const { t } = useTranslation()

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)

    const [tabIndex, setTabIndex] = useState()

    const [frameworksData, setFrameworksData] = useState([])
    const [frameworkFile, setFrameworkFile] = useState()

    const [frameworkFileAPI, setFrameworkFileAPI] = useState()
    const [frameworkFileJSON, setFrameworkFileJSON] = useState()
    const [FrameworksAPI, setFrameworksAPI] = useState([])

    const [selectedLevelFilter, setSelectedLevelFilter] = useState(null)

    const defaultDeleteModalFW = {
        deleteOpen: false,
        idDelete: null
    }

    const [{ deleteOpen, idDelete }, setDeleteFrameworkAPI] = useState(defaultDeleteModalFW)

    const superUser = useSelector(state => state.superUser)
    const ultra = useSelector(state => state.ultraUser)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t('FRAMEWORKS')
        })
    }, [dispatch, t])

    useEffect(() => {
        requestAvailableFrameworks()
    }, [page, superUser])

    useEffect(() => {
        if(tabIndex===0){
            requestFrameworks()
        }
        setSearch("")
    }, [tabIndex])

    useDidUpdateEffect(() => {
        const delaySearch = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                requestAvailableFrameworks()
            }
        }, 800)
        return () => {
            clearTimeout(delaySearch)
        }
    }, [search])

    const requestAvailableFrameworks = () => {
        if(superUser){
            const params = {
                search,
                page,
            }
            getAvailableFrameworks(params).then(res => {
                setTotalPages(res.data && res.data["pages"] ? res.data["pages"] : 0)
                setFrameworksAPI(res.data && res.data["results"] ? res.data["results"] : [])
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('AVAILABLE_FRAMEWORKS_ERR'), t('AVAILABLE_FRAMEWORKS_ERR_MSG'))
                )
            })
        }
    }

    const requestFrameworks = () => {
        let params = {}
        if(selectedLevelFilter){
            params["level"] = selectedLevelFilter
        }
        getFrameworks(params).then(response => {
            setFrameworksData(response.data ? response.data : [])
        }).catch(err => {
            if(err.response){
                console.log("Error code", err.response.status)
            }else{
                console.log("Exception", err)
            }
        })
    }

    const submitForm = (adminFW=false, json_format=false) => {
        console.log("Submit form")
        if(json_format){
            if(!frameworkFileJSON){
                Store.addNotification(
                    renderNotification("error", t('FRAMEWORK_UPLOAD_ERR'), t('ERROR_SELECT_JSON_FILE'))
                )
                return
            }
            console.log("Before reader")
            const reader = new FileReader();
            reader.onload = function(e) {
                const text = e.target.result
                try{
                    console.log("Before parse")
                    const json_framework = JSON.parse(text)
                    console.log("After parse")
                    postJsonFramework(
                        json_framework
                    ).then(() => {
                        Store.addNotification(
                            renderNotification("success", t('FRAMEWORK_UPLOAD'), t('FRAMEWORK_UPLOAD_MSG'))
                        )
                        requestFrameworks()
                        requestAvailableFrameworks()
                    }).catch(() => {
                        Store.addNotification(
                            renderNotification(
                                "error", t('FRAMEWORK_UPLOAD_ERR'), t('FRAMEWORK_UPLOAD_ERR_MSG')
                            )
                        )
                    })
                }catch(e){
                    Store.addNotification(
                        renderNotification("error", t('FRAMEWORK_UPLOAD_ERR'), t('ERROR_SELECT_JSON_FILE'))
                    )
                    return
                }
            }
            reader.onerror = (error) => {
                Store.addNotification(
                    renderNotification(
                        "error", t('FRAMEWORK_UPLOAD_ERR'), t('FRAMEWORK_UPLOAD_ERR_MSG')
                    )
                );
                console.log("Error reading file", error)
            };
            reader.readAsText(frameworkFileJSON);
        }
        else{
            const formData = new FormData()
            formData.append("xlsx_file", adminFW ? frameworkFileAPI : frameworkFile)
            const send = adminFW ? sendXLSXFileAPI : sendXLSXFile
            send(formData).then(() => {
                Store.addNotification(
                    renderNotification("success", t('FRAMEWORK_UPLOAD'), t('FRAMEWORK_UPLOAD_MSG'))
                )
                requestFrameworks()
                requestAvailableFrameworks()
            }).catch((err)=>{
                Store.addNotification(
                    renderNotification(
                        "error", t('FRAMEWORK_UPLOAD_ERR'), t('FRAMEWORK_UPLOAD_ERR_MSG')
                    )
                    )
            })
        }
    }

    const deleteFWAPI = (id) => {
        deleteFrameworkAPI(id).then(() => {
            Store.addNotification(
                renderNotification("success", "Framework", "Framework was deleted succesfully")
            )
            requestAvailableFrameworks()
        }).catch(() => {
            Store.addNotification(
                renderNotification("error", "Framework", "There was an error at deleting the framework")
            )
        })
    }

    const filter_options = [
        {
            key: "1",
            text: "Level 1",
            value: "1"
        },
        {
            key: "2",
            text: "Level 2",
            value: "2"
        },
        {
            key: "3",
            text: "Level 3",
            value: "3"
        },
        {
            key: "4",
            text: "Level 4",
            value: "4"
        },
        {
            key: "5",
            text: "Level 5",
            value: "5"
        }
    ]

    useEffect(() => {
        requestFrameworks()
    }, [selectedLevelFilter])

    const renderFrameworks = () => {
        return(
            <Grid className="setFlex1">
                <Grid.Column width={16} className="rightText">
                    <Form.Field>
                        <Form.Dropdown
                            label={`${t('FILTER_BY_FRAMEWORK_LEVEL')}: `}
                            placeholder={t('SELECT_LEVEL')}
                            options={filter_options}
                            value={selectedLevelFilter}
                            onChange={(e, { value }) => setSelectedLevelFilter(value)}
                            clearable
                            selection
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={16}>
                    <Grid>
                    {
                        frameworksData.map(itm => {
                            return <Grid.Column widescreen={3} mobile={16} tablet={8} computer={4} key={`boxFW-${itm.idFramework}`}>
                                <BoxFramework id={itm.idFramework} title={itm.title} complete={itm.progress}/>
                            </Grid.Column>
                        })
                    }
                    </Grid>
                </Grid.Column>
                {
                    superUser &&
                    <Fragment>
                        <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                            <h3>{t('UPLOAD_FW')}</h3>
                            <Form>
                                <Form.Group floated={"right"}>
                                    <Form.Input label={t('SELECT_FILE')} 
                                        name="xlsx_file" 
                                        type="file" 
                                        onChange={({target}) => setFrameworkFile(target.files[0])} 
                                        required/>
                                </Form.Group>
                                <Popup
                                    trigger={<Button onClick={() => submitForm()} className="buttonBlue">{t('SUBMIT')}</Button>}
                                    position="bottom left"
                                    flowing
                                >
                                    <div>
                                        <span>{t('SUBMIT_FAMRWORK_1')}</span><br/>
                                        <span>{t('SUBMIT_FAMRWORK_2')}</span><br/>
                                        <span>{t('SUBMIT_FAMRWORK_3')}</span><br/>
                                        <span>{t('SUBMIT_FAMRWORK_4')}</span><br/>
                                    </div>
                                </Popup>
                            </Form>
                        </Grid.Column>
                        <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                            <h3>{t('DOWNLOAD_TEMPLATE')}</h3>
                            <Popup
                                trigger={<Button as={'a'} href={`${API_URL}downloadTemplate`} className="buttonBlue">{t('DOWNLOAD')}</Button>}
                                content={t('DOWNLOAD_TEMPLATE_DESC')}
                                position="bottom left"
                            />
                        </Grid.Column>
                    </Fragment>
                }
            </Grid>
        )
    }

    const renderShop = () => {
        return(
            <Grid className="setFlex1">
                <Grid.Column width={16} className="setFlexColumn">
                    <Grid>
                        <Grid.Column width={15} className="marginTop marginBottom">
                            <Input icon placeholder={`${t('SEARCH')}...`} fluid >
                                <input onChange={({ target }) => setSearch(target.value)} value={search}/>
                                <Icon name='search'/>
                            </Input>
                        </Grid.Column>
                    </Grid>
                    <Grid centered className="setFlex1">
                        {
                            FrameworksAPI.map(itm => {
                                return <Grid.Column widescreen={3} mobile={16} tablet={8} computer={4} key={`boxFW-API-${itm.id_framework}`}>
                                    <BoxFramework id={itm.id_framework} title={itm.name} loadCallback={() => setTabIndex(0)} shop/>
                                </Grid.Column>
                            })
                        }
                    </Grid>
                    <Grid>
                        <Grid.Column width={16} className="centerText marginBottom">
                            <Pagination activePage={page} onPageChange={(e, { activePage }) => setPage(activePage)} totalPages={totalPages}/>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        )
    }

    const renderFrameworkAdmin = () => {
        return(
            <div className="setFlexColumn setFlex1">
                <Grid>
                    <Grid.Column width={16} className="marginTop marginBottom">
                        <Input icon placeholder={`${t('SEARCH')}...`} fluid>
                            <input onChange={({ target }) => setSearch(target.value)} value={search}/>
                            <Icon name='search'/>
                        </Input>
                    </Grid.Column>
                </Grid>
                <Grid className="flexOverflow">
                    <Grid.Column width={16}>
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='2'>
                                        {t('FRAMEWORK_LIST')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    FrameworksAPI.map((itm, ix) => (
                                        <Table.Row key={`table-list-admin-${itm.id_framework}-${ix}`}>
                                            <Table.Cell collapsing>{itm.name}</Table.Cell>
                                            <Table.Cell collapsing>
                                                <Button color="red" icon onClick={() => setDeleteFrameworkAPI({deleteOpen: true, idDelete: itm.id_framework})}>
                                                    <Icon name='trash alternate outline'/>
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid>
                <Grid>
                    <Grid.Column width={16} className="centerText marginBottom">
                        <Pagination activePage={page} onPageChange={(e, { activePage }) => setPage(activePage)} totalPages={totalPages}/>
                    </Grid.Column>
                </Grid>
                <Grid>
                    <Grid.Column widescreen={4} computer={4} tablet={8} mobile={16}>
                        <h3>{t('UPLOAD_FW')}</h3>
                        <Form>
                            <Form.Group floated={"right"}>
                                <Form.Input label={t('SELECT_FILE')} 
                                    name="xlsx_file" 
                                    type="file" 
                                    onChange={({target}) => setFrameworkFileAPI(target.files[0])} 
                                    required/>
                            </Form.Group>
                            <Popup
                                trigger={<Button onClick={() => submitForm(true)} className="buttonBlue">{t('SUBMIT')}</Button>}
                                position="bottom left"
                                flowing
                            >
                                <div>
                                    <span>{t('SUBMIT_FAMRWORK_1')}</span><br/>
                                    <span>{t('SUBMIT_FAMRWORK_2')}</span><br/>
                                    <span>{t('SUBMIT_FAMRWORK_3')}</span><br/>
                                    <span>{t('SUBMIT_FAMRWORK_4')}</span><br/>
                                </div>
                            </Popup>
                        </Form>
                    </Grid.Column>
                    <Grid.Column widescreen={4} computer={4} tablet={8} mobile={16}>
                        <h3>{t('UPLOAD_JSON_FW')}</h3>
                        <Form>
                            <Form.Group floated={"right"}>
                                <Form.Input label={t('SELECT_JSON_FILE')} 
                                    name="pdf_file" 
                                    type="file" 
                                    onChange={({target}) => setFrameworkFileJSON(target.files[0])} 
                                    required/>
                            </Form.Group>
                            <Button onClick={() => submitForm(true, true)} className="buttonBlue">{t('SUBMIT')}</Button>
                        </Form>
                    </Grid.Column>
                    <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                        <h3>{t('DOWNLOAD_TEMPLATE')}</h3>
                        <Popup
                            trigger={<Button as={'a'} href={`${API_URL}downloadTemplate`} className="buttonBlue">{t('DOWNLOAD')}</Button>}
                            content={t('DOWNLOAD_TEMPLATE_DESC')}
                            position="bottom left"
                        />
                    </Grid.Column>
                </Grid>
                <ConfirmModal
                    open={deleteOpen}
                    type="delete"
                    setOpen={() => setDeleteFrameworkAPI(defaultDeleteModalFW)}
                    title={t('DELETE_FRAMEWORK')}
                    description={t('DELETE_FRAMEWORK_QUESTION')}
                    callback={() => deleteFWAPI(idDelete)}
                />
            </div>
        )
    }

    const renderPanes = () => {
        let panes = [
            {
                menuItem: t('FRAMEWORKS'),
                render: renderFrameworks
            }
        ]
        if(superUser){
            panes.push({
                menuItem: t('FRAMEWORK_SHOP'),
                render: renderShop
            })
        }
        if(ultra){
            panes.push({
                menuItem: t('FRAMEWORK_ADMIN'),
                render: renderFrameworkAdmin
            })
        }
        return panes
    }

    return(
        <div className="frameworkDashboard setFlex1 setFlex firstChildFlex">
            {/* <Grid.Column width={16}>
                <h2>{t('HOME')}</h2>
            </Grid.Column> */}
            <Tab menu={{secondary: true, pointing: true}} panes={renderPanes()} activeIndex={tabIndex} onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}/>
            
        </div>
    )
}

export default FrameworkDashboard;