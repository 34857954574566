import { t } from "i18next";
import { useState, useEffect } from "react";
import { Grid, Image, Form, Modal, Button, Icon} from "semantic-ui-react";
import { getAreaCatallogue, getSimpleAreaProcess, getSimpleUserList, postNewArea } from "../util/requests";
import "./addArea.sass"

function AddArea({setSection}){
    const [areaCatalogue, setAreaCatalogue] = useState([])
    const [areaOptions, setAreaOptions] = useState([])
    const [users, setUsers] = useState([])
    const [currentAreaProcess, setCurrentAreaProcess] = useState(null)
    const [areaProcessList, setAreaProcessList] = useState([])

    const [selectedArea, setSelectedArea] = useState(null)
    const [manager, setManager] = useState()
    const [areaName, setAreaName] = useState("")
    const [areaProcess, setAreaProcess] = useState([])
    const [staff, setStaff] = useState([])

    const [errorSelectedArea, setErrorSelectedArea] = useState()
    const [errorManager, setErrorManager] = useState()
    const [errorAreaName, setErrorAreaName] = useState()
    const [errorAreaProcess, setErrorAreaProcess] = useState()

    const [modalOpen, setModalOpen] = useState(false)
    

    
    useEffect(() => {
        getAreaCatallogue().then(response =>{
            let options = response.data ? response.data : []
            setAreaCatalogue(options)
            setAreaOptions(options.map(itm => {
                return {
                    key: `area-option-${itm.idAreaOption}`,
                    text: itm.name,
                    value: itm.idAreaOption
                }
            }))
        }).catch(err => {
            if(err.response){
                console.log('Error status', err.response.status)
            }else{
                console.log('Exception', err)
            }
        })

        getSimpleUserList().then(response =>{
            let options = response.data ? response.data : []
            options = options.map(itm => {
                return {
                    key: `user-option-${itm.idUser}`,
                    text: `${itm.name} ${itm.lastName}`,
                    value: itm.idUser
                }
            })
            setUsers(options)
        }).catch(err => {
            if(err.response){
                console.log('Error status: ', err.response.status)
            }else{
                console.log('Exception', err)
            }
        })

        getSimpleAreaProcess().then(response => {
            let options = response.data ? response.data : []
            options = options.map(itm => {
                return {
                    key: `process-area-${itm.idFramework}`,
                    text: itm.title,
                    value: itm.idFramework
                }
            })
            setAreaProcessList(options)
        }).catch(err => {
            if(err.response){
                console.log('Error status: ', err.response.status)
            }else{
                console.log('Exception', err)
            }
        })
    }, [])

    const areaSelected = (value) => {
        const ix = areaCatalogue.findIndex(itm => itm.idAreaOption === value)
        setCurrentAreaProcess(areaCatalogue[ix]["processAreas"].map(itm => itm.title).join(", "))
        setSelectedArea(value)
    }

    const otherIx = areaCatalogue.findIndex(itm => itm.name === "Other")

    const checkInput = {
        areaName: () => {
            if(selectedArea === areaCatalogue[otherIx]["idAreaOption"]){
                if(!areaName){
                    setErrorAreaName({
                        content: t('ERR_ENTER_NAME')
                    })
                }else if(areaName.length < 2 || areaName.length > 100){
                    setErrorAreaName({
                        content: t('ERR_AREA_NAME_LENGTH')
                    })
                }else{
                    setErrorAreaName(null)
                }
            }
        },
        selectedArea: () => {
            if(!selectedArea){
                setErrorSelectedArea({
                    content: t('ERR_SELECT_AREA')
                })
            }else{
                setErrorSelectedArea(null)
            }
        },
        manager: () => {
            if(!manager){
                setErrorManager({
                    content: t('ERR_SELECT_MAN')
                })
            }else{
                setErrorManager(null)
            }
        },
        areaProcess: () => {
            if(areaProcess.length === 0){
                setErrorAreaProcess({
                    content: t('ERR_SELECT_PA')
                })
            }else{
                setErrorAreaProcess(null)
            }
        },
        checkAll: () => {
            return new Promise(resolve => {
                for (const key in checkInput) {
                    if(key === "checkAll")
                        continue
                    checkInput[key]()
                }
                resolve()
            })
        }
    }

    const createArea = async () => {
        await checkInput.checkAll()
        if(errorSelectedArea || errorManager || errorAreaName || errorAreaProcess)
            return

        let newArea = {
            idAreaOption: selectedArea,
            idManager: manager,
            staff
        }
        if(selectedArea === areaCatalogue[otherIx]["idAreaOption"]){
            newArea.name = areaName
            newArea.processAreas = areaProcess
        }
        postNewArea(newArea).then(response => {
            setModalOpen(true)
        }).catch(err => {
            if(err.response){
                console.log("Error code", err.response.status)
            }else{
                console.log("Exception", err)
            }
        })
    }

    return(
        <div className="setFullFlex">
            <Grid columns="2" className="addArea">
                <Grid.Column>
                    <div className="areaStepImg">
                        <Image src="/union7.png" size="small"/>
                    </div>
                    <div className="areaStepDescription">
                        <p>Add Area</p>
                        <div className="areaSub">
                            <p>Start by selecting the active areas of your company and choosing those responsible as well as the personnel in it    </p>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column>
                    <Grid centered>
                        <Grid.Column width={10}>
                            <Form className="formStyle">
                                <Form.Field>
                                    <label>Area</label>
                                    <Form.Select 
                                        options={areaOptions} 
                                        placeholder="Select area"
                                        error={errorSelectedArea}
                                        onChange={(e, {value}) => areaSelected(value)}
                                        onBlur={() => checkInput.selectedArea()}
                                        search
                                        />
                                </Form.Field>
                                { otherIx !== -1 && selectedArea === areaCatalogue[otherIx]["idAreaOption"] &&
                                    <Form.Field>
                                        <label>Set area name</label>
                                        <Form.Input
                                            type="text"
                                            placeholder="Area name"
                                            error={errorAreaName}
                                            onChange={(e, {value}) => setAreaName(value)}
                                            onBlur={() => checkInput.areaName()}
                                        />
                                    </Form.Field>
                                    }
                                <Form.Field>
                                    <label>Responsible of the area</label>
                                    <Form.Select
                                        options={users}
                                        placeholder="Select user"
                                        error={errorManager}
                                        onChange={(e, {value}) => setManager(value)}
                                        onBlur={() => checkInput.manager()}
                                        search
                                    />
                                    {/* <Form.Input type="text" required placeholder="Full user name"/> */}
                                </Form.Field>
                                { otherIx !== -1 && selectedArea === areaCatalogue[otherIx]["idAreaOption"] ?
                                    <Form.Field>
                                        <label>Related process area</label>
                                        <Form.Select
                                            placeholder="Select Process Area(s)"
                                            options={areaProcessList}
                                            onChange={(e, { value }) => setAreaProcess(value)}
                                            onBlur={() => checkInput.areaProcess()}
                                            search
                                            multiple
                                        />
                                    </Form.Field>
                                    :
                                    <Form.Field>
                                        <label>Related process area</label>
                                        <Form.Input 
                                            type="text"
                                            value={currentAreaProcess ? currentAreaProcess : "- -"}
                                            readOnly
                                        />
                                    </Form.Field>
                                }
                                <Form.Field>
                                    <label>STAFF (Optional)</label>
                                    {/* <Form.TextArea placeholder="Full users names"></Form.TextArea> */}
                                    <Form.Select
                                        options={users}
                                        placeholder="Select user(s)"
                                        onChange={(e, {value}) => setStaff(value)}
                                        search
                                        multiple
                                    />
                                </Form.Field>
                                <Form.Field style={{textAlign: "center"}}>
                                    <Form.Button onClick={() => createArea()}>+ Add Area</Form.Button>
                                </Form.Field>
                            </Form>
                            {/* <Button onClick={() => setModalOpen(true)}>
                                AYYYY
                            </Button> */}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            <Modal
                onClose={() => setModalOpen(false)}
                onOpen={() => setModalOpen(true)}
                open={modalOpen}
                className={"modalP"}
                size='mini'
            >
                <Modal.Content className="centerText">
                    <h3 className={"centerText"}>
                        Area created successfully
                    </h3>
                    <Button 
                        onClick={() => {setModalOpen(false); setSection('Operative')}}
                        className="okButton"
                        >
                        <Icon name="check"/>
                        OK
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default AddArea;