import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import { Button, Grid, Input, List, Modal, Pagination } from "semantic-ui-react"
import { renderNotification, useDidUpdateEffect } from "../../../util/util"
import { deleteTextRelations, getText } from "../requests"

function TextList({
    id,
    // TYPE OF RELATIONS ALLOWED AT THE MOMENT
    // FrameworkTextEvidence: 1
    // UserTaskTextEvidence: 2
    type,
    idKey="idText",
    deleteFunction=false,
}) {
    const [elements, setElements] = useState([])
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const detailsModalDefault = {
        isOpen: false,
        modalTitle: false,
        modalDescription: false
    }
    const [{ 
        isOpen, 
        modalTitle, 
        modalDescription 
    }, setModal] = useState(detailsModalDefault)

    useEffect(() => {
        getEvidenceText()
    }, [page])

    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                getEvidenceText()
            }
        }, 800)
        return () => {
            clearTimeout(delayFunction)
        }
    }, [search])

    const { t } = useTranslation()

    const deleteEvidenceText = (idText) => {
        if(type > 2 && type!==5){
            return
        }
        const data = {
            idText: [idText],
            id,
            // TYPE OF RELATIONS ALLOWED AT THE MOMENT
            // FrameworkTextEvidence: 1
            // UserTaskTextEvidence: 2
            // UserTaskLegTextEvidence: 5
            type
        }
        deleteTextRelations(data).then(res => {
            Store.addNotification(
                renderNotification("success", t('TEXT_RELATION_DELETE'), t('TEXT_RELATION_DELETE_MSG'))
            )
            getEvidenceText()
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('TEXT_RELATION_DELETE'), t('ERR_TEXT_RELATION_DELETE_MSG'))
            )
        })
    }

    const getEvidenceText = () => {
        let params = {
            page
        }
        if(search.length > 0){
            params["search"] = search
        }
        switch (type) {
            case 1:
                params["byFramework"] = id
                break;
            case 2:
                params["byUserTask"] = id
                break;
            case 5:
                params["byUserTaskLog"] = id
                break
            default:
                return
        }
        getText(params).then(res => {
            setElements(res.data && res.data.elements ? res.data.elements : [])
            setTotalPages(res.data && res.data.totalPages ? res.data.totalPages : 1)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('GET_TEXT_LIST'), t('GET_TEXT_LIST_ERR'))
            )
        })
    }

    const renderElements = (elements = []) => {
        return (
            <List selection>
                {
                    elements.map((itm, ix) => 
                        <List.Item
                            key={`element-${ix}-${itm[idKey]}`}
                        >
                            <List.Icon name="file text"/>
                            <List.Content>
                                <List.Content floated="right">
                                    <Button
                                        onClick={() => setModal({
                                            isOpen: true,
                                            modalTitle: itm.title ? itm.title : "-",
                                            modalDescription: itm.text
                                        })}
                                        className="buttonBlue"
                                        icon="eye"
                                    />
                                    {
                                        deleteFunction && 
                                        <Button
                                            onClick={() => deleteEvidenceText(itm[idKey])}
                                            color="red"
                                            icon="remove"
                                        />
                                    }
                                </List.Content>
                                <List.Header>{t('NAME')}: {itm.title ? itm.title : '-'}</List.Header>
                                <List.Description>{t('DATE')}: {itm.date} | {t('LAST_UPDATE')}: {itm.lastUpdate}</List.Description>
                                <List.Description>
                                    {t('POSTED_BY')}: {`${itm.postedBy && itm.postedBy.name ? itm.postedBy.name : '-'} 
                                    ${itm.postedBy && itm.postedBy.lastName ? itm.postedBy.lastName : '-'}`}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                }
            </List>
        )
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16} className="setFlexColumn">
                    <Input
                        icon='search' 
                        placeholder={`${t('SEARCH')}...`} 
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                        fluid
                    />
                    <div className="flexOverflow">
                        {
                            elements.length > 0 ? 
                                renderElements(elements)
                            :
                                <h2>{t('NO_TEXT_RELATED')}</h2>
                        }
                    </div>
                    <div className="centerText">
                        <Pagination
                            activePage={page}
                            onPageChange={(e, { activePage }) => setPage(activePage)}
                            totalPages={totalPages}
                        />
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Modal
                onClose={() => setModal(detailsModalDefault)}
                open={isOpen}
            >
                <Modal.Header>{modalTitle}</Modal.Header>
                <Modal.Content>
                    <div className="setFlexColumn modalHeight">
                        <div 
                            className="flexOverflow justifiedText paddingRight"
                            dangerouslySetInnerHTML={{__html: modalDescription}}/>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className="buttonDarkGray"
                        onClick={() => setModal(detailsModalDefault)}
                    >
                        {t('CLOSE')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    )
}

export default TextList