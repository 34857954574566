import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import { Button, Modal, Tab } from "semantic-ui-react"
import { renderNotification } from "../../util/util"
import { deleteRelations, getAllFiles, postNewRelation } from "../util/requests"
import Evidence from "./evidence"
import EvidenceSelection from "./evidenceSelection"

function ModalPracticeEvidence({ open, setOpen, idPractice}){
    const { t } = useTranslation()

    const [tabIndex, setTabIndex] = useState(0)
    const [relatedFiles, setRelatedFiles] = useState([])
    const [fileSelection, setFileSelection] = useState([])

    const selectorRef = useRef()

    useEffect(() => {
        getFiles()
    }, [idPractice])

    const getFiles = () => {
        if(!idPractice){
            return
        }
        const params = {
            // TYPES OF RELATIONS ALLOWED AT THE MOMENT
            // FrameworkEvidence: 1
            // TaskAttachments: 2
            // TaskEvidences: 3
            // UserTaskEvidences: 4
            // Since we are trying to retrieve FrameworkEvidence, we have a type 1 relation.
            relationType: 1,
            id: idPractice,
        }
        getAllFiles(params).then(res => {
            if(res.data){
                setRelatedFiles(res.data)
            }
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("err", t('FILE_LIST'), t('FILE_LIST_ERR'))
            )
        })
    }

    const setEvidence = () => {
        if(fileSelection.length === 0 || !idPractice){
            return
        }
        const data = {
            idFile: fileSelection,
            id: idPractice,
            // TYPES OF RELATIONS ALLOWED AT THE MOMENT
            // FrameworkEvidence: 1
            // TaskAttachments: 2
            // TaskEvidences: 3
            // UserTaskEvidences: 4
            // Since we are trying to post a FrameworkEvidence, we have a type 1 relation.
            type: 1
        }
        postNewRelation(data).then(res => {
            Store.addNotification(
                renderNotification("success", t('FILE_EVIDENCE'), t('FILE_EVIDENCE_MSG'))
            )
            setFileSelection([])
            getFiles()
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('FILE_EVIDENCE'), t('FILE_EVIDENCE_MSG_ERR'))
            )
        })
    }

    const deleteWorkingFileRelation = (id) => {
        const data = {
            idFile: [id],
            id: idPractice,
            // TYPES OF RELATIONS ALLOWED AT THE MOMENT
            // FrameworkEvidence: 1
            // TaskAttachments: 2
            // TaskEvidences: 3
            // UserTaskEvidences: 4
            // Since we are trying to post a FrameworkEvidence, we have a type 1 relation.
            type: 1
        }
        deleteRelations(data).then(res => {
            Store.addNotification(
                renderNotification("success", "FILE_RELATION_DELETE", "FILE_RELATION_DELETE_MSG")
            )
            getFiles()
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", "ERR_FILE_RELATION_DELETE", "ERR_FILE_RELATION_DELETE_MSG")
            )
        })
    }

    const renderFileList = () => {
        return(
            <Evidence
                id={idPractice}
                type={1}
            />
        )
        // return(
        //     <SXFileList
        //         files={relatedFiles}
        //         deleteFunction={deleteWorkingFileRelation}
        //     />
        // )
    }

    const renderPracticeEvidence = () => {
        return(
            <EvidenceSelection
                ref={selectorRef}
                id={idPractice}
                type={1}
                searchParams={{
                    checkFrameworkRelation: idPractice
                }}
            />
        )
        // return(
        //     <Grid>
        //         <Grid.Row>
        //             <Grid.Column width={16} className="FMClass">
        //                 <FileManager
        //                     fileSelector={1}
        //                     selectedRelationFiles={fileSelection}
        //                     setSelectedRelationFiles={setFileSelection}
        //                     searchParams={{
        //                         checkUserTaskRelation: idPractice
        //                     }}
        //                 />
        //             </Grid.Column>
        //         </Grid.Row>
        //     </Grid>
        // )
    }

    const panes = [
        {
            menuItem: t('EVIDENCE'),
            render: renderFileList,
        },
        {
            menuItem: t('UPDATE_EVIDENCE'),
            render: renderPracticeEvidence
        }
    ]

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>
                {t('PRACTICE_EVIDENCE')}
            </Modal.Header>
            <Modal.Content className="noMargin">
                <Tab 
                    menu={{secondary: true, pointing: true}} 
                    panes={panes}
                    activeIndex={tabIndex}
                    onTabChange={(e, { activeIndex } ) => setTabIndex(activeIndex)}
                    />
            </Modal.Content>
            <Modal.Actions>
                {
                    tabIndex === 1 &&
                        <Button
                            className="buttonBlue"
                            onClick={() => selectorRef.current.attach()}
                            >
                                {t('SAVE_EVIDENCE')}
                        </Button>
                }
                <Button
                    className="buttonDarkGray"
                    onClick={() => setOpen(false)}>
                    {t('CLOSE')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalPracticeEvidence