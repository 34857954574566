import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Store } from "react-notifications-component"
import { Button, Input, Item, Pagination } from "semantic-ui-react"
import { renderNotification, useDidUpdateEffect } from "../../../util/util"
import { deleteLinkRelations, getLinks } from "../requests"


function LinkList({
    deleteParams,
    // TYPE OF RELATIONS ALLOWED AT THE MOMENT
    // FrameworkLinkEvidence: 1
    // UserTaskLinkEvidence: 2
    requestParams={},
    idKey="idLink",
    deleteFunction=false
}){
    const [elements, setElements] = useState([])
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const { t } = useTranslation()

    useEffect(() => {
        getEvidenceLink()
    }, [page])

    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                getEvidenceLink()
            }
        }, 800)
        return () => {
            clearTimeout(delayFunction)
        }
    }, [search])

    const getEvidenceLink = () => {
        let params = {
            ...requestParams,
            page
        }
        if(search.length > 0){
            params["search"] = search
        }
        getLinks(params).then(res => {
            setElements(res.data && res.data.elements ? res.data.elements : [])
            setTotalPages(res.data&& res.data.totalPages ? res.data.totalPages : 1)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('GET_LINK_LIST'), t('GET_LINK_LIST_ERR'))
            )
        })
    }

    const deleteRelation = (idLink) => {
        let params = {
            ...deleteParams,
            idLink: [idLink],
        }
        deleteLinkRelations(params).then(res => {
            Store.addNotification(
                renderNotification("success", t('LINK_RELATION_DELETE'), t('LINK_RELATION_DELETE_MSG'))
            )
            getEvidenceLink()
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('LINK_RELATION_DELETE'), t('LINK_RELATION_DELETE_ERR'))
            )
        })
    }

    const renderElements = (items = []) => {
        const openLink = (e, link) => {
            e.stopPropagation()
            window.open(link, "_blank")
        }
        return(
            <Item.Group divided>
                {
                    items.length > 0 ?
                        items.map((itm, ix) => {
                            return (
                                <Item key={`item-details-${itm[idKey]}-${ix}`}>
                                    <Item.Image 
                                        size={"tiny"}
                                        src={itm.image && itm.image.image ? itm.image.image : "/image.png"}
                                        className={"imageContainer"}
                                    />
                                    <Item.Content>
                                        <Item.Header>{itm.title ? itm.title : "-"}</Item.Header>
                                        <Item.Meta>
                                            <span>{itm.link}</span>
                                        </Item.Meta>
                                        <Item.Extra>
                                            <p>{t('DATE')}: {itm.date} | {t('LAST_UPDATE')}: {itm.lastUpdate}</p>
                                            <p>
                                            {t('POSTED_BY')}: {`${itm.postedBy && itm.postedBy.name ? itm.postedBy.name : '-'} 
                                                ${itm.postedBy && itm.postedBy.lastName ? itm.postedBy.lastName : '-'}`}
                                            </p>
                                            
                                            <Button
                                                floated='right'
                                                icon="external alternate"
                                                className="buttonBlue"
                                                onClick={(e) => openLink(e, itm.link)}
                                            />
                                            {
                                                deleteFunction && deleteRelation &&
                                                <Button
                                                    color="red"
                                                    icon="delete"
                                                    onClick={() => deleteRelation(itm[idKey])}
                                                />
                                            }
                                        </Item.Extra>
                                    </Item.Content>
                                </Item>
                            )
                        })
                        :
                        <h2>{t('NO_LINKS_RELATED')}</h2>
                }
            </Item.Group>
        )
    }
    return(
        <div className="linkList setFlexColumn">
            <Input
                icon='search' 
                placeholder={`${t('SEARCH')}...`} 
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                fluid
            />
            <div className="flexOverflow paddingRight setFlex1 marginTop">
                {
                    renderElements(elements)
                }
            </div>
            <div className="centerText">
                <Pagination
                    activePage={page}
                    onPageChange={(e, { activePage }) => setPage(activePage)}
                    totalPages={totalPages}
                />
            </div>
        </div>
    )
}

export default LinkList