import { useTranslation } from "react-i18next"
import { Modal} from "semantic-ui-react"
import "./DetailModal.sass"

function DetailModal({open, setOpen, children}){
    // const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    // const renderTrigger = () => {
    //     if(popupContent){
    //         return <Popup
    //             trigger={<Button className='marginLeft erasePadding verticalAlignMiddle' size='mini' primary>{t('DETAILS')}</Button>}
    //             position="bottom left"
    //             content={popupContent}
    //         />
    //     }else{
    //         return <Button className='marginLeft erasePadding verticalAlignMiddle' size='mini' primary>{t('DETAILS')}</Button>
    //     }
    // }
    return (
        <Modal
            size='large'
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            //trigger={renderTrigger()}
            className="mainModal"
        >
            <Modal.Content 
                className="backgroundModal"
                //scrolling
            >
                {children}
            </Modal.Content>
        </Modal>
    )
}

export default DetailModal