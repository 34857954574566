import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "semantic-ui-react";
import { getCompanyCategories, getCompanyData, updateCompanyData } from "../util/requests";
import { Store } from "react-notifications-component";
import { renderNotification } from "../../util/util";


export default function CompanyConfiguration(){
    const { t } = useTranslation()

    const [companyName, setCompanyName] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [numberEmployees, setNumberEmployees] = useState("")
    const [companyCategory, setCompanyCategory] = useState("")

    const [companyNameError, setCompanyNameError] = useState(null)
    const [companyDescriptionError, setCompanyDescriptionError] = useState(null)
    const [numberEmployeesError, setNumberEmployeesError] = useState(null)
    const [companyCategoryError, setCompanyCategoryError] = useState(null)


    const [companyCategories, setCompanyCategories] = useState([])

    const requestCompanyInfo = async () => {
        try{
            const response = await getCompanyData()
            setCompanyName(response.data["companyName"] ? response.data["companyName"] : "")
            setCompanyDescription(response.data["companyDescription"] ? response.data["companyDescription"] : "")
            setNumberEmployees(response.data["numberEmployees"] ? response.data["numberEmployees"] : 0)
            setCompanyCategory(response.data["idCategory"])
        }catch(e){
            console.log(e)
        }
    }

    const requestCompanyCategories = async () => {
        try{
            const response = await getCompanyCategories()
            setCompanyCategories(response.data.map((itm, ix) => ({
                key: `category-${itm["idCategory"]}-${ix}`,
                value: itm["idCategory"],
                text: itm["name"]
            })))
        }catch(e){
            console.log(e)
        }
    }

    useEffect(() => {
        requestCompanyInfo()
        requestCompanyCategories()
    }, [])

    const checkInput = {
        companyName: () => {
            if(companyName.length === 0){
                setCompanyNameError(t('COMPANY_NAME_REQUIRED'))
                return false
            }
            if(companyName.length > 100){
                setCompanyNameError(t('COMPANY_NAME_LENGTH'))
                return false
            }
            setCompanyNameError(null)
            return true
        },
        companyDescription: () => {
            if(companyDescription.length > 500){
                setCompanyDescriptionError(t('COMPANY_DESCRIPTION_LENGTH'))
                return false
            }
            setCompanyDescriptionError(null)
            return true
        },
        numberEmployees: () => {
            if(numberEmployees < 0){
                setNumberEmployeesError(t('NUMBER_EMPLOYEES_MIN'))
                return false
            }
            setNumberEmployeesError(null)
            return true
        },
        companyCategory: () => {
            if(!companyCategory){
                setCompanyCategoryError(t('COMPANY_CATEGORY_REQUIRED'))
                return false
            }
            setCompanyCategoryError(null)
            return true
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    const updateCompany = async () => {
        if(!await checkInput.checkAll()){
            return
        }
        let data = {
            companyName,
            numberEmployees: Number(numberEmployees),
            idCategory: companyCategory
        }
        if(companyDescription){
            data["companyDescription"] = companyDescription
        }
        try{
            const result = await updateCompanyData(data)
            Store.addNotification(
                renderNotification('success', t('EDIT_COMPANY'), t('EDIT_COMPANY_SUCCESS'))
            )
            requestCompanyInfo()
        }catch(error){
            Store.addNotification(
                renderNotification('error', t('EDIT_COMPANY'), t('EDIT_COMPANY_ERR'))
            )
        }

    }

    return (
        <div className="companyConfiguration">
            <Form>
                <Form.Field>
                    <label>{t('COMPANY_NAME')}</label>
                    <Form.Input
                        placeholder={t('COMPANY_NAME')}
                        value={companyName}
                        error={companyNameError}
                        onBlur={checkInput.companyName}
                        onChange={({ target }) => setCompanyName(target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('COMPANY_DESCRIPTION')}</label>
                    <Form.TextArea
                        placeholder={t('COMPANY_DESCRIPTION')}
                        value={companyDescription}
                        error={companyDescriptionError}
                        onBlur={checkInput.companyDescription}
                        onChange={({ target }) => setCompanyDescription(target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('NUMBER_EMPLOYEES')}</label>
                    <Form.Input
                        placeholder={t('NUMBER_EMPLOYEES')}
                        type="number"
                        value={numberEmployees}
                        error={numberEmployeesError}
                        onBlur={checkInput.numberEmployees}
                        onChange={({ target }) => setNumberEmployees(target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('COMPANY_CATEGORY')}</label>
                    <Form.Dropdown
                        options={companyCategories}
                        value={companyCategory}
                        error={companyCategoryError}
                        onBlur={checkInput.companyCategory}
                        onChange={(e, { value }) => setCompanyCategory(value)}
                        search
                        selection
                    />
                </Form.Field>
                <div>
                    <Button
                        content={t('SAVE')}
                        onClick={updateCompany}
                        className="buttonBlue"
                        floated="right"
                    />
                </div>
            </Form>
        </div>
    )
}