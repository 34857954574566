import { Gantt, Task, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Grid } from 'semantic-ui-react';
import { projectExtractor, renderNotification, } from '../../util/util';
import { getTasksWithFrameworkTree, updateDates } from '../util/requests';
import { ViewSwitcher } from "./viewSwitcher"

function GanttDiagram(){
    const { t:any, i18n } = useTranslation()
    // interface ITaskListHeader {
    //     headerHeight: number;
    //     rowWidth: string;
    //     fontFamily: string;
    //     fontSize: string;
    // }
    // const TaskListHeader: React.FC<ITaskListHeader> = () => {
    //     return (
    //       <Grid.Row style={{ width: 400, marginTop: 18 }}>
    //         <Grid.Column width={6}>Título:</Grid.Column>
    //         <Grid.Column width={5}>Início:</Grid.Column>
    //         <Grid.Column width={5}>Fin:</Grid.Column>
    //       </Grid.Row>
    //     );
    // };

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [frameworks, setFrameworks] = useState([])
    const [projects, setProjects] = useState([])
    const [tasks, setTasks] = useState<Task[]>([])

    
    const [view, setView] = useState<ViewMode>(ViewMode.Week)
    const [isChecked, setIsChecked] = useState<boolean>(true)

    const [edited, setEdited] = useState<Task[]>([])

    const [frameworkOptions, setFrameworkOptions] = useState<any[]>([])
    const [selectedFramework, setSelectedFramework] = useState<number | null>(null)

    const getProjects = async (fw:number) => {
        const ix = frameworks.findIndex((itm:any) => itm.idFramework === fw)
        let projects = await projectExtractor(frameworks[ix]["sfw"])
        setProjects(projects)
        
    }

    useEffect(() => {
        formatProject()
    }, [projects])



    const formatProject = () => {
        let count:number = 1
        const formatedTasks:Task[] = projects.reduce((tasks:Task[], itm:any) => {
            let count:number = tasks.length +1
            let projectTasks:Task[] = []
            let projectStart = null
            let projectEnd = null
            let progress = 0
            const projectIx = count
            for (const task of itm["tasks"]) {
                if(!task["deadLine"]){
                    continue
                }
                count = count + 1
                let start = task["start"] ? task["start"].split("-") : null
                start = start ? new Date(start[0], start[1] - 1, start[2]) : null
                let end = task["deadLine"].split("-")
                end = new Date(end[0], end[1] - 1, end[2])
                let taskProgress = (task["users"].reduce((tot:any, itm:any) => {
                    return tot + (itm["progress"] ? itm["progress"] : 0)
                }, 0))
                taskProgress = taskProgress === 0 ? 0 : (taskProgress/task["users"].length)
                if(!task["isHito"]){
                    if(start){
                        projectStart = projectStart  === null || projectStart > start ? start : projectStart
                    }
                }else{
                    projectStart = projectStart  === null || projectStart > end ? end : projectStart
                }
                projectEnd = projectEnd === null || projectEnd < end ? end : projectEnd
                progress += taskProgress

                projectTasks.push({
                    start: task["isHito"] ? end : start,
                    end,
                    name: task["title"],
                    id: `task-${task["idTask"]}`,
                    type: task["isHito"] ? 'milestone' : 'task' ,
                    project: `project-${itm["idProject"]}`,
                    progress: taskProgress,
                    dependencies: task["idParent"] ? [`task-${task["idParent"]}`] : [],
                    displayOrder: count

                })
            }
            if(projectStart && projectEnd){
                const project:Task = {
                    name: itm["name"],
                    id: `project-${itm["idProject"]}`,
                    type: "project",
                    hideChildren: false,
                    start: projectStart ? projectStart : projectEnd,
                    end: projectEnd,
                    progress,
                    displayOrder: projectIx
                }
                projectTasks = [project, ...projectTasks]
            }
            return [...tasks, ...projectTasks]
        }, [])
        setTasks(formatedTasks)
    }

    const update = async () => {
        if(edited.length > 0){
            const requests = edited.map(itm => {
                const deadLine = `${itm.end.getFullYear()}-${itm.end.getMonth() + 1}-${itm.end.getDate()}`
                let data:{[key: string]:string} = {
                    deadLine
                }
                if(itm.type === "task"){
                    const start = `${itm.start.getFullYear()}-${itm.start.getMonth() + 1}-${itm.start.getDate()}`
                    data["start"] = start
                }
                //console.log("MeinTask", (itm.id.split("-"))[1])
                return updateDates((itm.id.split("-"))[1], data)
            })
            Promise.all(requests).then(res => {
                Store.addNotification(
                    renderNotification("success", t('TASK_DATES_UPD'), t('TASK_DATES_UPD_MSG'))
                )
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('TASK_DATES_UPD_ERR'), t('TASK_DATES_UPD_ERR_MSG'))
                )
            })
        }
    }
                        
    const handleExpanderClick = (task:Task) => {
        const newTasks = tasks.map(t => (t.id === task.id ? task : t))
        setTasks(newTasks)
    }

    const revertChanges = () => {
        setEdited([])
        formatProject()
        //setTasks(originalTasks)
    }

    const handleTaskChange = (task: Task) => {
        let newTasks = tasks.map(t => (t.id === task.id ? task : t))
        if(task.project){
            const projectIx = newTasks.findIndex(itm => itm["id"] === task["project"])

            const reduce = newTasks.reduce((total:any, itm:any) => {
                if(itm.project === task.project &&  itm.id !== task.project){
                    total["start"] = total["start"] === null || total["start"] > itm["start"] ? itm["start"] : total["start"]
                    total["end"] = total["end"] === null || total["end"] < itm["end"] ? itm["end"] : total["end"]
                }
                return total
            }, {
                start: null,
                end: null
            })

            newTasks[projectIx]["start"] = reduce["start"]
            newTasks[projectIx]["end"] = reduce["end"]
        }
        if(task.type === "task" || task.type === "milestone"){
            let newEdited = JSON.parse(JSON.stringify(edited))
            const editedIx = newEdited.findIndex((itm:any) => itm.id === task.id)
            if(editedIx === -1){
                setEdited([...newEdited, task])
            }else{
                newEdited[editedIx] = task
                setEdited(newEdited)
            }
        }
        setTasks(newTasks)
    }

    
    useEffect(() => {
        if(frameworks && frameworks.length > 0){
            const options = frameworks.map((itm:any, ix:number) => {
                return {
                    key: `framework-${ix}`,
                    text: itm.title,
                    value: itm.idFramework
                }
            })
            setFrameworkOptions(options)
        }
    }, [frameworks])

    useEffect(() => {
        if(frameworkOptions.length > 0 && selectedFramework === null)
            setSelectedFramework(frameworkOptions[0]["value"])
    }, [frameworkOptions])

    useEffect(() => {
        const ix = frameworks.findIndex((itm:any) => itm.idFramework === selectedFramework)
        if(ix !== -1){
            dispatch({
                type: "SET_HEADER_TITLE",
                headerTitle: `${t("GANTT_DIAGRAM_FOR")} ${frameworks[ix]["title"]}`
            })
        }else{
            dispatch({
                type: "SET_HEADER_TITLE",
                headerTitle: t("GANTT_DIAGRAM")
            })
        }
    }, [selectedFramework, dispatch, t])

    useEffect(() => {
        if(selectedFramework !== null)
            getProjects(selectedFramework)
    }, [selectedFramework])

    useEffect(() => {
        getTasksWithFrameworkTree().then(res => {
            setFrameworks(res.data ? res.data : {})
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('FRAMEWORK_REQUEST_ERR'), t('FRAMEWORK_REQUEST_ERR_MSG'))
            )
        })
    }, [])
    return (       
        <div>
            <Grid>
                <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                    {
                        frameworkOptions.length > 0 && selectedFramework &&
                            <Dropdown
                                options={frameworkOptions}
                                placeholder='Framework'
                                value={selectedFramework}
                                onChange={(e,  o:any) => setSelectedFramework(o.value)}
                                fluid
                                search
                                selection
                            />
                    }
                </Grid.Column>
                <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                    {
                        tasks.length > 0 && 
                            <ViewSwitcher
                                onViewModeChange={setView}
                                onViewListChange={setIsChecked}
                                viewMode={view}
                                isChecked={isChecked}
                            />
                    }
                </Grid.Column>
                {
                    tasks.length === 0 ?
                        <Grid.Column width={16}>
                            <h1 className='centeredText'>{`${t('NO_TASKS_FRAMEWORK')}...`}</h1>
                        </Grid.Column>
                    :
                    <Fragment>
                        <Grid.Column width={16} className={"ganttMax"}>
                            {tasks.length > 0 ?
                                    <Gantt 
                                        tasks={tasks} 
                                        viewMode={view}
                                        listCellWidth={isChecked ? "155px" : ""}
                                        locale={i18n.language}
                                        onExpanderClick={handleExpanderClick}
                                        onDateChange={handleTaskChange}
                                        ganttHeight={500}
                                        barProgressColor="#0061FF"
                                        barBackgroundColor="#B1B1B1"
                                        barBackgroundSelectedColor='#98BFFF'
                                        barProgressSelectedColor='#0061FF'
                                        fontFamily='lato'
                                        columnWidth={110}
                                    />
                            :null}
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <Button floated='right' className='buttonBlue' onClick={() => update()} disabled={edited.length === 0}>
                                Update tasks
                            </Button>
                            <Button floated='right' className='buttonDarkGray' onClick={() => revertChanges()} disabled={edited.length === 0}>
                                Revert changes
                            </Button>
                        </Grid.Column>
                    </Fragment>
                }
            </Grid>
        </div>
    )
}


export default GanttDiagram;