import { useEffect, useRef } from "react"
import { Store } from "react-notifications-component"
import { Header, Icon } from "semantic-ui-react"
import { getAIAutocomplete, getAllFiles } from "../components/util/requests"

export const SITE_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : `https://${window.location.host}`//"https://uat.simplix.io" 

export const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:5000/api/" : `https://${window.location.host}/api/`//"https://uat.simplix.io/api/"

export const TRACKING_MILISECONDS_TO_IDLE = 600000

export const MIN_SECONDS_FOR_TRACKING = 10

// Quill editor configuration.
export const quillModules ={
    toolbar: [
        // [{ 'font': [] }],
        //[{ 'size': ['small', false, 'large', 'huge'] }], 
        [{ 'header': 1 }, { 'header': 2 }], 
        ['bold', 'italic', 'underline', 'strike'], 
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        // [{ 'color': [] }, { 'background': [] }], 
        [{ 'align': [] }],
        ['link'],
        ['clean']
    ]
}


export const element_url = {
    "Framework": {
        link: "frameworks?id=",
        name: "Framework"
    },
    "Process Area": {
        link: "frameworks/processArea/",
        name: "Process Area"
    },
    "Practices": {
        link: "frameworks/practice/",
        name: "Practice"
    },
    "Project": {
        link: "frameworks/project/",
        name: "Project"
    }
}

export const readParents = async (data, result = []) => {   
    //console.log("TEST", data, data.category.name)
    const section = data.category && data.category.name ? data.category.name : "Project"
    result.unshift({
        "title": `${element_url[section]["name"]}: ${data.title ? data.title: data.name}`,
        "link": `/dashboard/${element_url[section]["link"]}${data.idFramework ? data.idFramework : data.idProject}`
    })
    if(data.parent){
         return await readParents(data.parent, result)
    }
    else{
        return result
    }
}

export const quillFormats = [
    'background',
    'bold',
    'color',
    'font',
    //'code',
    'italic',
    'link',
    'size',
    'strike',
    //'script',
    'underline',
    //'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    //'code-block',
    //'formula'
    //'image'
    // 'video'
]

// FUNCIÓN RECURSIVA PARA LA EXTRACCIÓN DE PROYECTOS
// DESDE CUALQUIER NIVEL DEL FRAMEWORK
// DECLARACIÓN DE LA FUNCIÓN ASÍNCRONA 
export const projectExtractor = async (data) => {
    //ARRAY EN DONDE SE ALMACENAN LOS PROYECTOS
    let projects = []
    // CICLO PARA REVISAR TODOS LOS HIJOS DEL FRAMEWORK
    for (const child of data) {
        // SI ESTE ELEMENTO CONTIENE UNA PROPIEDAD LLAMADA "PROJECTS"
        // Y LA LONGITUD ES MAYOR A 0, SE ADJUNTAN LOS PROYECTOS
        if(child.hasOwnProperty("projects") && child["projects"].length > 0){
            projects = [...projects, ...child["projects"]]
        }
        // SI CONTIENE LA PROPIEDAD LLAMADA "SWF"(SUB FRAMEWORK)
        // LLAMA A ESTA FUNCIÓN PARA QUE HAGA LA EXTRACCIÓN EN ESTE NUEVO NIVEL
        if(child.hasOwnProperty("sfw") && child["sfw"].length > 0){
            const nestedProjects = await projectExtractor(child["sfw"])
            projects = [...projects, ...nestedProjects]
        }
    }
    return projects
}

export const dateToStr = async (date = new Date()) => {
    return `${date.getFullYear}-${date.getMonth() + 1}-${date.getDate()}`
}

export const frameworkProgressExtractor = async (data) => {
    if(data.tasks && data.tasks.length > 0){
        // for (task of data.tasks){
        //     task.progress
        // }
        let progress = data.tasks.reduce((total, itm) => {
            //return total + (itm.progress ? itm.progress : 0)
            let userTasks = itm.users ? itm.users : []
            let totalUserTasks = userTasks.reduce((ut, it) => {
                return ut + (it.progress ? it.progress : 0)
            }, 0)
            totalUserTasks = totalUserTasks ? totalUserTasks/userTasks.length : 0
            return total + totalUserTasks
        }, 0)
        progress = progress ?  progress/data.tasks.length : 0
        return progress
    }
    if(data.projects && data.projects.length > 0){
        let progress = 0
        for(const project of data.projects){
            progress += await frameworkProgressExtractor(project)
        }
        progress = progress ? progress/data.projects.length : 0
        return progress
    }
    if(data.sfw && data.sfw.length > 0){
        let progress = 0
        for (const s of data.sfw) {
            progress += await frameworkProgressExtractor(s)
        }
        progress = progress ? progress/data.sfw.length : 0
        return progress
    }
    return 0
}

export const taskExtractor = async (data) => {
    let tasks = []
    for (const row of data) {
        if(row.hasOwnProperty("projects") && row["projects"]){
            for (const project of row["projects"]) {
                if(project.hasOwnProperty("tasks") && project["tasks"]){
                    for (const t of project["tasks"]) {
                        if(t.hasOwnProperty("users") && t["users"]){
                            tasks = tasks.concat(t["users"].map(itm =>{
                                let deadLine = t["deadLine"] ? t["deadLine"] : null
                                let start = t["start"] ? t["start"] : null
                                let dl = null
                                let st = null
                                if(deadLine){
                                    let dateParts = deadLine.split("-");
                                    dl = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0,2));
                                }
                                if(start){
                                    let dateParts = start.split("-");
                                    st = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0,2));
                                }
                                return{
                                    id: itm.idUserTask,
                                    status: itm.status,
                                    progress: itm.progress,
                                    start: st,
                                    deadLine: dl,
                                    title: t["title"]
                                }
                            }))
                        }
                    }
                }
            }
        }
        if(row.hasOwnProperty("sfw") && row["sfw"]){
            const sub = await taskExtractor(row["sfw"])
            tasks = tasks.concat(sub)
        }
    }
    return tasks
}

export const startExtractor = async (processAreaData, callback) => {
    let overview = {
        "completed": 0,
        "pending": 0,
        "process": 0,
        "critical": 0,
        "progress": 0,
    }
    let practiceList = []

    if(!processAreaData.sfw){
        return
    }
    for (const practice of processAreaData.sfw) {
        const tasks = await taskExtractor(practice ? [practice] : [])
        const days = tasks.reduce((status, itm) => {
            if(!itm.deadLine){
                return status
            }
            const days = (itm.deadLine.getTime() - Date.now()) / (1000 * 3600 * 24)
            return status === null ? days : days < status ? days : status
        }, null) 
        practiceList.push({
            "id": practice.idFramework,
            "name": practice.title,
            "processArea": processAreaData.title,
            "progress": tasks.length > 0 ? ((tasks.reduce((total, itm) => {
                return itm.progress ? itm.progress + total : total
            }, 0)) / tasks.length) : 0,
            days,
            "status": days === null ? "--" : 
                days >= 15 ? "On time" : 
                days >= 10 ? "Priority" : 
                days >= 5 ? "Urgent" : "Critical"
        })
    }
    const tasks = await taskExtractor(processAreaData.sfw ? processAreaData.sfw : [])
    const now = Date.now()
    overview = tasks.length > 0 ? (tasks.reduce((total, itm) => {
        if(itm.progress === 100){
            total["completed"] += 1
        }else if(itm.start.getTime() > now){
            total["pending"] += 1
        }else if(itm.deadLine.getTime() < now){
            total["critical"] += 1
        }else{
            total["process"] += 1
        }
        total["progress"] += itm["progress"] ? itm["progress"] : 0
        return total
    }, overview)) : []
    
    const allProgress = overview["progress"] ? overview.progress/tasks.length : 0
    const data = {
        progress: Math.floor(allProgress),
        practice: practiceList,
        status: overview
    }
    callback(data)
}

export const assignedCount = (names, limit=3) => {
    let result = ""
    for (let i = 0; i < names.length; i++) {
        result += i === 0 ? names[i] : `, ${names[i]}`
        if(i === limit -1){
            break
        }
    }
    if(names.length > limit){
        result += ` and ${names.length - limit} more...` 
    }
    return result;
}

export const notificationTemplate = {
    type: "default",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 8000,
        onScreen: false
    }
}

export const renderMessage = (title, message, icon) => {
    return(
        <Header as='h3' className="notificationDiv">
            {icon && <Icon name={icon}/>}
            <Header.Content>
                {title}
                <Header.Subheader>{message}</Header.Subheader>
            </Header.Content>
        </Header>
    )
}

export const formatToTranslate = (text) => {
    return `${text}`.toLocaleUpperCase().replace(/ /g,"_")
}

// export const notificationTemplate = {
//     title: "Wonderful!",
//     message: "teodosii@react-notifications-component",
//     type: "default",
//     insert: "top",
//     container: "top-right",
//     animationIn: ["animate__animated", "animate__fadeIn"],
//     animationOut: ["animate__animated", "animate__fadeOut"],
//     dismiss: {
//         duration: 8000,
//         onScreen: false
//     }
// }

export const renderNotification = (type, title, message) => {
    const types = {
        "success": {
            type: "default",
            message: renderMessage(title, message, "check circle")
        },
        "error": {
            type: "danger",
            message: renderMessage(title, message, "times circle")
        }
    }
    return {
        ...notificationTemplate,
        ...types.hasOwnProperty(type) ? types[type] : {}
    }

}

export const setDownload = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a')
    link.href = url
    link.target='_blank'
    link.rel='noopener noreferrer'
    link.download=response.headers["content-disposition"].split('=')[1]
    document.body.appendChild(link)
    link.click()
}

export const useDidUpdateEffect = (fn, inputs=[]) => {
    const didMountRef = useRef(false)

    useEffect(() => {
        if(didMountRef.current){
            return fn()
        }else{
            didMountRef.current = true
        }
    }, inputs)
}

export const getFiles = (relationType, id, setFunction) => {
    // TYPES OF RELATIONS ALLOWED AT THE MOMENT
    // FrameworkEvidence: 1
    // TaskAttachments: 2
    // TaskEvidences: 3
    // UserTaskEvidences: 4
    // Since we are trying to retrieve TaskAttachments, we have a type 2 relation.
    if(!relationType || !id || !setFunction){
        return
    }
    let params = {
        relationType,
        id
    }
    getAllFiles(params).then(res => {
        if(res.data){
            setFunction(res.data)
        }
    }).catch(err => {
        console.log(err)
        Store.addNotification(
            renderNotification("error", "File list", "There was an error retrieving the file list.")
        )
    })
}