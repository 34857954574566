import { useEffect, useRef, useState } from "react";
import { Image } from 'semantic-ui-react'
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./dashboard.sass"
import DashHeader from "./dashHeader";
import { userData, postLog } from "../util/requests";
import DashMenu from "./dashMenu";
import { useTranslation } from "react-i18next";
import axios from 'axios'
import { API_URL, MIN_SECONDS_FOR_TRACKING, TRACKING_MILISECONDS_TO_IDLE } from "../../util/util";

function Dashboard(){
    const { t } = useTranslation()
    const [loaded, setLoaded] = useState(false)
    const [clicks, setClicks] = useState(0)

    const [loadedForUseLogs, setLoadedForUseLogs] = useState(false)

    



    const dispatch = useDispatch()
    const rol = useSelector(state => state.rol)
    const navigate = useNavigate()
    const location = useLocation()
    // const [userName, setUserName] = useState('-')
    
    const companyIcon = useSelector(state => state.companyIcon)
    const iconContain = useSelector(state => state.iconContain)

    const [imgSrc, setImgSrc] = useState(null)

    const delayFunction = useRef(null)
    const start_time = useRef(null)
    const sended = useRef(false)

    useEffect(() => {
        if(loaded){
            triggerTimer(true)
        }
    }, [clicks])

    const triggerTimer = (click) => {
        const current_location = location.pathname
        if(!click || !start_time.current){
            start_time.current = Date.now()
        }
        sended.current = false
        clearTimeout(delayFunction.current)
        delayFunction.current = setTimeout(() => {
            sended.current = true
            const seconds = Math.floor((Date.now() - start_time.current)/1000)
            console.log("LOCATION (TIME)!", current_location, seconds)
            start_time.current=null
            const data = {
                seconds,
                site:{
                    route: current_location
                }
            }
            postLog(data).catch(err => {
                console.log(err)
            })
        }, TRACKING_MILISECONDS_TO_IDLE)
    }

    useEffect(() => {
        if(loaded){
            const current_location = location.pathname
            triggerTimer()
            return () => {
                if(!sended.current && location.pathname){
                    const seconds = Math.floor((Date.now() - start_time.current)/1000)
                    if(seconds > MIN_SECONDS_FOR_TRACKING){
                        const data = {
                            seconds,
                            site:{
                                route: current_location
                            }
                        }
                        postLog(data).catch(err => {
                            console.log(err)
                        })   
                    }
                }
                clearTimeout(delayFunction.current)
            }
        }
    }, [location.pathname, loaded])

    const requestUserData = async () => {
        try{
            const response = await userData()
            dispatch({
                type: 'SET_USER',
                userName: `${response.data["name"]} ${response.data["lastName"]}`
            })
            dispatch({
                type: 'SET_USER_ID',
                idUser: response.data["idUser"]
            })
            dispatch({
                type: 'SET_ROL',
                rol: response.data["roles"] ? response.data["roles"].map(itm => itm["name"]) : []
            })
            dispatch({
                type: 'SET_SUPER_USER',
                superUser: response.data["superUser"] ? response.data["superUser"] : false
            })
            dispatch({
                type: 'SET_COMPANY_NAME',
                companyName: response.data["companyName"]
            })
            dispatch({
                type: 'SET_ULTRA',
                ultraUser: response.data["ultraUser"] ? response.data["ultraUser"] : false
            })
            dispatch({
                type: 'SET_PARTNER',
                partner: response.data["partner"] ? response.data["partner"] : false
            })
            dispatch({
                type: 'SET_COMPANY_ICON',
                companyIcon: response.data["companyIcon"] ? response.data["companyIcon"] : false
            })
            dispatch({
                type: 'SET_ICON_CONTAIN',
                iconContain: response.data["iconContain"] ? response.data["iconContain"] : false
            })
            if(!response.data["companyIcon"]){
                setLoaded(true)
            }
            setLoadedForUseLogs(true)
        }catch(err){
            if(err.response){
                console.log("ERROR CODE", err.response.status)
            }else{
                console.log("ERROR", err.message)
            }
        }
        // }).catch((err)=>{
        //     if(err.response){
        //         console.log("ERROR CODE", err.response.status)
        //     }else{
        //         console.log("ERROR", err.message)
        //     }
        // })
    }    

    useEffect(()=>{
        requestUserData()
    }, [])

    useEffect(() => {
        if(companyIcon){
            const fetchImage = async () => {
                const acc = localStorage.getItem('AT')
                const idc = localStorage.getItem('c-id')
                try {
                    const response = await axios.get(`${API_URL}companyIcon`, { 
                        headers:{
                            'X-API-KEY': acc,
                            'connection-id': idc
                        },
                        responseType: 'blob',
                        params:{
                            img: companyIcon
                        }
                    });
                    const blob = new Blob([response.data], { type: 'image/jpeg' });
                    const imgSrc = URL.createObjectURL(blob);
                    setImgSrc(imgSrc);
                    setLoaded(true);
                } catch (error) {
                    console.error(error);
                    setLoaded(true);
                }
            };
        
            fetchImage();
        }        
      }, [companyIcon]);

    return(
        <div className="dashboardClass">
            <div className="classContainer">
                <div className="sideMenu">
                    {
                        loaded && companyIcon !== null &&
                        <div className="logoContainer" onClick={() => navigate("/dashboard/")}>
                            {
                                imgSrc ?
                                <div style={{
                                    backgroundImage: `url(${imgSrc})`, 
                                    width: '100%', 
                                    aspectRatio: 1,
                                    backgroundSize: iconContain ? 'contain' : 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }
                                }></div>
                                :
                                <Image src={"/logo_svg.svg"}/>
                            }
                        </div>
                    }
                    <DashMenu/>
                </div>
                <div className="dashboardContent">
                        <DashHeader/>
                        {
                            loaded ? 
                                rol && rol.length > 0 ?
                                <div className="dashboardMainContent">
                                    <Outlet/>
                                </div>
                                :
                                    <h1>{t('NO_ROL')}</h1>
                                :
                            <div></div>
                        }
                </div>
            </div>
        </div>
        // <Grid className="dashboardClass">
        //     <Grid.Column width={16}>
        //         <Grid className="dashContainer">
        //             <Grid.Column width={1} className="sideMenu">
        //                 <Image src="/mainIcon.png"/>
        //                 <DashMenu setArea={setArea}/>
        //             </Grid.Column>
                        // <Grid.Column width={15} className="dashboardContent" style={{backgroundImage: "url(/background-isotipo-simplix.png)"}}>
                        //     <DashHeader/>
                        //     {
                        //         rol && rol.length > 0 ?
                        //         <div className="dashboardMainContent">
                        //             <Outlet/>
                        //         </div>
                        //         :
                        //         <h1>{t('NO_ROL')}</h1>
                        //     }
                        // </Grid.Column>
        //         </Grid>
        //     </Grid.Column>
        // </Grid>
    )
}

export default Dashboard;