import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Grid } from "semantic-ui-react";
// import ReactCrop from "react-image-crop";
import './platformConfiguration.sass'
import axios from 'axios'
import { API_URL, renderNotification } from "../../util/util";
import { Store } from "react-notifications-component";
import { useDispatch } from "react-redux";

export default function PlatformConfiguration() {
    const { t } = useTranslation()
    const [companyIcon, setCompanyIcon] = useState([])
    const [viewMode, setViewMode] = useState('contain')
    const [iconStyle, setIconStyle] = useState({})
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    // const [crop, setCrop] = useState({
    //     unit: '%', // Can be 'px' or '%'
    //     x: 25,
    //     y: 25,
    //     width: 50,
    //     height: 50
    //   })

    const onDrop = useCallback(acceptedFiles => {
        setCompanyIcon([...acceptedFiles])
    })
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        multiple: false
    });
    
    const removeFile = file => {
        setCompanyIcon([])
    }

    const renderDropzone = () => (
        <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {
                companyIcon.length > 0 ?
                    // <div>
                    //     <Icon name="file pdf outline" size="massive"/>
                    //     <p className="fileName">{companyIcon[0].path}</p>
                    // </div>
                    <div className="imageDisplay" style={{backgroundImage: `url(${URL.createObjectURL(companyIcon[0])})`, ...iconStyle}}>

                    </div>
                :
                    <p>{t('DRAG_N_DROP')}</p>
            }
        </div>
    )

    const saveIcon = async () => {
        if(companyIcon.length === 0){
            return
        }
        const formData = new FormData();
        formData.append('file', companyIcon[0])
        formData.append('iconContain', viewMode==='contain' ? 1 : 0)
        setLoading(true)
        try{
            const acc = localStorage.getItem('AT')
            const idc = localStorage.getItem('c-id')
            const result = await axios.post(
                `${API_URL}companyIcon`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-API-KEY': acc,
                        'connection-id': idc
                    }
                }
            )
            Store.addNotification(
                renderNotification('success', 'Icon', 'Icon updated')
            )
            if(result.data["iconContain"]){
                dispatch({
                    type: 'SET_ICON_CONTAIN',
                    iconContain: true
                })
            }
            if(result.data["customIcon"]){
                dispatch({
                    type: 'SET_COMPANY_ICON',
                    companyIcon: result.data["customIcon"]
                })
            }
        }catch(error){
            console.log(error)
            Store.addNotification(
                renderNotification('error', 'Icon', 'Error updateing icon')
            )
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        if(viewMode === 'contain'){
            setIconStyle({
                backgroundSize: 'contain'
            })
        }else{
            setIconStyle({
                backgroundSize: 'cover'
            })
        }
        console.log(viewMode)
    }, [viewMode])

    const viewOptions = [
        {
            key: 'contain',
            text: 'Contain',
            value: 'contain'
        },
        {
            key: 'cover',
            text: 'Cover',
            value: 'cover'
        }
    ]

    return (
        <div className="platformConfiguration">
            <h1>Platform Configuration</h1>
            <Grid>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <h2>Company Icon</h2>
                    {
                        renderDropzone()
                    }
                    <Dropdown
                        placeholder="Select view"
                        options={viewOptions}
                        value={viewMode}
                        onChange={(e, { value }) => setViewMode(value)}
                        selection
                        fluid
                    />
                    <Button 
                        style={{marginTop: '1em'}}
                        onClick={saveIcon} 
                        disabled={loading} 
                        primary>
                        Save
                    </Button>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                    {
                        // companyIcon.length > 0 &&
                        // <ReactCrop src={URL.createObjectURL(companyIcon[0])} crop={crop} onChange={newCrop => setCrop(newCrop)}>
                        //     <img src={URL.createObjectURL(companyIcon[0])}/>
                        // </ReactCrop>
                    }
                </Grid.Column>
            </Grid>
        </div>
    )
}