import { Form, Grid, Icon, Image, Button, Modal, Header } from "semantic-ui-react";
import './registration.sass'
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { createCompany, getCompanyCategories } from "../util/requests";
import { Store } from "react-notifications-component";
import { renderNotification } from "../../util/util";
import { PASS_REGEX, RegExEmail } from "../util/regex";
import { Link, useNavigate } from "react-router-dom";


function Registration(){
    const { t } = useTranslation()
    const navigate = useNavigate()
    
    const [modal, setModal] = useState(false)

    // Form Data
    const [companyName, setCompanyName] = useState('')
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [companyType, setCompanyType] = useState(null)
    const [companySize, setCompanySize] = useState("")

    const [companyNameError, setCompanyNameError] = useState(null)
    const [nameError, setNameError] = useState(null)
    const [lastNameError, setLastNameError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [companyTypeError, setCompanyTypeError] = useState(null)
    const [companySizeError, setCompanySizeError] = useState(null)


    const [companyCategories, setCompanyCategories] = useState([])

    useEffect(() => {
        getCompanyCategories().then(res => {
            const data = res.data ? res.data : []
            setCompanyCategories(
                data.map((itm, ix) => ({
                    key: `category-${itm["idCategory"]}-${ix}`,
                    value: itm["idCategory"],
                    text: itm["name"]
                }))
            )
        }).catch(err => {
            Store.addNotification(
                renderNotification('error', t('ERROR_CATEGORIES'), t('ERROR_CATEGORIES_MSG'))
            )
        })
    }, [])

    const numberController = (e, { value }) => {
        const v = Number(value)
        if(isNaN(v) && v !== ""){
            return
        }
        
        setCompanySize(value.replace('.', ''))
    }

    const checkInput = {
        companyName: async () => {
            if(!companyName){
                setCompanyNameError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(companyName.length > 100){
                setCompanyNameError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else{
                setCompanyNameError(null)
                return true
            }
        },
        name: async() => {
            if(!name){
                setNameError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(name.length > 100){
                setNameError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else{
                setNameError(null)
                return true
            }
        },
        lastName: async() => {
            if(!lastName){
                setLastNameError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(lastName.length > 100){
                setLastNameError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else{
                setLastNameError(null)
                return true
            }
        },
        email: async() => {
            if(!email){
                setEmailError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(email.length > 320){
                setEmailError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else if(!RegExEmail.test(String(email))){
                setEmailError({
                    content: t('ERR_EMAIL_VALID')
                })
                return false
            }else{
                setEmailError(null)
                return true
            }
        },
        password: async() => {
            if(!password){
                setPasswordError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(password.length < 8 || password.length > 128){
                setPasswordError({
                    content: t('ERR_PASSWORD_LENGTH')
                })
                return false
            }else if(!PASS_REGEX.test(password)){
                setPasswordError({
                    content: t('ERR_PASSWORD_REGEX')
                })
                return false
            }else{
                setPasswordError(null)
                return true
            }
        },
        companyType: async() => {
            if(!companyType){
                setCompanyTypeError({
                    content: t('ERR_EMPTY_OPTION')
                })
                return false
            }else{
                setCompanyTypeError(null)
                return true
            }
        },
        companySize: async() => {
            if(!companySize){
                setCompanySizeError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else{
                setCompanySizeError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    const postCompany = async () => {
        const checked = await checkInput.checkAll()
        if(!checked){
            return
        }
        const data = {
            companyName,
            numberEmployees: companySize,
            userName: name,
            userLastName: lastName,
            email,
            password,
            companyType
        }
        createCompany(data).then(response => {
            setModal(true)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", "Company creation", "User email already registered")
            )
            console.log(err)
        })
    }

    const okModal = () => {
        setModal(false)
        navigate("/dashboard")
    }

    return(
        /* 
            - company name
            - name
            - last name
            - email
            - password 
            - type of company
            - size of company
        */
        <Grid className="registration noMargin" centered style={{backgroundImage: "url(/bg-login.png)"}}>\
            <Grid.Column computer={5} tablet={8} mobile={10}> 
                <Grid centered>
                    <Grid.Column computer={8} tablet={10} mobile={16} className="marginTop">
                        <Image src="/white-mainicon.png"  className="chooseFWImg"/>
                        <p className='welcome'>
                            {t("REGISTRATION")}
                        </p>
                    </Grid.Column>
                </Grid>
                <Form>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('COMPANY_NAME')}
                        </label>
                        <Form.Input
                            type='text'
                            placeholder={t('COMPANY')}
                            value={companyName}
                            error={companyNameError}
                            onChange={(e, { value }) => setCompanyName(value)}
                            onBlur={checkInput.companyName}
                        />
                    </Form.Field>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                            <Form.Field>
                                <label className="whiteColor">
                                    {t('NAME')}
                                </label>
                                <Form.Input
                                    type='text'
                                    placeholder={t('NAME')}
                                    value={name}
                                    error={nameError}
                                    onChange={(e, {value}) => setName(value)}
                                    onBlur={checkInput.name}
                                />
                            </Form.Field>
                            </Grid.Column>
                            <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                            <Form.Field>
                                <label className="whiteColor">
                                    {t('LAST_NAME')}
                                </label>
                                <Form.Input
                                    type='text'
                                    placeholder={t('LAST_NAME')}
                                    value={lastName}
                                    error={lastNameError}
                                    onChange={(e, {value}) => setLastName(value)}
                                    onBlur={checkInput.lastName}
                                />
                            </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('EMAIL')}
                        </label>
                        <Form.Input
                            type='text'
                            placeholder={t('EMAIL')}
                            value={email}
                            error={emailError}
                            onChange={(e, { value }) => setEmail(value)}
                            onBlur={checkInput.email}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('PASSWORD')}
                        </label>
                        <Form.Input
                            type='password'
                            placeholder={t('PASSWORD')}
                            value={password}
                            error={passwordError}
                            onChange={(e, { value }) => setPassword(value)}
                            onBlur={checkInput.password}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('NUMBER_EMPLOYEES')}
                        </label>
                        <Form.Input
                            type="text"
                            placeholder="0"
                            value={companySize}
                            error={companySizeError}
                            onChange={numberController}
                            onBlur={() => checkInput.companySize()}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('COMPANY_CATEGORY')}
                        </label>
                        <Form.Dropdown
                            options={companyCategories}
                            value={companyType}
                            error={companyTypeError}
                            onChange={(e, { value }) => setCompanyType(value)}
                            onBlur={checkInput.companyType}
                            search
                            selection
                        />
                    </Form.Field>
                </Form>
                <Grid centered>
                    <Grid.Column computer={8} tablet={12} mobile={14} className="marginTop">
                        <Button fluid className='buttonBlue' onClick={postCompany}>{t('REGISTER')}</Button>
                        <Link to="/" className="whiteColor">
                            {<p className='link centerText'>&#8592;{" Go back to login"}</p>}
                        </Link>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Modal
                basic
                onClose={() => okModal()}
                onOpen={() => setModal(true)}
                open={modal}
                size='small'
            >
                <Header icon>
                    <Icon name='info'/>
                    {t('ACCOUNT_CREATED')}
                </Header>
                <Modal.Content>
                    <p>{t('ACCOUNT_CREATED_MESSAGE')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color="blue" onClick={() => okModal()}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    )
}

export default Registration