import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { Button, Grid, Image } from "semantic-ui-react";
import { accountActivation } from "../util/requests";

export default function ActivateAccount(){
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [requestSuccess, setRequestSuccess] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const isCompany = queryParams.get('iscompany');

    useEffect(() => {
        if(!token){
            navigate('/')
        }
        setLoading(true)
        const delayedFunction = () => {
            accountActivation(token).then(() => {
                setRequestSuccess(true)
            }).catch((err) => {
                setRequestSuccess(false)
            }).finally(() => {
                setLoading(false)
            })
        }
        const timer = setTimeout(delayedFunction, 3000);
        return () => clearTimeout(timer);
    }, [])

    return(
        <Grid className="recoverPassword noMargin" centered style={{backgroundImage: "url(/bg-login.png)"}}>
            <Grid.Column computer={8} tablet={10} mobile={10}>
                <Grid centered>
                    <Grid.Column computer={6} tablet={9} mobile={10}>
                        <Image src="/white-mainicon.png"  className="chooseFWImg"/>
                        <p className='welcome'>
                            {t("WELCOME!")}
                        </p>
                        {
                            loading ?
                            <h1 className="whiteColor centerText">
                                {t("ACTIVATING_ACCOUNT_MESSAGE")}...
                            </h1>
                            :
                            requestSuccess ? 
                                <Fragment>
                                    <h1 className="whiteColor centerText">
                                        {
                                            isCompany ? 
                                            `${t("ACCOUNT_ACTIVATED_NO_PARTNER_MESSAGE")}, ${t('REQUEST_ON_REVIEW')} ` : 
                                            t("ACCOUNT_ACTIVATED_MESSAGE")
                                        }
                                    </h1>
                                    <Button fluid className='buttonBlue' onClick={() => navigate('/')}>{t('GO_BACK_SIMPLIX')}</Button>
                                </Fragment>
                            :
                                <Fragment>
                                    <h1 className="whiteColor centerText">
                                        {t("ERROR_ACTIVATING_ACCOUNT")}
                                    </h1>
                                    <Button fluid className='buttonBlue' onClick={() => navigate('/')}>{t('GO_BACK_SIMPLIX')}</Button>
                                </Fragment>
                        }
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    )
}