import { useNavigate } from "react-router-dom";
import { Grid, Modal, Button, Dimmer, Loader, Header } from "semantic-ui-react";
import "./boxFramework.sass"
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getShopFramework, postShopFramework } from "../requests";
import { Store } from "react-notifications-component";
import { renderNotification } from "../../../util/util";

function BoxFramework({id, title, complete, shop, loadCallback=null}){
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    const [fwDetails, setFwDetails] = useState(null)

    const goToFramework = () => {
        navigate({
            pathname: "/dashboard/frameworks",
            search: `?id=${id}`
        })
    }

    useEffect(() => {
        if(open && id){
            requestFrameworkDetails()
        }else{
            setFwDetails(null)
        }
    }, [open])

    const requestFrameworkDetails = () => {
        getShopFramework(id).then(res => {
            const data = res.data ? res.data : {}
            setFwDetails(data)
        }).catch((err) => {
            Store.addNotification(
                renderNotification("error", "Error", "Framework not available")
            )
        })
    }

    const addFramework = (id) => {
        postShopFramework(id).then(res => {
            closeModal()
            Store.addNotification(
                renderNotification("success", "Load Framework", "The framework has been loaded succesfully")
            )
            loadCallback()
        }).catch(err => {
            Store.addNotification("error", "Load Framework", "There was an error during framework loading")
        })
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <Fragment>
            <div 
                className={"boxFramework"} 
                style={{backgroundImage: "url(/background-card-framework.png)", display:"flex"}} 
                onClick={shop ? () => setOpen(true) : goToFramework}
                >
                <Grid className="BFMainContainer">
                    <Grid.Column width={16}>
                        <Grid centered>
                            <Grid.Column width={14} className="fwContent">
                                {
                                    shop ? 
                                            <h2 className="centerText">{title}</h2>
                                        :
                                        <Fragment>
                                            <h1 className="centerText">{Math.floor(complete * 10)/10}%</h1>
                                            <h2 className="centerText">{title}</h2>
                                        </Fragment>
                                }
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </div>
            <Modal
                dimmer={'blurring'}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={closeModal}
            >
                <Modal.Header>{t('FRAMEWORK_DETAILS')}</Modal.Header>
                <Modal.Content scrolling>
                    {
                        !fwDetails ?
                            <Dimmer active>
                                <Loader/>
                            </Dimmer>
                            :
                            <div>
                                <Header as='h2'>
                                    {fwDetails["name"]}
                                    <Header.Subheader>
                                        {`${t('DESCRIPTION')}: ${fwDetails["description"]}`}
                                    </Header.Subheader>
                                </Header>
                                {
                                    fwDetails["process_areas"].map((process_area, ix) => (
                                        <div key={`process_area_${ix}`} className="paddingLeft marginBot marginTop">
                                            <Header as='h3'>
                                                {process_area["name"]}
                                                <Header.Subheader>
                                                    {`${t('DESCRIPTION')}: ${process_area["description"]}`}
                                                </Header.Subheader>
                                            </Header>
                                            {
                                                process_area["practices"].map((practice, ix) => (
                                                    <div key={`practice_${ix}`} className="paddingLeft marginBot">
                                                        <Header as='h4' >
                                                            {practice["name"]}
                                                            <Header.Subheader>
                                                                {`${t('DESCRIPTION')}: ${practice["description"]}`}
                                                            </Header.Subheader>
                                                        </Header>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        onClick={() => addFramework(id)}>
                            {t('LOAD_FRAMEWORK')}
                    </Button>
                    <Button negative
                        onClick={closeModal}>
                            {t('CLOSE')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Fragment>
    )
}

export default BoxFramework;