import { Grid, Button, Loader, Dimmer, Popup, Modal, Input, Form } from 'semantic-ui-react'
import BoxSection from '../../util/boxSection/boxSection';
import { deleteFramework, downloadExecutiveReport, generateFrameworkTasks, getSingleFramework } from "../../util/requests";
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import DetailModal from '../../util/detailModal/DetailModal';
import EditFramework from '../../framework/EditFramework';
import { readParents, renderNotification } from '../../../util/util';
import { Store } from 'react-notifications-component';
import ReportView from '../../reportView/reportView';
import ConfirmModal from '../../util/confirmModal/ConfirmModal';

function FWProcessAreas(){
    //let { FWid } = useParams()
    const [processAreas, setProcessAreas] = useState({})
    const [selectedFramework, setSelectedFramework] = useOutletContext()
    const [openModal, setOpenModal] = useState(false)
    const section = useSelector(state => state.dashSection)
    const [deleteFrameworkModal, setDeleteFrameworkModal] = useState(false)
    const [inputFrameworkName, setInputFrameworkName] = useState("")
    const navigate = useNavigate()

    const [downloadingReport, setDownloadingReport] = useState(false)

    const [modalGenerateReport, setModalGenerateReport] = useState(false)

    const [selectedLevelFilter, setSelectedLevelFilter] = useState(null)

    const [ { generateFrameworkId, generateModalOpen } , setGenerateAllTasks] = useState({
        generateFrameworkId: null,
        generateModalOpen: false
    })
    const [generatedFrameworkStarted, setGeneratedFrameworkStarted] = useState(false)
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: `${t('FRAMEWORK')}: ${processAreas.title ? processAreas.title : ""}`
        })
    }, [dispatch, t, processAreas])

    // const company = useSelector(state => state.companyName)
    // FWid = FWid ? FWid :

    const loadProcessArea = () => {
        let params = {}
        if(selectedLevelFilter){
            params.level = selectedLevelFilter
        }
        if(selectedFramework){
            getSingleFramework(selectedFramework, params).then(response => {
                setProcessAreas(response.data ? response.data : {})
            }).catch(err => {
                if(err.response){
                    console.log("Error code", err.response.status)
                }else{
                    console.log("Exception", err)
                }
            })
        }
    }

    const setDownload = (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = url
        link.target='_blank'
        link.rel='noopener noreferrer'
        link.download=response.headers["content-disposition"].split('=')[1]
        document.body.appendChild(link)
        link.click()
        setDownloadingReport(false)
    }

    const downloadReport = () => {
        setDownloadingReport(true)
        downloadExecutiveReport(selectedFramework).then(res => {
            setDownload(res)
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('DOWNLOAD'), t('ERROR_DOWNLOAD_REPORT'))
            )
        })
    }

    const requestDeleteFramework = (id) => {
        if(inputFrameworkName !== processAreas.title){
            return
        }
        deleteFramework(id).then(response => {
            Store.addNotification(
                renderNotification("success", t('DELETE_FRAMEWORK'), t('SUCCESS_DELETE_FRAMEWORK'))
            )
            navigate("/dashboard/")
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('DELETE_FRAMEWORK'), t('ERROR_DELETE_FRAMEWORK'))
            )
        })
    }

    useEffect(() => {
        loadProcessArea()
    }, [selectedFramework, selectedLevelFilter])

    useEffect(() => {
        //console.log("PROCESSAREAS", processAreas)
        const runEffect = async () =>{
            dispatch({
                type: 'SET_DASH_SECTION',
                // dashSection: "List of Process Areas"
                dashSection:t('LIST_OF_PROCESS_AREAS')
            })
            if(processAreas && Object.keys(processAreas).length > 0){
                const rs = await readParents(processAreas)
                dispatch({
                    type: "SET_BREADCRUMBS",
                    breadcrumbs: rs
                })
            }
        }
        runEffect()
    }, [processAreas])
    //console.log("MEINFW", selectedFramework)

    const startGenerateFrameworkTasks = async (id) => {
        try{
            const result = await generateFrameworkTasks(id)
            setGeneratedFrameworkStarted(true)
        }catch(err){
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('GENERATE_PROJECT_TASKS'), t('ERROR_GENERATE_PROJECT_TASKS'))
            )
        }
    }

    const filter_options = [
        {
            key: "1",
            text: "Level 1",
            value: "1"
        },
        {
            key: "2",
            text: "Level 2",
            value: "2"
        },
        {
            key: "3",
            text: "Level 3",
            value: "3"
        },
        {
            key: "4",
            text: "Level 4",
            value: "4"
        },
        {
            key: "5",
            text: "Level 5",
            value: "5"
        }
    ]

    return(
        <Grid>
            <Grid.Column width={16}>
                <Grid>
                    <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16}>
                        <h1 className="dashSubtitle">
                            {processAreas.title} 
                        </h1>
                        {
                            selectedFramework && processAreas && processAreas.title && processAreas.description &&
                            <Fragment>
                                <Popup
                                    trigger={
                                        <Button
                                            onClick={() => setOpenModal(true)}
                                            className='frameworkButton marginLeft verticalAlignMiddle buttonBlue' 
                                            size='mini' 
                                            primary>
                                                {t('DETAILS')}
                                        </Button>
                                    }
                                    position="bottom left"
                                    content={t('DETAILS_DESC')}
                                />
                                <DetailModal open={openModal} setOpen={setOpenModal}>
                                    <EditFramework
                                        id={selectedFramework}
                                        // pTitle={processAreas.title}
                                        // pDescription={processAreas.description}
                                        data={processAreas}
                                        okButton={() => setOpenModal(false)}
                                        callback={loadProcessArea}
                                    />
                                </DetailModal>
                                <Button 
                                    // onClick={() => downloadReport()} 
                                    onClick={() => setModalGenerateReport(true)}
                                    className="frameworkButton marginLeft verticalAlignMiddle buttonBlue" 
                                    size='mini' 
                                    disabled={downloadingReport}
                                    loading={downloadingReport}
                                    primary>{t('GENERATE_REPORT')}</Button>
                                <Button
                                    className="frameworkButton marginLeft verticalAlignMiddle buttonRed"
                                    size='mini'
                                    onClick={() => setDeleteFrameworkModal(true)}
                                    >
                                        {t('DELETE_FRAMEWORK')}
                                </Button>
                            </Fragment>
                        }
                    </Grid.Column>
                    <Grid.Column className='rightText' widescreen={8} computer={8} tablet={16} mobile={16}>
                        <Form.Dropdown
                            options={filter_options}
                            placeholder={t('SELECT_LEVEL')}
                            label={`${t('FILTER_BY_FRAMEWORK_LEVEL')}: `}
                            value={selectedLevelFilter}
                            onChange={(e, {value}) => setSelectedLevelFilter(value)}
                            selection
                            clearable
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Button
                            className='frameworkButton verticalAlignMiddle buttonBlue'
                            onClick={() => setGenerateAllTasks({generateModalOpen: true, generateFrameworkId: selectedFramework})}
                            primary>
                            {t('GENERATE_PROJECT_TASKS')}
                        </Button>
                    </Grid.Column>
                </Grid>
                <ConfirmModal
                    open={generateModalOpen}
                    setOpen={() => setGenerateAllTasks({generateModalOpen: false, generateFrameworkId: null})}
                    type='create'
                    title={t('GENERATE_PROJECT_TASKS')}
                    description={t('GENERATE_PROJECT_TASKS_CONFIRM')}
                    callback={() => {
                        startGenerateFrameworkTasks(generateFrameworkId)
                    }}
                />
                <ConfirmModal
                    open={generatedFrameworkStarted}
                    setOpen={() => setGeneratedFrameworkStarted(false)}
                    type='complete'
                    title={t('GENERATE_PROJECT_TASKS')}
                    description={t('GENERATE_PROJECT_TASKS_STARTED')}
                />
                <Modal
                    onClose={() => setModalGenerateReport(false)}
                    onOpen={() => setModalGenerateReport(true)}
                    open={modalGenerateReport}
                    size='large'
                >
                    <Modal.Header>{t('GENERATE_REPORT')}</Modal.Header>
                    <Modal.Content>
                        <ReportView currentFramework={selectedFramework} closeFunction={() => setModalGenerateReport(false)}/>
                    </Modal.Content>
                </Modal>
                <Modal
                    onClose={() => setDeleteFrameworkModal(false)}
                    onOpen={() => setDeleteFrameworkModal(true)}
                    open={deleteFrameworkModal}
                    size='small'
                >
                    <Modal.Header>{t('DELETE_FRAMEWORK')}</Modal.Header>
                    <Modal.Content>
                        <p>{(t('DELETE_FRAMEWORK_CONFIRM')).replace('{NAME}', processAreas.title)}</p>
                        <Input
                            placeholder={processAreas.title}
                            value={inputFrameworkName}
                            onChange={(e, {value}) => setInputFrameworkName(value)}
                            fluid
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            color='black'
                            onClick={() => setDeleteFrameworkModal(false)}
                        >
                            {t('CANCEL')}
                        </Button>
                        <Button
                            content={t('DELETE')}
                            onClick={() => {
                                setDeleteFrameworkModal(false)
                                requestDeleteFramework(selectedFramework)
                            }}
                            disabled={inputFrameworkName !== processAreas.title}
                            negative
                        />
                    </Modal.Actions>
                </Modal>
            </Grid.Column>
            <Grid.Column width={16}>
                
            </Grid.Column>
            {
                selectedFramework !== null && processAreas["processAreas"] &&
                    processAreas.processAreas.map(itm => 
                        <Grid.Column mobile={16} tablet={8} computer={5} widescreen={4} key={`fw-${itm.idFramework}`}>
                            <BoxSection processAreaData={itm} title={itm.title} url={`/dashboard/frameworks/processArea/${itm.idFramework}`}/>
                        </Grid.Column>
                    )
            }
        </Grid>)
}

export default FWProcessAreas