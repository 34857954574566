import { Fragment, useEffect, useRef, useState } from "react"
import { useDidUpdateEffect } from "../../../../util/util"
import OvalShape from "./ovalShape"
import { Handle, NodeResizer, Position } from "reactflow"

export default function OvalNode({ id, data, selected }){
	const [text, setText] = useState(data.label)
    const textareaRef = useRef(null)
	
	let { node_connections } = data
	node_connections = node_connections ? node_connections : []
	const targetHandle = node_connections.filter(itm => itm.type.toLowerCase() === 'target')
	const sourceHandle = node_connections.filter(itm => itm.type.toLowerCase() === 'source')

	useDidUpdateEffect(() => {
		const delayFunction = setTimeout(() => {
            data.update(id, {label: text})
        }, 500)
		return () => {
			clearTimeout(delayFunction)
		}
	}, [text])

    useEffect(() => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = 'auto'; // Reset height to recalculate
			textarea.style.height = textarea.scrollHeight + 'px'; // Set to scroll height to expand as needed
		}
    }, [text]);

	return (
		<Fragment>
			<NodeResizer 
				handleClassName='handleResize'
				lineClassName='handleLineResize'
				isVisible={selected}
			/>
			<OvalShape>
				<input 
					type="text" 
					value={text} 
                    ref={textareaRef}
					onChange={({target}) => setText(target.value)}
					style={{
						background: 'transparent',
						border: 'none',
						textAlign: 'center',
						outline: 'none',
						width: '100%',
					}}
				/>
			</OvalShape>
			{
				node_connections.length === 0 ? (
					<Fragment>
						<Handle
							id={"a"}
							type={"target"}
							position={Position.Top}
						/>
						<Handle
							id={"b"}
							type={"source"}
							position={Position.Bottom}
						/>
					</Fragment>
				)
				:
				<Fragment>
					{
						targetHandle.map((itm, ix) => (
							<Handle
								key={ix}
								id={itm.id}
								type={itm.type}
								position={Position.Top}
								style={{left : targetHandle.length === 1 ? '50%' : `${(ix+1) * 100 / targetHandle.length}%`}}
							/>
						))
					}
					{
						sourceHandle.map((itm, ix) => (
							<Handle
								key={ix}
								id={itm.id}
								type={itm.type}
								position={Position.Bottom}
								style={{left : sourceHandle.length === 1 ? '50%' : `${(ix+1) * 100 / sourceHandle.length}%`}}
							/>
						))
					}
				</Fragment>
			}
		</Fragment>
	);
};