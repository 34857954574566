import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";


export function UltraPirvateRoute({children, ...rest}){
    const ultra = useSelector(state => state.ultraUser)
    if(ultra){
        return children
    }else{
        return <Navigate to="/dashboard"/>
    }
}

export function PrivateRoute({children, ...rest}){
    const roles = useSelector(state => state.rol)
    const requiredRol = rest.reqRol ? rest.reqRol : null
    const session = localStorage.getItem("AT")
    if (session){
        if(requiredRol !== null){
            if(roles.findIndex(itm => itm === requiredRol) !== -1){
                return children        
            }else{
                return <Navigate to="/dashboard/report"/>
            }
        }
        return children
    }else{
        return <Navigate to="/"/>
    }
}

export function LoginRedirect({children, ...rest}){
    const session = localStorage.getItem("AT")
    if(!session){
        return children
    }else{
        return <Navigate to="/dashboard"/>
    }
}