import { Fragment, useEffect, useRef, useState } from "react";
import { Form, Grid, Label, Modal, Button, Tab} from "semantic-ui-react";
import { deleteTaskFile, getCriticalLevels, getSimpleUserList, getTaskFiles, postNewRelation, postNewTask, sendTaskAttachment, updateTaks } from "../util/requests";
import { Store } from "react-notifications-component";
import { formatToTranslate, renderNotification } from "../../util/util";
import FileManager from "../util/FileManager/FileManager";
import SXFileList from "../util/FileManager/SXFileList";
import { useTranslation } from "react-i18next";


function NewTask({ project, tasks=[], requestProject, isEdit=false, task={}, floated='right'}){

    const { t } = useTranslation()

    const [possibleParents, setPossibleParents] = useState()
    const [users, setUsers] = useState([])
    const [criticalLevels, setCriticalLevels] = useState([])
    const [edit, setEdit] = useState(isEdit)
    const [idTask, setIdTask] = useState()

    const [myFiles, setMyFiles] = useState([])
    const [loading, setLoading] = useState(0)
    const [showForm, setShowForm] = useState(true)
    const [taskFiles, setTaskFiles] = useState([])

    const [activeIndex, setActiveIndex] = useState(0)

    const [confirmated, setConfirmated] = useState(null)
    const [fileToDelete, setFileToDelete] = useState(null)

    const [relationFiles, setRelationFiles] = useState([])

    const fileManagerRef = useRef()

    // const [likehood, setLikehood] = useState(1)
    // const [severity, setSeverity] = useState(1)

    const initialPostState = {
        idParent: null,
        title: "",
        description: "",
        idCriticalLevel: "",
        start: "",
        isHito: false,
        deadLine: "",
        assignedTo: [],
        recurrent: false,
        separationCount: 0,
        maxOcurrencies: 0,
        riskDescription: "",
        riskSeverity: "",
        riskLikehood: "",
        cost: "",
        profit: "",
    }
    const [titleError, setTilteError] = useState()
    const [descriptionError, setDescriptionError] = useState()
    const [idCriticalLevelError, setCriticalLevelError] = useState()
    const [startError, setStartError] = useState()
    const [deadLineError, setDeadLineError] = useState()
    const [assignedToError, setAssignedToError] = useState()
    const [riskDescriptionError, setRiskDescriptionError] = useState()
    const [separationCountError, setSeparationCountError] = useState()
    const [maxOcurrenciesError, setMaxOcurrenciesError] = useState()
    const [riskSeverityError, setRiskSeverityError] = useState()
    const [riskLikehoodError, setRiskLikehoodError] = useState()
    const [costError, setCostError] = useState()
    const [profitError, setProfitError] = useState()


    
    const [{
        idParent,
        title,
        description,
        idCriticalLevel,
        isHito,
        start,
        deadLine,
        assignedTo,
        recurrent,
        separationCount,
        maxOcurrencies,
        riskDescription,
        riskSeverity,
        riskLikehood,
        cost,
        profit,
    }, setPost] = useState(initialPostState)

    const [modalTask, setModalTask] = useState(false)

    const { idProject } = project

    // useEffect(() => {
    //     getRecurringTypes().then(res => {
    //         let data = res.data ? res.data : []
    //         data = data.map((itm, ix) => {
    //             return {
    //                 key: `recurring-type-${itm['idRecurringType']}-${ix}`,
    //                 text: itm["name"],
    //                 value: itm['idRecurringType']
    //             }
    //         })
    //         setRecurringTypes(data)
    //     }).catch(err => {
    //         console.log(err)
    //         Store.addNotification(
    //             renderNotification('error', t('RECURRENT_TASK'), t('RECURRENT_TASK_ERR'))
    //         )
    //     })
    // }, [])

    useEffect(() => {
        if(edit){
            setIdTask(task.idTask)
            const selectedTask = {
                ...initialPostState,
                idParent: task.idParent ? task.idParent : idParent,
                title: task.title,
                description: task.description,
                idCriticalLevel: task.idCriticalLevel,
                isHito: task.isHito,
                start: task.start ? task.start : '',
                deadLine: task.deadLine ? task.deadLine : '',
                recurrent: task.recurrent,
                separationCount: task.separationCount,
                maxOcurrencies: task.maxOcurrencies,
                assignedTo: task.users ? task.users.map(itm => itm.idUser) : [],
                riskDescription: task.riskDescription ? task.riskDescription : "",
                riskSeverity: task.riskSeverity ? task.riskSeverity : riskSeverity,
                riskLikehood: task.riskLikehood ? task.riskLikehood : riskLikehood,
                cost: task.cost ? task.cost : cost,
                profit: task.profit ? task.profit : profit,
            }
            // console.log("STASK", selectedTask)
            // console.log("TASK", task)
            setPost(selectedTask)
        }
        //console.log(task.users ? task.users.map(itm => itm.idUserTask) : [])
    }, [task])

    // useEffect(() => {
    //     console.log("ASSTO", assignedTo)
    // },[idParent,
    //     title,
    //     description,
    //     idCriticalLevel,
    //     start,
    //     deadLine,
    //     assignedTo,
    //     riskSeverity,
    //     riskLikehood,
    //     cost,
    //     profit,
    //     recurring])

    

    useEffect(() => {
        setPossibleParents(tasks.map(itm => {
            return {
                key: `parent-task-${itm.idTask}`,
                value: itm.idTask,
                text: itm.title
            }
        }))
        getSimpleUserList().then(response =>{
            let options = response.data ? response.data : []
            options = options.map(itm => {
                return {
                    key: `user-option-${itm.idUser}`,
                    text: `${itm.name} ${itm.lastName}`,
                    value: itm.idUser
                }
            })
            setUsers(options)
        }).catch(err => {
            if(err.response){
                console.log('Error status: ', err.response.status)
            }else{
                console.log('Exception', err)
            }
        })
        getCriticalLevels().then(response => {
            let options = response.data ? response.data : []
            options = options.map(itm => {
                return {
                    key: `critical-option-${itm.idCriticalLevel}`,
                    //text: itm.name,
                    text: t(formatToTranslate(itm.name)),
                    value: itm.idCriticalLevel
                }
            })
            setCriticalLevels(options)
        }).catch(err => {
            if(err.response){
                console.log('Error status: ', err.response.status)
            }else{
                console.log('Exception', err)
            }
        })
    }, [tasks])

    useEffect(()=>{
        if(edit !== isEdit)
            setEdit(isEdit)
    }, [isEdit, modalTask])

    // useEffect(() => {
    //     console.log("ACS DI")
    //     setLocalModalTask(modalTask)
    // }, [modalTask])

    useEffect(()=>{
        if(!modalTask && !isEdit){
            setShowForm(true)
            setMyFiles([])
            setIdTask(null)
            setLoading(0)
        }
    }, [modalTask])

    useEffect(()=> {
        if(idTask)
            requestTaskFiles()
    }, [idTask])

    const updateData = async () => {
        const check = await checkInput.checkAll()
        if(!check){
            return
        }
        let data = {
            isHito
        }
        
        if(title !== task.title)
            data["title"] = title
        if(description !== task.description)
            data["description"] = description
        if(idCriticalLevel !== task.idCriticalLevel)
            data["idCriticalLevel"] = idCriticalLevel
        if(start !== task.start)
            data["start"] = start
        if(deadLine !== task.deadLine)
            data["deadLine"] = deadLine
        const originalAssignedTo = task.assignedTo ? task.assignedTo : []
        if(assignedTo.length > 0 && assignedTo.length !== originalAssignedTo.length){
            data["assignedTo"] = assignedTo
        }else{
            if(assignedTo.length > 0){
                for (const at of assignedTo) {
                    const ix = originalAssignedTo.indexOf(itm => itm === at)
                    if(ix === -1){
                        data["assignedTo"] = assignedTo
                        break
                    }
                }
            }
        }


        data["recurrent"] = recurrent
        if(recurrent){
            data["separationCount"] = Number(separationCount)
            data["maxOcurrencies"] = Number(maxOcurrencies)
        }
        if(riskDescription !== task.riskDescription)
            data["riskDescription"] = riskDescription
        if(riskSeverity !== task.riskSeverity)
            data["riskSeverity"] = riskSeverity ? riskSeverity : null
        if(riskLikehood !== task.riskLikehood)
            data["riskLikehood"] = riskLikehood ? riskLikehood : null
        if(cost !== task.cost)
            data["cost"] = cost ? Number(cost) : null
        if(profit !== task.profit)
            data["profit"] = profit ? Number(profit) : null

        //
        updateTaks(idTask, data).then(response => {
            Store.addNotification(
                renderNotification("success", t('TASK_UPDATED'), `"${data["title"] ? data["title"] : title}" ${t('TASK_UPDATED_MSG')}`)
            )
            requestProject()
            setPost(initialPostState)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('TASK_UPDATED_ERR'), `"${data["title"] ? data["title"] : title}" ${t('TASK_UPDATED_ERR_MSG')}`)
            )
        })
    }

    const submitData =  async () => {
        const check = await checkInput.checkAll()
        if(!check){
            return
        }
        let data = {
            idProject,
            title,
            description,
            idCriticalLevel,
            isHito,
            deadLine,
            assignedTo,
            recurrent,
            riskSeverity: Number(riskSeverity),
            riskLikehood: Number(riskLikehood),
            cost: Number(cost),
            profit: Number(profit)
        }
        if(recurrent){
            data["separationCount"] = Number(separationCount)
            data["maxOcurrencies"] = Number(maxOcurrencies)
        }
        if(!isHito){
            data["start"] = start
        }
        if(idParent){
            data["idParent"] = idParent
        }
        if(riskDescription){
            data["riskDescription"] = riskDescription
        }
        // Store.addNotification(
        //     renderNotification("success", "my tilte", "my description")
        // )
        postNewTask(data).then(response => {
            Store.addNotification(
                {
                    ...renderNotification("success", t('NEW_TASK'), `"${data.title}" ${t('NEW_TASK_MSG')}`),
                    dismiss: {
                        duration: 10000,
                        onScreen: false
                    }
                }
            )
            setShowForm(false)
            requestProject()
            setPost(initialPostState)
            // setModalTask(false)
            //uploadTaskFiles(response.data.idTask, myFiles)
            setIdTask(response.data.idTask)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('NEW_TASK_ERR'), t('NEW_TASK_ERR_MSG'))
            )
        })
        //console.log(data)
    }

    const onChange = ({ target }, sTarget) => {
        const {name, value, checked} = sTarget ? sTarget : target
        setPost(prevPost => ({...prevPost, [name]: value || value === false ? value : checked }))
    }

    const deleteFile = (id) => {
        deleteTaskFile(id).then(res => {
            Store.addNotification(
                renderNotification("success", t('DELETE_TASK_FILE'), t('DELETE_TASK_FILE_MSG'))
            )
            requestTaskFiles()
            setConfirmated(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('DELETE_TASK_FILE'), t('DELETE_TASK_FILE_ERR_MSG'))
            )
            requestTaskFiles()
            setConfirmated(false)
        })
    }

    
    useEffect(() => {
        if(fileToDelete){
            setConfirmated(true)
        }
    }, [fileToDelete])

    useEffect(() => {
        if(confirmated === false){
            setFileToDelete(null)
        }
    }, [confirmated])
    


    const uploadTaskFiles = (id, files = [], progress=0) => {
        if(files.length > 0){
            const singleProgress = Math.ceil(100/myFiles.length)
            const formData = new FormData()
            const file = files.pop()
            formData.append("taskFileName", file)
            sendTaskAttachment(id, formData).then(res => {
                const newProgress = progress + singleProgress
                setLoading(newProgress)
                if(files.length > 0)
                    uploadTaskFiles(id, files, newProgress)
                else
                    Store.addNotification(
                        renderNotification("success", t('FILE_UPLOAD'), t('FILE_UPLOAD_MSG'))
                    )
                    requestTaskFiles()
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('FILE_UPLOAD'), `${t('FILE_UPLOAD_ERR_MSG')} ${file.path}`)
                )
                requestTaskFiles()
            })
        }
    }

    const requestTaskFiles = () => {
        if(idTask){
            getTaskFiles(idTask).then(res => {
                setTaskFiles(res.data ? res.data : [])
            }).catch(err => {
                Store.addNotification(
                    renderNotification("success", t('GET_TASK_FILES'), t('GET_TASK_FILES_MSG'))
                )
            })
        }
    }

    const checkInput = {
        title: async () => {
            if(!title){
                setTilteError({
                    content: t('ERR_TASK_NAME')
                })
                return false
            }else if(title.length < 3 || title.length > 100){
                setTilteError({
                    content: t('ERR_TASK_NAME_LENGTH')
                })
                return false
            }else{
                setTilteError(null)
                return true
            }
        },
        description: async () => {
            if(!description){
                setDescriptionError({
                    content: t('ERR_DESCRIPTION_NAME')
                })
                return false
            }else if(description.length < 3 || description.length > 65535){
                setDescriptionError({
                    content: t('ERR_DESCRIPTION_NAME_LENGTH')
                })
                return false
            }else{
                setDescriptionError(null)
                return true
            }
        },
        criticalLevel: async () => {
            if(!idCriticalLevel){
                setCriticalLevelError({
                    content: t('ERR_CRITICAL_LVL')
                })
                return false
            }else{
                setCriticalLevelError(null)
                return true
            }
        },
        start: async () => {
            if(!isHito && !start){
                setStartError({
                    content: t('ERR_START_DATE')
                })
                return false
            }else{
                setStartError(null)
                return true
            }
        },
        deadLine: async () => {
            if(!deadLine){
                setDeadLineError({
                    content: t('ERR_DUE_DATE')
                })
                return false
            }else{
                setDeadLineError(null)
                return true
            }
        },
        assignedTo: async () => {
            if(!assignedTo || assignedTo.length === 0){
                setAssignedToError({
                    content: t('ERR_ASSIGNED_TO')
                })
                return false
            }else{
                setAssignedToError(null)
                return true
            }
        },
        riskDescription: async () => {
            if((riskDescription.length > 0 && riskDescription.length < 4) || riskDescription.length > 800){
                setRiskDescriptionError({
                    content: t('ERR_RISK_DESCRIPTION')
                })
                return false
            }else{
                setRiskDescriptionError(null)
                return true
            }
        },
        riskSeverity: async () => {
            // if(!riskSeverity){
            //     setRiskSeverityError({
            //         content: t('ERR_RISK_SEVERITY')
            //     })
            //     return false
            // }else
            if(isNaN(Number(riskSeverity))){
                setRiskSeverityError({
                    content: t('ERR_RISK_SEVERITY_TYPE')
                })
            }else if((riskSeverity || riskSeverity === 0) && (riskSeverity < 1 || riskSeverity > 5)){
                setRiskSeverityError({
                    content: t('ERR_RISK_SEVERITY_RANGE')
                })
                return false
            }else{
                setRiskSeverityError(null)
                return true
            }
        },
        riskLikehood: async () => {
            // if(!riskLikehood){
            //     setRiskLikehoodError({
            //         content: t('ERR_RISK_LIKEHOOD')
            //     })
            //     return false
            // }else 
            if(isNaN(Number(riskLikehood))){
                setRiskLikehoodError({
                    content: t('ERR_RISK_LIKEHOOD_TYPE')
                })
                return false
            }else if((riskLikehood || riskLikehood === 0) && (riskLikehood < 1 || riskLikehood > 5)){
                setRiskLikehoodError({
                    content: t('ERR_RISK_LIKEHOOD_RANGE')
                })
                return false
            }else {
                setRiskLikehoodError(null)
                return true
            }
        },
        separationCount: async() => {
            if(!recurrent){
                setSeparationCountError(null)
                return true
            }
            if(isNaN(Number(separationCount))){
                setSeparationCountError({
                    content: t('ERR_SEPARATION_COUNT_EMPTY')
                })
                return false
            }else if(separationCount <= 0){
                setSeparationCountError({
                    content: t('ERR_SEPARATION_COUNT_MIN')
                })
                return false
            }else{
                setSeparationCountError(null)
                return true
            }
        },
        maxOcurrencies: async() => {
            if(!recurrent){
                setSeparationCountError(null)
                return true
            }
            if(isNaN(Number(maxOcurrencies))){
                setPost(prevPost => ({...prevPost, maxOcurrencies: 0}))
                // setMaxOcurrenciesError({
                //     content: t('ERR_MAX_OCURRENCIES_EMPTY')
                // })
                return false
            }else if(maxOcurrencies < 0){
                setMaxOcurrenciesError({
                    content: t('ERR_MAX_OCURRENCIES_MIN')
                })
                return false
            }else{
                setMaxOcurrenciesError(null)
                return true
            }
        },
        cost: async() => {
            if(isNaN(Number(cost))){
                setCostError({
                    content: t('ERR_COST')
                })
                return false
            }else if(cost < 0){
                setCostError({
                    content: t('ERR_COST_ABS')
                })
                return false
            }else{
                setCostError(null)
                return true
            }
        },
        profit: async() => {
            if(isNaN(Number(profit))){
                setProfitError({
                    content: t('ERR_PROFIT')
                })
                return false
            }else if(profit < 0){
                setProfitError({
                    content: t('ERR_PROFIT_ABS')
                })
                return false
            }else{
                setProfitError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    const activityOptions = [
        {
            key: "activity-01",
            value: false,
            text: t('ACTIVITY_TASK')
        },
        {
            key: "activity-02",
            value: true,
            text: t('MILESTONE')
        }
    ]

    

    const renderForm = () => {
        return (
            <Form>
                <h1 className="centerText">{edit ? t('EDIT_TASK') : t('ADD_NEW_TASK')}</h1>
                <Form.Group>
                    <Form.Field width={8}>
                        <label>{t('PROJECT')}</label>
                        {/* <Form.Dropdown
                            placeholder="Select a Proect"
                            search
                            selection
                        /> */}
                        <Form.Input 
                            name={"idProject"} 
                            value={project.name} 
                            disabled
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        <label>{t('PARENT_TASK')}</label>
                        <Form.Dropdown
                            placeholder="Select a parent task"
                            options={possibleParents}
                            name="idParent"
                            onChange={onChange}
                            value={idParent}
                            // onChange={(e, { value })=> setPost()}
                            search
                            clearable
                            selection
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <label>{t('TASK_NAME')}</label>
                    <Form.Input
                        placeholder="Name"
                        //onChange={(e, { value }) => setTitle(value) }
                        name="title"
                        type="text"
                        error={titleError}
                        value={title}
                        onChange={onChange}
                        onBlur={checkInput.title}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('TASK_DESCRIPTION')}</label>
                    <Form.TextArea
                        //onChange={(e, { value }) => setDescription(value)}
                        name="description"
                        value={description}
                        error={descriptionError}
                        onChange={onChange}
                        onBlur={checkInput.description}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('TYPE_OF_ACTIVITY')}</label>
                    <Form.Dropdown
                        options={activityOptions}
                        value={isHito}
                        name='isHito'
                        onChange={onChange}
                        fluid
                        selection
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Checkbox
                        checked={recurrent}
                        label={t('RECURRENT_TASK')}
                        name="recurrent"
                        onChange={onChange}
                        disabled={isEdit}
                    />
                </Form.Field>
                {
                    recurrent && 
                    <Fragment>
                        <Form.Field>
                            <label>{t('EVERY_N_DAYS')}</label>
                            <Form.Input
                                value={separationCount}
                                name="separationCount"
                                onBlur={checkInput.separationCount}
                                error={separationCountError}
                                onChange={onChange}
                                min={1}
                                type={"number"}
                                disabled={isEdit}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>{t('MAX_OCURRENCIES')}</label>
                            <Form.Input
                                value={maxOcurrencies}
                                error={maxOcurrenciesError}
                                min={1}
                                onBlur={checkInput.maxOcurrencies}
                                name="maxOcurrencies"
                                onChange={onChange}
                                type={"number"}
                                disabled={isEdit}
                            />
                        </Form.Field>
                    </Fragment>
                }
                <Form.Group>
                    {
                        !isHito &&
                            <Form.Field width={8}>
                                <label>{t('START_DATE')}</label>
                                <Form.Input
                                    type="date"
                                    name="start"
                                    value={start}
                                    error={startError}
                                    onChange={onChange}
                                    onBlur={checkInput.start}
                                    //onChange={(e, { value }) => setStart(value) }
                                />
                            </Form.Field>
                    }
                    <Form.Field width={8}>
                        <label>{t('DUE_DATE')}</label>
                        <Form.Input
                            placeholder="Select"
                            type="date"
                            name="deadLine"
                            value={deadLine}
                            error={deadLineError}
                            onChange={onChange}
                            onBlur={checkInput.deadLine}
                            //onChange={(e, { value }) => setDeadLine(value)}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                            <label>{t('ASSIGNED_TO')}</label>
                            <Form.Dropdown
                                placeholder="Select"
                                options={users}
                                name="assignedTo"
                                value={assignedTo}
                                error={assignedToError}
                                onChange={onChange}
                                onBlur={checkInput.assignedTo}
                                //onChange={(e, { value })=> setAssignedTo(value)}
                                multiple
                                selection
                            />
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>{t('CRITICAL_LEVEL')}</label>
                            <Form.Dropdown
                                placeholder="Select a critical level"
                                name="idCriticalLevel"
                                onChange={onChange}
                                value={idCriticalLevel}
                                options={criticalLevels}
                                error={idCriticalLevelError}
                                onBlur={checkInput.criticalLevel}
                                //onChange={(e, { value }) => setIdCriticalLevel(value)}
                                selection
                            />
                    </Form.Field>
                </Form.Group>
                <h4>{t('RISK_MANAGEMENT')}</h4>
                <Form.Group>
                    <Form.Field width={16}>
                        <label>{t('RISK_DESCRIPTION')}</label>
                        <Form.TextArea
                            name = "riskDescription"
                            value = {riskDescription}
                            onChange = {onChange}
                            error = {riskDescriptionError}
                            onBlur = {checkInput.riskDescription}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                        <label>{t('RISK_LIKEHOOD')}</label>
                        <Form.Input
                            type={"number"}
                            value={riskLikehood}
                            onChange={onChange}
                            name="riskLikehood"
                            error={riskLikehoodError}
                            onBlur={checkInput.riskLikehood}
                            //onChange={(e, { value }) => setRiskLikehood(value)}
                            max={5}
                            min={1}
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        <label>{t('RISK_SEVERITY')}</label>
                        <Form.Input
                            type={"number"}
                            value={riskSeverity}
                            onChange={onChange}
                            name="riskSeverity"
                            error={riskSeverityError}
                            onBlur={checkInput.riskSeverity}
                            //onChange={(e, { value })=> setRiskSeverity(value)}
                            max={5}
                            min={1}
                        />
                    </Form.Field>
                </Form.Group>
                <h4>{t('COST_PROFIT_MANAGEMENT')}</h4>
                <Form.Group>
                    <Form.Field width={8}>
                        <label>{t('COST')}</label>
                        <Form.Input labelPosition="right" error={costError}>
                            <Label>$</Label>
                            <input
                                name="cost"
                                value={cost}
                                onChange={onChange}
                                onBlur={checkInput.cost}
                                //onChange={({ target }) => setCost(target.value)}
                                />
                        </Form.Input>
                    </Form.Field>
                    <Form.Field width={8}>
                        <label>{t('PROFIT')}</label>
                        <Form.Input labelPosition="right" error={profitError}>
                            <Label>$</Label>
                            <input 
                                name="profit"
                                value={profit}
                                onChange={onChange}
                                onBlur={checkInput.profit}
                                //onChange={({ target }) => setProfit(target.value)}
                            />
                        </Form.Input>
                    </Form.Field>
                </Form.Group>
            </Form>
        )
    }

    const renderFileUpload = () => {
        return (
            <Grid>
                <Grid.Column width={16} className="FMClass">
                    {
                        !!idTask &&
                        <FileManager 
                            ref={fileManagerRef}
                            fileSelector={1} 
                            selectedRelationFiles={relationFiles} 
                            setSelectedRelationFiles={setRelationFiles}
                            searchParams={{
                                checkTaskAttachmentRelation: idTask
                            }}
                        />
                    }
                </Grid.Column>
            </Grid>
            // <Grid>
                
            //     <Grid.Column width={16}>
            //         <TaskFiles files={taskFiles} onDelete={confirmDelete}/>
            //         <DropFiles 
            //             title="Upload task attachments" 
            //             description="Upload all tasks attachments (if any)"
            //             myFiles={myFiles} 
            //             setMyFiles={setMyFiles} 
            //             loading={loading}/>
            //         <Confirm
            //             open={confirmated}
            //             onCancel={() => setConfirmated(false)}
            //             onConfirm={() => deleteFile(fileToDelete)}
            //         />
            //     </Grid.Column>
            // </Grid>
        )
    }

    const renderFileList = () => {
        return (
            <Grid>
                <Grid.Column width={16} className="FMClass">
                    {
                        idTask &&
                            <SXFileList 
                                deleteFunction={true}
                                requestParams={{
                                    byTaskAttachment: idTask
                                }}
                                deleteParams={{
                                    type: 2,
                                    id: idTask
                                }}
                            />
                    }
                </Grid.Column>
            </Grid>
        )
    }

    const panes = [
        {
            menuItem: t('TASK_EDIT'),
            render: renderForm,
        },
        {
            menuItem: t('ATTACHMENTS'),
            render: renderFileList
        },
        {
            menuItem: t('ADD_ATTACHMENTS'),
            render: renderFileUpload,
        }
    ]

    const attachNewFiles = () => {
        const data = {
            idFile: relationFiles,
            id: idTask,
            // TYPES OF RELATIONS ALLOWED AT THE MOMENT
            // FrameworkEvidence: 1
            // TaskAttachments: 2
            // TaskEvidences: 3
            // UserTaskEvidences: 4
            // Since we are trying to make a relation on TaskAttachments, we have a type 2 relation.
            type: 2
        }
        postNewRelation(data).then(res => {
            Store.addNotification(
                renderNotification("success", t("FILE_ATTACHMENT"), t("FILE_ATTACHMENT_SUCCESS_MSG"))
            )
            setRelationFiles([])
            fileManagerRef.current.reload()
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification("error", t("FILE_ATTACHMENT"), t("FILE_ATTACHMENT_ERR_MSG"))
            )
        })
    }

    return(
        <div>
            <Modal
                open={modalTask}
                // onClose={() => setModalTask(false)}
                // onOpen={() => setModalTask(true)}
            >
                <Modal.Header>{edit ? t('TASK_DETAILS') : t('CREATE_NEW_TASK')}</Modal.Header>
                <Modal.Description>
                    {/* <NewTask project={{
                            idProject: project && project.idProject ? project.idProject : null, 
                            name: project && project.name ? project.name : null
                        }}
                            tasks={tasks}/> */}
                    <div className="setFullFlex">
                        <Grid centered>
                            {
                                edit ? 
                                    <Grid.Column width={14}>
                                        <Tab 
                                            menu={{secondary: true, pointing:true}} 
                                            onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                                            activeIndex={activeIndex}
                                            panes={panes}/>
                                    </Grid.Column>
                                    :
                                    <Grid.Column width={14}>
                                        {
                                            showForm && 
                                                renderForm()
                                        }
                                        {
                                            !showForm  &&
                                                <div className="marginTop">
                                                    {
                                                        renderFileUpload()
                                                    }
                                                </div>
                                        }
                                    </Grid.Column>
                            }
                        </Grid>
                    </div>
                </Modal.Description>
                <Modal.Actions>
                    <Button 
                        onClick={() => setModalTask(false)}
                        className="buttonDarkGray">
                        {t('CLOSE')}
                    </Button>
                    {
                        (!edit && showForm) || (edit && activeIndex === 0) ? 
                            <Button 
                                onClick={() => (edit ? updateData() : submitData())} 
                                className="buttonBlue"
                                primary>
                                {edit ? t('UPDATE') : t('CREATE')}
                            </Button>
                        : 
                            activeIndex === 1 ?
                            null
                            :
                            <Button 
                                // onClick={() => uploadTaskFiles(idTask, myFiles)} 
                                // disabled={!showForm && myFiles.length <= 0}
                                onClick={() => attachNewFiles()}
                                className="buttonBlue"
                                primary>
                                {t('ATTACH_FILES')}
                            </Button>
                    }
                </Modal.Actions>
            </Modal>
            <Button 
                floated={floated ? floated : null} 
                onClick={() => setModalTask(true)}
                className="buttonBlue">
                    {edit ? t('EDIT_TASK') : t('ADD_NEW_TASK')}
            </Button>
        </div>
    )
}
export default NewTask;