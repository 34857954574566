import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Tab } from "semantic-ui-react"
import SXFileList from "../util/FileManager/SXFileList"
import LinkList from "../util/linkEvidence/linkList"
import TextList from "../util/textEvidence/textList"

function Evidence({id, type, className=""}){
    const [tabIndex, setTabIndex] = useState(0)
    const [searchParams, setSearchParams] = useState({})

    useEffect(() => {
        switch (type) {
            case 1:
                setSearchParams({
                    byFramework: id
                })
                break;
            case 2:
                setSearchParams({
                    byTaskAttachment: id
                })
            case 3:
                setSearchParams({
                    byTaskEvidence: id
                })
            case 4:
                setSearchParams({
                    byUserTask: id
                })
            case 5:
                setSearchParams({
                    byUserTaskLog: id
                })
            default:
                break;
        }
    }, [type])

    const { t } = useTranslation()

    const user = useSelector(state => state.idUser)

    const renderFileList = () => {
        return (
            <SXFileList
                deleteFunction={true}
                requestParams={searchParams}
                deleteParams={{
                    type,
                    id
                }}
            />
        )
    }

    const renderTextList = () => {
        return(
            <TextList
                id={id}
                type={type}
                deleteFunction={true}
            />
        )
    }

    const renderLinkList = () => {
        return(
            <LinkList
                requestParams={searchParams}
                deleteParams={{
                    id: id,
                    type
                }}
                deleteFunction={true}
            />
        )
    }

    const panes = [
        {
            menuItem: t('FILES'),
            render: () => renderFileList()
        },
        {
            menuItem: t('TEXTS'),
            render: () => renderTextList()
        },
        {
            menuItem: t('LINKS'),
            render: () => renderLinkList()
        }
    ]

    return(
        <div className={`FMClass ${className}`}>
            {
                Object.keys(searchParams).length > 0 &&
                <Tab
                    menu={{secondary: true, pointing: true}}
                    activeIndex={tabIndex}
                    onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
                    panes={panes}
                />
            }
        </div>
    )
}

export default Evidence