import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Image, Form, Button, Modal, Icon, Header } from "semantic-ui-react";

import './registration.sass'
import { useState } from "react";
import { PASS_REGEX, RegExEmail } from "../util/regex";
import { postPartner } from "../util/requests";
import { Store } from "react-notifications-component";
import { renderNotification } from "../../util/util";

function SignupAsPartner(){
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [modal, setModal] = useState(false)

    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [nameError, setNameError] = useState(null)
    const [lastNameError, setLastNameError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [repeatPasswordError, setRepeatPasswordError] = useState(null)
    
    

    const checkInput = {
        name: async() => {
            if(!name){
                setNameError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(name.length > 100){
                setNameError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else{
                setNameError(null)
                return true
            }
        },
        lastName: async() => {
            if(!lastName){
                setLastNameError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(lastName.length > 100){
                setLastNameError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else{
                setLastNameError(null)
                return true
            }
        },
        email: async() => {
            if(!email){
                setEmailError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(email.length > 320){
                setEmailError({
                    content: t('ERR_LENGTH')
                })
                return false
            }else if(!RegExEmail.test(String(email))){
                setEmailError({
                    content: t('ERR_EMAIL_VALID')
                })
                return false
            }else{
                setEmailError(null)
                return true
            }
        },
        password: async() => {
            if(!password){
                setPasswordError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(password.length < 8 || password.length > 128){
                setPasswordError({
                    content: t('ERR_PASSWORD_LENGTH')
                })
                return false
            }else if(!PASS_REGEX.test(password)){
                setPasswordError({
                    content: t('ERR_PASSWORD_REGEX')
                })
                return false
            }else{
                setPasswordError(null)
                return true
            }
        },
        repeatPassword: async() => {
            if(!repeatPassword){
                setRepeatPasswordError({
                    content: t('ERR_EMPTY')
                })
                return false
            }else if(password !== repeatPassword){
                setRepeatPasswordError({
                    content: t('PASSWORD_DONT_MATCH')
                })
                return false
            }else{
                setRepeatPasswordError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    const savePartner = async () => {
        const checked = await checkInput.checkAll()
        if(!checked){
            return
        }
        const data = {
            userName: name,
            userLastName: lastName,
            email,
            password,
        }
        postPartner(data).then(response => {
            setModal(true)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", "Partner", "There was an error with your account creation.")
            )
            console.log(err)
        })
    }

    const okModal = () => {
        setModal(false)
        navigate("/")
    }

    return(
        <Grid className="registration noMargin" centered style={{backgroundImage: "url(/bg-login.png)"}}>
            <Grid.Column computer={5} tablet={8} mobile={10}> 
                <Grid centered>
                    <Grid.Column computer={8} tablet={10} mobile={16} className="marginTop">
                        <Image src="/white-mainicon.png"  className="chooseFWImg"/>
                        <p className='welcome'>
                            {t("PARTNER_REGISTRATION")}
                        </p>
                    </Grid.Column>
                </Grid>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                            <Form.Field>
                                <label className="whiteColor">
                                    {t('NAME')}
                                </label>
                                <Form.Input
                                    type='text'
                                    placeholder={t('NAME')}
                                    value={name}
                                    error={nameError}
                                    onChange={(e, {value}) => setName(value)}
                                    onBlur={checkInput.name}
                                />
                            </Form.Field>
                            </Grid.Column>
                            <Grid.Column widescreen={8} computer={8} tablet={8} mobile={16}>
                            <Form.Field>
                                <label className="whiteColor">
                                    {t('LAST_NAME')}
                                </label>
                                <Form.Input
                                    type='text'
                                    placeholder={t('LAST_NAME')}
                                    value={lastName}
                                    error={lastNameError}
                                    onChange={(e, {value}) => setLastName(value)}
                                    onBlur={checkInput.lastName}
                                />
                            </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('EMAIL')}
                        </label>
                        <Form.Input
                            type='text'
                            placeholder={t('EMAIL')}
                            value={email}
                            error={emailError}
                            onChange={(e, { value }) => setEmail(value)}
                            onBlur={checkInput.email}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('PASSWORD')}
                        </label>
                        <Form.Input
                            type='password'
                            placeholder={t('PASSWORD')}
                            value={password}
                            error={passwordError}
                            onChange={(e, { value }) => setPassword(value)}
                            onBlur={checkInput.password}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label className="whiteColor">
                            {t('REPEAT_PASSWORD')}
                        </label>
                        <Form.Input
                            type='password'
                            placeholder={t('PASSWORD')}
                            value={repeatPassword}
                            error={repeatPasswordError}
                            onChange={(e, { value }) => setRepeatPassword(value)}
                            onBlur={checkInput.repeatPassword}
                        />
                    </Form.Field>
                </Form>
                <Grid centered>
                    <Grid.Column computer={8} tablet={12} mobile={14} className="marginTop">
                        <Button fluid className='buttonBlue' onClick={savePartner}>{t('REGISTER')}</Button>
                        <Link to="/" className="whiteColor">
                            {<p className='link centerText'>&#8592;{" Go back to login"}</p>}
                        </Link>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Modal
                basic
                onClose={() => okModal()}
                onOpen={() => setModal(true)}
                open={modal}
                size='small'
            >
                <Header icon>
                    <Icon name='info'/>
                    {t('ACCOUNT_CREATED')}
                </Header>
                <Modal.Content>
                    <p>{t('ACCOUNT_CREATED_MESSAGE')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color="blue" onClick={() => okModal()}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    )
}

export default SignupAsPartner