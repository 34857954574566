import React from 'react';
import 'rapidoc';

function ApiDocumentation(){
    return <div><rapi-doc
            spec-url = ".\api.yaml"
            //spec-url = ".\swagger.yaml"
            render-style="read"
            theme="light"
            show-header="false"
            bg-color="#F9F9FA"
            nav-bg-color="#3f4d67"
            nav-text-color="#a9b7d0"
            nav-hover-bg-color="#333f54"
            nav-hover-text-color="#fff"
            nav-accent-color="#f44236"
            primary-color="#5c7096"
            // render-style = "black"
            //theme="dark"
            //schema-style="table"
            style = {{ height: "100vh", width: "100%" }}
            >
        </rapi-doc>
        </div>
}

export default ApiDocumentation;