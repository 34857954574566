
export default function DiamondShape({ children, style={} }){
    return (
        <div className="diamond-node" style={{ position: 'relative', width: '100%', height: '100%', minWidth: "80px", minHeight: "80px", ...style}}>
            <div className="diamond-shape" style={{
                content: '',
                position: 'absolute',
                top: '50%',
                left: '50%',
                minWidth: "50px", 
                minHeight: "50px",
                width: '70%',
                height: '70%',
                border: '1px solid #000',
                transform: 'translate(-50%, -50%) rotate(45deg)',
                backgroundColor: 'white'
            }}></div>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                width: '100%',
            }}>
                {children}
            </div>
        </div>
    )
}