import { useTranslation } from "react-i18next";
import { Store } from "react-notifications-component";
import { Button, Form, Segment } from "semantic-ui-react";
import { API_URL, renderNotification } from "../../util/util";
import { useEffect, useState } from "react";
import { getFrameworkNames, getSimpleAreaProcess } from "../util/requests";


export default function ReportView({currentFramework=null, closeFunction=null}) {
    const { t } = useTranslation()
    const [frameworkList, setFrameworkList] = useState([])
    const [selectedFramework, setSelectedFramework] = useState(null)

    const [processAreaList, setProcessAreaList] = useState([])
    const [selectedProcessAreas, setSelectedProcessAreas] = useState([])
    const [detailLevel, setDetailLevel] = useState("concise")
    const [reportTone, setReportTone] = useState("formal")

    const [overAllCompliance, setoverAllCompliance] = useState(false)
    const [complianceByProcessArea, setComplianceByProcessArea] = useState(false)
    // const [complianceTrend, setComplianceTrend] = useState(false)
    // const [riskDistribution, setRiskDistribution] = useState(false)

    const [executiveSummary, setExecutiveSummary] = useState(false)
    const [riskAssesment, setRiskAssesment] = useState(false)
    const [recommendations, setRecommendations] = useState(false)
    const [conclusion, setConclusion] = useState(false)

    const [downloadingReport, setDownloadingReport] = useState(false)

    const setDownload = (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a')
        link.href = url
        link.target='_blank'
        link.rel='noopener noreferrer'
        const contentDisposition = response.headers ? response.headers['content-disposition'] : null;
        const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'report.pdf';
        link.download = fileName.replace(/['"]/g, ''); // Remove any quotes around the filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link);
        setDownloadingReport(false)
    }

    const requestDownloadReport = async () => {
        if(selectedProcessAreas.length === 0 || selectedFramework === null){
            return
        }
        setDownloadingReport(true)
        const body = {
            processAreas: selectedProcessAreas,
            reportTone,
            detailLevel,
            overAllCompliance,
            complianceByProcessArea,
            complianceTrend: false,
            riskDistribution: false,
            executiveSummary,
            riskAssesment,
            recommendations,
            conclusion
        }
        try{
            const acc = localStorage.getItem('AT')
            const idc = localStorage.getItem('c-id')
            const response = await fetch(`${API_URL}generateReport/${selectedFramework}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': acc,
                    'connection-id': idc
                },
                body: JSON.stringify(body)
            });
            // if(!response.ok){
            //     throw new Error('Error downloading report')
            // }
            setDownload(await response.blob())
        }catch(err){
            console.log(err)
            Store.addNotification(
                renderNotification("error", t('DOWNLOAD'), t('ERROR_DOWNLOAD_REPORT'))
            )
            setDownloadingReport(false)
        }

    }

    const requestFrameworkList = async () => {
        try{
            const response = await getFrameworkNames()
            const fwList = response.data.map(itm => (
                {
                    key: itm.idFramework,
                    text: itm.title,
                    value: itm.idFramework
                }
            ))
            setFrameworkList(fwList)
            setSelectedFramework(currentFramework ? currentFramework : null)
        }catch(err){
            Store.addNotification(
                renderNotification('error', t('ERROR'), t('ERROR_LOADING_FRAMEWORKS'))
            )
            console.log(err)
        }
    }

    const requestProcessAreaList = async (id) => {
        try{
            const response = await getSimpleAreaProcess({id})
            let fwList = []
            let selected = []
            for (let i = 0; i < response.data.length; i++) {
                const itm = response.data[i];
                fwList.push({
                    key: itm.idFramework,
                    text: itm.title,
                    value: itm.idFramework
                })
                selected.push(itm.idFramework)
            }
            console.log("SELECTED", selected)
            setSelectedProcessAreas(selected)
            setProcessAreaList(fwList)
        }catch(err){
            Store.addNotification(
                renderNotification('error', t('ERROR'), t('ERROR_LOADING_PROCESS_AREAS'))
            )
            setDownloadingReport(false)
        }
    }

    useEffect(() => {
        requestFrameworkList()
    }, [])

    useEffect(() => {
        if(selectedFramework){
            requestProcessAreaList(selectedFramework)
        }else{
            setProcessAreaList([])
        }
    }, [selectedFramework])

    const reportToneOptions = [
            {
                key: 'formal',
                text: t('FORMAL'),
                value: 'formal'
            },
            {
                key: 'conversational',
                text: t('CONVERSATIONAL'),
                value: 'conversational'
            },
            {
                key: 'technical',
                text: t('TECHNICAL'),
                value: 'technical'
            }
    ]

    const detailLevelOptions = [
        {
            key: 'concise',
            text: t('CONCISE'),
            value: 'concise'
        },
        {
            key: 'balanced',
            text: t('BALANCED'),
            value: 'balanced'
        },
        {
            key: 'detailed',
            text: t('DETAILED'),
            value: 'detailed'
        }
    ]

    return (
        <div className="reportView">
            <Form>
                <Segment>
                    <Form.Field>
                        <Form.Dropdown
                            label={t('SELECT_FRAMEWORK')}
                            placeholder={t('SELECT_FRAMEWORK')}
                            options={frameworkList}
                            value={selectedFramework}
                            onChange={(e, { value }) => setSelectedFramework(value)}
                            disabled={currentFramework !== null}
                            search
                            selection
                        />
                    </Form.Field>
                </Segment>
                <Segment>
                    <h2>{t('FOCUS_AREAS_TO_EMPHASIZE')}</h2>
                    <Form.Field>
                        <Form.Dropdown
                            label={t('SELECT_PROCESS_AREAS')}
                            placeholder={t('SELECT_PROCESS_AREAS')}
                            options={processAreaList}
                            value={selectedProcessAreas}
                            onChange={(e, { value }) => setSelectedProcessAreas(value)}
                            disabled={selectedFramework === null}
                            search
                            selection
                            multiple
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Dropdown
                            label={t('REPORT_TONE')}
                            placeholder={t('REPORT_TONE')}
                            options={reportToneOptions}
                            value={reportTone}
                            onChange={(e, { value }) => setReportTone(value)}
                            disabled={selectedFramework === null}
                            selection
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Dropdown
                            label={t('DETAIL_LEVEL')}
                            placeholder={t('DETAIL_LEVEL')}
                            options={detailLevelOptions}
                            value={detailLevel}
                            onChange={(e, { value }) => setDetailLevel(value)}
                            disabled={selectedFramework === null}
                            selection
                        />
                    </Form.Field>
                </Segment>
                <Segment>
                    <h2>{t('Include metric charts')}</h2>
                    <Form.Checkbox
                        label={t('OVERALL_COMPLIANCE')}
                        checked={overAllCompliance}
                        onChange={(e, { checked }) => setoverAllCompliance(checked)}
                        disabled={selectedFramework === null}
                        />
                    <Form.Checkbox
                        label={t('COMPLIANCE_BY_PROCESS_AREA')}
                        checked={complianceByProcessArea}
                        onChange={(e, { checked }) => setComplianceByProcessArea(checked)}
                        disabled={selectedFramework === null}
                    />
                    {/* <Form.Checkbox
                        label={t('COMPLIANCE_TREND')}
                        checked={complianceTrend}
                        onChange={(e, { checked }) => setComplianceTrend(checked)}
                        disabled={selectedFramework === null}
                    />
                    <Form.Checkbox
                        label={t('RISK_DISTRIBUTION')}
                        checked={riskDistribution}
                        onChange={(e, { checked }) => setRiskDistribution(checked)}
                        disabled={selectedFramework === null}
                    /> */}
                </Segment>
                <Segment>
                    <h2>{t('Include sections')}</h2>
                    <Form.Checkbox
                        label={t('EXECUTIVE_SUMMARY')}
                        checked={executiveSummary}
                        onChange={(e, { checked }) => setExecutiveSummary(checked)}
                        disabled={selectedFramework === null}
                    />
                    <Form.Checkbox
                        label={t('RISK_ASSESMENT')}
                        checked={riskAssesment}
                        onChange={(e, { checked }) => setRiskAssesment(checked)}
                        disabled={selectedFramework === null}
                    />
                    <Form.Checkbox
                        label={t('RECOMMENDATIONS')}
                        checked={recommendations}
                        onChange={(e, { checked }) => setRecommendations(checked)}
                        disabled={selectedFramework === null}
                    />
                    <Form.Checkbox
                        label={t('CONCLUSION')}
                        checked={conclusion}
                        onChange={(e, { checked }) => setConclusion(checked)}
                        disabled={selectedFramework === null}
                    />
                </Segment>
                {
                    closeFunction &&
                    <Button className="buttonDarkGray" onClick={() => closeFunction()} floated="right">
                        {t('CANCEL')}
                    </Button>
                }
                <Button 
                    loading={downloadingReport}
                    disabled={downloadingReport}
                    className="buttonBlue marginBottom" 
                    floated="right" 
                    onClick={() => requestDownloadReport()}>
                    {t('GENERATE_REPORT')}
                </Button>
            </Form>
        </div>
    )
}