import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
    MiniMap, 
    Controls,
    Background,
    applyNodeChanges,
    addEdge,
    applyEdgeChanges,
	ReactFlowProvider,
	useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { requestFlowDiagram, postDiagram, getFlowDiagram, updateDiagram } from '../util/requests';
import { Button, Form, Modal } from 'semantic-ui-react';
import { Store } from 'react-notifications-component';
import { renderNotification } from '../../util/util';
import { useTranslation } from 'react-i18next';
import './BPMGenerator.sass';
import DiamondNode from './nodes/diamond/diamondNode';
import DiamondShape from './nodes/diamond/diamondShape';
import RectangleNode from './nodes/rectangle/rectangleNode';
import RectangleShape from './nodes/rectangle/rectangleShape';
import OvalNode from './nodes/oval/ovalNode';
import OvalShape from './nodes/oval/ovalShape';
import { useNavigate, useParams } from 'react-router-dom';


// const initialNodes = [
//     { id: '1', type: 'diamond', position: { x: 0, y: 0 }, data: { label: 'Start' } },
//     { id: '2', position: { x: 0, y: 100 }, data: { label: 'End' } },
//     { id: '3', position: { x: 100, y: 100 }, data: { label: 'End 2' } },
//   ];
// const initialEdges = [{ id: 'e1-2', source: '1', target: '2', sourceHandle: 'a', type: 'smoothstep', animated: true}];

const Sidebar = () => {
	const { t } = useTranslation()
	const shapes = [
		{
			label: t('OVAL'),
			type: 'oval',
		},
		{
			label: t('RECTANGLE'),
			type: 'rectangle',
		},
		{
			label: t('DIAMOND'),
			type: 'diamond',
		},
	]

	return (
		<aside style={
			{ 
				width: '110px', 
				background: '#FFF', 
				padding: '10px',
				position: 'absolute',
				zIndex: 100,
			}
		}>
			<h3 className='centerText'>{t('SHAPES')}</h3>
			<div>
				{
					shapes.map((shape, ix) => (
						<div
							key={ix}
							draggable={true}
							onDragStart={(event) => {
							// Set the type of node being dragged
							event.dataTransfer.setData('application/reactflow', shape.type);
							// Effect to indicate a move
							event.dataTransfer.effectAllowed = 'move';
							}}
							style={{marginBottom: '10px', cursor: 'grab'}}
						>
							{
								shape.type === 'oval' ? (
									<OvalShape style={{width: "100%"}}>
										{shape.label}
									</OvalShape>
								) : shape.type === 'rectangle' ? (
									<RectangleShape style={{width: "100%"}}>
										{shape.label}
									</RectangleShape>
								) : shape.type === 'diamond' ? (
									<DiamondShape style={{width: "90%"}}>
										{shape.label}
									</DiamondShape>
								) : null
							}
						</div>
					))
				}
			</div>
		</aside>
	)
}

const nodeTypes = { diamond: DiamondNode, rectangle: RectangleNode, oval: OvalNode };

export default function BPMGenerator(){
	const { id } = useParams()
	return (
		<ReactFlowProvider>
			<BPMGeneratorContent id={id}/>
		</ReactFlowProvider>
	)
}

function BPMGeneratorContent({id}) {

	const { t } = useTranslation()
	const navigate = useNavigate()

	const [isEdit, setIsEdit] = useState(!!id)

	const reactFlowWrapper = useRef(null);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

	const [modalSaveOpen, setModalSaveOpen] = useState(false)	

	const [flowName, setFlowName] = useState('')
	const [flowDescription, setFlowDescription] = useState('')
	const [flowNameError, setFlowNameError] = useState(null)
	const [flowDescriptionError, setFlowDescriptionError] = useState(null)
	const [savingDiagram, setSavingDiagram] = useState(false)

	const [originalFlowName, setOriginalFlowName] = useState('')
	const [originalFlowDescription, setOriginalFlowDescription] = useState('')

	const [modalRequestAI, setModalRequestAI] = useState(false)
	const [processText, setProcessText] = useState('')
	const [loadingAI, setLoadingAI] = useState(false)

    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [])
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [])

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [])

	const reactFlowInstance = useReactFlow()

	

	const onDragOver = (event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	};
	  
	const onDrop = (event) => {
		event.preventDefault();
		
		//const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
		const type = event.dataTransfer.getData('application/reactflow');
		
		// Calculate position of the new node
		// const position = reactFlowInstance.project({
		// 	x: event.clientX - reactFlowBounds.left,
		// 	y: event.clientY - reactFlowBounds.top,
		// });

		const position = reactFlowInstance.screenToFlowPosition({
			x: event.clientX,
			y: event.clientY,
		});
		
		// Create a new node
		const newNode = {
			id: Date.now().toString(),
			type,
			position,
			data: { 
				label: `${type} node`,
				update: updateNodeDataById,
			},
		};
		// Add the new node to the nodes array
		setNodes((nds) => {
			return nds.concat(newNode)
		});
	};
	
	const updateNodeDataById = (nodeId, newData) => {
		// Clone the nodes array
		setNodes((nds) => {
			const updatedNodes = [...nds];
			
			// Find the index of the node
			const nodeIndex = updatedNodes.findIndex(node => node.id === nodeId);
			
			// If the node is found, update its data
			if (nodeIndex !== -1) {
				const nodeToUpdate = updatedNodes[nodeIndex];
				updatedNodes[nodeIndex] = {
					...nodeToUpdate,
					data: {
					...nodeToUpdate.data,
					...newData,
					},
				};
				
				// Update the nodes state
				return updatedNodes;
			}
		})
	};

	const saveDiagram = async (nodes, edges) => {
		if(! await checkInput.checkAll()){
			return
		}
		setSavingDiagram(true)
		const saveFunction = isEdit && id ? (data) => updateDiagram(id, data) : postDiagram
		let diagram = {
			nodes,
			edges
		}
		try{
			diagram = JSON.stringify(diagram)
			const data = {
				name: flowName,
				description: flowDescription,
				diagram
			}
			const response = await saveFunction(data)
			if(response.status === 200){
				Store.addNotification(
					renderNotification('success', t('DIAGRAM_SAVE'), t('DIAGRAM_SAVE_SUCCESS'))
				)
			}
			setModalSaveOpen(false)
		}catch(e){
			Store.addNotification(
				renderNotification('error', t('DIAGRAM_SAVE'), t('DIAGRAM_SAVE_ERR'))
			)
		}finally{
			setSavingDiagram(false)
		}
	}

	const loadFlowDiagram = async () => {
		if(!id){
			return
		}
		try{
			const response = await getFlowDiagram(id)
			const data = response.data
			const diagram = JSON.parse(data.diagram)

			const newNodes = (diagram["nodes"] ? diagram["nodes"] : []).map(itm => (
				{
					...itm,
					data: { 
						...itm.data,
						update: updateNodeDataById,
					}
				}
			))
			const newEdges = (diagram["edges"] ? diagram["edges"] : []).map((itm, ix) => (
				{
					...itm,
					data: { 
						...itm.data,
						update: updateNodeDataById,
					}
				}
			))
			setNodes(newNodes)
			setEdges(newEdges)
			setOriginalFlowName(data.name)
			setOriginalFlowDescription(data.description)
		}catch(e){
			console.log(e)
			Store.addNotification(
				renderNotification('error', t('DIAGRAM_LOAD'), t('DIAGRAM_LOAD_ERR'))
			)
		}
	}

	const requestAIFlow = async () => {
		try{
			setLoadingAI(true)
			const post_data = {
				description: processText
			}
			const response = await requestFlowDiagram(post_data)
			if(!modalRequestAI){
				return
			}
			
			const data = response.data

			const newNodes = (data["nodes"] ? data["nodes"] : []).map(itm => (
			{
				id: `${itm.id}`, 
				type: (itm.node_type === 'Decision' ? 'diamond' : 'rectangle'), 
				position: itm.position, 
				data: { 
					label: itm.content,
					node_connections: itm.node_connections,
					update: updateNodeDataById,
				}
			}))
			const newEdges = (data["edges"] ? data["edges"] : []).map((itm, ix) => (
				{ 
					id: `${itm.source}-${itm.target}-${ix}`, 
					source: `${itm.source}`, 
					target: `${itm.target}`, 
					sourceHandle: itm.sourceHandle, 
					targetHandle: itm.targetHandle,
					type: 'smoothstep',
					markerEnd: {
						type: 'arrow',
					},
				}
			))
			setNodes(newNodes)
			setEdges(newEdges)
			setModalRequestAI(false)
		}catch(e){
			console.log(e)
			Store.addNotification(
				renderNotification('error', t('AI_FD_GENERATION'), t('AI_FD_GENERATION_ERR'))
			)
		}finally{
			setLoadingAI(false)
		}
	}

	useEffect(() => {
		loadFlowDiagram()
	}, [])

	useEffect(() => {
		console.log("MODAL SAVE OPEN", modalSaveOpen)
		console.log("IS EDIT", isEdit)
		if(isEdit && modalSaveOpen){
			setFlowName(originalFlowName)
			setFlowDescription(originalFlowDescription)
		}
	}, [modalSaveOpen, isEdit])

	const checkInput = {
		checkFlowName: async () => {
			if(!flowName){
				setFlowNameError({
					content: t('FLOW_NAME_REQUIRED')
				})
				return false
			}
			if(flowName.length > 100){
				setFlowNameError({
					content: t('FLOW_NAME_MAX')
				})
				return false
			}
			setFlowNameError(null)
			return true
		},
		checkFlowDescription: async () => {
			if(!flowDescription){
				setFlowDescriptionError({
					content:t('FLOW_DESCRIPTION_REQUIRED')
				})
				return false
			}
			if(flowDescription.length > 250){
				setFlowDescriptionError({
					content: t('FLOW_DESCRIPTION_MAX')
				})
				return false
			}
			setFlowDescriptionError(null)
			return true
		},
		checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
	}

    return (
        <div className="BPMGenerator">
			<div>
				<Button onClick={() => setModalRequestAI(true)} color='black'>{t('AI_FD_GENERATION')}</Button>
				{
					isEdit && (
						<Button className='buttonBlue' onClick={() => setModalSaveOpen(true)}>{t('SAVE')}</Button>
					)
				}
			</div>
            <div style={{ width: '100%', height: '100%' }} ref={reactFlowWrapper}>
				<Sidebar/>
                <ReactFlow 
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onDragOver={onDragOver}
					onDrop={onDrop}
					nodeTypes={nodeTypes}
					onConnect={onConnect}
					defaultEdgeOptions={{ type: 'smoothstep' }}
                >
                    <Controls />
                    <MiniMap zoomable pannable/>
                    <Background variant="dots" gap={12} size={1} />
                </ReactFlow>
            </div>
			<div className='bottomButtons'>
				<div className='buttonContainer'>
					<Button onClick={() => navigate('/dashboard/diagrams')}>{t('CANCEL')}</Button>
					<Button className='buttonBlue' onClick={() => setModalSaveOpen(true)}>{t('SAVE_CHANGES')}</Button>
				</div>
			</div>
			<Modal
				open={modalRequestAI}
				onClose={() => setModalRequestAI(false)}
			>
				<Modal.Header>
					{t('AI_FD_GENERATION')}
				</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<Form>
							<Form.Field>
								<label>{t('AI_FD_GENERATION_TEXT')}</label>
								<Form.TextArea
									value={processText}
									onChange={({ target }) => setProcessText(target.value)}
								/>
							</Form.Field>
						</Form>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button className='buttonDarkGray' onClick={() => setModalRequestAI(false)}>
						{t('CANCEL')}
					</Button>
					<Button className='buttonBlue' onClick={() => requestAIFlow()} loading={loadingAI} disabled={loadingAI}>
						{t('GENERATE')}
					</Button>
				</Modal.Actions>
			</Modal>
			<Modal 
				open={modalSaveOpen}
				onClose={() => setModalSaveOpen(false)}
				>
				<Modal.Header>
					{t('SAVE_FLOW_DIAGRAM')}
				</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<Form>
							<Form.Field>
								<label>{t('FLOW_DIAGRAM_NAME')}</label>
								<Form.Input
									type="text"
									value={flowName}
									onBlur={checkInput.checkFlowName}
									error={flowNameError}
									onChange={({ target }) => setFlowName(target.value)}
								/>
							</Form.Field>
							<Form.Field>
								<label>{t('FLOW_DIAGRAM_DESCRIPTION')}</label>
								<Form.TextArea
									value={flowDescription}
									onBlur={checkInput.checkFlowDescription}
									error={flowDescriptionError}
									onChange={({ target }) => setFlowDescription(target.value)}
								/>
							</Form.Field>
						</Form>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button className='buttonDarkGray' onClick={() => setModalSaveOpen(false)}>
						{t('CANCEL')}
					</Button>
					<Button className='buttonBlue' onClick={() => saveDiagram(nodes, edges)}>
						{t('SAVE')}
					</Button>
				</Modal.Actions>
			</Modal>
        </div>
    )
}