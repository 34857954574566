import { ViewMode } from "gantt-task-react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "semantic-ui-react";

type ViewSwitcherProps = {
    isChecked: boolean;
    viewMode: ViewMode;
    onViewListChange: (isChecked: boolean) => void;
    onViewModeChange: (viewMode: ViewMode) => void;
};

export const ViewSwitcher: React.FunctionComponent<ViewSwitcherProps> = ({
    onViewModeChange,
    onViewListChange,
    viewMode,
    isChecked,
}) => {
    const { t } = useTranslation()
    return(
        <div className="viewContainer">
            <Button
                onClick={() => onViewModeChange(ViewMode.Day)} 
                className={viewMode===ViewMode.Day ? "buttonBlue" : ""}>
                    {t('DAY')}
            </Button>
            <Button
                onClick={() => onViewModeChange(ViewMode.Week)} 
                className={viewMode===ViewMode.Week ? "buttonBlue" : ""}>
                    {t('WEEK')}
            </Button>
            <Button
                onClick={() => onViewModeChange(ViewMode.Month)}
                className={viewMode===ViewMode.Month ? "buttonBlue" : ""}>
                    {t('MONTH')}
            </Button>
            <div style={{marginTop: "10px"}} className="switchBlue">
                <Checkbox toggle label={t('SHOW_TASK_LIST')} checked={isChecked} onChange={() => onViewListChange(!isChecked)}/>
            </div>
        </div>
    )
}