import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "react-notifications-component";
import { useDispatch } from "react-redux";
import { Button, Form, Grid } from "semantic-ui-react";
import { renderNotification } from "../../../util/util";
import { PASS_REGEX } from "../../util/regex";
import { updateMyPassword } from "../../util/requests";

function Profile(){
    const { t } = useTranslation()
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordComprobation, setNewPasswordComprobation] = useState("")

    const [passwordError, setPasswordError] = useState()
    const [newPasswordError, setNewPasswordError] = useState()
    const [newPasswordComprobationError, setNewPasswordComprobationError] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t('PROFILE')
        })
    }, [])

    const resetInputs = () => {
        setPassword("")
        setNewPassword("")
        setNewPasswordComprobation("")
        setPasswordError(null)
        setNewPasswordError(null)
        setNewPasswordComprobationError(null)
    }

    const checkInput = {
        "password": async () => {
            if(!password){
                setPasswordError({
                    content: t('ERR_PASSWORD')
                })
                return false
            }else{
                setPasswordError(null)
                return true
            }
        },
        "newPassword": async () => {
            if(!newPassword){
                setNewPasswordError({
                    content: t('ERR_NEW_PASSWORD')
                })
                return false
            }else if(!PASS_REGEX.test(newPassword)){
                setNewPasswordError({
                    content: t('ERR_PASSWORD_REGEX')
                })
                return false
            }else{
                setNewPasswordError(null)
                return true
            }
        },
        "newPasswordComprobation": async () => {
            if(!newPasswordComprobation){
                setNewPasswordComprobationError({
                    content: t('ERR_NEW_PASSWORD_COMPROBATION')
                })
                return false
            }else if(newPasswordComprobation !== newPassword){
                setNewPasswordComprobationError({
                    content: t('ERR_NEW_PASSWORD_COMPROBATION_DIFF')
                })
                return false
            }else{
                setNewPasswordComprobationError(null)
                return true
            }
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    const changePassword = async () => {
        const check = await checkInput.checkAll()
        if(!check){
            return
        }
        const data = {
            password,
            newPassword
        }
        updateMyPassword(data).then(res => {
            Store.addNotification(
                renderNotification("success", "Password update", "The password has been updated succesfully")
            )
            resetInputs()
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", "Password update", "There was an error trying to change your password (wrong password?)")
            )
        })
    }

    return(
        <div className="sx-user">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h2>{t('CHANGE_PASSWORD')}</h2>
                        <Form>
                            <Form.Field>
                                <label>{t('ENTER_PASSWORD')}</label>
                                <Form.Input
                                    placeholder={t('PASSWORD')} 
                                    type="password"
                                    value={password}
                                    onBlur={() => checkInput.password()}
                                    onChange={({ target }) => setPassword(target.value)}
                                    error={passwordError}
                                    required/>
                            </Form.Field>
                            <Form.Field>
                                <label>{t('ENTER_NEW_PASSWORD')}</label>
                                <Form.Input 
                                    placeholder={t('NEW_PASSWORD')} 
                                    type="password" 
                                    value={newPassword}
                                    onBlur={() => checkInput.newPassword()}
                                    onChange={({ target }) => setNewPassword(target.value)}
                                    error={newPasswordError}
                                    required/>
                            </Form.Field>
                            <Form.Field>
                                <label>{t('ENTER_NEW_PASSWORD_AGAIN')}</label>
                                <Form.Input 
                                    placeholder={t('NEW_PASSWORD')} 
                                    type="password" 
                                    value={newPasswordComprobation}
                                    onBlur={() => checkInput.newPasswordComprobation()}
                                    onChange={({ target }) => setNewPasswordComprobation(target.value)}
                                    error={newPasswordComprobationError}
                                    required/>
                            </Form.Field>
                            <Button 
                                type="submit" 
                                floated="right" 
                                onClick={() => changePassword()}
                                className="buttonBlue">
                                    {t('SAVE_PASSWORD')}
                            </Button>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default Profile;