import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Button, Header, Image, Dropdown, Popup, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux";
import './dashHeader.sass'
import { useTranslation } from "react-i18next";
import { formatToTranslate } from "../../util/util";
import { ReactComponent  as WorldIcon} from "../util/svg/world.svg"
import { ReactComponent as LogOutIcon} from "../util/svg/ICON-LOGOUT.svg"
import { ReactComponent as DasboardIcon} from "../util/svg/dashboard.svg"
import { ReactComponent as FrameworkIcon} from "../util/svg/frameworks.svg"
import { ReactComponent as TasksIcon} from "../util/svg/list.svg"
import { ReactComponent as GanttIcon} from "../util/svg/stats.svg"
import { ReactComponent as UsersIcon} from "../util/svg/users.svg"
import { ReactComponent as BarsIcon} from "../util/svg/bars.svg"

function DashHeader({partnerDashboard=false}){
    const { t, i18n } = useTranslation()

    const [lng, setLng] = useState()
    const [showMenu, setShowMenu] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()
    const section = location.pathname.split("/").length >= 3 ? location.pathname.split("/")[2].toLowerCase() : ""

    const dispatch = useDispatch()

    const title = useSelector(state => state.headerTitle)
    const user = useSelector(state => state.userName)
    const rol = useSelector(state => state.rol)
    const partner = useSelector(state => state.partner)


    const dropDownRef = useRef(null)

    const toggleLanguage = (ref) => {
        if(ref.current.state.open){
            ref.current.close()
        }else{
            ref.current.open()
        }
    }

    useEffect(() => {
        if(showMenu){
            setShowMenu(false)
        }
    }, [lng])

    const logOut = () => {
        localStorage.removeItem("AT")
        localStorage.removeItem('c-id')
        dispatch({
            type: 'RESET'
        })
        navigate("/")
    }

    useEffect(() => {
        if(lng){
            i18n.changeLanguage(lng)
            localStorage.setItem('language', lng)
        }else{
            const l = localStorage.getItem('language')
            if(!l){
                setLng('en')
            }else{
                setLng(l)
            }
        }
    }, [lng])

    const languages = [
        {
            key: 'english',
            text: 'EN',
            value: 'en'
        },
        {
            key: 'spanish',
            text: 'ES',
            value: 'es'
        }
    ]

    const localRol = rol.map((itm) => {
        return t(formatToTranslate(itm))
    })

    const renderMobileMenu = () => {
        const navigateMobileMenu = (routeSelected) => {
            navigate(routeSelected)
            setShowMenu(false)
        }

        return (
            <Fragment>
                <div className="fullBackground" onClick={() => setShowMenu(false)}>

                </div>
                <div className="topBarMobileMenu">
                    <ul>
                        <li>
                            <div className="setFlex userSection">
                                <div className="setFlex centerDivFlex">
                                    <div className="headerUser">
                                        <Header as='h3' textAlign="right">
                                            <Header.Content>
                                                {user}
                                                <Header.Subheader>
                                                    {localRol.join(",")}
                                                </Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                    </div>
                                    <div className="headerProfile">
                                        <Popup
                                            trigger={<Button icon='user circle' circular/>}
                                            content={t('COMING_SOON')}
                                            position="bottom right"
                                        />
                                    </div>
                                    {
                                        rol.length > 0 && rol.findIndex(itm => itm === "Administrator") !== -1 && !partnerDashboard &&
                                        <div className="headerProfile">
                                            <Button icon='setting' onClick={() => navigate("/dashboard/config")} circular/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="iconSection setFlex">
                                <div className="setFlex centerDivFlex">
                                    <div className="elementContainer marginRight">
                                        <div className="svgContainer">
                                            <WorldIcon/>
                                        </div>
                                        <div className="elementContent">
                                            <span>
                                                <Dropdown
                                                    inline
                                                    options={languages}
                                                    onChange={(e, { value }) => setLng(value)}
                                                    value={lng}
                                                    ref={dropDownRef}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="elementContainer" onClick={logOut}>
                                        <div className="svgContainer">
                                            <LogOutIcon/>
                                        </div> 
                                        <div className="elementContent">
                                            <span>{t('LOG_OUT')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li 
                            className={section === "" ? "selectedMobileMenu" : ""}
                            onClick={() => {navigateMobileMenu("/dashboard/")}}>
                            <div className="elementContainer">
                                <div class="elementContainer">
                                    <div className="svgContainer">
                                        <DasboardIcon/>
                                    </div>
                                </div>
                                <div className="elementContent">
                                    <span>{t('DASHBOARD')}</span>
                                </div>
                            </div>
                        </li>
                        <li 
                            className={section === "frameworks" ? "selectedMobileMenu" : ""}
                            onClick={() => { navigateMobileMenu("/dashboard/frameworks")}}>
                            <div className="elementContainer">
                                <div class="elementContainer">
                                    <div className="svgContainer">
                                        <FrameworkIcon/>
                                    </div>
                                </div>
                                <div className="elementContent">
                                    <span>{t('MY_FRAMEWORKS')}</span>
                                </div>
                            </div>
                        </li>
                        <li 
                            className={section === "report" ? "selectedMobileMenu" : ""}
                            onClick={() => { navigateMobileMenu("/dashboard/report")}}>
                            <div className="elementContainer">
                                <div class="elementContainer">
                                    <div className="svgContainer">
                                        <TasksIcon/>
                                    </div>
                                </div>
                                <div className="elementContent">
                                    <span>{t('MY_ACTIVITIES')}</span>
                                </div>
                            </div>
                        </li>
                        <li 
                            className={section === "gantt" ? "selectedMobileMenu" : ""}
                            onClick = {() => {navigateMobileMenu("/dashboard/gantt")}}>
                            <div className="elementContainer">
                                <div class="elementContainer">
                                    <div className="svgContainer">
                                        <GanttIcon/>
                                    </div>
                                </div>
                                <div className="elementContent">
                                    <span>{t('GANTT')}</span>
                                </div>
                            </div>
                        </li>
                        <li 
                            className={section === "users" || section === "adduser" ? "selectedMobileMenu" : ""}
                            onClick={() => {navigateMobileMenu("/dashboard/users")}}>
                            <div className="elementContainer">
                                <div class="elementContainer">
                                    <div className="svgContainer">
                                        <UsersIcon/>
                                    </div>
                                </div>
                                <div className="elementContent">
                                    <span>{t('USERS')}</span>
                                </div>
                            </div>
                        </li>
                        <li 
                            className={section === "profile" ? "selectedMobileMenu" : ""}
                            onClick={() => {navigateMobileMenu("/dashboard/profile")}}>
                            <div className="elementContainer">
                                <div class="elementContainer">
                                    <div className="svgContainer">
                                        <Icon name="user outline"/>
                                    </div>
                                </div>
                                <div className="elementContent">
                                    <span>{t('PROFILE')}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </Fragment>
        )
    }

    return(
        <Grid>
            <Grid.Column width={16} className="DashHeader">
                <div className="headerSearch">
                    {/* <Button icon='search' circular/> */}
                    <h1 className="titleHeader">{title}</h1>
                </div>
                {/* <div className="headerNotifications">
                    <Link to="notifications">
                        <Button icon='bell outline' circular/>
                    </Link>
                </div>
                <div className="headerMessages">
                    <Button icon='comment outline' circular/>
                </div> */}
                {
                    partner && !partnerDashboard &&
                    <div className="iconHeader until1024" onClick={() => navigate("/partner")}>
                        <div className="ascenter">
                            <Icon name="arrow left"/>
                            {t('PARTNER_DASHBOARD')}
                        </div>
                    </div>
                }
                <div className="iconHeader until1024">
                    <div className="language" onClick={() => toggleLanguage(dropDownRef)}>
                    {/* <div className="language" onClick={() => dropDownRef.current.toggle()}> */}
                        <Image src="/world.svg"/>
                    </div>
                    <div className="languageDropdown">
                        <Dropdown
                            inline
                            options={languages}
                            onChange={(e, { value }) => setLng(value)}
                            value={lng}
                            ref={dropDownRef}
                            trigger={<></>}
                        />
                    </div>
                </div>
                <div className="iconHeader until1024" onClick={logOut}>
                    <div className="ascenter">
                        <Image src="/ICON-LOGOUT.svg"/>
                    </div>
                    <div className="logout ascenter">
                        {t('LOG_OUT')}
                    </div>
                </div>
                <div className="headerUser until1024">
                    <Header as='h3'>
                        <Header.Content>
                            {user}
                            <Header.Subheader>
                                {localRol.join(",")}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </div>
                <div className="headerProfile until1024">
                    <Popup
                        trigger={<Button icon='user circle' circular/>}
                        content={t('COMING_SOON')}
                        position="bottom right"
                    />
                </div>
                {
                    rol.length > 0 && rol.findIndex(itm => itm === "Administrator") !== -1 && !partnerDashboard &&
                        <div className="headerProfile until1024">
                            <Button icon='setting' onClick={() => navigate("/dashboard/config")} circular/>
                        </div>
                }
                <div 
                    className="iconHeader openMobileMenuBar upTo1024"
                    onClick={() => setShowMenu(!showMenu)}>
                    <BarsIcon/>
                </div>
            </Grid.Column>
            <Grid.Column width={16} className="mobileMenuContainer">
                {
                    showMenu &&
                        renderMobileMenu()
                }
            </Grid.Column>
        </Grid>
    )
}

export default DashHeader;