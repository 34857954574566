import api from "./api";
import apiDw from "./downloads/api";
/////////////////////////////// GET SECTION ///////////////////////////////

export const userData = () => api.get('currentUser')

export const users = () => api.get('user')

export const getAreas = () => api.get('area')

export const getSimpleUserList = () => api.get('simpleUserList')

export const getAreaCatallogue = () => api.get('areaCatalogue')

export const getRoles = () => api.get(`roles`)

export const getSimpleAreaProcess = (params) => api.get('simpleAreaProcess', params)

export const getCompanyAreas = () => api.get('companyArea')

export const getFrameworks = (params) => api.get('framework', params)

export const getFullFrameworks = () => api.get('fullframework')

export const getSingleFramework = (id, params) => api.get(`framework/${id}`, params)

export const getFrameworkNames = () => api.get('frameworkNames')

export const getTasksByProcessArea = (id, params) => api.get(`taskByProcessArea/${id}`, params)

export const getTasksByPractice = (id) => api.get(`taskByPractice/${id}`)

export const getProjectById = (id) => api.get(`project/${id}`)

export const getTaskStatus = () => api.get(`taskStatus`)

export const getCriticalLevels = () => api.get(`criticalLevels`)

export const downloadTemplate = () => api.get(`downloadTemplate`)

export const getTaskFiles = (id) => api.get(`getTaskFiles/${id}`)

export const getUserTaskFiles = (id) => api.get(`filesByUser/${id}`)

export const downloadTaskFile = (id) => apiDw.get(`downloadTaskFile/${id}`)

export const downloadUserTaskFile = (id) => apiDw.get(`downloadUserTaskFile/${id}`)

export const downloadExecutiveReport = (id) => apiDw.get(`testingReport/${id}`)

export const downloadVersionFile = (id) => apiDw.get(`versionFile/${id}`)

export const downloadFile = (id) => apiDw.get(`file/${id}`)

export const getMyTasksWithFrameworkTree = () => api.get(`myTasksWithFrameworkTree`)

export const getTasksWithFrameworkTree = () => api.get(`tasksWithFrameworkTree`)

export const getAIAutocomplete = (id, field, lng) => api.get(`AIAutocompleteRequest/${id}/${field}`, {lng})

export const getAllFiles = (params) => api.get(`file`, params)

export const getMyFiles = () => api.get(`myFiles`)

export const getSingleFileNotes = (id) => api.get(`fileNotes/${id}`)

export const getText = (params) => api.get(`text`, params)

export const getProcessAreas = () => api.get(`processAreas`)

export const getPracticesByProcessArea = (id) => api.get(`practicesByProcessArea/${id}`)

export const getLinks = (params) => api.get(`link`, params)

export const searchFiles = (params) => api.get(`searchFiles`, params)

export const getRecurringTypes = () => api.get(`recurringType`)

export const getPositions = () => api.get(`position`)

export const getFrameworksExp = ()  => api.get(`frameworkExperience`)

export const getCompanyCategories = () => api.get(`companyCategories`)

export const getCompanies = () => api.get(`companyadmn`)

export const getAvailableFrameworks = (params) => api.get(`FrameworksAPI`, params)

export const getShopFramework = (id) => api.get(`FrameworksAPI/${id}`)

export const partnerData = () => api.get('currentUserPartner')

export const getCompanyLists = () => api.get('partnercompanies')

export const getFlowDiagrams = (params) => api.get('flowDiagram', params)

export const getFlowDiagram = (id) => api.get(`flowDiagram/${id}`)

export const getCompanyData = () => api.get('editCompany')

export const getFrameworkProgress = (params) => api.get('frameworkProgress', params)

export const getAllFrameworkProgress = (params) => api.get('allFrameworkProgress', params)

export const getFrameworkPercentagesData = (params) => api.get('frameworkProgressPercentages', params)

export const getUserFrameworkProgress = (params) => api.get('usersProgress', params) 

export const getUsersByCompany = (id) => api.get(`usersbycompany/${id}`)

export const getLogs = (params) => api.get(`simplixPagelog`, params)

export const checkPWRecoveryToken = (token) => api.get(`recoverpassword/${token}`)

export const accountActivation = (token) => api.get(`activateaccount/${token}`)

/////////////////////////////// POST SECTION ///////////////////////////////

// POSTS WITH MULTIPART FORM DATA START

export const sendXLSXFile = (body) => api.post('savexlsx', body)

export const sendXLSXFileAPI = (body) => api.post('savexlsxapi', body)

export const sendTaskAttachment = (id, body) => api.post(`uploadTaskFile/${id}`, body)

export const sendUserTaskAttachment = (id, body) => api.post(`uploadUserTaskFiles/${id}`, body)

export const sendFile = (body) => api.post(`file`, body)

export const sendVersion = (id, body) => api.put(`file/${id}`, body)

// POSTS WITH MULTIPART FORM DATA END

export const login = (email, password) => api.post('login', {email, password});

export const postNewUser = (body) => api.post('user', body)

export const postNewArea = (body) => api.post('area', body)

export const postNewTask = (body) => api.post('task', body)

export const postNewProject = (body) => api.post('project', body)

export const postNewVersionNote = (body) => api.post('note', body)

export const postNewRelation = (body) => api.post('setRelation', body)

export const postNewText = (body) => api.post('text', body)

export const postNewLink = (body) => api.post('link', body)

export const postTextRelation = (body) => api.post('setRelationText', body)

export const postLinkRelation = (body) => api.post('setRelationLink', body)

export const AICreateProjects = (id, lng) => api.post(`AICreateProjects/${id}?lng=${lng}`)

export const createCompany = (body) => api.post(`company`, body)

export const postShopFramework = (id) => api.post(`FrameworksAPI/${id}`)

export const partnerCreateCompany = (body) => api.post(`companyPartner`, body)

export const postPartner = (body) => api.post(`partner`, body)

export const checkEmail = (body) => api.post('userexists', body)

export const postUserToCompany = (body) => api.post('usercompany', body)

export const postDiagram = (body) => api.post('flowDiagram', body)

export const requestFlowDiagram = (data) => api.post('flowDiagramAI', data)

export const downloadReport = (id, body) => api.post(`generateReport/${id}`, body)

export const postJsonFramework = (body) => api.post('savejsonapi', body)

export const postLog = (body) => api.post('simplixPagelog', body)

export const generateFrameworkTasks = (id) => api.post(`generateFrameworkTasks/${id}`)

export const recoverPasswordRequest = (body) => api.post(`recoverpassword`, body)

export const recoverPassword = (token, body) => api.post(`recoverpassword/${token}`, body)

/////////////////////////////// PUT SECTION ///////////////////////////////

export const updateTaks = (id, body) => api.put(`task/${id}`, body)

export const updateDates = (id, body) => api.put(`taskDates/${id}`, body)

export const updateTaskProgress = (id, progress) => api.put(`taskProgress/${id}`, {progress})

export const updateTaskLogProgress = (id, progress) => api.put(`taskLog/${id}`, {progress})

export const setReviewTask = (id, body) => api.put(`reviewTask/${id}`, body)

export const updateUser = (id, body) => api.put(`user/${id}`, body)

export const updateFramework = (id, body) => api.put(`framework/${id}`, body)

export const updateMyPassword = (body) => api.put(`changepassword`, body)

export const deleteRelations = (body) => api.put('setRelation', body)

export const completeTasks = (id) => api.put(`completeTasks/${id}`)

export const putText = (id, body) => api.put(`text/${id}`, body)

export const putLink = (id, body) => api.put(`link/${id}`, body)

export const deleteTextRelations = (body) => api.put(`setRelationText`, body)

export const deleteLinkRelations = (body) => api.put(`setRelationLink`, body)

export const activateCompany = (body) => api.put(`companyadmn`, body)

export const updateDiagram = (id, body) => api.put(`flowDiagram/${id}`, body)

export const updateCompanyData = (body) => api.put(`editCompany`, body)

///////////////////////////// DELETE SECTION /////////////////////////////

export const deleteUserTaskFile = (id) => api.delete(`deleteUserTaskFile/${id}`)

export const deleteTaskFile = (id) => api.delete(`deleteTaskFile/${id}`)

export const deleteUser = (id) => api.delete(`deleteUser/${id}`)

export const deleteProject = (id) => api.delete(`deleteProject/${id}`)

export const deleteTask = (id) => api.delete(`deleteTask/${id}`)

export const deletePractice = (id) => api.delete(`taskByPractice/${id}`)

export const deleteText = (id) => api.delete(`text/${id}`)

export const deleteLink = (id) => api.delete(`link/${id}`)

export const deleteFrameworkAPI = (id) => api.delete(`FrameworksAPI/${id}`)

export const deleteFramework = (id) => api.delete(`framework/${id}`)

export const deleteDiagram = (id) => api.delete(`flowDiagram/${id}`)