import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal, Header } from "semantic-ui-react";

function ConfirmModal({open, setOpen, type, title, description, callback}) {
    const [color, setColor] = useState("")
    const [text, setText] = useState("")

    const { t } = useTranslation()

    useEffect(() => {
        switch (type) {
            case "delete":
                setColor("red")
                setText("DELETE")
                break;
            default:
                setColor("blue")
                setText("OK")
                break;
        }
    }, [type])

    const okButton = () => {
        if(callback){
            callback()
        }
        setOpen(false)
    }

    return (
        <Modal
            basic
            onClose={() => setOpen(false)}
            open={open}
            size='small'
        >
            <Header icon>
                {
                    type === "delete" ?
                    <Fragment>
                        <Icon name="trash alternate outline"/>
                        {title}
                    </Fragment>
                    :
                    type === "complete" ? 
                    <Fragment>
                        <Icon name="check"/>
                        {title}
                    </Fragment>
                    :
                    type === "create" ? 
                    <Fragment>
                        <Icon name="list ol"/>
                        {title}
                    </Fragment>
                    :
                    null
                }
            </Header>
            <Modal.Content>
                    <h3>{description}</h3>
            </Modal.Content>
            <Modal.Actions>
                <Button color={color ? color : "blue"} inverted onClick={() => okButton()}>
                    {t(text)}
                </Button>
                <Button color={"grey"} inverted onClick={() => setOpen(false)}>
                    {t('CANCEL')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ConfirmModal