import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

export default function Diagrams(){
    const dispatch = useDispatch()
    const { t } = useTranslation()
    useEffect(() => {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t('DIAGRAMS')
        })
    }, [])
    return(
        <div className="setFlex setFlex1">
            <Outlet/>
        </div>
    )
}