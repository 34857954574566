import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ApiDocumentation from './components/apiDocumentation/apiDocumentation';
import Dashboard from './components/dashboard/dashboard';
import AreaSection from './components/area/areaSection';
import AddUser from './components/user/addUser';
import Users from './components/user/users';
import DBFrameworks from './components/framework/DBFrameworks'
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Login from './components/login/login';
import Reducer from './util/reducer';
import FrameworkDashboard from './components/dashboard/frameworkDashboard';
import ProcessArea from './components/area/processArea/processArea';
import FWProcessAreas from './components/area/processArea/FWProcessAreas';
import Practice from './components/practice/practice';
import Project from './components/project/project';
import { LoginRedirect, PrivateRoute } from './components/util/privateRoute';
import Report from './components/report/report';
import GanttDiagram from './components/ganttDiagram/ganttDiagram';
import EditFramework from './components/framework/EditFramework';
import Profile from './components/user/profile/profile';
import Library from './components/library/library';
import Testing from './components/util/test';
import Registration from './components/registration/registration';
import ActivateCompany from './components/registration/activateCompany/activateCompany';
import PricingTable from './components/pricingTable/pricingTable';
import Partner from './components/dashboard/partner/partner';
import PartnerDashboard from './components/dashboard/partner/partnerDashboard/PartnerDashboard';
import ComingSoon from './components/util/comingSoon/comingSoon';
import SignupAsPartner from './components/registration/signupAsPartner';
import PlatformConfiguration from './components/platformConfiguration/platformConfiguration';
import BPMGenerator from './components/BPMGenerator/BPMGenerator';
import Diagrams from './components/DiagramList/diagrams';
import DiagramTabs from './components/DiagramList/diagramTabs';
import GraphView from './components/graphs/graphs';
import ReportView from './components/reportView/reportView';
import AdminConfiguration from './components/adminConfiguration/adminConfiguration';
import RecoverPassword from './components/login/recoverPassword';
import ActivateAccount from './components/login/activateAccount';

const root = ReactDOM.createRoot(document.getElementById('root'))
const store = createStore(Reducer)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/apidoc" element={<ApiDocumentation/>}/>
          <Route path="/" element={<App/>}>
            <Route path="signup" element={<LoginRedirect><Registration/></LoginRedirect>}/>
            <Route path="partnersignup" element={<LoginRedirect><SignupAsPartner/></LoginRedirect>}/>
            <Route path="dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>}>
              <Route path="" element={<PrivateRoute reqRol={"Administrator"}><FrameworkDashboard/></PrivateRoute>}/>
              {/* <Route path="frameworkDashboard" element={<FrameworkDashboard/>}/> */}
              <Route path="areas" element={<PrivateRoute reqRol={"Administrator"}><AreaSection/></PrivateRoute>}/>
              {/* <Route path="notifications" element={<NotificationCenter/>}/> */}
              {/* <Route path="newCompany" element={<CreateCompany/>}/> */}
              <Route path="adduser" element={<PrivateRoute reqRol={"Administrator"}><AddUser/></PrivateRoute>}/>
              {/* <Route path="addarea" element={<AddArea/>}/> */}
              <Route path="users" element={<PrivateRoute reqRol={"Administrator"}><Users/></PrivateRoute>}/>
              {/* <Route path="mytasks" element={<MyTasks/>}/> */}
              <Route path="report" element={<Report/>}/>
              <Route path="gantt" element={<GanttDiagram/>}/>
              <Route path={"frameworks"} element={<PrivateRoute reqRol={"Administrator"}><DBFrameworks/></PrivateRoute>}>
                <Route path="" element={<FWProcessAreas/>}/>
                <Route path="processArea/:id" element={<ProcessArea/>}/>
                <Route path="practice/:id" element={<Practice/>}/>
                <Route path='project/:id' element={<Project/>}/>
              </Route>
              {/* <Route path="processArea/:id" element={<ProcessArea/>}/> */}
              {/* <Route path="testing" element={<TaskFiles files={[{"name":"Forecasting_Free_Cash_Flo4556w.xlsx","date":"2022-05-10T22:24:52","uploadedBy":2,"serverName":"2-1652239492023765.xlsx","idFile":6,"idTask":8},{"name":"review_of_website_layout.docx","date":"2022-05-10T22:24:52","uploadedBy":2,"serverName":"2-1652239492116941.docx","idFile":7,"idTask":8},{"name":"review_of_website_layout_1.docx","date":"2022-05-10T22:24:52","uploadedBy":2,"serverName":"2-1652239492222654.docx","idFile":8,"idTask":8},{"name":"Full-NIST-CSF-Cybersecurity-Program-Report-Word.docx","date":"2022-05-10T22:25:30","uploadedBy":2,"serverName":"2-1652239530893846.docx","idFile":9,"idTask":8},{"name":"Forecasting_Free_Cash_Flow.xlsx","date":"2022-05-10T22:25:30","uploadedBy":2,"serverName":"2-1652239530976469.xlsx","idFile":10,"idTask":8},{"name":"Template2.xlsx","date":"2022-05-10T22:29:00","uploadedBy":2,"serverName":"2-1652239740632593.xlsx","idFile":11,"idTask":8}]}/>}/> */}
              <Route path="profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
              <Route path="library" element={<Library/>}/>
              <Route path="ultraConfig" element={<AdminConfiguration/>}/>
              <Route path="charts" element={<PrivateRoute><GraphView/></PrivateRoute>}/>
              <Route path="testing" element={<ReportView/>}/>
              <Route path="diagrams" element={<PrivateRoute reqRol={"Administrator"}><Diagrams/></PrivateRoute>}>
                <Route path="" element={<DiagramTabs/>}/>
                <Route path="new" element={<BPMGenerator/>}/>
                <Route path=":id" element={<BPMGenerator/>}/>
              </Route>
              <Route path="config" element={<PrivateRoute reqRol={"Administrator"}><PlatformConfiguration/></PrivateRoute>}/>
            </Route>
            <Route path='partner' element={<Partner/>}>
              <Route path="" element={<PartnerDashboard/>}/>
              <Route path="*" element={<ComingSoon/>}/>
            </Route>
            <Route path="" element={<LoginRedirect><Login/></LoginRedirect>}/>
            <Route path="recoverPassword/:token" element={<LoginRedirect><RecoverPassword/></LoginRedirect>}/>
            <Route path="activateAccount/:token" element={<LoginRedirect><ActivateAccount/></LoginRedirect>}/>
            {/* <Route path="" element={<FWForm/>}/> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
