import { useEffect, useState } from "react"
import { getCompanyCategories, getCompanyLists } from "../../../util/requests"
import { Store } from "react-notifications-component"
import { API_URL, renderNotification } from "../../../../util/util"
import { useTranslation } from "react-i18next"
import { Grid, Button, Dropdown, DropdownMenu, DropdownHeader, DropdownItem, Modal, Input, Form, Icon } from "semantic-ui-react"
import { useNavigate } from "react-router-dom"
import CreateCompany from "./createCompany"
import { useDispatch } from "react-redux"
import "./partnerDashboard.sass"
import axios from "axios"

function PartnerDashboard(){
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [createCompanyOpen, setCreateCompanyOpen] = useState(false)
    const [deleteCompanyModal, setDeleteCompanyModal] = useState(null)
    const [inputCompanyName, setInputCompanyName] = useState("")
    const [loading, setLoading] = useState(false)

    // const editCompanyDefault = {
    //     idCompany: null,
    //     companyName: "",
    //     companyDescription: "",
    //     numberEmployees: 0,
    //     companyCategory: "",
    //     loaded: false
    // }

    // const [
    //     {
    //         idCompany, 
    //         companyName, 
    //         companyDescription, 
    //         numberEmployees, 
    //         companyCategory, 
    //         loaded
    //     },
    //     setEditCompany] = useState(editCompanyDefault)

    const [companyName, setCompanyName] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [numberEmployees, setNumberEmployees] = useState(0)
    const [companyCategory, setCompanyCategory] = useState(null)
    
    const [companyNameError, setCompanyNameError] = useState(null)
    const [companyDescriptionError, setCompanyDescriptionError] = useState(null)
    const [numberEmployeesError, setNumberEmployeesError] = useState(null)
    const [companyCategoryError, setCompanyCategoryError] = useState(null)



    const [loadedEditData, setLoadedEditData] = useState(false)


    const [editCompanyModal, setEditCompanyModal] = useState(null)

    const [companies, setCompanies] = useState([])

    const [companyCategories, setCompanyCategories] = useState([])

    const initComponent = () => {
        getCompanyLists().then(res => {
            setCompanies(res.data ? res.data : [])
        }).catch(err => {
            console.log(err)
            Store.addNotification(
                renderNotification('error', t('COMPANY_LIST'), t('COMPANY_LIST_ERR'))
            )
        })
        getCompanyCategories().then(res => {
            const data = res.data ? res.data : []
            setCompanyCategories(
                data.map((itm, ix) => ({
                    key: `category-${itm["idCategory"]}-${ix}`,
                    value: itm["idCategory"],
                    text: itm["name"]
                }))
            )
        }).catch(err => {
            Store.addNotification(
                renderNotification('error', t('ERROR_CATEGORIES'), t('ERROR_CATEGORIES_MSG'))
            )
        })
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t('COMPANIES')
        })
    }


    useEffect(() => {
        initComponent()
    }, [])

    const selectCompany = (id) => {
        localStorage.setItem('c-id', id)
        navigate("/dashboard")
    }

    const requestDeleteCompany = async (id) => {
        if(id && inputCompanyName===deleteCompanyModal["companyName"] && !loading){
            setLoading(true)
            try{
                const acc = localStorage.getItem('AT')
                const idc = id
                const result = await axios.delete(
                    `${API_URL}editCompany`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'X-API-KEY': acc,
                            'connection-id': idc
                        }
                    }
                )
                
                Store.addNotification(
                    renderNotification('success', t('DELETE_COMPANY'), t('DELETE_COMPANY_SUCCESS'))
                )
            }catch(error){
                console.log(error)
                Store.addNotification(
                    renderNotification('error', t('DELETE_COMPANY'), t('DELETE_COMPANY_ERR'))
                )
            }finally{
                setLoading(false)
                initComponent()
            }
        }
    }

    const requestCompanyData = async (id) => {
        if(!id || loading){
            return
        }
        setLoading(true)
        try{
            const result = await axios.get(
                `${API_URL}editCompany`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-API-KEY': localStorage.getItem('AT'),
                        'connection-id': id
                    }
                }
            )
            setCompanyName(result.data["companyName"] ? result.data["companyName"] : "")
            setCompanyDescription(result.data["companyDescription"] ? result.data["companyDescription"] : "")
            setNumberEmployees(result.data["numberEmployees"] ? result.data["numberEmployees"] : 0)
            setCompanyCategory(result.data["idCategory"])
        }catch(error){
            console.log(error)
            Store.addNotification(
                renderNotification('error', t('EDIT_COMPANY'), t('EDIT_COMPANY_ERR'))
            )
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        if(editCompanyModal){
            requestCompanyData(editCompanyModal["idCompany"])
        }else{
            setCompanyName("")
            setCompanyDescription("")
            setNumberEmployees(0)
            setCompanyCategory(null)
            setCompanyNameError(null)
            setCompanyDescriptionError(null)
            setNumberEmployeesError(null)
            setCompanyCategoryError(null)
        }
    }, [editCompanyModal])

    useEffect(() => {
        if(!deleteCompanyModal){
            setInputCompanyName("")
        }
    }, [deleteCompanyModal]) 

    const updateCompany = async () => {
        if(!await checkInput.checkAll()){
            return
        }
        let data = {
            companyName,
            numberEmployees: Number(numberEmployees),
            idCategory: companyCategory
        }
        if(companyDescription){
            data["companyDescription"] = companyDescription
        }
        try{
            const result = await axios.put(
                `${API_URL}editCompany`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-API-KEY': localStorage.getItem('AT'),
                        'connection-id': editCompanyModal["idCompany"]
                    }
                }
            )
            Store.addNotification(
                renderNotification('success', t('EDIT_COMPANY'), t('EDIT_COMPANY_SUCCESS'))
            )
            setEditCompanyModal(null)
            initComponent()
        }catch(error){
            Store.addNotification(
                renderNotification('error', t('EDIT_COMPANY'), t('EDIT_COMPANY_ERR'))
            )
        }

    }

    const checkInput = {
        companyName: () => {
            if(companyName.length === 0){
                setCompanyNameError(t('COMPANY_NAME_REQUIRED'))
                return false
            }
            if(companyName.length > 100){
                setCompanyNameError(t('COMPANY_NAME_LENGTH'))
                return false
            }
            setCompanyNameError(null)
            return true
        },
        companyDescription: () => {
            if(companyDescription.length > 500){
                setCompanyDescriptionError(t('COMPANY_DESCRIPTION_LENGTH'))
                return false
            }
            setCompanyDescriptionError(null)
            return true
        },
        numberEmployees: () => {
            if(numberEmployees < 0){
                setNumberEmployeesError(t('NUMBER_EMPLOYEES_MIN'))
                return false
            }
            setNumberEmployeesError(null)
            return true
        },
        companyCategory: () => {
            if(!companyCategory){
                setCompanyCategoryError(t('COMPANY_CATEGORY_REQUIRED'))
                return false
            }
            setCompanyCategoryError(null)
            return true
        },
        checkAll: async (skip=[]) =>{
            let result = true
            for(const key in checkInput){
                if(key === "checkAll" || skip.findIndex(itm => itm === key) !== -1){
                    continue
                }
                const response = await checkInput[key]()
                if(result && !response){
                    result = false
                }
            }
            return result
        }
    }

    return(
        <div className="partnerDashboard setFlex1 setFlexColumn">
            <div className="setFlex1 setFlexColumn">
                <div width={16} className="marginTop setFlex1">
                    <Grid>
                        {
                            companies.map((itm, ix) => (
                                <Grid.Column widescreen={4} computer={4} tablet={4} mobile={1} key={`partner-company-${itm["idCompany"]}-${ix}`}>
                                    <div 
                                        className="boxFramework"
                                        style={{backgroundImage: "url(/background-card-framework.png)", display:"flex"}} 
                                        onClick={() => selectCompany(itm["idCompany"])}
                                    >
                                        {/* <Button 
                                            icon='ellipsis horizontal' 
                                            className="optionsIcon"
                                            onClick={onClickMenu}
                                        /> */}
                                        <Dropdown item icon={'ellipsis horizontal'} className="optionsIcon">
                                            <DropdownMenu>
                                                <DropdownHeader>{t('OPTIONS')}</DropdownHeader>
                                                <DropdownItem onClick={() => setEditCompanyModal(itm)}>
                                                    <Icon name="edit"/>
                                                    {t('EDIT')}
                                                </DropdownItem>
                                                <DropdownItem 
                                                    onClick={() => setDeleteCompanyModal(itm)}
                                                    className={"deleteButton"}>
                                                        <Icon name="trash alternate"/>
                                                        {t('DELETE')}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <Grid className="BFMainContainer">
                                            <Grid.Column width={16}>
                                                <Grid centered>
                                                    <Grid.Column width={14} className="fwContent">
                                                        <h2 className="centerText">{itm["companyName"]}</h2>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid>
                                    </div>
                                </Grid.Column>
                            ))
                        }
                    </Grid>
                    <Modal
                        onClose={() => setEditCompanyModal(null)}
                        open={editCompanyModal !== null}
                        size='small'
                    >
                        <Modal.Header>{t('EDIT_COMPANY')}</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Input
                                    label={t('COMPANY_NAME')}
                                    placeholder={t('COMPANY_NAME')}
                                    value={companyName}
                                    error={companyNameError}
                                    onChange={(e, {value}) => setCompanyName(value)}
                                    onBlur={checkInput.companyName}
                                    fluid
                                />
                                <Form.TextArea
                                    label={t('COMPANY_DESCRIPTION')}
                                    placeholder={t('COMPANY_DESCRIPTION')}
                                    value={companyDescription}
                                    error={companyDescriptionError}
                                    onChange={(e, {value}) => setCompanyDescription(value)}
                                    onBlur={checkInput.companyDescription}
                                    fluid
                                />
                                <Form.Input
                                    label={t('NUMBER_EMPLOYEES')}
                                    placeholder={t('NUMBER_EMPLOYEES')}
                                    type="number"
                                    value={numberEmployees}
                                    error={numberEmployeesError}
                                    onChange={(e, {value}) => setNumberEmployees(value)}
                                    onBlur={checkInput.numberEmployees}
                                    fluid
                                />
                                <Form.Dropdown
                                    label={t('COMPANY_CATEGORY')}
                                    options={companyCategories}
                                    value={companyCategory}
                                    placeholder={t('COMPANY_CATEGORY')}
                                    error={companyCategoryError}
                                    onChange={(e, { value }) => setCompanyCategory(value)}
                                    onBlur={checkInput.companyCategory}
                                    search
                                    selection
                                />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions
                            style={{display: 'flex', justifyContent: 'space-between'}}
                        >
                            <Button
                                color='black'
                                onClick={() => setEditCompanyModal(null)}
                            >
                                {t('CANCEL')}
                            </Button>
                            <Button
                                content={t('SAVE')}
                                onClick={() => {
                                    updateCompany()
                                }}
                                className="buttonBlue"
                            />
                        </Modal.Actions>
                    </Modal>
                    {
                        deleteCompanyModal && 
                        <Modal
                            onClose={() => setDeleteCompanyModal(null)}
                            open={deleteCompanyModal !== null}
                            size='small'
                        >
                            <Modal.Header>{t('DELETE_COMPANY')}</Modal.Header>
                            <Modal.Content>
                                <p>{(t('DELETE_COMPANY_CONFIRM')).replace('{NAME}', deleteCompanyModal["companyName"])}</p>
                                <Input
                                    placeholder={deleteCompanyModal["companyName"]}
                                    value={inputCompanyName}
                                    onChange={(e, {value}) => setInputCompanyName(value)}
                                    fluid
                                />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    color='black'
                                    onClick={() => setDeleteCompanyModal(null)}
                                >
                                    {t('CANCEL')}
                                </Button>
                                <Button
                                    content={t('DELETE')}
                                    onClick={() => {
                                        setDeleteCompanyModal(null)
                                        requestDeleteCompany(deleteCompanyModal["idCompany"])
                                    }}
                                    disabled={inputCompanyName !== deleteCompanyModal["companyName"]}
                                    negative
                                />
                            </Modal.Actions>
                        </Modal>
                    }
                </div>
                <div>
                    <Button floated="right" onClick={() => setCreateCompanyOpen(true)} className="buttonBlue">
                        {t('NEW_COMPANY')}
                    </Button>
                </div>
            </div>
            <CreateCompany openModal={createCompanyOpen} closeModal={() => setCreateCompanyOpen(false)} callback={initComponent}/>
        </div>
    )
}

export default PartnerDashboard