import { t } from "i18next"
import { useState } from "react"
import { Grid } from "semantic-ui-react"
import "./showMore.sass"

function ShowMore({ text, limit=250 }){
    const [showMore, setShowMore] = useState(false)

    return (
        <div className="showMore">
            <Grid>
                <Grid.Column width={16}>
                    {
                        limit > text.length ?
                        <h4 className="centerText">
                            {text}
                        </h4>
                        :
                        <h4 className="centerText">
                            {showMore ? text : `${text.substring(0, limit)}... `} &emsp;
                            <span className="linkSpan centerText" onClick={() => setShowMore(!showMore)}>{showMore ? t('SHOW_LESS') : t('SHOW_MORE')}</span>
                        </h4>
                    }
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default ShowMore