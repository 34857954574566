import { ResponsiveBar } from '@nivo/bar'
import EmptyChart from './emptyChart'


const SimplixResponsiveBar = ({ keys = [], data = [], indexBy="", bottomTitle="", leftTitle=""}) => {
    const simplixBlueShades = [
        "#0047AB", 
        "#0066CC", 
        "#1E90FF", 
        "#4169E1", 
        "#87CEFA", 
    ];
    return ( 
        data.length === 0 ? <EmptyChart/> :
        <ResponsiveBar
            data={data}
            keys={keys}
            indexBy={(itm) => `${itm.ix}.${itm[indexBy]}`}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={simplixBlueShades}
            fill={null}
            labelTextColor={"#ffffff"}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: bottomTitle,
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: leftTitle,
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            role="application"
            ariaLabel="Bar"
        />
    )
}

export default SimplixResponsiveBar