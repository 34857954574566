import { ResponsiveSunburst } from "@nivo/sunburst";
import EmptyChart from "./emptyChart";


export default function SimplixSunburst({ data }){
    const simplixBlueShades = [
        "#0047AB", 
        "#0066CC", 
        "#1E90FF", 
        "#4169E1", 
        "#87CEFA", 
    ];
    return (
        !data.children || data.children.length === 0 ? <EmptyChart/> :
        <ResponsiveSunburst
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        id={"name"}
        value="value"
        cornerRadius={0}
        colors={simplixBlueShades}
        borderColor={{ theme: 'background' }}
        childColor={{
            from: 'color',
            modifiers: [
                [
                    'brighter',
                    0.13
                ]
            ]
        }}
        enableArcLabels={true}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={"#FFFFFF"}
        />
    )
}