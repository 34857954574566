import { useEffect, useState } from "react";
import { Button, Grid, Input, List, Pagination } from "semantic-ui-react";
import "./diagramList.sass";
import { deleteDiagram, getFlowDiagrams } from "../util/requests";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { renderNotification, useDidUpdateEffect } from "../../util/util";
import ConfirmModal from "../util/confirmModal/ConfirmModal";
import { useTranslation } from "react-i18next";


export default function DiagramList({ diagrams }) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [search, setSearch] = useState("")
    const [diagramList, setDiagramList] = useState([])

    const defaultConfirmDiagram = {
        openModal: false,
        id: null
    }
    const [{openModal, id}, setConfirmModal] = useState(defaultConfirmDiagram)

    const requestDiagrams = async () => {
        const params = {
            page,
            search
        }
        try{
            const result = await getFlowDiagrams(params)
            setDiagramList(result.data["elements"])
            setTotalPages(result.data["totalPages"])
        }catch(e){
            console.log(e)
        }
    }

    const requestDeleteDiagram = async (id) => {
        try{
            await deleteDiagram(id)
            Store.addNotification(
                renderNotification("success", t('DELETE_DIAGRAM'), t('DELETE_DIAGRAM_SUCCESS'))
            )
            requestDiagrams()
        }catch(e){
            console.log(e)
            Store.addNotification(
                renderNotification("error", t('DELETE_DIAGRAM'), t('DELETE_DIAGRAM_ERR'))
            )
        }
    }

    const handleDeleteClick = (e, id) => {
        e.stopPropagation()
        setConfirmModal({id, openModal: true})
    }

    useEffect(() => {
        requestDiagrams()
    }, [page])

    useDidUpdateEffect(() => {
        const delayFunction = setTimeout(() => {
            if(page !== 1){
                setPage(1)
            }else{
                requestDiagrams()
            }
        }, 800)
        return () => clearTimeout(delayFunction)
    }, [search])

    const renderList = (diagrams=[]) => {
        return (
            diagrams.map((diagram, ix) => (
                <List.Item key={ix} onClick={() => navigate(`/dashboard/diagrams/${diagram.idFlowDiagram}`)}>
                    <List.Icon name='file' />
                    <List.Content>
                        <List.Content floated='right'>
                            <Button onClick={(e) => handleDeleteClick(e, diagram.idFlowDiagram)} icon='trash' color='red'/>
                        </List.Content>
                        <List.Header>{diagram.name}</List.Header>
                        <List.Description>{diagram.description}</List.Description>
                        <List.Description>{diagram.date}</List.Description>
                    </List.Content>
                </List.Item>
            ))
        )
    }
    return (
        <div className="diagramList">
            <Grid className="gridContainer">
                <Grid.Column width={16}>
                    <Input icon='search' placeholder='Search...' value={search} onChange={({ target }) => setSearch(target.value)} fluid/>
                </Grid.Column>
                <Grid.Column width={16} className="listContainer">
                    <Grid.Column width={16}>
                        <List selection>
                            {
                                renderList(diagramList)
                            }
                        </List>
                    </Grid.Column>
                </Grid.Column>
                <Grid.Column width={16} className="centerText">
                    <Pagination
                        activePage={page}
                        onPageChange={(e, { activePage }) => setPage(activePage)}
                        totalPages={totalPages}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    <Button 
                        className="buttonBlue" 
                        onClick={() => navigate("/dashboard/diagrams/new")}
                        floated="right">
                        {t('NEW_DIAGRAM')}
                    </Button>
                </Grid.Column>
                <ConfirmModal
                    open={openModal}
                    setOpen={() => setConfirmModal(defaultConfirmDiagram)}
                    title={t('DELETE_DIAGRAM')}
                    description={t('DELETE_DIAGRAM_CONFIRM')}
                    type="delete"
                    callback={() => requestDeleteDiagram(id)}
                />
            </Grid>
        </div>
    );
}