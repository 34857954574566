import { useTranslation } from 'react-i18next';
import { Grid, Icon, Header } from 'semantic-ui-react';
import { formatToTranslate } from '../../util/util';
import './userComponent.sass'

function UserComponent(props){
    const { t } = useTranslation()
    return(
        <Grid className='userComponent'>
            {/* <Grid.Column width={16}> */}
                {/* <div className='setFlex'> */}
                    {/* <Image src='/image.png'  circular/>
                        <span>Antonio Smith</span> */}
            <Grid.Column widescreen={3} computer={3} tablet={8} mobile={16}>
                <div className='setFlex'>
                    <div style={{
                            backgroundImage: "url(/image.png)"
                        }} className='userAvatarImg'></div>
                    <Header as='h4' className='verticalAlignFlex marginLeft'>
                            <Header.Content>
                                {`${props.name} ${props.lastName}`}
                                <Header.Subheader>{Array.isArray(props.roles) ? props.roles.map(itm => t(formatToTranslate(itm.name))).join(",") : props.roles}</Header.Subheader>
                            </Header.Content>
                    </Header>
                </div>
            </Grid.Column>
            {/* <Grid.Column width={3}>
                <Header as='h4' className='verticalAlignFlex marginLeft'>
                    <Header.Content>
                        Area
                        <Header.Subheader>
                            {props.area}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            </Grid.Column> */}
            <Grid.Column widescreen={4} computer={4} tablet={8} mobile={16}>
                <Header as='h4' className='verticalAlignFlex marginLeft'>
                    {t('EMAIL')}
                    <Header.Subheader>
                        {props.email}
                    </Header.Subheader>
                </Header>
            </Grid.Column>
            <Grid.Column widescreen={4} computer={4} tablet={8} mobile={16}>
                <Header as='h4' className='verticalAlignFlex marginLeft'>
                    {t('PHONE')}
                    <Header.Subheader>
                        {props.phone}
                    </Header.Subheader>
                </Header>
            </Grid.Column>
            {/* <Grid.Column width={3}>
                <Header as='h4' className='verticalAlignFlex marginLeft'>
                    Privilegies
                    <Header.Subheader>
                        {props.privilegies}
                    </Header.Subheader>
                </Header>
            </Grid.Column> */}
            <Grid.Column widescreen={3} computer={3} tablet={8} mobile={16}>
                <Header as='h4' className='verticalAlignFlex marginLeft'>
                    {t('ACTION')}
                    <Header.Subheader>
                        <Icon name='edit' onClick={() => props.editUser(props.idUser)}/>
                        <span className='delete' onClick={() => props.deleteUser(props.idUser)}>{t('DELETE')}</span>
                    </Header.Subheader>
                </Header>
            </Grid.Column>
        </Grid>
    )
}

export default UserComponent;