import { Grid, Header, List } from 'semantic-ui-react'
import ReactSpeedometer from "react-d3-speedometer"
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import ContainerDimensions from '../hooks/containerDimensions/ContainerDimensions';
import "./overview.sass"

function Overview({ completed, pending, process, critical, progress, module=false, title="-"}){
    const { t } = useTranslation()
    progress = progress || progress === 0 ?  Number(progress.toFixed(2)) : null;

    const smRef = useRef()
    const overviewRef = useRef()
    const overviewContainer = ContainerDimensions(overviewRef)
    const { width , height } = ContainerDimensions(smRef)

    const renderSpeedometer = () => {
        return(
            <Grid centered>
                <Grid.Column width={16} style={{paddingBottom: "0px"}}>
                    <Header as='h1' className="percentage centerText">
                        {progress || progress === 0 ? progress : "--"}%
                        <Header.Subheader className="centerText">
                            {t('COMPLETED')}
                        </Header.Subheader>
                    </Header>
                </Grid.Column>
                <Grid.Column className="speedometerContainer" width={16}>
                    {/* <h4>W:{width}, H: {height}</h4> */}
                    <div style={{width: "100%", height: "100%"}} ref={smRef}>
                        <ReactSpeedometer
                            forceRender={true}
                            maxValue={100}
                            value={progress ? progress : 0}
                            customSegmentStops={[0, progress ? progress : 0, 100]}
                            currentValueText={""}
                            segments={1}
                            segmentColors={["#001737", "blue"]}
                            needleColor="#727176"
                            maxSegmentLabels={1}
                            needleTransitionDuration={2000}
                            fluidWidth={true}
                            width={width}
                            needleTransition="easeElastic"
                            ringWidth={module ? 30 : 45}
                            //startColor='#001737'
                            //endColor='blue'
                            textColor={"#000"}
                        />
                    </div>
                </Grid.Column>
            </Grid>
        )
    }

    return(
        <div ref={overviewRef}>
            {
                module ? 
                    <Grid>
                        <Grid.Column width={16}>
                            <h4>{title}</h4>
                        </Grid.Column>
                        {
                            overviewContainer.width < 220 ? 
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <List divided>
                                        <List.Item>
                                            <List.Header as={"h3"}>{completed || completed === 0 ? completed : "-"}</List.Header>
                                            <List.Description> {t('COMPLETED')}</List.Description>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as={"h3"}>{pending || pending === 0 ? pending : "-"}</List.Header>
                                            <List.Description> {t('PENDING')}</List.Description>
                                        </List.Item>
                                    </List>    
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <List divided>
                                        <List.Item>
                                            <List.Header as={"h3"}>{process || process === 0 ? process : "-"}</List.Header>
                                            <List.Description> {t('IN_PROCESS')}</List.Description>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as={"h3"}>{critical || critical === 0 ? critical : "-"}</List.Header>
                                            <List.Description>{t('CRITICAL')}</List.Description>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                            :
                            <Grid.Column width={6}>
                                <List divided>
                                    <List.Item>
                                        <List.Header as={"h3"}>{completed || completed === 0 ? completed : "-"}</List.Header>
                                        <List.Description> {t('COMPLETED')}</List.Description>
                                    </List.Item>
                                    <List.Item>
                                        <List.Header as={"h3"}>{pending || pending === 0 ? pending : "-"}</List.Header>
                                        <List.Description> {t('PENDING')}</List.Description>
                                    </List.Item>
                                    <List.Item>
                                        <List.Header as={"h3"}>{process || process === 0 ? process : "-"}</List.Header>
                                        <List.Description> {t('IN_PROCESS')}</List.Description>
                                    </List.Item>
                                    <List.Item>
                                        <List.Header as={"h3"}>{critical || critical === 0 ? critical : "-"}</List.Header>
                                        <List.Description>{t('CRITICAL')}</List.Description>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        }
                        <Grid.Column width={overviewContainer.width < 220 ? 16 : 10}>
                            {renderSpeedometer()}
                        </Grid.Column>
                    </Grid>
                :
                <div>
                    <h1>{t('OVERVIEW')}</h1>
                    <Grid className="centerText">
                        <Grid.Column width={4}>
                            <Header as='h2'>
                                {completed || completed === 0 ? completed : "-"}
                                <Header.Subheader>
                                    {t('COMPLETED')}
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2'>
                                {pending || pending === 0 ? pending : "-"}
                                <Header.Subheader>
                                    {t('PENDING')}
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2'>
                                {process || process === 0 ? process : "-"}
                                <Header.Subheader>
                                    {t('IN_PROCESS')}
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2'>
                                {critical || critical === 0 ? critical : "-"}
                                <Header.Subheader>
                                    {t('CRITICAL')}
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            {renderSpeedometer()}
                        </Grid.Column>
                    </Grid>
                </div>
            }
        </div>
    )
}

export default Overview;