import { useEffect } from "react";
import "./boxSection.sass"
import { Grid, Button } from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
import Overview from "../overview/overview";
import { useTranslation } from "react-i18next";

function BoxSection({title, url, processAreaData}){
    const { t } = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        // if(processAreaData){
        //     startExtractor(processAreaData, ({progress, practice, status}) => {
        //         //setProgress(progress)
        //         const processAreaProgress = (practice.reduce((total, itm) => {
        //             return total + itm.progress
        //         }, 0)) / practice.length
        //         setProgress(Math.floor(processAreaProgress))
        //         //setPracticeDetails(practice)
        //         //console.log("S T A T U S !", status)
        //         setTaskStatus(status)
        //     })
        // }
    }, [])

    return (
        <div className="boxSection" onClick={() => navigate(url)}>
            <div className="imageContainer">
                <Overview 
                    completed={processAreaData["complete"] || processAreaData["complete"] === 0 ? processAreaData["complete"] : null}
                    pending={processAreaData["pending"] || processAreaData["pending"] === 0 ? processAreaData["pending"] : null}
                    process={processAreaData["inProcess"] || processAreaData["inProcess"] === 0 ? processAreaData["inProcess"] : null}
                    critical={processAreaData["critical"] || processAreaData["critical"] === 0 ? processAreaData["critical"] : null}
                    progress={processAreaData["progress"]}
                    // completed={taskStatus["completed"] || taskStatus["completed"] === 0 ? taskStatus["completed"] : null}
                    // pending={taskStatus["pending"] || taskStatus["pending"] === 0 ? taskStatus["pending"] : null}
                    // process={taskStatus["process"] || taskStatus["process"] === 0 ? taskStatus["process"] : null}
                    // critical={taskStatus["critical"] || taskStatus["critical"] === 0 ? taskStatus["critical"] : null}
                    // progress={progress}
                    title={title}
                    module={true}
                />
            </div>
            <div className="boxFooter">
                {/* <div className="boxTitle">
                    <p>{title}</p>
                </div> */}
                <Grid className="boxButtonSection" centered>
                    <Grid.Column width={16}>
                        <Button className="buttonBlueWhite" onClick={() => navigate(url)} fluid>{t('SEE_DETAILS')}</Button>
                    </Grid.Column>
                    {/* <Grid.Column width={8}>
                        <Button className="buttonWhiteBlack" fluid>EDIT</Button>
                    </Grid.Column> */}
                </Grid>
            </div>
        </div>
    )
}

export default BoxSection;