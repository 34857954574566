import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "semantic-ui-react";
import '../dashMenu.sass'
import { useTranslation } from "react-i18next";
import { ReactComponent as DashboardIcon} from "../../util/svg/dashboard.svg"
import { ReactComponent as MetricsIcon} from "../../util/svg/bars.svg"
import { ReactComponent as ClientsIcon} from "../../util/svg/Clients.svg"
import { ReactComponent as ProfileIcon} from "../../util/svg/Profile.svg"

function DashPartnerMenu(){
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const section = location.pathname.split("/").length >= 3 ? location.pathname.split("/")[2].toLowerCase() : ""

    return(
        <Menu icon="labeled" vertical fluid borderless size="massive" className="dashMenu">
            <Menu.Item
                name="/"
                active={ section === ''}
                onClick={() => {navigate("/partner/")}}>
                    <div>
                        <DashboardIcon/>
                        <br/><span className="itemName">{t('DASHBOARD')}</span>
                    </div>
            </Menu.Item>
            <Menu.Item
                name="metrics"
                active={section === 'metrics'}
                onClick={() => {navigate("/partner/metrics")}}>
                    <div>
                        <MetricsIcon/>
                        <br/><span className="itemName">{t('METRICS')}</span>
                    </div>
            </Menu.Item>
            <Menu.Item
                name="clients"
                active={section==='clients'}
                onClick={() => {navigate("/partner/clients")}}
            >
                <div>
                    <ClientsIcon/>
                    <br/><span className="itemName">{t('CLIENTS')}</span>
                </div>
            </Menu.Item>
            <MenuItem
                name="profile"
                active={section==='profile'}
                onClick={() => {navigate("/partner/profile")}}
            >
                <div>
                    <ProfileIcon/>
                    <br/> <span className="itemName">{t('PROFILE')}</span>
                </div>
            </MenuItem>
        </Menu>
    )
}

export default DashPartnerMenu;