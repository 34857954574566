import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Dropdown, Grid, Pagination, Table } from "semantic-ui-react"
import { getCompanies, getLogs, getUsersByCompany } from "../../util/requests"

function Logs(){
    const { t } = useTranslation()
    const [logList, setLogList] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [userList, setUserList] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [companyList, setCompanyList] = useState([])
    const [selectedCompany, setSelectedCompany] = useState()

    useEffect(() => {
        runGetCompanies()
    }, [])

    useEffect(() => {
        if(selectedCompany){
            runGetUsers(selectedCompany)
        }
        setSelectedUser(null)
    }, [selectedCompany])

    useEffect(() => {
        if(selectedCompany && selectedUser){
            runGetLogs(selectedCompany, selectedUser, page)
        }else{
            setLogList([])
        }
    }, [selectedCompany, selectedUser, page])

    useEffect(() => {
        setPage(1)
    }, [selectedCompany, selectedUser])


    const runGetLogs = (company, user, page) => {
        const params = {
            company,
            user,
            page
        }
        getLogs(params).then(res => {
            const data = res.data["elements"] ? res.data["elements"] : []
            const tp = res.data["totalPages"] ? res.data["totalPages"] : 1
            setLogList(data)
            setTotalPage(tp)
        }).catch(err => {
            console.log(err)
        })
    }

    const runGetUsers = (id) => {
        getUsersByCompany(id).then(res => {
            const data = res.data ? res.data : []
            setUserList(
                data.map(itm => (
                    {
                        key: `user-logs-${itm["idUser"]}`,
                        text: `${itm["name"]} ${itm["lastName"]}`,
                        value: itm["idUser"]
                    }
                ))
            )
        }).catch(err => {
            console.log(err)
        })
    }

    const runGetCompanies = () => {
        getCompanies().then(res => {
            const data = res.data ? res.data : []
            setCompanyList(
                data.map(itm => (
                    {
                        key: `company-logs${itm['idCompany']}`,
                        text: itm['companyName'],
                        value: itm['idCompany']
                    }
                ))
            )
        }).catch(err => {
            console.log(err)
        })
    }

    return(
        <div className="logs setFlexColumn flexOverflow" style={{overflowX: "hidden"}}>
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Dropdown
                                placeholder={t('COMPANY')}
                                options={companyList}
                                value={selectedCompany}
                                onChange={(e, { value }) => setSelectedCompany(value)}
                                fluid
                                search
                                selection
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Dropdown
                                placeholder={t('USER')}
                                options={userList}
                                value={selectedUser}
                                onChange={(e, { value }) => setSelectedUser(value)}
                                disabled={!selectedCompany}
                                fluid
                                search
                                selection
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div className="setFlex1 marginTop">
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                { t('NAME') }
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                { t('EMAIL') }
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                { t('DATE') } 
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                { t('PATH') }
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                { t('SECONDS') }
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            logList.map((itm, ix) => (
                                <Table.Row key={`log-${itm['idLogPage']}-${ix}`}>
                                    <Table.Cell>
                                        {
                                            `${itm['userCompany']['user']['name']} ${itm['userCompany']['user']['lastName']}`
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            itm['userCompany']['user']['email']
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            itm['date']
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            itm['site']['route']
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            itm['seconds']
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </div>
            <div className="centerText">
                <Pagination totalPages={totalPage} activePage={page} onPageChange={(e, { activePage }) => setPage(activePage)}/>
            </div>
        </div>
    )
}

export default Logs