import { Fragment, useEffect, useRef, useState } from "react";
import { Store } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Button, Checkbox, Confirm, Form, Grid, Icon, Input, List, Modal, Tab, Table, Message, Popup, Divider, Segment, Loader} from "semantic-ui-react";
import { formatToTranslate, getFiles, renderNotification } from "../../util/util";
import { 
    deleteUserTaskFile, 
    getTasksWithFrameworkTree, 
    getMyTasksWithFrameworkTree, 
    getTaskStatus, 
    getUserTaskFiles, 
    sendUserTaskAttachment, 
    setReviewTask, 
    updateTaskProgress, 
    completeTasks,
    updateTaskLogProgress} from "../util/requests";
import ShowMore from "../util/showMore/showMore";
import { ReactComponent as HitoIcon } from "../util/svg/square45.svg"

import "./report.sass"
import { useTranslation } from "react-i18next";
import EditFramework from "../framework/EditFramework";
import DetailModal from "../util/detailModal/DetailModal";
import SXFileList from "../util/FileManager/SXFileList";
import ConfirmModal from "../util/confirmModal/ConfirmModal";
import ModalPracticeEvidence from "./modalPracticeEvidence";
import Evidence from "./evidence";
import EvidenceSelection from "./evidenceSelection";

import { useLocation } from "react-router-dom";

function Report(){
    const { t } = useTranslation()

    const defaultReject = {
        notes: "",
        progress: 0
    }


    const location = useLocation();

    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const idtask = Number(queryParams.get('idtask')); // Replace 'someQueryParam' with your query parameter name

    const [loadedFromParams, setLoadedFromParams] = useState(false)
    const [taskSelectedFromParams, setTaskSelectedFromParams] = useState(null)

    const selectorRef = useRef()

    const [showFilter, setShowFilter] = useState(false)

    const [frameworks, setFrameworks] = useState(null)

    const [openModal, setOpenModal] = useState([false, false, false])

    const [checkController, setCheckController] = useState({})

    const [filteredTasks, setFilteredTasks] = useState(null)


    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)

    const [allFrameworksSelected, setAllFrameworksSelected] = useState(true)

    const idUser = useSelector(state => state.idUser)
    const rol = useSelector(state => state.rol)


    const [myFiles, setMyFiles] = useState([])
    const [loading, setLoading] = useState(0)

    const [loadingTaskList, setLoadingTaskList] = useState(true)

    const [fileToDelete, setFileToDelete] = useState(null)
    const [confirmated, setConfirmated] = useState(false)

    const [statusOptions, setStatusOptions] = useState([])
    const [selectedStatus, setSelectedStatus] = useState()

    const [{notes, progress}, setRvTask] = useState(defaultReject)

    const [attachmentList, setAttachmentList] = useState([])

    // NEW VARIABLES
    const [newFilteredTasks, setNewFilteredTasks] = useState(null)
    const [fileSelection, setFileSelection] = useState([])
    const [workingFileList, setWorkingFileList] = useState([])

    const [displayedLogs, setDisplayedLogs] = useState([])

    const [tabIndex, setTabIndex] = useState(0)
    const defaultConfirmPractice = {
        titleConfirmPractice: "",
        descriptionConfirmPractice: "",
        openPracticeConfirm: false,
        idPractice: 0,
        practiceTasks: []
    }
    const defaultModalPractice = {
        open: false,
        idPractice: null
    }
    const [{
        titleConfirmPractice,
        descriptionConfirmPractice,
        openPracticeConfirm, 
        idPractice, 
        practiceTasks}, setConfirmCompletePractice] = useState(defaultConfirmPractice)
    const [modalPractice, setModalPractice] = useState(defaultModalPractice)

    const dispatch = useDispatch()

    const requestFrameworks = () => {
        const ix = rol.findIndex(itm => itm === "Administrator")
        setLoadingTaskList(true)
        if(ix === -1){
            getMyTasksWithFrameworkTree().then(res => {
                setFrameworks(res.data ? res.data : [])
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('FRAMEWORK_REQUEST_ERR'), t('FRAMEWORK_REQUEST_ERR_MSG'))
                )
            }).finally(() => {
                setLoadingTaskList(false)
            })
        }else{
            getTasksWithFrameworkTree().then(res => {
                setFrameworks(res.data ? res.data : [])
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('FRAMEWORK_REQUEST_ERR'), t('FRAMEWORK_REQUEST_ERR_MSG'))
                )
            }).finally(() => {
                setLoadingTaskList(false)
            })
        }
    }

    useEffect(()=> {
        dispatch({
            type: "SET_HEADER_TITLE",
            headerTitle: t("TASK_LIST")
        })
        requestFrameworks()
        getTaskStatus().then(res => {
            setStatusOptions(res.data.map(itm => {
                return {
                    key: `optionStatus-${itm.idTaskStatus}`,
                    text: itm.name,
                    value: itm.idTaskStatus,
                }
            }))
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('REQUEST_ERR'), t('REQUEST_ERR_MSG'))
            )
        })
    }, [])

    useEffect(() => {
        if(modalData){
            getFiles(2, modalData["idTask"], setAttachmentList)
            getFiles(4, modalData["idUserTask"], setWorkingFileList)
        }
    }, [modalData])

    const completePracticeFunc = (id, tasks=[]) => {
        completeTasks(id).then(res => {
            const result = filteredTasks.map((ft) =>{
                const ix = tasks.indexOf(ft.idUserTask)
                if(ix !== -1){
                    ft["progress"] = 100
                }
                return ft
            })
            setFilteredTasks(result)
            Store.addNotification(
                renderNotification("success", t('COMPLETE_PRACTICE'), t('COMPLETE_PRACTICE_MSG'))
            )
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('COMPLETE_PRACTICE'), t('COMPLETE_PRACTICE_MSG_ERR'))
            )
        })
    }

    // useEffect(() => {
    //     console.log("FILTERED TASKS", filteredTasks)
    // }, [filteredTasks])

    // useEffect(() => {
    //     console.log("NEW FILTERED TASKS", newFilteredTasks)
    // }, [newFilteredTasks])


    useEffect(() => {
        if(statusOptions.length > 0){
            setSelectedStatus(statusOptions[0]["value"])
        }
    },[statusOptions])

    useEffect(() => {
        setLoading(0)
        setFileSelection([])
        setTabIndex(0)
    }, [modalOpen])
    
    useEffect(() => {
        if(frameworks && frameworks.length > 0)
            setKeyController()
    }, [frameworks])

    const onCheckBoxClick = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        let newChecks = JSON.parse(JSON.stringify(checkController))
        for (const key in newChecks) {
            if (Object.hasOwnProperty.call(newChecks, key)) {
               newChecks[key]["checked"] = allFrameworksSelected;
            }
        }
        setCheckController(newChecks)
    }, [allFrameworksSelected])

    const checkCascade = async (data) => {
        let keys = []
        for (const itm of data) {
            keys.push(itm)
            if(checkController[itm]["childs"] && checkController[itm]["childs"].length > 0){
                const ch = await checkCascade(checkController[itm]["childs"])
                keys = [...keys, ...ch]
            }
        }
        return keys
    }

    const filterTasks = () => {
        setNewFilteredTasks(null)
        setFilteredTasks(null)
        if(!loadingTaskList){
            setLoadingTaskList(true)
        }
        try{
            if(frameworks === null || Object.keys(checkController).length === 0){
                return
            }
            let tasks = []
            let hierarchyFormat = []
            const now = Date.now()
            for (const key in checkController) {
                if (Object.hasOwnProperty.call(checkController, key)) {
                    if(checkController[key]["childs"].length === 0 && checkController[key]["checked"]){
                        let reference = []
                        let projects = checkController[key]["coordinates"].reduce((total, itm, ix) => {
                            let referenceFormat = JSON.parse(JSON.stringify(total[itm]))
                            referenceFormat["sfw"] = []
                            referenceFormat["projects"] = []
                            reference.push(referenceFormat)
                            if(ix < checkController[key]["coordinates"].length -1){
                                return total[itm]["sfw"]
                            }else{
                                return total[itm]["projects"]
                            }
                        }, frameworks)
                        projects = projects ? projects : []
                        if(projects.length > 0){
                            const projectTasks = projects.reduce((res, itm) => {
                                const rows = itm["tasks"].reduce((res, task) => {
                                    let datePartsDL = task.deadLine ?  task.deadLine.split("-") : null;
                                    const dl = datePartsDL ?  new Date(datePartsDL[0], datePartsDL[1] - 1, datePartsDL[2].substr(0,2)) : null;
                                    let datePartsSt = task.start ? task.start.split("-") : null;
                                    const st = datePartsSt ?  new Date(datePartsSt[0], datePartsSt[1] - 1, datePartsSt[2].substr(0,2)) : null;
                                    const rows = task["users"].map(uTask => {
                                        let status = null
                                        if(uTask["progress"] === 100){
                                            status = "Completed"
                                        }else if(st && st.getTime() > now){
                                            status = "Pending"
                                        }else if( dl && dl.getTime() < now){
                                            status = "Critical"
                                        }else{
                                            status = "In Process"
                                        }
                                        const currentTask = {
                                            key: `userTask-${uTask["idUserTask"]}`,
                                            reference,
                                            idUserTask: uTask["idUserTask"],
                                            idTask: task["idTask"],
                                            title: task["title"],
                                            description: task["description"],
                                            status,
                                            isHito: task["isHito"],
                                            idUser: uTask["user"] ? uTask["user"]["idUser"] : null,
                                            user: `${uTask["user"] ? uTask["user"]["name"] ? uTask["user"]["name"] : '-' : '-'} 
                                                    ${uTask["user"] ? uTask["user"]["lastName"] ? uTask["user"]["lastName"] : '-' : '-'}`,
                                            start: task["start"],
                                            deadLine: task["deadLine"],
                                            initialProgress: uTask["progress"],
                                            progress: uTask["progress"],
                                            files: task["files"],
                                            userFiles: uTask["files"],
                                            statusAudit: uTask["status"],
                                            notes: uTask["notes"],
                                            recurrentTasks: task["recurrent"] ? uTask["userTaskLogs"].map(itm => ({...itm, initialProgress: itm['progress']})) : []
                                        }
                                        if(currentTask["idUserTask"] === idtask){
                                            setTaskSelectedFromParams({...currentTask})
                                        }
                                        return currentTask
                                    })
                                    return [...res, ...rows]
                                }, [])
                                return [...res, ...rows]
                            }, [])
                            let hierarchy = []
                            let frameworkIxArr = []
                            let data = hierarchyFormat
    
                            // hierarchy = reference.reduceRight((carry, itm) => {
                            //     if(carry !== null){
                            //         itm["child"] = carry
                            //     }
                            //     itm["child"] = projectTasks
                            //     return itm
                            // }, null)
                            //console.log("REFERENCE", reference)
                            for (let x = 0; x < reference.length; x++) {
                                const ix = data.findIndex(itm => itm.idFramework === reference[x].idFramework)
                                frameworkIxArr.push(ix)
                                if(ix === -1){
                                    const newData = (reference.slice(x)).reduceRight((carry, itm) => {
                                        if(carry !== null){
                                            itm["sfw"].push(carry)
                                        }else{
                                            itm["tasks"] = projectTasks.map(itm => itm.idUserTask)
                                        }
                                        return itm
                                    }, null)
                                    data.push(newData)
                                    break
                                }
                                data = data[ix]["sfw"]
                            }
    
                            // console.log("HIERARCHYDATA", hierarchyFormat)
    
                            
                            // let hierarchy = reference.reduceRight((carry, itm) => {
                            //     if(carry !== null){
                            //         itm["child"] = carry
                            //     }
                            //     itm["child"] = projectTasks
                            //     return itm
                            // }, null)
                            tasks = [...tasks, ...projectTasks]
                        }
                    }
                }
            }
            setNewFilteredTasks(hierarchyFormat)
            setFilteredTasks(tasks)
        }catch(err){
            console.error(err)
        }finally{
            setLoadingTaskList(false)
        }
    }

    useEffect(() => {
        console.log("TASK SELECTED FROM PARAMS", taskSelectedFromParams)
        if(newFilteredTasks && filteredTasks  && !loadedFromParams && idtask && taskSelectedFromParams){
            console.log("TASK SELECTED FROM PARAMS INSIDE", taskSelectedFromParams)
            openDetails(taskSelectedFromParams)
            setLoadedFromParams(true)
        }
    }, [newFilteredTasks, filteredTasks, loadedFromParams, idtask, taskSelectedFromParams])

    const renderTasks = (tasks = []) => {
        const toggleDisplayedLogs = (idTask) => {
            const ix = displayedLogs.findIndex(itm => itm === idTask)
            if(ix === -1){
                setDisplayedLogs([...displayedLogs, idTask])
            }else{
                setDisplayedLogs(displayedLogs.filter(itm => itm !== idTask))
            }
        }
        return (
            <Table className="tableStyle">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('ACTIVITY_NAME')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('STATUS')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('ASSIGNED')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('START_DATE')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('DUE_DATE')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('PROGRESS')}</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        tasks.length > 0 && tasks.map(itm => 
                            <Fragment key={itm["key"]}>
                                <Table.Row>
                                    <Table.Cell>
                                        <div className="setFlex">
                                            <div className="hitoIcon marginRight">
                                                {
                                                    itm.isHito &&
                                                    <Popup
                                                        content={t('IS_A_MILESTONE')}
                                                        trigger={<HitoIcon />}
                                                        position="bottom left"
                                                    />
                                                }
                                            </div>
                                            {
                                                itm["title"].length > 40 ?
                                                    <Popup
                                                        content={itm["title"]}
                                                        trigger={<span>{itm["title"].substring(0, 40)} ...</span>}
                                                        flowing
                                                    />
                                                :
                                                    <span>{itm["title"]}</span>
                                            }
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>{t(formatToTranslate(itm["status"]))}</Table.Cell>
                                    <Table.Cell>{itm["user"]}</Table.Cell>
                                    <Table.Cell>
                                        {itm["isHito"] ? "-" : itm["start"]}
                                    </Table.Cell>
                                    <Table.Cell>{itm["deadLine"]}</Table.Cell>
                                    <Table.Cell>
                                        {
                                            itm["recurrentTasks"].length ? 
                                                `${Math.floor(itm["recurrentTasks"].reduce((res, rTask) => res + (rTask["progress"] ? rTask["progress"] : 0), 0)/itm["recurrentTasks"].length)}%`
                                            :
                                            idUser === itm["idUser"] ? 
                                            <Input 
                                                type="number" 
                                                value={itm["progress"]} 
                                                min={0}
                                                max={100}
                                                onChange={(e) => changeProgress(e, itm["idUserTask"])} 
                                                className="progressInput"
                                                />
                                            : 
                                            `${(itm["progress"] || itm["progress"] === 0 ? itm["progress"] : "-")}%`
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {
                                            idUser === itm["idUser"] &&
                                                <Button 
                                                    className="smallButton marginRight buttonTable buttonBlue" 
                                                    onClick={() => completeTask(itm["idUserTask"], itm["progress"] === 100)} 
                                                    primary>
                                                        {itm["progress"] < 100 ? t('COMPLETE_TASK') : t('RESTART')}
                                                </Button>
                                        }
                                        {
                                            itm["recurrentTasks"].length ? 
                                                <Button icon={
                                                    displayedLogs.includes(itm["idUserTask"]) ? 'triangle down' : 'triangle right'}
                                                    onClick={() => toggleDisplayedLogs(itm["idUserTask"])}
                                                />
                                            :
                                                <Button 
                                                    className={idUser === itm["idUser"] ? "smallButton buttonTable buttonBlue" : "marginButton smallButton buttonTable buttonBlue"}
                                                    onClick={() => openDetails(itm)} 
                                                    primary>
                                                        {t('TASK_DETAILS')}
                                                </Button>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                {
                                    itm["recurrentTasks"].length && displayedLogs.includes(itm["idUserTask"]) ?
                                        <Table.Row>
                                            <Table.Cell colSpan="7">
                                                {
                                                    <Table className="tableStyle">
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell>#</Table.HeaderCell>
                                                                <Table.HeaderCell>{t('START_DATE')}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t('DUE_DATE')}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t('PROGRESS')}</Table.HeaderCell>
                                                                <Table.HeaderCell></Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {
                                                                itm["recurrentTasks"].map(
                                                                    (taskLog, ix) => (
                                                                        <Table.Row key={`usertask-${itm["idUserTask"]}-log-${taskLog["idUserTaskLog"]}`}>
                                                                            <Table.Cell>
                                                                                {ix + 1}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {ix ? itm["recurrentTasks"][ix -1]["dueDate"] : itm["start"]}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {taskLog["dueDate"]}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {
                                                                                    idUser === itm["idUser"] ? 
                                                                                    <Input 
                                                                                        type="number" 
                                                                                        value={taskLog["progress"]} 
                                                                                        min={0}
                                                                                        max={100}
                                                                                        onChange={(e) => changeProgress(e, itm["idUserTask"], taskLog["idUserTaskLog"])} 
                                                                                        className="progressInput"
                                                                                        />
                                                                                : 
                                                                                    `${taskLog["progress"] ? taskLog["progress"] : 0}%`
                                                                                }
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {
                                                                                    idUser === itm["idUser"] &&
                                                                                        <Button 
                                                                                            className="smallButton marginRight buttonTable buttonBlue" 
                                                                                            onClick={() => completeLogTask(itm["idUserTask"], taskLog['idUserTaskLog'], taskLog["progress"] === 100)} 
                                                                                            primary>
                                                                                                {taskLog["progress"] < 100 ? t('COMPLETE_TASK') : t('RESTART')}
                                                                                        </Button>
                                                                                }
                                                                                <Button 
                                                                                    className={idUser === itm["idUser"] ? "smallButton buttonTable buttonBlue" : "marginButton smallButton buttonTable buttonBlue"}
                                                                                    onClick={() => openDetails(itm, taskLog)} 
                                                                                    primary>
                                                                                        {t('TASK_DETAILS')}
                                                                                </Button>
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                )
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                        :
                                        null
                                }
                            </Fragment>
                        )
                    }
                </Table.Body>
            </Table>
        )
    }

    const renderHierarchyTasks = (fwTree, level=0) => {
        const setConfirmCompletePracticeAux = async (itm, title, desc) => {
            const getTasks = async (itm) => {
                let tasks = []
                if(itm["tasks"]){
                    tasks = [...tasks, ...itm["tasks"]]
                }
                if(itm["sfw"]){
                    for (const fw of itm["sfw"]) {
                        tasks = [...tasks, ...(await getTasks(fw))]
                    }
                }
                return tasks
            }
            setConfirmCompletePractice({
                titleConfirmPractice: title,
                descriptionConfirmPractice: desc,
                openPracticeConfirm: true,
                idPractice: itm.idFramework,
                practiceTasks: await getTasks(itm)
            })
        }

        const disableRecursiveCheck = (itm) => {
            if(itm["tasks"]){
                for (const tsk of itm["tasks"]) {
                    const notComplete = filteredTasks.findIndex(ft => {
                        return tsk === ft["idUserTask"] && ft["progress"] < 100
                    })
                    if(notComplete !== -1){
                        return false
                    }
                }
            }
            if(itm["sfw"]){
                for (const fw of itm["sfw"]) {
                    const completed = disableRecursiveCheck(fw)
                    if(!completed){
                        return false
                    }
                }
            }
            return true
        }

        return (
            <Segment.Group>
                {
                    fwTree.map((itm, ix) => 
                        {
                            const currentTasks = itm.tasks && itm.tasks.length >0 ? filteredTasks.filter(ft => itm.tasks.indexOf(ft.idUserTask) !== -1) : []
                            return(
                                <Fragment key={`framework-${ix}`}>
                                    <Segment>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column 
                                                    widescreen={level > 0 ? 10 : 16} 
                                                    computer={level > 0 ? 10 : 16} 
                                                    tablet={level > 0 ? 12 : 16} 
                                                    mobile={16}>
                                                    {itm.title}
                                                </Grid.Column>
                                                {
                                                    level === 1 && itm.managers.findIndex(mng => mng.idUser === idUser) !== -1 &&
                                                        <Grid.Column widescreen={6} computer={6} tablet={4} mobile={16}>
                                                            <Button 
                                                                floated="right"
                                                                className="buttonBlue"
                                                                onClick={() => setConfirmCompletePracticeAux(itm, t("TITLE_CONFIRM_PROCESS_AREA"), t("DESCRIPTION_CONFIRM_PROCESS_AREA"))}
                                                                disabled={disableRecursiveCheck(itm)}>
                                                                    {t('COMPLETE_PROCESS_AREA')}
                                                            </Button>
                                                            <Button 
                                                                floated="right"
                                                                className="buttonBlue"
                                                                onClick={() => setModalPractice({open: true, idPractice: itm.idFramework})}>
                                                                    {t('ADD_PROCESS_AREA_EVIDENCE')}
                                                            </Button>
                                                        </Grid.Column>
                                                }
                                                {
                                                    level === 2 && itm.managers.findIndex(mng => mng.idUser === idUser) !== -1 && 
                                                        <Grid.Column widescreen={6} computer={6} tablet={4} mobile={16}>
                                                            <Button 
                                                                floated="right"
                                                                className="buttonBlue"
                                                                // onClick={() => completePracticeFunc(itm.idFramework, itm.tasks ? itm.tasks : [])}>
                                                                onClick={() => setConfirmCompletePracticeAux(itm, t("TITLE_CONFIRM_PRACTICE"), t("DESCRIPTION_CONFIRM_PRACTICE"))}
                                                                disabled={disableRecursiveCheck(itm)}>
                                                                    {t('COMPLETE_PRACTICE')}
                                                            </Button>
                                                            <Button 
                                                                floated="right"
                                                                className="buttonBlue"
                                                                onClick={() => setModalPractice({open: true, idPractice: itm.idFramework})}>
                                                                    {t('ADD_PRACTICE_EVIDENCE')}
                                                            </Button>
                                                        </Grid.Column>
                                                }
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    {
                                        itm.sfw && itm.sfw.length > 0 ? 
                                            renderHierarchyTasks(itm.sfw, level+1)
                                            :
                                            null
                                    }
                                    {
                                        itm.tasks && itm.tasks.length > 0 &&
                                            <Segment>
                                                {
                                                    renderTasks(currentTasks)
                                                }
                                            </Segment>
                                    }
                                </Fragment>
                            )
                        }
                    )
                }
            </Segment.Group>
        )
    }

    const renderDetailHeader = () => {
        if (modalData.length > openModal.length){
            const diff = modalData.length - openModal.length
            let newState = JSON.parse(JSON.stringify(openModal))
            for (let i = 0; i < diff; i++) {
                newState.append(false)
            }
        }
        return (
            <Grid.Column width={16}>
                    {
                        modalData.reference.map((itm, ix) => 
                            <Grid centered key={`key-details-${ix}`}>
                                <Grid.Column width={6}>
                                    <div className="detailsContainer">
                                        <div><Button
                                            onClick={() => setOpenModalElement(ix, true)}
                                            className='marginLeft erasePadding verticalAlignMiddle buttonBlue' 
                                            size='mini' 
                                            primary>
                                                {t('DETAILS')}
                                        </Button>
                                        <DetailModal open={openModal[ix]} setOpen={(state) => setOpenModalElement(ix, state)}>
                                            <EditFramework
                                                id={itm.idFramework}
                                                okButton={() => setOpenModalElement(ix, false)}
                                                type={ix === 0 ? "framework" : ix === 1 ? "process_area" : "practice"}
                                                editable={false}
                                            />
                                        </DetailModal>
                                        </div>
                                        <div className="setFlex1">
                                            <h4 className="subtitle">
                                                {ix === 0 ? t('FRAMEWORK') : ix === 1 ? t('PROCESS_AREA') : t('PRACTICE')}
                                            </h4>
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <h4>
                                        {itm.title}
                                    </h4>
                                </Grid.Column>
                                <Grid.Column width={16} className="noVerticalPadding">
                                    <Divider/>
                                </Grid.Column>
                            </Grid>
                        )
                    }
            </Grid.Column>
        )
    }


    useEffect(filterTasks, [checkController, frameworks])

    const onCheckBoxChange = async (e, key) => {
        let newChecks = JSON.parse(JSON.stringify(checkController))
        const childKeys = await checkCascade([key])


        for (const k of childKeys) {
            newChecks[k]["checked"] = !checkController[key]["checked"]
        }

        setCheckController(newChecks)
    }

    const setKeyController = async () => {
        const result = await generateKeyController(frameworks)
        setCheckController(result)
    }

    

    const generateKeyController = async (data, level=0, parents=[], coordinates=[]) => {
        let ck = {}
        let ix = 0
        for (const itm of data) {
            const key = `fw-${itm["idFramework"]}-lv-${level}-ix-${ix}`
            const hasChilds = itm.sfw && itm.sfw.length > 0
            const newCoordinates = [...coordinates, ix]
            ck[key] = {
                parents,
                checked: true,
                childs: hasChilds ? itm.sfw.map((itm, ix) => `fw-${itm["idFramework"]}-lv-${level + 1}-ix-${ix}`) : [],
                coordinates: newCoordinates
            }
            if(hasChilds){
                const subFrameworks = await generateKeyController(itm.sfw, level + 1, [...parents, key], newCoordinates)
                ck = {...ck, ...subFrameworks}
            }
            ix++
        }
        return ck
    }


    const mapFramework = (data = [], level =0, index=0) => {
        let content = data.map( (itm, ix) => {
            const lastLevel = !itm.sfw || itm.sfw.length
            const key = `fw-${itm["idFramework"]}-lv-${level}-ix-${ix}`
            return {
                key,
                // title: {
                //     "content": <Checkbox label={`${itm["title"]}`}/>
                // },
                title: {
                    "content": <span>
                        {/* <Checkbox onChange={onCheckedBox}/><span>{`${itm["title"]}`}</span> */}
                        <input 
                            type={"checkbox"} 
                            name={key}
                            checked={checkController[key]["checked"]} 
                            onClick={onCheckBoxClick}
                            onChange={(e) => onCheckBoxChange(e, key)}/> <span>{`${itm["title"]}`}</span>
                    </span>
                },
                content: itm.sfw && itm.sfw.length > 0 ? mapFramework(itm.sfw, level + 1, ix) : null
            }
        })
        return {
            key: `Framework-accordeon-${level}-${index}`,
            content: <Accordion.Accordion exclusive={false} panels={content} style={{paddingLeft: `${10 * level + 1}px`}}/>
        }
    }


    const renderMenu = (data, level=0, coordinates=[]) => {
        return level ? 
        (
            <List.List><Button icon color="red">
            <Icon name="times"/>
        </Button>
                {/* <List.Item key={ky}>
                    <List.Icon name="dropdown"/>
                    <List.Content>
                        <Checkbox onChange={onChangeFilter} name={ky}/>
                        {itm.title ? itm.title : itm.name ? itm.name : "??"}
                    </List.Content>
                </List.Item> */}
                {data.map((itm, ix)=> {
                    const ky = [...coordinates, ix].join('-')//`fw-${ix}-lvl-${level}-id-${itm.idFramework ? itm.idFramework : itm.idProject}`
                    return (
                        <List.Item key={ky}>
                            <List.Icon name="dropdown"/>
                            <List.Content>
                                <Checkbox name={ky}/>
                                {itm.title ? itm.title : itm.name ? itm.name : "??"}<h1>KEK</h1>
                                {itm.sfw  ? renderMenu(itm.sfw, level + 1, [...coordinates, ix]) : null}
                            </List.Content>
                        </List.Item>
                    )
                }
                )}
            </List.List>
        )
        :
        (
            <List>
                {data.map((itm, ix)=> {
                    const ky = [...coordinates, ix].join('-')
                    return(
                        <List.Item key={ky}>
                            <List.Icon name="dropdown"/>
                            <List.Content>
                                <Checkbox name={ky}/>
                                {itm.title ? itm.title : itm.name ? itm.name : "??"}<h1>KEK</h1>
                                {itm.sfw ? renderMenu(itm.sfw, level + 1, [...coordinates, ix]) : null}
                            </List.Content>
                        </List.Item>
                    )
                })
                }
                
            </List>
        )
    }

    const changeProgress = ({ target }, id, idLog = null) => {
        const ix = filteredTasks.findIndex(itm => itm["idUserTask"] === id)
        const newData = JSON.parse(JSON.stringify(filteredTasks))
        let newValue = Number(target.value)
        if(isNaN(newValue)){
            return
        }
        if(idLog){
            const logix = newData[ix]["recurrentTasks"].findIndex(itm => itm['idUserTaskLog'] === idLog)
            newData[ix]["recurrentTasks"][logix]["progress"] = newValue
        }else{
            newData[ix]["progress"] = newValue
        }
        setFilteredTasks(newData)
    }

    const completeLogTask = (id, idLog, isReset=false) => {
        let edited = structuredClone(filteredTasks)
        let ix = edited.findIndex(itm => itm["idUser"] === idUser && itm["idUserTask"] === id)
        if(ix === -1){
            return
        }
        let ixLog = edited[ix]['recurrentTasks'].findIndex(itm => itm["idUserTaskLog"] === idLog)
        if(ixLog === -1){
            return
        }
        const progress = isReset ? 0 : 100
        updateTaskLogProgress(idLog, progress).then(res => {
            edited[ix]['recurrentTasks'][ixLog]["progress"] = progress
            edited[ix]['recurrentTasks'][ixLog]["initialProgress"] = progress
            setFilteredTasks(edited)
            Store.addNotification({
                ...renderNotification("success", t('PROGRESS_UPDATE'), t('PROGRESS_UPDATE_MSG'))
            })
        }).catch(err => {
            Store.addNotification({
                ...renderNotification("error", t('PROGRESS_UPDATE_ERR'), t('PROGRESS_UPDATE_ERR_MSG'))
            })
            requestFrameworks()
        })
    }

    const completeTask = (id, isReset=false) => {
        let edited = structuredClone(filteredTasks)
        let ix = edited.findIndex(itm => itm["idUser"] === idUser && itm["idUserTask"] === id)
        const progress = isReset ? 0 : 100
        if(ix === -1){
            return
        }
        updateTaskProgress(id, progress).then(res => {
            edited[ix]["progress"] = progress
            edited[ix]["initialProgress"] = progress
            setFilteredTasks(edited)
            Store.addNotification({
                ...renderNotification("success", t('PROGRESS_UPDATE'), t('PROGRESS_UPDATE_MSG'))
            })
        }).catch(err => {
            Store.addNotification({
                ...renderNotification("error", t('PROGRESS_UPDATE_ERR'), t('PROGRESS_UPDATE_ERR_MSG'))
            })
            requestFrameworks()
        })
    }

    const saveProgress = () => {
        const edited = filteredTasks.filter(itm => 
            itm["idUser"] === idUser && itm["progress"] !== itm["initialProgress"])
        if(edited.length > 0){
            const promises = edited.map(itm => updateTaskProgress(itm["idUserTask"], itm["progress"]))
            Promise.all(promises).then(response => {
                Store.addNotification({
                    ...renderNotification("success", t('PROGRESS_UPDATE'), t('PROGRESS_UPDATE_MSG'))
                })
                const update = filteredTasks.map(itm => {
                    if(itm["idUser"] === idUser){
                        itm["initialProgress"] = itm["progress"]
                    }
                    return itm
                })
                setFilteredTasks(update)
            }).catch(err => {
                Store.addNotification({
                    ...renderNotification("error", t('PROGRESS_UPDATE_ERR'), t('PROGRESS_UPDATE_ERR_MSG'))
                })
                requestFrameworks()
            })
        }
    }

    const openDetails = (data, logData) => {
        const md = {
            reference: data["reference"] ? data["reference"] : [],
            idUserTask: data["idUserTask"] ? data["idUserTask"] : null,
            idTask: data["idTask"] ? data["idTask"] : null,
            idUser: data["idUser"] ? data["idUser"] : null,
            title: data["title"] ? data["title"] : null,
            description: data["description"] ? data["description"] : null,
            taskFiles: data["files"],
            userTaskFiles: data["userFiles"],
            statusAudit: data["statusAudit"],
            notes: data["notes"],
            logData
        }
        setModalData(md)
        setModalOpen(true)
    }

    useEffect(() => {
        if(modalData && modalData.statusAudit && modalData.statusAudit.idTaskStatus){
            setSelectedStatus(modalData.statusAudit.idTaskStatus)
        }
    }, [modalData])

    const deleteFile = (id) => {
        deleteUserTaskFile(id).then(res => {
            Store.addNotification(
                renderNotification("success", t('DELETE_TASK_FILE'), t('DELETE_TASK_FILE_MSG'))
            )
            requestUserTaskfiles()
            setConfirmated(false)
        }).catch(err => {
            Store.addNotification(
                renderNotification("error", t('DELETE_TASK_FILE'), t('DELETE_TASK_FILE_ERR_MSG'))
            )
            requestUserTaskfiles()
            setConfirmated(false)
        })
    }

    const requestUserTaskfiles = () => {
        if(modalData["idTask"]){
            getUserTaskFiles(modalData["idTask"]).then(res => {
                setModalData({
                    ...modalData,
                    userTaskFiles: res.data ? res.data : []
                })
            }).catch(err => 
                Store.addNotification(
                    renderNotification("error", t('TASK_FILES_ERR'), t('TASK_FILES_ERR_MSG'))
                )
            )
        }
    }

    useEffect(() => {
        if(fileToDelete){
            setConfirmated(true)
        }
    }, [fileToDelete])

    useEffect(() => {
        if(confirmated === false){
            setFileToDelete(null)
        }
    }, [confirmated])

    const setOpenModalElement = (ix, state) => {
        let newState = JSON.parse(JSON.stringify(openModal))
        newState[ix] = state
        setOpenModal(newState)
    }

    const renderFileList = (files, deleteFunction) => {
        return (
            <div className="FMClass">
                <SXFileList
                    files={files}
                    deleteFunction={deleteFunction && modalData && modalData["idUser"] == idUser ? deleteFunction : null}
                />
            </div>
        )
    }
    
    const renderTaskDetails = () => {
        return (
            <Fragment>
                {
                    renderDetailHeader()
                }
                <Grid.Column width={12}>
                    <h3 className="centerText">{t('TASK')}: {modalData["title"]}</h3>
                </Grid.Column>
                <Grid.Column width={12}>
                    <h4 className="centerText">{t('DESCRIPTION')}</h4>
                    {/* <p className="centerText">{modalData["description"]}</p> */}
                    <ShowMore  text={modalData["description"]}/>
                </Grid.Column>
                <Grid.Column width={16} className="noVerticalPadding">
                    <Divider/>
                </Grid.Column>
                {
                    modalData["statusAudit"] && modalData["statusAudit"]["name"] === "Rejected" && 
                    <Message negative>
                        <Message.Header>{t('TASK_REJECTED')}</Message.Header>
                        <p>{t('TASK_REJECTED_DESC')}</p>
                        {
                            modalData["notes"] &&
                                <p>{t('NOTES')}: {modalData["notes"]}</p>
                        }
                    </Message>
                }
            </Fragment>
        )
    }

    const renderLoadWorkingFiles = (id, params) => {
        return(
            // TYPE OF RELATIONS ALLOWED AT THE MOMENT
            // FrameworkTextEvidence: 1
            // UserTaskTextEvidence: 4
            <EvidenceSelection
                ref={selectorRef}
                id={id}
                type={modalData["logData"] ? 5 : 4}
                searchParams={params}
            />
        )
    }

    const renderTabPanes = () => {
        let panes = [
            {
                menuItem: t('TASK_DETAILS'),
                render: () => renderTaskDetails()
            },
            {
                menuItem: t('REFERENCE_FILES'),
                // render: () => <TaskFiles files={modalData["taskFiles"]} attachMsg={false}/>
                render: () => renderFileList(attachmentList)
            },
            {
                // menuItem: t('WORKING_FILES'),
                menuItem: t('EVIDENCE'),
                //render: () => renderFileList(workingFileList, deleteWorkingFileRelation)
                render: () => <Evidence 
                                id={modalData["logData"] ? modalData["logData"]["idUserTaskLog"] : modalData["idUserTask"]} 
                                // TYPE OF RELATIONS ALLOWED AT THE MOMENT
                                // FrameworkTextEvidence: 1
                                // UserTaskTextEvidence: 4
                                // UserTaskLogEvidence: 5
                                type={modalData["logData"] ? 5 : 4}
                                className="evidenceTabFlex"
                                />
            }
        ]

        if(modalData && idUser && modalData["idUser"] === idUser){
            let idElement = 0
            let params = {}
            if(modalData["logData"]){
                idElement = modalData["logData"]["idUserTaskLog"]
                params = {
                    checkUserTaskLogRelation: idElement
                }
            }else{
                idElement = modalData["idUserTask"]
                params = {
                    checkUserTaskRelation: idElement
                }
            }
            panes.push({
                menuItem: t('UPDATE_EVIDENCE'),
                render: () => renderLoadWorkingFiles(idElement, params)
            })
        }

        if(rol.findIndex(itm => itm === "Auditor") !== -1){
            panes.push({
                menuItem: 'Review Task',
                render: renderAuditorPane
            })
        }

        return panes;
    }

    const reviewTask = (reject = false) => {
        if(!reject){
            setReviewTask(modalData["idUserTask"], {status: selectedStatus}).then(res => {
                Store.addNotification(
                    renderNotification("success", t('STATUS_UPDATED'), t('STATUS_UPDATED_MSG'))
                )
                setModalOpen(false)
                requestFrameworks()
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('STATUS_UPDATED_ERR'), t('STATUS_UPDATED_ERR_MSG'))
                )
            })
        }else{
            if(!notes || notes.length < 3 || (!progress && progress != 0)){
                Store.addNotification(
                    renderNotification("error", t('DATA_INCOMPLETE'), t('DATA_INCOMPLETE_MSG'))
                )
            }else{
                const data = {
                    notes,
                    progress,
                    status: selectedStatus
                }
                setReviewTask(modalData["idUserTask"], data).then(res => {
                    Store.addNotification(
                        renderNotification("success", t('STATUS_UPDATED'), t('STATUS_UPDATED_MSG'))
                    )
                    setRvTask(defaultReject)
                    setModalOpen(false)
                    requestFrameworks()
                }).catch(err => {
                    Store.addNotification(
                        renderNotification("error", t('STATUS_UPDATED_ERR'), t('STATUS_UPDATED_ERR_MSG'))
                    )
                    setRvTask(defaultReject)
                })
            }
        }
    }

    const onChangeReview = ({ target }, name) => {
        let data = {
            notes,
            progress
        }
        data = {...data, [name]: target.value}
        setRvTask(data)
    }

    const renderAuditorPane = () => {
        return (
            <Tab.Pane attached={false}>
                <Grid>
                    <Grid.Column width={16}>
                        <h3 className="centerText">Reject Task</h3>
                        <Form>
                            <Form.Dropdown options={statusOptions} value={selectedStatus} onChange={(e, {value}) => setSelectedStatus(value)} selection/>
                            {
                                selectedStatus && statusOptions.findIndex(itm => itm.value === selectedStatus && itm.text === "Rejected") !== -1 ?
                                <Fragment>
                                    <Form.TextArea value={notes} onChange={(e) => onChangeReview(e, "notes")} label={"Notes about rejection"}/>
                                    <Form.Input value={progress} onChange={(e) => onChangeReview(e, "progress")} type="number" label="Modify progress" min={0} max={100}/>
                                    <Form.Button color="red" floated="right" onClick={() => reviewTask(true)}>Reject task</Form.Button>
                                </Fragment>
                                : 
                                <Button 
                                    className="buttonBlue"
                                    floated="right" 
                                    onClick={() => reviewTask()}
                                    >
                                        {t('SAVE')}
                                </Button>
                            }
                        </Form>
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        )
    }

    const uploadTaskFiles = (id, files = [], progress=0) => {
        if(files.length > 0){
            const singleProgress = Math.ceil(100/myFiles.length)
            const formData = new FormData()
            const file = files.pop()
            formData.append("userTaskFile", file)
            sendUserTaskAttachment(id, formData).then(res => {
                const newProgress = progress + singleProgress
                setLoading(newProgress)
                if(files.length > 0)
                    uploadTaskFiles(id, files, newProgress)
                else
                    Store.addNotification(
                        renderNotification("success", t('FILE_UPLOAD'), t('FILE_UPLOAD_MSG'))
                    )
                    requestUserTaskfiles()
            }).catch(err => {
                Store.addNotification(
                    renderNotification("error", t('FILE_UPLOAD'), `${t('FILE_UPLOAD_ERR_MSG')} ${file.path}`)
                )
                requestUserTaskfiles()
            })
        }
    }

    return(
        <div className="report">
            <Grid className="report">
                <Grid.Column width={16} className="setFlexColumn">
                    <div className="setFlex setFlex1">
                        <div className={`filterSection ${showFilter ? "" : "filterSectionDeactivated"}`}>
                            {
                                frameworks && frameworks.length > 0 && Object.keys(checkController).length > 0 &&
                                    <div className="listContainer">
                                        <span className="marginTop">
                                            <input 
                                                type={"checkbox"} 
                                                name={"all-frameworks"}
                                                checked={allFrameworksSelected} 
                                                onChange={({target}) => setAllFrameworksSelected(target.checked)}/>
                                                <span> Frameworks</span>
                                        </span>
                                        <Accordion exclusive={false} panels={[mapFramework(frameworks)]}/>
                                        <Grid className="filterSectionButtons">
                                            <Grid.Column className="marginTop">
                                                <Button 
                                                    className="marginRight buttonDarkGray" 
                                                    floated="right"
                                                    onClick={() => setShowFilter(false)}
                                                    >
                                                        {t('CLOSE')}
                                                    </Button>
                                            </Grid.Column>
                                        </Grid>
                                    </div>
                            }
                        </div>
                        <div className="marginTop filterSectionButtons">
                            <Button 
                                icon="filter" 
                                className="marginLeft buttonBlue" 
                                onClick={() => setShowFilter(true)}
                                primary/>
                        </div>
                        <div className="setFlex1">
                            <Grid className={"tableGrid"}>
                                <Grid.Column width={16} className={"tableColumn"}>
                                    {
                                        loadingTaskList || newFilteredTasks === null || filteredTasks === null ?
                                            <div style={{padding: "10px"}}>
                                                <Loader active inline='centered' size='massive'>Loading...</Loader>
                                            </div>
                                        :
                                            newFilteredTasks.length > 0  && filteredTasks.length > 0 ?
                                                renderHierarchyTasks(newFilteredTasks)
                                            :
                                                <h2>{t('NO_ACTIVITIES_DESC')}</h2>
                                    }
                                </Grid.Column>
                            </Grid>
                            <Grid.Column width={16}>
                                <Button 
                                    className="floatingUpdate blueButton"
                                    disabled={filteredTasks === null || filteredTasks.findIndex(itm => itm["idUser"] === idUser && itm["progress"] !== itm["initialProgress"]) === -1}
                                    onClick={() => saveProgress()}
                                >
                                    <Icon name="save"/> {t('UPDATE')}
                                </Button>
                                {/* {downloadDocument()} */}
                            </Grid.Column>
                        </div>
                    </div>
                </Grid.Column>
            </Grid>
            <ModalPracticeEvidence
                open={modalPractice["open"]}
                setOpen={(isOpen) => setModalPractice(isOpen ? {...modalPractice, open: isOpen} : defaultModalPractice)}
                idPractice={modalPractice["idPractice"]}
            />
            <ConfirmModal
                open={openPracticeConfirm}
                setOpen={(isOpen) => isOpen ? 
                    setConfirmCompletePractice({...openPracticeConfirm, openPracticeConfirm: true}) : 
                    setConfirmCompletePractice(defaultConfirmPractice)}
                type="complete"
                title={titleConfirmPractice}
                description={descriptionConfirmPractice}
                callback={() => completePracticeFunc(idPractice, practiceTasks)}
            />
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={16}>
                            {
                                modalData && Object.keys(modalData).length > 0 &&
                                    <Grid centered>

                                        <Grid.Column width={12}>
                                            <Tab 
                                                menu={{secondary: true, pointing: true}} 
                                                panes={renderTabPanes()}
                                                activeIndex={tabIndex}
                                                onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={12} className={"centerText"}>
                                            {
                                                tabIndex === 3 &&
                                                <Button
                                                    className="buttonBlue"
                                                    floated="right"
                                                    onClick={() => selectorRef.current.attach() }>
                                                    {t('ATTACH_EVIDENCE')}
                                                </Button>
                                            }
                                            <Button 
                                                floated="right" 
                                                className="buttonDarkGray" 
                                                onClick={() => setModalOpen(false)}>
                                                    {t('CLOSE')}
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                            }
                        </Grid.Column>
                    </Grid>
                    <Confirm
                        open={confirmated}
                        onCancel={() => setConfirmated(false)}
                        onConfirm={() => deleteFile(fileToDelete)}
                    />
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default Report;