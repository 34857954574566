import axios from "axios"
import { SITE_URL } from "../../../util/util"

const axiosForDownloads = axios.create()

axiosForDownloads.interceptors.request.use(
    config => {
        const acc = localStorage.getItem('AT')
        const idc = localStorage.getItem('c-id')
        config.headers = {}
        config.headers["X-API-KEY"] = acc
        config.headers["connection-id"] = idc
        config.responseType = 'blob'
        return config
    },
    error => Promise.reject(error)
)

axiosForDownloads.interceptors.response.use(
    response => response,
    error => {
        if(error.response){
            if(error.response.status === 403){
                if(localStorage.getItem('AT'))
                        localStorage.removeItem('AT')
                        window.location.href = SITE_URL
            }
        }
        return Promise.reject(error)
    }
)

export default axiosForDownloads